import {
  getLookup,
  updateSubQueriesWithLookupIds,
  getLookupDefinition,
  isNull,
  isEmpty,
  getEntityFactory,
  getLookupFactory,
  replaceAndDecode,
  buildLookup,
  formatFTSText,
  renderFieldValue,
  fieldAccessor,
} from "../util/common";
import * as cloneDeep from "lodash/cloneDeep";
import { gql } from "@apollo/client";
import definitionHelper from "../util/definitions.js";
import {
  filterLookup,
  groupAndConvertLookup,
  getGlobalFilter,
} from "../util/common";
import Toast from "../components/Toast";
import * as Nominatim from "nominatim-browser";

export function nominatimSearch(input, callback) {
  Nominatim.geocode({
    viewbox: "113.338953078, -43.6345972634, 153.569469029, -10.6681857235",
    bounded: 1,
    q: input,
  })
    .then((results) => {
      let formattedResults = [];
      if (!isEmpty(results)) {
        results.forEach((item) => {
          let rowName = item?.display_name;
          if (rowName) {
            if (rowName.endsWith(", Australia"))
              rowName = rowName.substring(0, rowName.length - 11);
            if (
              isNull(formattedResults.find((iter) => iter?.name === rowName))
            ) {
              let row = {
                id: JSON.stringify({
                  lat: item.lat,
                  lon: item.lon,
                  address: item?.display_name,
                }),
                name: rowName,
              };

              formattedResults.push(row);
            }
          }
        });

        callback(formattedResults);
      } else {
        callback(formattedResults);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function convertLookupItems(
  layoutGraph,
  allEntityFactories,
  lookupCollection,
  lookupPrimaryFields,
  startWithId,
  includeAdditional
) {
  if (isNull(lookupCollection)) {
    lookupCollection = [];
  }
  return buildLookup(
    { layoutGraph: layoutGraph, allEntityFactories: allEntityFactories },
    lookupCollection,
    lookupPrimaryFields,
    startWithId,
    includeAdditional
  );
}

function buildJSONLookup(
  definitionField,
  layoutGraph,
  allEntityFactories,
  primaryCollection
) {
  let options = [];
  if (primaryCollection && primaryCollection.length > 0) {
    for (let i = 0; i < primaryCollection.length; i++) {
      let row = primaryCollection[i];
      let newItem = {};
      newItem.id = -i - 1;
      newItem.name = "";

      let definition = definitionField.definition;
      let fields = definitionHelper.findAllDisplayFields(definition.fields);
      if (fields) {
        for (let i = 0; i < fields.length; i++) {
          let field = fields[i];
          if (!field.hidden) {
            if (newItem.name.length > 0) {
              newItem.name += " - ";
            }
            if (isNull(row[field.fieldName])) {
              newItem.name += "<Unknown>";
            } else {
              //fieldAccessor
              let props = {};
              props.layoutGraph = layoutGraph;
              props.allEntityFactories = allEntityFactories;
              newItem.name += renderFieldValue(
                props,
                field,
                fieldAccessor(row, field),
                null
              );
            }
          }
        }
      }

      options.push(newItem);
    }
  }
  return options;
}

export function getLabel(
  definitionfield,
  isTouched,
  isSubmitted,
  error,
  required = false
) {
  const title = definitionfield?.title ? definitionfield.title : "";
  const label = definitionfield?.title
    ? definitionfield.title
    : definitionfield.fieldName;
  const errorMsg = (isTouched || isSubmitted) && error ? error.message : null;
  const finalLabel = isEmpty(errorMsg)
    ? label + (required ? " *" : "")
    : errorMsg + (required ? " *" : "");
  return {
    finalLabel,
    errorMsg,
    title,
    displayTitle: !isEmpty(title) || !isEmpty(errorMsg),
  };
}

export function removeIdFromCollection(primaryCollection) {
  let valueArray = [];
  if (primaryCollection && primaryCollection.length > 0) {
    for (let i = 0; i < primaryCollection.length; i++) {
      let row = primaryCollection[i];
      let newItem = {};
      for (let key in row) {
        if (key !== "id") {
          if (Object.prototype.hasOwnProperty.call(row, key)) {
            newItem[key] = row[key];
          }
        }
      }
      valueArray.push(newItem);
    }
  }
  return valueArray;
}

export function calcJSONSelection(
  id,
  primaryCollection,
  selection,
  selectFieldName,
  multiSelect
) {
  if (isNull(selection)) selection = [];
  let newIds = [...selection];
  if (id === 0) {
    if (multiSelect) {
      newIds = [];
      if (selection.length === 0) {
        for (let i = primaryCollection.length - 1; i >= 0; i--) {
          newIds.push(-i - 1);
        }
      }
    } else {
      if (newIds.length > 0) {
        newIds = [];
      } else {
        if (primaryCollection && primaryCollection.length > 0) {
          newIds = [-1];
        }
      }
    }
  } else {
    let pos = newIds.indexOf(id);
    if (!multiSelect) {
      if (pos > -1) {
        newIds = [];
      } else {
        newIds = [id];
      }
    } else {
      if (pos > -1) {
        newIds.splice(pos, 1);
      } else {
        newIds.push(id);
      }
    }
  }

  let selectedItem = null;
  if (selectFieldName && primaryCollection && primaryCollection.length > 0) {
    for (let i = primaryCollection.length - 1; i >= 0; i--) {
      let row = primaryCollection[i];
      if (newIds.indexOf(-i - 1) >= 0) {
        row[selectFieldName] = true;
        if (newIds.length === 1) {
          selectedItem = row;
        }
      } else {
        row[selectFieldName] = false;
      }
    }
  }
  //let valueArray = removeIdFromCollection(primaryCollection);
  return {
    primaryCollection,
    selection: newIds,
    selectedItem,
    formValue: JSON.stringify(primaryCollection),
  };
}

export function convertLookupItem(
  definitionField,
  layoutGraph,
  allEntityFactories,
  lookupItem,
  decodedValue,
  lookupPrimaryFields,
  startWithId,
  includeAdditional,
  selectFieldName
) {
  if (isNull(lookupItem)) {
    return null;
  }
  let lookupCollection = [];
  lookupCollection.push(lookupItem);
  let convertedItems = [];
  if (definitionField.type === "json") {
    convertedItems = buildJSONLookup(
      definitionField,
      layoutGraph,
      allEntityFactories,
      lookupCollection
    );
    if (!isEmpty(decodedValue) && !isEmpty(convertedItems)) {
      let convIndex = 0;
      for (let i = 0; i < decodedValue.length; i++) {
        let item = decodedValue[i];
        if (item[selectFieldName]) {
          convertedItems[convIndex].id = -i - 1;
          convIndex++;
          if (convIndex >= convertedItems.length) {
            break;
          }
        }
      }
    }
  } else {
    convertedItems = buildLookup(
      { layoutGraph: layoutGraph, allEntityFactories: allEntityFactories },
      lookupCollection,
      lookupPrimaryFields,
      startWithId,
      includeAdditional
    );
  }
  if (isEmpty(convertedItems)) {
    return lookupItem;
  } else {
    return convertedItems[0];
  }
}

export function getRangeSpec(inputType, field) {
  let spec = {};
  if (
    inputType === "number" ||
    inputType === "range" ||
    inputType === "radio" ||
    inputType === "inlineradio" ||
    inputType === "select"
  ) {
    if (field.multipleOf) {
      spec.step = field.multipleOf;
    }
    if (field.minimum || field.minimum === 0) {
      spec.min = field.minimum;
    }
    if (field.maximum || field.maximum === 0) {
      spec.max = field.maximum;
    }
  }
  return spec;
}

export function getFixedOptions(
  definitionField,
  layoutGraph,
  allEntityFactories,
  values,
  lookupFilter,
  decodedValue,
  rangeSpec,
  options,
  allowGrouping
) {
  let groupFieldName =
    allowGrouping && definitionField && definitionField.groupBy
      ? definitionField.groupBy
      : undefined;
  let fixedOptions = undefined;
  if (!isNull(options)) {
    fixedOptions = options;
  } else if (definitionField.type === "json") {
    return buildJSONLookup(
      definitionField,
      layoutGraph,
      allEntityFactories,
      decodedValue
    );
  } else if (!isNull(definitionField.lookup)) {
    let theProps = {
      layoutGraph: layoutGraph,
      allEntityFactories: allEntityFactories,
    };
    if (!isNull(lookupFilter)) {
      let invalidFilter = false;
      for (let key in lookupFilter) {
        if (Object.prototype.hasOwnProperty.call(lookupFilter, key)) {
          if (
            lookupFilter[key].val &&
            lookupFilter[key].val.toString().startsWith("%%")
          ) {
            invalidFilter = true;
            break;
          }
        }
      }
      if (invalidFilter) {
        fixedOptions = [];
      } else {
        fixedOptions = getLookup(
          theProps,
          definitionField,
          true,
          null,
          true,
          false,
          values,
          false,
          true
        );
        let field = { ...definitionField };
        field.lookupFilter = lookupFilter;
        fixedOptions = filterLookup(theProps, fixedOptions, field, values);
        fixedOptions = groupAndConvertLookup(
          theProps,
          field,
          fixedOptions,
          groupFieldName
        );
        //let definition = getLookupDefinition(theProps, field);
        //fixedOptions = convertLookup(theProps, definition, fixedOptions, field, null, true);
      }
    } else {
      fixedOptions = getLookup(
        theProps,
        definitionField,
        true,
        null,
        true,
        false,
        values,
        false,
        true
      );
      fixedOptions = groupAndConvertLookup(
        theProps,
        definitionField,
        fixedOptions,
        groupFieldName
      );
      //let definition = getLookupDefinition(theProps, definitionField);
      //fixedOptions = convertLookup(theProps, definition, fixedOptions, definitionField, null, true);
    }
  } else if (
    !isNull(rangeSpec.min) &&
    !isNull(rangeSpec.max) &&
    !isNull(rangeSpec.step)
  ) {
    fixedOptions = [];
    for (let i = rangeSpec.min; i <= rangeSpec.max; i += rangeSpec.step) {
      fixedOptions.push({ name: i.toString(), id: i });
    }
  }
  return fixedOptions;
}

export function getLayoutDefinition(layoutGraph, allEntityFactories, field) {
  return getLookupDefinition(
    { layoutGraph: layoutGraph, allEntityFactories: allEntityFactories },
    field
  );
}

export function getLayoutPath(definition) {
  let layoutPath = "";
  if (definition) {
    layoutPath = "all" + definition.pluralName;
  }
  return layoutPath;
}

export function getFactoryForLookup(layoutGraph, allEntityFactories, field) {
  return getLookupFactory(
    { layoutGraph: layoutGraph, allEntityFactories: allEntityFactories },
    field
  );
}

export function getLookupFilter(field, values) {
  let lookupFilter = null;
  if (values && field.lookupFilter) {
    lookupFilter = cloneDeep(field.lookupFilter);
    for (let key in lookupFilter) {
      if (Object.prototype.hasOwnProperty.call(lookupFilter, key)) {
        lookupFilter[key] = replaceAndDecode(lookupFilter[key], values);
      }
    }
  }
  return lookupFilter;
}

export function calcLookupFilter(definitionfield, values, fieldNameParts) {
  let lookupValues = values;
  if (fieldNameParts && fieldNameParts.length > 2) {
    let additionalValues =
      values[fieldNameParts[0]][parseInt(fieldNameParts[1])];
    lookupValues = { ...values, ...additionalValues };
  }
  return getLookupFilter(definitionfield, lookupValues);
}

export function lookupIsCacheable(
  definitionField,
  layoutGraph,
  allEntityFactories,
  lookupFactory,
  lookupFilter
) {
  if (definitionField.type === "json") {
    return true;
  } else {
    let cacheable = lookupFactory ? lookupFactory.cacheable() : true;

    if (cacheable && !isNull(lookupFilter)) {
      for (let key in lookupFilter) {
        if (Object.prototype.hasOwnProperty.call(lookupFilter, key)) {
          let proxyTable = lookupFilter[key].proxyTable;
          if (!isEmpty(proxyTable)) {
            let proxyFactory = getEntityFactory(allEntityFactories, proxyTable);
            if (isNull(proxyFactory)) {
              cacheable = false;
            } else {
              cacheable = proxyFactory.cacheable();
            }
            if (!cacheable) break;
          }
        }
      }
    }

    return cacheable;
  }
}

export function getItemRow(definitionField, lookup, value, selectFieldName) {
  let currentItem = undefined;
  if (!isNull(definitionField.lookup)) {
    return getItemFromLookup(lookup, value);
  } else if (definitionField.type === "json") {
    if (selectFieldName && !isEmpty(value)) {
      for (let i = 0; i < value.length; i++) {
        let item = value[i];
        if (item[selectFieldName] === true) {
          currentItem = { ...item };
          currentItem.id = -i - 1;
          break;
        }
      }
    }
    return currentItem;
  }
  return currentItem;
}

export function getItemId(definitionField, value, selectFieldName) {
  if (!isNull(definitionField.lookup)) {
    return { id: value };
  } else if (definitionField.type === "json") {
    let currentItem = undefined;
    if (selectFieldName && !isEmpty(value)) {
      for (let i = 0; i < value.length; i++) {
        let item = value[i];
        if (item[selectFieldName] === true) {
          currentItem = { ...item };
          currentItem.id = -i - 1;
          break;
        }
      }
    }
    if (!isNull(currentItem)) {
      return currentItem;
    } else {
      return { id: null };
    }
  } else {
    return { id: null };
  }
}

export function decodeFieldValue(definitionField, value) {
  if (!isEmpty(value) && definitionField.type === "json") {
    return JSON.parse(value);
  } else {
    return value;
  }
}

export function getItemFromLookup(lookup, value) {
  let currentItem = undefined;
  if (!isNull(value) && !isEmpty(lookup)) {
    currentItem = lookup.find((item) => item.id === value);
  }
  return currentItem;
}

function getTouchedObj(errors) {
  const touched = {};
  if (errors !== undefined) {
    Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        errors[key].map((val, index) => {
          if (!Array.isArray(touched[key])) touched[key] = [];
          while (touched[key].length < index) {
            touched[key].push({});
          }
          touched[key].push(getTouchedObj(val));
        });
      } else {
        touched[key] = true;
      }
    });
  }
  return touched;
}

export function validateAndSubmit(validateForm, handleSubmit, setTouched) {
  validateForm()
    .then((errors) => {
      if (Object.keys(errors).length > 0) {
        setTouched(getTouchedObj(errors));
      } else {
        handleSubmit();
      }
    })
    .catch((e) => {
      Toast.toast(`[Validation Error]: ` + e.toString(), 5000);
      return null;
    });
}

function getLinks(
  client,
  setRawSearchResults,
  subQueries,
  index,
  callback,
  lookupProps,
  lookupField
) {
  let subQuery = subQueries[index];
  let variables = { filters: subQuery.filter, globalFilter: "Organisation" };
  client
    .query({
      query: gql(subQuery.entityFactory.getEntityItemQueryString()),
      variables: variables,
    })
    .then((results) => {
      let lookupData =
        results &&
        results.data &&
        results.data["search" + subQuery.entityFactory.definition.entityName]
          ? results.data[
              "search" + subQuery.entityFactory.definition.entityName
            ]
          : [];
      lookupProps.layoutGraph[
        "all" + subQuery.entityFactory.definition.pluralName
      ] = lookupData;

      if (index < subQueries.length - 1) {
        getLinks(
          client,
          setRawSearchResults,
          subQueries,
          index + 1,
          callback,
          lookupProps,
          lookupField
        );
      } else {
        let rawLookup = getLookup(
          lookupProps,
          lookupField,
          true,
          null,
          true,
          true
        );
        let lookup = getLookup(
          lookupProps,
          lookupField,
          false,
          null,
          true,
          true,
          null,
          true
        );
        setRawSearchResults(rawLookup);

        callback(lookup);
      }
    })
    .catch((exception) => {
      Toast.toast(`[Search Error]: ` + exception.toString(), 5000);
      console.log(`[Search Error]: `, exception);
      callback([]);
    });
}

export function loadOptions(
  layoutGraph,
  allEntityFactories,
  client,
  lookupFilter,
  entityFactory,
  field,
  setRawSearchResults,
  input,
  callback
) {
  let filters = [];
  if (!isNull(lookupFilter)) {
    filters.push(lookupFilter);
  }
  let globalFilter = getGlobalFilter();
  let variables = {
    fts: formatFTSText(input),
    filters: filters,
    limit: 100,
    globalFilter,
    timeStamp: Date.now(),
  };
  client
    .query({
      query: gql(entityFactory.getEntityItemFTSQueryString()),
      variables: variables,
      fetchPolicy: "network-only",
    })
    .then((results) => {
      let lookupData =
        results &&
        results.data &&
        results.data["ftsSearch" + entityFactory.definition.entityName]
          ? results.data["ftsSearch" + entityFactory.definition.entityName]
          : [];
      let lookupProps = {};
      lookupProps.allEntityFactories = allEntityFactories;
      lookupProps.layoutGraph = {};
      for (let i = 0; i < lookupProps.allEntityFactories.length; i++) {
        let entityFactory = lookupProps.allEntityFactories[i];
        if (entityFactory.cacheable()) {
          lookupProps.layoutGraph["all" + entityFactory.definition.pluralName] =
            layoutGraph["all" + entityFactory.definition.pluralName];
        }
      }
      lookupProps.layoutGraph["all" + entityFactory.definition.pluralName] =
        lookupData;

      let subQueries = [];
      let lookupFields = definitionHelper.findAllTopLevelLookupFields(
        entityFactory.definition.fields
      );
      for (let i = lookupFields.length - 1; i >= 0; i--) {
        if (!lookupFields[i].primaryField) {
          lookupFields.splice(i, 1);
        }
      }
      updateSubQueriesWithLookupIds(
        lookupProps,
        lookupData,
        lookupFields,
        subQueries,
        false,
        false,
        null
      );
      if (subQueries.length > 0) {
        getLinks(
          client,
          setRawSearchResults,
          subQueries,
          0,
          callback,
          lookupProps,
          field
        );
      } else {
        // /{ layoutGraph: layoutGraph, allEntityFactories: allEntityFactories }
        let rawLookup = getLookup(lookupProps, field, true, null, true, true);
        let lookup = getLookup(
          lookupProps,
          field,
          false,
          null,
          true,
          false,
          null,
          true
        );
        setRawSearchResults(rawLookup);
        callback(lookup);
      }
    })
    .catch((exception) => {
      //Toast.toast(`[Fulltext Search Error]: ` + exception.toString(), 5000);
      console.log(exception);
      callback([]);
    });

  //setTimeout(() => {
  //}, 500);
}
/*
export function getLayoutLookup(layoutGraph, allEntityFactories, field) {
  if (isNull(field.field)) return undefined;
  let lookupPathString = field.lookup;
  let definition = getLookupDefinition({layoutGraph: layoutGraph, allEntityFactories: allEntityFactories}, field);
  if (definition) {
    lookupPathString = "layoutGraph.all" + definition.pluralName;
  }

  let parts = lookupPathString.split(".");
  let lookup = {layoutGraph: layoutGraph};
  for (let i = 0; i < parts.length; i++) {
    lookup = lookup[parts[i]];
  }

  return lookup;
}
*/
