/*
 * This is a NON persistent entity.
 */
import React from "react";
import GridBase from "../gridBase";
import definition from "./definition";
import { View } from "react-native";
import { BlackTypography } from "../gridCommon";

function renderNoRowsMessageOverride() {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BlackTypography variant="body1">
        Select a error row on the left to display it&apos;s errors here.
      </BlackTypography>
    </View>
  );
}

const ErrorMessageGrid = (props) => {
  return (
    <GridBase
      {...props}
      definition={definition}
      renderNoRowsMessageOverride={renderNoRowsMessageOverride}
      noFullPageLink={true}
    />
  );
};

export default ErrorMessageGrid;
