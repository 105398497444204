import React from "react";
import { View, StatusBar } from "react-native";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";

const DrawerHeader = ({
  publicStyle,
  closeDrawer,
  navigateHome,
  renderLogo,
  title,
}) => {
  return (
    <AppBar position="static" color="secondary">
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingTop: StatusBar.currentHeight + 10,
          alignItems: "center",
          alignContent: "center",
          height: 50,
        }}
      >
        {renderLogo ? renderLogo() : undefined}
        <Typography
          variant="h6"
          display="block"
          color={"inherit"}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={(event) => {
            closeDrawer(event);
          }}
        >
          <CloseIcon />
        </IconButton>
      </View>
    </AppBar>
  );
};

/*
      <TouchableOpacity onPress={() => { closeDrawer(); }}>
        <View
          style={{
            display: "flex",
            width: 24,
            height: 24,
            backgroundColor: styles.foregroundColor,
            alignItems: "center",
            justifyContent: "center"
          }}>
          <FontAwesome name="times" style={{ color: styles.titleColor, fontSize: 20 }} />
        </View>
      </TouchableOpacity> 
*/
/*
<FontIcon.Button
name="times"
size={20}
color={styles.titleColor}
backgroundColor={styles.foregroundColor}
onPress={() => {
  closeDrawer()
}}
/>
*/

export default DrawerHeader;
