/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import WrapperForm from "./wrapperForm";

const WrapperPage = (props) => {
  //HEIGHTHERE
  let ua = navigator.userAgent.toLowerCase();
  let isAndroidOrWindowsOrFirefox =
    ua.indexOf("android") > -1 ||
    ua.indexOf("windows") > -1 ||
    ua.indexOf("linux") > -1 ||
    ua.indexOf("firefox") > -1;
  return (
    <View
      style={{
        flex: 1,
        width: "100vw",
        height: isAndroidOrWindowsOrFirefox ? props.windowHeight : undefined,
        position: isAndroidOrWindowsOrFirefox ? "fixed" : "relative",
      }}
    >
      <WrapperForm {...props} isPage={true} />
    </View>
  );
};

export default WrapperPage;
