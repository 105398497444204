/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import { View } from "react-native";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { TOKENS } from "./theme";
import Constants from "expo-constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  borderRadius: 10,
  padding: 10,
  backgroundColor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function OfflineGuard({ children }) {
  const [online, setOnline] = useState(navigator.onLine);
  const goOnline = useCallback(() => setOnline(true), [setOnline]);
  const goOffline = useCallback(() => setOnline(false), [setOnline]);

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);
  return (
    <>
      <Modal
        open={!online}
        aria-labelledby="offline-title"
        aria-describedby="offline-description"
      >
        <Box style={style}>
          <Typography id="offline-title" variant="h6" component="h2">
            You are not online!
          </Typography>
          <Typography id="offline-description">
            Please check your internet connection.
          </Typography>
        </Box>
      </Modal>
      {online ? (
        children
      ) : (
        <View style={{ flex: 1, flexDirection: "column", width: "100%" }}>
          <AppBar position="static" color="primary">
            <View
              style={{
                flexDirection: "row",
                height: 50,
              }}
            >
              <Typography
                key={"frt"}
                variant="h6"
                display="block"
                color={"inherit"}
                style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
              >
                {Constants.manifest.name}
              </Typography>
            </View>
          </AppBar>
          <View
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
              backgroundImage: "linear-gradient(white 20%, DarkGray 100%)",
            }}
          >
            <View
              style={{
                flex: 1,
                height: "100%",
                maxWidth: 600,
                flexDirection: "column",
                backgroundColor: TOKENS?.backgroundColor,
                boxShadow: "0px 0px 10px 5px rgb(190,190,190)",
                border: "1px solid rgb(170,170,170)",
              }}
            ></View>
          </View>
        </View>
      )}
    </>
  );
}
