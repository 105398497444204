import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const URL_ACTION_MUTATION = gql`
  mutation applyPayload($key: String!) {
    applyPayload(key: $key) {
      status
    }
  }
`;

export default graphql(URL_ACTION_MUTATION, {
  options: {},
  props: ({ mutate }) => ({
    applyPayload: (key) => {
      return mutate({
        variables: { key },
      });
    },
  }),
});
