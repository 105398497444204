/* eslint-disable react/prop-types */
import React, { Suspense, useState, useMemo, useEffect } from "react";
import { View } from "react-native";
import Skeleton from "@material-ui/lab/Skeleton";
import { useForm } from "react-hook-form";
import { FormProvider } from "../form/formContext";
import FormRoot from "../form/FormRoot";
import { getWidget } from "../form/helper";
import { isNull, isEmpty, getScreenSize } from "../util/common.js";
import PageLayout from "./pageLayout";

const FilePage = ({
  entityFactory,
  windowWidth,
  windowHeight,
  queryResult,
  layoutGraph,
  hasSideBar,
  changeLoginState,
  menuOnlyHasHome,
  drawerMenu,
}) => {
  const [screenSize] = useState(() => {
    return getScreenSize(windowWidth);
  });

  const initialValues = useMemo(
    () =>
      queryResult
        ? {
            file: JSON.stringify(queryResult),
          }
        : {},
    [queryResult]
  );

  const methods = useForm({
    mode: "all",
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (initialValues) methods.reset(initialValues);
  }, [methods, initialValues]);

  const onFormSubmit = () => {};

  const renderFile = () => {
    let Widget = getWidget(false, { type: "file" });
    let entityName = entityFactory?.definition?.entityName;
    let title = isEmpty(entityName) ? "File" : `${entityName} File`;
    return (
      <Suspense
        fallback={<Skeleton animation="wave" width="100%" height={40} />}
      >
        <Widget
          name={"file"}
          definitionfield={{ fieldName: "file" }}
          label={title}
          disabled={true}
        />
      </Suspense>
    );
  };

  const renderBody = () => {
    return (
      <FormRoot>
        <View style={{ flex: 1, zIndex: 999, margin: 5 }}>
          {isNull(queryResult) ? undefined : (
            <FormProvider
              {...methods}
              defaultValues={initialValues}
              onFormSubmit={onFormSubmit}
            >
              {renderFile()}
            </FormProvider>
          )}
        </View>
      </FormRoot>
    );
  };

  let currentUser = layoutGraph ? layoutGraph.currentUser : null;

  return (
    <PageLayout
      windowHeight={windowHeight}
      currentUser={currentUser}
      classes={null}
      theme={null}
      screenSize={screenSize}
      wafelButtons={[]}
      hasSideBar={hasSideBar}
      entityFactory={null}
      entityId={null}
      changeLoginState={changeLoginState}
      drawerMenu={drawerMenu}
      onWorkflowSubmit={null}
      hideTopLeft={false}
      showNavigation={!hasSideBar && !menuOnlyHasHome}
      title={"File"}
      onLogoPress={null}
      rightContent={undefined}
      bodyContent={renderBody()}
    />
  );
};

export default FilePage;
