/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
//import cx from 'classnames'
//import { isNull } from '../util/types';

// KaiHotz react-formik-ui
// https://github.com/KaiHotz/react-formik-ui/tree/master/src

//let className = cx('react-formik-ui form-wrapper', isNull(this.props.theme) ? "themed" : this.props.theme);
//this._root.setNativeProps({ className });
//ref={(component) => (this._root = component)}

const Form = (props) => {
  return (
    <View
      key={"formroot"}
      style={{
        flex: 1,
        zIndex: -999,
      }}
    >
      {props.children}
    </View>
  );
};

export default Form;
