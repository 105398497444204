import DEV from "./envDev";
import PROD from "./envProd";

function getEnvironment() {
  if (__DEV__) {
    return DEV;
  } else {
    return PROD;
  }
}

export default getEnvironment;
