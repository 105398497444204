import {
  renameKey,
  isNull,
  isEmpty,
  getLookupFactory,
  findEntityFactory,
  isInt,
  replaceAndDecode,
  isString,
} from "./common";
import * as cloneDeep from "lodash/cloneDeep";

export function getUserMenuItem(
  currentUser,
  allEntityFactories,
  layoutGraph,
  uiGraph
) {
  let menu = null;
  let allMenus = [];
  if (layoutGraph?.allMenus && layoutGraph?.allMenus.length > 0) {
    allMenus = layoutGraph.allMenus;
  } else if (uiGraph?.allMenus && uiGraph.allMenus.length > 0) {
    allMenus = uiGraph.allMenus;
  }
  if (allMenus.length > 0) {
    let l1 = layoutGraph ? layoutGraph : {};
    let l2 = uiGraph ? uiGraph : {};
    let layoutGraph = { ...l1, ...l2 };
    let menuId = currentUser.menuId;
    let menuEntityFactory = findEntityFactory(allEntityFactories, "Menu");
    if (!isNull(menuId)) {
      if (!isNull(menuEntityFactory)) {
        menu = menuEntityFactory.getRowById(layoutGraph, menuId);
      }
    }
    let userFactory = null;
    let parentFactory = null;
    if (isNull(menu)) {
      let entityMenuName = null;
      for (let i = 0; i < allEntityFactories.length; i++) {
        let entityFactory = allEntityFactories[i];
        if (
          entityFactory.lowerCaseEntityName() ===
          currentUser.userEntityName.toLowerCase()
        ) {
          userFactory = entityFactory;
          if (isNull(currentUser.parentEntityName) || !isNull(parentFactory))
            break;
        }
        if (
          !isNull(currentUser.parentEntityName) &&
          entityFactory.lowerCaseEntityName() ===
            currentUser.parentEntityName.toLowerCase()
        ) {
          parentFactory = entityFactory;
          if (!isNull(userFactory)) break;
        }
      }
      if (
        !isNull(parentFactory) &&
        !isNull(parentFactory.definition) &&
        !isNull(parentFactory.definition.menu)
      ) {
        entityMenuName = parentFactory.definition.menu;
      }
      if (
        !isNull(userFactory) &&
        !isNull(userFactory.definition) &&
        !isNull(userFactory.definition.menu)
      ) {
        entityMenuName = userFactory.definition.menu;
      }
      if (!isEmpty(entityMenuName)) {
        if (!isNull(menuEntityFactory)) {
          menu = menuEntityFactory.getRowByName(layoutGraph, entityMenuName);
        }
      }
    }
    if (isNull(menu)) {
      if (!isNull(menuEntityFactory)) {
        menu = menuEntityFactory.getRowByName(layoutGraph, "Default");
      }
    }

    if (!isNull(menu)) {
      menu = cloneDeep(menu);
      try {
        let def = JSON.parse(menu.definition);
        menu.definition = def;
      } catch (ex) {
        menu = null;
      }
    }
  }
  return menu;
}

export function getMenu(
  currentUser,
  layoutGraph,
  uiGraph,
  allEntityFactories,
  dbMenuRow,
  navigationDefaults
) {
  let menu = {};
  menu["/home:Home"] = {
    path: ["Home"],
    icon: "fa-home",
    roles: ["Everyone"],
    isDefault: true,
  };
  if (isNull(currentUser)) return {};

  if (
    currentUser &&
    uiGraph &&
    uiGraph.allMenus &&
    uiGraph.allMenus.length > 0
  ) {
    if (!isNull(dbMenuRow)) {
      try {
        let menuDefinition = dbMenuRow.definition;
        menu = {};
        menu = addChildMenuFields(
          currentUser,
          layoutGraph,
          uiGraph,
          allEntityFactories,
          menu,
          navigationDefaults,
          menuDefinition.fields,
          null
        );
        let count = 0;
        for (let key in menu) {
          if (Object.prototype.hasOwnProperty.call(menu, key)) {
            count += 1;
            break;
          }
        }
        if (count < 1) {
          menu = {};
          menu["/home:Home"] = {
            path: ["Home"],
            icon: "fa-home",
            roles: ["Everyone"],
          };
        }
      } catch (ex) {
        menu = {};
        menu["/home:Home"] = {
          path: ["Home"],
          icon: "fa-home",
          roles: ["Everyone"],
        };
      }
    }
  }

  if (!Object.prototype.hasOwnProperty.call(menu, "/home")) {
    menu["/home:Home"] = {
      path: ["Home"],
      icon: "fa-home",
      roles: ["Everyone"],
    };
  }

  return menu;
}

function addChildMenuFields(
  currentUser,
  layoutGraph,
  uiGraph,
  allEntityFactories,
  menu,
  navigationDefaults,
  fields,
  parent
) {
  fields = isNull(fields) ? [] : fields;
  for (let i = 0; i < fields.length; i++) {
    let currentField = fields[i];
    if (currentField.type !== "logout") {
      let childrenFirst = currentField.childrenFirst;
      if (childrenFirst) {
        if (!isNull(currentField.definition)) {
          menu = addChildMenuFields(
            currentUser,
            layoutGraph,
            uiGraph,
            allEntityFactories,
            menu,
            navigationDefaults,
            currentField.definition.fields,
            currentField
          );
        }
        menu = addMenuField(
          currentUser,
          layoutGraph,
          uiGraph,
          allEntityFactories,
          menu,
          navigationDefaults,
          currentField,
          parent
        );
      } else {
        menu = addMenuField(
          currentUser,
          layoutGraph,
          uiGraph,
          allEntityFactories,
          menu,
          navigationDefaults,
          currentField,
          parent
        );
        if (!isNull(currentField.definition)) {
          menu = addChildMenuFields(
            currentUser,
            layoutGraph,
            uiGraph,
            allEntityFactories,
            menu,
            navigationDefaults,
            currentField.definition.fields,
            currentField
          );
        }
      }
    }
  }
  return menu;
}

function addMenuField(
  currentUser,
  layoutGraph,
  uiGraph,
  allEntityFactories,
  menu,
  navigationDefaults,
  field,
  parent
) {
  let hasValidRole = checkUserRolesForField(
    currentUser,
    layoutGraph,
    uiGraph,
    field
  );
  if (!field.hidden && hasValidRole) {
    if (field.type === "home") {
      field.icon = !isNull(parent)
        ? null
        : isEmpty(field.icon)
        ? "fa-home"
        : field.icon;
      addHomeMenuItem(menu, field, parent);
    } else if (field.type === "autoMenu") {
      field.icon = !isNull(parent)
        ? null
        : isEmpty(field.icon)
        ? "fa-sitemap"
        : field.icon;
      addAutoMenu(
        currentUser,
        layoutGraph,
        allEntityFactories,
        menu,
        navigationDefaults,
        field
      );
    } else if (field.type === "page") {
      field.icon = !isNull(parent)
        ? null
        : isEmpty(field.icon)
        ? "fa-th-large"
        : field.icon;
      addPageMenuItem(layoutGraph, menu, field, parent);
    } else if (field.type === "logout") {
      field.icon = !isNull(parent)
        ? null
        : isEmpty(field.icon)
        ? "fa-logout"
        : field.icon;
      addLogoutMenuItem(menu, field, parent);
    } else if (field.type === "singleEntity") {
      field.icon = !isNull(parent)
        ? null
        : isEmpty(field.icon)
        ? "fa-cube"
        : field.icon;
      menu = addSingleEntityMenuItem(
        layoutGraph,
        allEntityFactories,
        menu,
        navigationDefaults,
        field,
        parent
      );
    } else if (field.type === "singleEntityItem") {
      field.icon = !isNull(parent)
        ? null
        : isEmpty(field.icon)
        ? "fa-file-o"
        : field.icon;
      addSingleEntityInstanceMenuItem(
        layoutGraph,
        currentUser,
        allEntityFactories,
        menu,
        navigationDefaults,
        field,
        parent
      );
    } else if (field.type === "object") {
      field.icon = !isNull(parent)
        ? null
        : isEmpty(field.icon)
        ? "fa-folder"
        : field.icon;
      addObjectMenu(menu, field);
    }
  }
  return menu;
}

function addItemsToMenu(menu, newItems) {
  newItems.sort((a, b) => a.name.localeCompare(b.name));
  for (let i = 0; i < newItems.length; i++) {
    let newItem = newItems[i];
    let menuUrl = newItem.url;
    delete menu[menuUrl + ":" + newItem.menu.path.join("")];
    menu[menuUrl + ":" + newItem.menu.path.join("")] = newItem.menu;
  }
}

function addHomeMenuItem(menu, field, parent) {
  let title = isNull(field.title) ? field.fieldName : field.title;
  let parentTitle = isNull(parent)
    ? null
    : isNull(parent.title)
    ? parent.fieldName
    : parent.title;
  let path = isNull(parent) ? [title] : [parentTitle, title];
  let menuUrl = "/home";
  delete menu[menuUrl + ":" + path.join("")];
  menu[menuUrl + ":" + path.join("")] = {
    path: path,
    icon: field.icon,
    separator: field.separator,
    roles: field.roles,
  };
}

function getPageIdFromName(layoutGraph, field) {
  if (!isEmpty(layoutGraph?.allPages) && !isEmpty(field?.navigationPageName)) {
    let page = layoutGraph.allPages.find(
      (item) => item?.name === field.navigationPageName
    );
    if (!isNull(page?.id)) return page.id;
  }
  return null;
}

function setNavigation(layoutGraph, field, defaultNavigation) {
  let retVal = defaultNavigation;
  let pageId = getPageIdFromName(layoutGraph, field);
  if (isNull(pageId)) {
    retVal = null;
  } else {
    retVal.pageId = pageId;
  }
  return retVal;
}

function addAutoMenu(
  currentUser,
  layoutGraph,
  allEntityFactories,
  menu,
  navigationDefaults,
  field
) {
  let useSubMenu = isNull(field.useSubMenu) ? true : field.useSubMenu;

  let newItems = [];
  for (let i = 0; i < allEntityFactories.length; i++) {
    let entityFactory = allEntityFactories[i];
    let entityName = entityFactory.definition.entityName;
    let entityTitle = isNull(entityFactory.definition.short)
      ? entityFactory.definition.title
      : entityFactory.definition.short;
    let defaultNavigation = null;
    if (field.navigation) {
      defaultNavigation = {
        navigation: field.navigation,
        pageId: field.navigationPageId,
      };
      if (
        defaultNavigation.navigation === "page" &&
        !isInt(defaultNavigation.pageId)
      ) {
        defaultNavigation = setNavigation(
          layoutGraph,
          field,
          defaultNavigation
        );
      }
    }
    if (isNull(defaultNavigation)) {
      let defaultNav = isNull(navigationDefaults)
        ? null
        : navigationDefaults[entityName];
      if (!isNull(defaultNav) && !isNull(defaultNav.navigation)) {
        defaultNavigation = defaultNav;
      }
    }

    let entityUrl = getNavigationBaseUrl(defaultNavigation, entityFactory);
    let isValid = entityFactory.definition.type === field.entityType;
    if (isValid) {
      if (entityFactory.hasSomeViewRights(currentUser)) {
        if (useSubMenu) {
          let title = isNull(field.title) ? field.fieldName : field.title;
          newItems.push({
            url: entityUrl,
            menu: { path: [title, entityTitle], entityFactory: entityFactory },
            name: entityTitle,
          });
          //menu[entityUrl] = { path: ['Entities', title], entityFactory: entityFactory };
        } else {
          if (entityFactory.groupRightsDirectUrl(currentUser).length === 0) {
            let icon =
              entityFactory &&
              entityFactory.definition &&
              entityFactory.definition.icon
                ? entityFactory.definition.icon
                : "fa-file";
            newItems.push({
              url: entityUrl,
              menu: {
                path: [entityTitle],
                entityFactory: entityFactory,
                icon: icon,
              },
              name: entityTitle,
            });
            //menu[entityUrl] = { path: [title], entityFactory: entityFactory, icon: icon };
          }
        }
      }
    }
  }

  if (useSubMenu && newItems.length > 0) {
    let title = isNull(field.title) ? field.fieldName : field.title;
    menu["/" + title.toLowerCase() + ":" + title] = {
      path: [title],
      icon: field.icon,
      separator: field.separator,
      roles: field.roles,
      hideIfNoChildren: true,
    };
  }

  addItemsToMenu(menu, newItems);
}

function addPageMenuItem(layoutGraph, menu, field, parent) {
  let title = isNull(field.title) ? field.fieldName : field.title;
  let parentTitle = isNull(parent)
    ? null
    : isNull(parent.title)
    ? parent.fieldName
    : parent.title;
  let path = isNull(parent) ? [title] : [parentTitle, title];
  if (
    field &&
    field.lookupFilter &&
    field.lookupFilter.name &&
    field.lookupFilter.name.val &&
    layoutGraph &&
    layoutGraph.allPages &&
    layoutGraph.allPages.length > 0
  ) {
    let page = layoutGraph.allPages.find(
      (item) => item?.name === field.lookupFilter.name.val
    );
    if (page) {
      let menuUrl = "/page/" + page.id.toString();
      delete menu[menuUrl + ":" + path.join("")];
      menu[menuUrl + ":" + path.join("")] = {
        path: path,
        icon: field.icon,
        separator: field.separator,
        roles: field.roles,
      };
    }
  } else if (
    field &&
    field.lookupFilter &&
    field.lookupFilter.id &&
    field.lookupFilter.id.val
  ) {
    let menuUrl = "/page/" + field.lookupFilter.id.val.toString();
    delete menu[menuUrl + ":" + path.join("")];
    menu[menuUrl + ":" + path.join("")] = {
      path: path,
      icon: field.icon,
      separator: field.separator,
      roles: field.roles,
    };
  }
}

function addLogoutMenuItem(menu, field, parent) {
  let title = isNull(field.title) ? field.fieldName : field.title;
  let parentTitle = isNull(parent)
    ? null
    : isNull(parent.title)
    ? parent.fieldName
    : parent.title;
  let path = isNull(parent) ? [title] : [parentTitle, title];
  let menuUrl = "/logout";
  delete menu[menuUrl + ":" + path.join("")];
  menu[menuUrl + ":" + path.join("")] = {
    path: path,
    icon: field.icon,
    separator: field.separator,
  };
}

function addObjectMenu(menu, field) {
  let menuUrl = "/" + field.fieldName;
  let title = isNull(field.title) ? field.fieldName : field.title;
  delete menu[menuUrl + ":" + title];
  menu[menuUrl + ":" + title] = {
    path: [title],
    icon: field.icon,
    separator: field.separator,
    hideIfNoChildren: true,
  };
}

function checkUserRolesForField(currentUser, layoutGraph, uiGraph, field) {
  let hasRole = false;
  if (currentUser) {
    let allRoles = [];
    if (
      layoutGraph &&
      layoutGraph.allRoles &&
      layoutGraph.allRoles.length > 0
    ) {
      allRoles = layoutGraph.allRoles;
    } else if (uiGraph && uiGraph.allRoles && uiGraph.allRoles.length > 0) {
      allRoles = uiGraph.allRoles;
    }

    if (currentUser) {
      if (
        field.roles === undefined ||
        field.roles === null ||
        field.roles.length === 0
      ) {
        hasRole = true;
      } else {
        for (let j = 0; j < field.roles.length; j++) {
          let menuRole = field.roles[j];
          let roleId = null;
          for (let k = 0; k < allRoles.length; k++) {
            let currentRole = allRoles[k];
            if (currentRole.name === menuRole) {
              roleId = currentRole.id;
              break;
            }
          }
          for (let k = 0; k < currentUser.roles.length; k++) {
            if (currentUser.roles[k] === roleId) {
              hasRole = true;
              break;
            }
          }
          if (hasRole) break;
        }
      }
    }
  }
  return hasRole;
}

function addSingleEntityMenuItem(
  layoutGraph,
  allEntityFactories,
  menu,
  navigationDefaults,
  field,
  parent
) {
  if (isNull(field.lookup)) return;
  let lookupEntityFactory = getLookupFactory({ allEntityFactories }, field);
  if (isNull(lookupEntityFactory)) return;
  //let lookupName = lookupEntityFactory.lowerCasePluralName();
  //let link = null;

  let menuUrl = null;
  //if (field.navigation === "page" && isInt(field.navigationPageId)) {
  //  menuUrl = "/pagenav/" + lookupName + "/" + field.navigationPageId.toString();
  //} else {
  //  menuUrl = getNavigationBaseUrl(field, "links") + "/" + lookupName;
  //}
  let defaultNavigation = null;
  if (field.navigation) {
    defaultNavigation = {
      navigation: field.navigation,
      pageId: field.navigationPageId,
    };
    if (
      defaultNavigation.navigation === "page" &&
      !isInt(defaultNavigation.pageId)
    ) {
      defaultNavigation = setNavigation(layoutGraph, field, defaultNavigation);
    }
  }
  if (isNull(defaultNavigation)) {
    let defaultNav = isNull(navigationDefaults)
      ? null
      : navigationDefaults[field.lookup];
    if (!isNull(defaultNav) && !isNull(defaultNav.navigation)) {
      defaultNavigation = defaultNav;
    }
  }
  menuUrl = getNavigationBaseUrl(defaultNavigation, lookupEntityFactory);
  let title = isNull(field.title) ? field.fieldName : field.title;
  let parentTitle = isNull(parent)
    ? null
    : isNull(parent.title)
    ? parent.fieldName
    : parent.title;
  let path = isNull(parent) ? [title] : [parentTitle, title];
  let menuItem = {
    path: path,
    entityFactory: lookupEntityFactory,
    icon: field.icon,
    separator: field.separator,
  };
  let exists = false;
  if (parent && parent.type === "autoMenu") {
    for (let key in menu) {
      if (Object.prototype.hasOwnProperty.call(menu, key)) {
        let item = menu[key];
        if (
          item &&
          !isNull(item.entityFactory) &&
          item.entityFactory === lookupEntityFactory
        ) {
          if (JSON.stringify(item.path) === JSON.stringify(path)) {
            exists = true;
            if (key.localeCompare(menuUrl + ":" + path.join("")) !== 0) {
              menu = renameKey(menu, key, menuUrl + ":" + path.join(""));
            }
            menu[menuUrl + ":" + path.join("")] = menuItem; //icon and separator could have been overriden.
          }
          break;
        }
      }
    }
  }
  if (!exists) {
    delete menu[menuUrl + ":" + menuItem.path.join("")];
    menu[menuUrl + ":" + menuItem.path.join("")] = menuItem;
  }
  return menu;
}

function addSingleEntityInstanceMenuItem(
  layoutGraph,
  currentUser,
  allEntityFactories,
  menu,
  navigationDefaults,
  field,
  parent
) {
  if (isNull(field.lookup)) return;
  if (
    isNull(field.lookupFilter) ||
    isNull(field.lookupFilter.id) ||
    isNull(field.lookupFilter.id.val)
  )
    return;
  let lookupEntityFactory = getLookupFactory({ allEntityFactories }, field);
  if (isNull(lookupEntityFactory)) return;
  //let lookupName = lookupEntityFactory.lowerCasePluralName();
  let menuUrl = null;
  let filterValue = field.lookupFilter.id.val;
  if (isString(filterValue) && filterValue.startsWith("%%")) {
    filterValue = replaceAndDecode(filterValue, currentUser);
  }
  let defaultNavigation = null;
  if (field.navigation) {
    defaultNavigation = {
      navigation: field.navigation,
      pageId: field.navigationPageId,
    };
    if (
      defaultNavigation.navigation === "page" &&
      !isInt(defaultNavigation.pageId)
    ) {
      defaultNavigation = setNavigation(layoutGraph, field, defaultNavigation);
    }
  }
  if (isNull(defaultNavigation)) {
    let defaultNav = isNull(navigationDefaults)
      ? null
      : navigationDefaults[field.lookup];
    if (!isNull(defaultNav) && !isNull(defaultNav.navigation)) {
      defaultNavigation = defaultNav;
    }
  }
  if (
    defaultNavigation &&
    defaultNavigation.navigation === "page" &&
    isInt(defaultNavigation.pageId)
  ) {
    menuUrl =
      "/page/" +
      defaultNavigation.pageId.toString() +
      "/" +
      filterValue.toString();
  } else {
    menuUrl =
      getNavigationBaseUrl(defaultNavigation, lookupEntityFactory) +
      "/" +
      filterValue.toString();
  }
  let title = isNull(field.title) ? field.fieldName : field.title;
  let parentTitle = isNull(parent)
    ? null
    : isNull(parent.title)
    ? parent.fieldName
    : parent.title;
  let path = isNull(parent) ? [title] : [parentTitle, title];
  delete menu[menuUrl + ":" + path.join("")];
  menu[menuUrl + ":" + path.join("")] = {
    path: path,
    icon: field.icon,
    separator: field.separator,
  };
}

function getNavigationBaseUrl(defaultNavigation, entityFactory) {
  let url = null;
  let entityPath = null;
  //if (!(nav && nav.navigation === "page")) {
  entityPath = entityFactory.lowerCasePluralName();
  //}

  url = "/entities/" + entityPath;

  return url;
}
