/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from "react";
import { View } from "react-native";

const AccessibleView = (props) => {
  const _root = useRef(null);
  useEffect(() => {
    const { id, role } = props;
    let extra = {};
    if (role) {
      extra.role = role;
    }
    if (id) {
      extra.id = id;
    }
    _root.current.setNativeProps({ ...extra });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <View key={"AV"} ref={_root} {...props}>
      {props.children}
    </View>
  );
};

export default AccessibleView;
