export default {
  entityName: "Workflow Task",
  pluralName: "Workflow Tasks",
  guidId: true,
  fields: [
    {
      fieldName: "workflowTitle",
      title: "Workflow Title",
      type: "string",
      required: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
    },
    {
      fieldName: "display",
      title: "Current Display",
      type: "string",
      required: false,
      readOnly: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
    },
    {
      fieldName: "userName",
      title: "User Name",
      type: "string",
      required: false,
      readOnly: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
    },
    {
      fieldName: "timeStamp",
      title: "Date",
      type: "string",
      required: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
      showInMore: true,
    },
    {
      fieldName: "step",
      title: "Step",
      type: "string",
      required: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
      showInMore: true,
    },
    {
      fieldName: "name",
      title: "Workflow Name",
      type: "string",
      required: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: true,
      widget: null,
      bulkAppend: false,
    },
    {
      fieldName: "threadStateData",
      title: "State",
      type: "json",
      required: false,
      inPlace: false,
      readOnly: false,
      hidden: true,
      bulkAppend: true,
      showInMore: false,
    },
    {
      fieldName: "userId",
      title: "UserId",
      type: "integer",
      required: false,
      readOnly: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: true,
      widget: null,
      bulkAppend: false,
    },
    {
      fieldName: "processing",
      title: "Processing",
      type: "boolean",
      required: false,
      readOnly: true,
      default: false,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
    },
  ],
  sections: [],
  references: [],
  minimal: false,
  showDetail: true,
  leftActions: ["singleedit", "export"],
};
