/* eslint-disable react/prop-types */
import React, {
  useState,
  useEffect,
  useMemo,
  Suspense,
  useCallback,
} from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  useExpanded,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  usePagination,
} from "react-table";
import { View, ScrollView } from "react-native";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ExploreIcon from "@material-ui/icons/Explore";
import ListIcon from "@material-ui/icons/List";
import GridOnIcon from "@material-ui/icons/GridOn";
import UndoIcon from "@material-ui/icons/Undo";
import SaveIcon from "@material-ui/icons/Save";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AccessibleView from "../components/accessibleView";
import { isNull, isEmpty } from "../util/types";
import ExportExcelDataSet from "../components/exportExcelDataSet";
import {
  Table,
  Row,
  HeaderRow,
  Header,
  Cell,
  GridButton,
  Resizer,
} from "./Table";
import { SCREEN } from "../util/constants";
//import GridMap from '../components/gridMap';
//import GridCard from '../components/gridCard';
import {
  lowerCasePluralName,
  getFilteredData,
  onDelete,
  hasEditRights,
  hasDeleteRights,
} from "./tableCommon";
import { dateComparator } from "./gridCommon";
import { useCache } from "../hoc/withCache";
import { getWafelButtons } from "../util/common";
import { useWorkflow } from "../hooks/useWorkflow";
import definitionHelper from "../util/definitions";
import { TOKENS } from "../theme";

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    return {
      "&.Mui-selected": {
        background: theme.palette[props.color].contrastText,
      },
      color: theme.palette[props.color].contrastText,
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "white",
        color: theme.palette["primary"].main,
      },
    };
  },
}));

function StyledToggleButton(props) {
  const { color, ...other } = props;
  const classes = useStyles({ color });
  return <ToggleButton classes={classes} {...other} />;
}

let GridMap = React.lazy(() => import("../components/gridMap"));
let GridCard = React.lazy(() => import("../components/gridCard"));

const headerProps = (props, { column }) => {
  let res = getStyles(props, column.align);
  if (column.id !== "selection") {
    res.push(column.getSortByToggleProps());
  }
  return res;
};

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

function getSelectionFromCollection(
  setSelection,
  primaryCollection,
  selectFieldName
) {
  let initialSelection = {};
  if (setSelection && selectFieldName) {
    for (let i = 0; i < primaryCollection.length; i++) {
      let item = primaryCollection[i];
      if (selectFieldName && item[selectFieldName]) {
        initialSelection[item.id.toString()] = true;
      }
    }
  }
  return initialSelection;
}

function renderRows(
  page,
  definition,
  prepareRow,
  wrapWithLink,
  getSubComponent,
  disableFilters
) {
  return page.map((row, i) => {
    prepareRow(row);
    let rowProps = row.getRowProps();
    return (
      <div key={rowProps.key}>
        <Row key={rowProps.key} even={i % 2 === 0} role="row">
          {row.cells.map((cell) => {
            let cellJSX = cell.render("Cell");
            let isSystem =
              cell.column.id === "selection" ||
              (cell.column.id === "expander" && disableFilters);
            if (!isSystem) {
              cellJSX = wrapWithLink(definition, cell, cellJSX);
            }
            let cellProps = cell.getCellProps();
            return (
              <Cell key={`c${cell.column.id}`} {...cellProps}>
                {cellJSX}
              </Cell>
            );
          })}
        </Row>
        {row.isExpanded ? (
          <div style={{ flexDirection: "column" }}>
            {getSubComponent({ row })}
          </div>
        ) : undefined}
      </div>
    );
  });
}

/*
In addition, you should use your own select handlers (in your custom table) to maintain selected rows state in your parent component 
(to update controlledSelectedRowIds in above example) and not use handlers (toggleRowSelected...) provided by the useRowSelect hook.
*/
export default function EntityTable({
  defaultSort,
  showFilter,
  columns,
  hiddenColumns,
  outerScroll,
  screenSize,
  definition,
  getSubComponent,
  geoFieldResolver,
  initViewMode,
  Card,
  defaultFilter,
  publicStyle,
  layoutGraph,
  onSubmit,
  allEntityFactories,
  primaryCollection,
  mapViewPort,
  setMapViewPort,
  readOnly,
  deleteItems,
  update,
  isWidget,
  currentUser,
  hasSomeEditRights,
  hasSomeDeleteRights,
  hasSomeAddRights,
  setSelection,
  wrapWithLink,
  fieldAccessor,
  entityFactory,
  cacheRoot,
  setActiveTab,
  onSelectedChanged,
  noRowsMessage,
  isSubmitting,
  onGridSubmit,
  onGridDiscard,
  onWafelClick,
  hasErrors,
  hideViewSwitcher,
}) {
  const { generalCache } = useCache(cacheRoot);
  const { executeAction } = useWorkflow();
  const theme = useTheme();
  const [anchor, setAnchor] = useState(null);
  const [disableFilters, setDisableFilters] = useState(
    () => Object.keys(defaultFilter).length === 0 && showFilter.length === 0
  );
  const [modes, setModes] = React.useState(() => {
    let initModes = [];
    if (generalCache.showGrid) initModes.push("grid");
    if (generalCache.showMap) initModes.push("map");
    if (generalCache.showCard) initModes.push("card");

    if (isEmpty(initModes))
      return isEmpty(initViewMode) ? ["grid"] : [initViewMode];
    return initModes;
  });
  const [hasGridDisplay, setGridDisplay] = useState(() =>
    isNull(generalCache.showGrid) ? modes[0] === "grid" : generalCache.showGrid
  );
  const [hasMapDisplay, setMapDisplay] = useState(() =>
    isNull(generalCache.showMap) ? modes[0] === "map" : generalCache.showMap
  );
  const [hasCardDisplay, setCardDisplay] = useState(() =>
    isNull(generalCache.showCard) ? modes[0] === "card" : generalCache.showCard
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const selectFieldName = useMemo(() => {
    return definitionHelper.findSelectFieldName({ definition });
  }, [definition]);

  const [controlledSelectedRowIds, setControlledSelectedRowIds] = useState(
    () => {
      return getSelectionFromCollection(
        setSelection,
        primaryCollection,
        selectFieldName
      );
    }
  );

  const filteredData = useMemo(
    () =>
      getFilteredData(
        definition,
        fieldAccessor,
        primaryCollection,
        layoutGraph,
        allEntityFactories
      ),
    [
      definition,
      fieldAccessor,
      primaryCollection,
      layoutGraph,
      allEntityFactories,
    ]
  );

  let isDirty = !isEmpty(primaryCollection) && !isNull(onGridSubmit);
  let defaultFilters = [];
  if (defaultFilter) {
    for (let key in defaultFilter) {
      if (Object.prototype.hasOwnProperty.call(defaultFilter, key)) {
        defaultFilters.push({ id: key, value: defaultFilter[key] });
      }
    }
  }

  const handleModeChange = useCallback(
    (event, selected) => {
      let newModes = selected.slice();
      let hadGrid = modes.indexOf("grid") >= 0;
      let hadCard = modes.indexOf("card") >= 0;
      let hadMap = modes.indexOf("map") >= 0;

      if (newModes.indexOf("grid") >= 0 && newModes.indexOf("card") >= 0) {
        let tempModes = [];
        if (newModes.indexOf("map") >= 0) {
          tempModes.push("map");
        }
        if (hadGrid) {
          tempModes.push("card");
        } else {
          tempModes.push("grid");
        }
        newModes = tempModes;
      }

      if (screenSize === SCREEN.SMALL && newModes.length > 1) {
        let tempModes = [];
        for (let i = 0; i < newModes.length; i++) {
          let newMode = newModes[i];
          if (modes.indexOf(newMode) < 0) {
            tempModes.push(newMode);
            break;
          }
        }
        newModes = tempModes;
      }

      if (newModes.length === 0) {
        if (modes.length > 0) {
          newModes.push(modes[0]);
        } else {
          newModes.push("grid");
        }
      }

      let hasGrid = newModes.indexOf("grid") >= 0;
      let hasCard = newModes.indexOf("card") >= 0;
      let hasMap = newModes.indexOf("map") >= 0;

      if (hasGrid) {
        if (hadCard) {
          generalCache.showCard = false;
          setCardDisplay(false);
        }
        if (!hadGrid) {
          generalCache.showGrid = true;
          setGridDisplay(true);
        }
      }
      if (hasCard) {
        if (hadGrid) {
          generalCache.showGrid = false;
          setGridDisplay(false);
        }
        if (!hadCard) {
          generalCache.showCard = true;
          setCardDisplay(true);
        }
      }
      if (hasMap) {
        if (!hadMap) {
          generalCache.showMap = true;
          setMapDisplay(true);
        }
        if (!hasGrid && !hasCard) {
          if (hadCard) {
            generalCache.showCard = false;
            setCardDisplay(false);
          }
          if (hadGrid) {
            generalCache.showGrid = false;
            setGridDisplay(false);
          }
        }
      } else {
        if (hadMap) {
          generalCache.showMap = false;
          setMapDisplay(false);
        }
      }

      setModes(newModes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modes, screenSize]
  );

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 1, // minWidth is only used as a limit for resizing
      width: 15, // width is used for both the flex-basis and flex-grow
      maxWidth: 2000, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const customDateComparator = useCallback((rowA, rowB, columnId, desc) => {
    const valA =
      rowA?.original?.raw && columnId ? rowA?.original?.raw[columnId] : null;
    const valB =
      rowB?.original?.raw && columnId ? rowB?.original?.raw[columnId] : null;

    return dateComparator(valA, valB);
  }, []);

  const sortTypes = {
    customDateComparator: customDateComparator,
  };

  const {
    getTableProps,
    getTableBodyProps,
    setAllFilters,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      sortTypes: sortTypes,
      defaultColumn,
      autoResetSelectedRows: false,
      autoResetExpanded: false,
      disableFilters: true,
      autoResetFilters: false,
      initialState: {
        hiddenColumns,
        sortBy: defaultSort,
        pageIndex: 0,
        pageSize: 100,
        filters: defaultFilters,
        selectedRowIds: controlledSelectedRowIds,
      },
      debug: true,
      getResetSelectedRowPathsDeps: false,
      getResetExpandedDeps: false,
      getRowId: (row) => row.id,
      useControlledState: (state) => {
        if (setSelection) {
          return { ...state, selectedRowIds: controlledSelectedRowIds };
        }
        onSelectedChanged(state.selectedRowIds);
        if (state.columnResizing) {
          if (!isNull(state.columnResizing.columnWidths)) {
            for (let key in state.columnResizing.columnWidths) {
              if (
                Object.prototype.hasOwnProperty.call(
                  state.columnResizing.columnWidths,
                  key
                )
              ) {
                if (state.columnResizing.columnWidths[key] < 1) {
                  state.columnResizing.columnWidths[key] = 1;
                }
              }
            }
          }
        }
        return state;
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    (hooks) => {
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );

  useEffect(() => {
    if (setSelection) {
      setControlledSelectedRowIds(
        getSelectionFromCollection(
          setSelection,
          primaryCollection,
          selectFieldName
        )
      );
    }
  }, [primaryCollection, setSelection, selectFieldName]);

  //useGlobalFilter,
  let disabled = isEmpty(selectedFlatRows) || readOnly === true;
  let deleteDisabled = useMemo(() => {
    return (
      disabled ||
      isDeleting ||
      !hasDeleteRights(entityFactory, currentUser, selectedFlatRows)
    );
  }, [disabled, isDeleting, entityFactory, currentUser, selectedFlatRows]);
  let editDisabled = useMemo(() => {
    //Temporarily do not allow bulk update
    return (
      disabled ||
      selectedFlatRows.length > 1 ||
      !hasEditRights(entityFactory, currentUser, selectedFlatRows)
    );
  }, [disabled, entityFactory, currentUser, selectedFlatRows]);
  let addDisabled = definition.readOnly;
  //addRow(history, definition)
  //editRows(history, definition, selectedFlatRows)
  let gridActions = isEmpty(definition?.leftActions)
    ? []
    : definition?.leftActions;
  let showActions = gridActions.indexOf("actions") >= 0;
  let showExport =
    gridActions.indexOf("export") >= 0 && screenSize > SCREEN.SMALL;
  let showEdit = gridActions.indexOf("edit") >= 0 && screenSize > SCREEN.SMALL;
  let showAdd = gridActions.indexOf("add") >= 0;
  let showDelete = gridActions.indexOf("delete") >= 0;
  let actionButtons = [];
  if (entityFactory?.id > 0 && showActions) {
    actionButtons = getWafelButtons(
      layoutGraph,
      entityFactory,
      entityFactory.definition.entityName,
      !showActions
    );
    if (actionButtons.length < 1) showActions = false;
  }
  let isOpen = false;
  if (showActions) {
    isOpen = isNull(anchor) ? false : Boolean(anchor);
  }
  const actionId = isOpen
    ? "token-popover-" + entityFactory?.definition?.entityName
    : undefined;
  return (
    <View
      style={{
        flex: outerScroll ? undefined : 3,
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: "#fff",
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#fff",
        }}
      >
        {hasGridDisplay ? (
          <View style={{ flex: 1, flexDirection: "column" }}>
            <Table {...getTableProps()}>
              <div style={{ backgroundColor: theme.palette.secondary.main }}>
                <div {...getTableBodyProps()}>
                  {headerGroups.map((headerGroup) => {
                    let headerGroupProps = headerGroup.getHeaderGroupProps();
                    return (
                      <HeaderRow
                        key={headerGroupProps.key}
                        role={headerGroupProps.role}
                        style={{
                          backgroundColor: theme.palette.secondary.main,
                          color: theme.palette.secondary.contrastText,
                        }}
                      >
                        {headerGroup.headers.map((column) => {
                          let header = isNull(column.Header)
                            ? ""
                            : column.render("Header");

                          let filter = isNull(column.Filter)
                            ? undefined
                            : column.render("Filter");
                          let columnProps = column.getHeaderProps(headerProps);
                          let onClickSort = columnProps.onClick;
                          delete columnProps.onClick;
                          let caret = undefined;
                          if (column.id === "expander") {
                            columnProps.title = "Toggle Filter";
                            onClickSort = () => {
                              let disabled = !disableFilters;
                              setDisableFilters(disabled);
                              if (disabled) {
                                setAllFilters([]);
                                column.minWidth = 35;
                                column.width = 35;
                                column.maxWidth = 35;
                              } else {
                                column.minWidth = 150;
                                column.width = 150;
                                column.maxWidth = 150;
                                setAllFilters(defaultFilters);
                              }
                            };
                            caret = (
                              <IconButton
                                aria-label="filter"
                                size="small"
                                color="inherit"
                                disabled={addDisabled}
                                onClick={onClickSort}
                              >
                                {disableFilters ? (
                                  <AddCircleOutlineOutlinedIcon />
                                ) : (
                                  <ArrowDropDownCircleOutlinedIcon />
                                )}
                              </IconButton>
                            );

                            if (!disableFilters) {
                              header = (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>Grid Filter</div>
                                  {caret}
                                </div>
                              );
                            } else {
                              header = caret;
                            }
                          }
                          return (
                            <Header
                              key={`h${column.id}`}
                              aria-sort={
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? "descending"
                                    : "ascending"
                                  : "none"
                              }
                              {...columnProps}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  height: "100%",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                  borderColor: "black",
                                }}
                              >
                                <div
                                  onClick={onClickSort}
                                  style={{
                                    flex: 1,
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  {header}
                                  <span>
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? " 🔽"
                                        : " 🔼"
                                      : ""}
                                  </span>
                                </div>
                                {filter && !disableFilters ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {filter}
                                  </div>
                                ) : undefined}
                              </div>
                              {/* Use column.getResizerProps to hook up the events correctly */}
                              {column.canResize ? (
                                <Resizer
                                  {...column.getResizerProps()}
                                  className={`resizer ${
                                    column.isResizing ? "isResizing" : ""
                                  }`}
                                />
                              ) : undefined}
                            </Header>
                          );
                        })}
                      </HeaderRow>
                    );
                  })}
                </div>
              </div>
            </Table>
            {primaryCollection &&
            primaryCollection.length > 0 &&
            page &&
            page.length > 0 ? (
              outerScroll ? (
                <AccessibleView role="table">
                  <div role="rowgroup">
                    {renderRows(
                      page,
                      definition,
                      prepareRow,
                      wrapWithLink,
                      getSubComponent,
                      disableFilters
                    )}
                  </div>
                </AccessibleView>
              ) : (
                <ScrollView
                  style={{ height: 100, flex: 1 }}
                  contentContainerStyle={{ flex: 1 }}
                  nestedScrollEnabled={true}
                >
                  <div role="rowgroup">
                    {renderRows(
                      page,
                      definition,
                      prepareRow,
                      wrapWithLink,
                      getSubComponent,
                      disableFilters
                    )}
                  </div>
                </ScrollView>
              )
            ) : primaryCollection && primaryCollection.length > 0 ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  display="block"
                  color={"textSecondary"}
                  style={{ flex: 1, textAlign: "center" }}
                >
                  No matches found.
                </Typography>
              </View>
            ) : (
              noRowsMessage
            )}
          </View>
        ) : undefined}
        {hasCardDisplay ? (
          <View
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
              backgroundImage: "linear-gradient(white 20%, DarkGray 100%)",
            }}
          >
            <View
              style={{
                flex: 1,
                height: "100%",
                maxWidth: 600,
                flexDirection: "column",
                backgroundColor: TOKENS?.backgroundColor,
                boxShadow: "0px 0px 10px 5px rgb(190,190,190)",
                border: "1px solid rgb(170,170,170)",
              }}
            >
              <ScrollView
                style={{ height: 100, flex: 1 }}
                contentContainerStyle={{ flex: 1 }}
                nestedScrollEnabled={true}
              >
                <Suspense
                  fallback={
                    <Skeleton animation="wave" width="100%" height={120} />
                  }
                >
                  <GridCard
                    EntityCard={Card}
                    layoutGraph={layoutGraph}
                    screenSize={screenSize}
                    navigationUrl={
                      "/entities/" + lowerCasePluralName(definition)
                    }
                    allEntityFactories={allEntityFactories}
                    publicStyle={publicStyle}
                    onWafelClick={onWafelClick}
                    onItemClick={(row) => {
                      if (!row.toggleRowSelected) {
                        prepareRow(row);
                      }
                      toggleAllRowsSelected(false);
                      row.toggleRowSelected(true);
                    }}
                    key={"cm"}
                    selection={selectedFlatRows}
                    rows={rows}
                    definition={definition}
                  />
                </Suspense>
              </ScrollView>
            </View>
          </View>
        ) : undefined}
        {hasMapDisplay ? (
          <View style={{ flex: 1, flexDirection: "column" }}>
            <Suspense
              fallback={
                <Skeleton animation="wave" width="100%" style={{ flex: 1 }} />
              }
            >
              <GridMap
                setMapViewPort={setMapViewPort}
                mapViewPort={mapViewPort}
                navigationUrl={"/entities/" + lowerCasePluralName(definition)}
                onMarkerClick={(row) => {
                  if (!row.toggleRowSelected) {
                    prepareRow(row);
                  }
                  toggleAllRowsSelected(false);
                  row.toggleRowSelected(true);
                }}
                key={"gm"}
                selection={selectedFlatRows}
                rows={rows}
                geoFieldResolver={geoFieldResolver}
                definition={definition}
                layoutGraph={layoutGraph}
              />
            </Suspense>
          </View>
        ) : undefined}
      </View>
      <AppBar position="static" color="secondary">
        <View
          style={{
            flexDirection: "row",
            height: 40,
            justifyContent: "space-between",
            flex: 1,
            borderBottomWidth: 1,
            borderBottomColor: "#AAAAAA",
          }}
        >
          <View style={{ justifyContent: "center", flexDirection: "row" }}>
            {showAdd && hasSomeAddRights && !readOnly ? (
              screenSize > SCREEN.SMALL ? (
                <Button
                  size={"medium"}
                  variant={"text"}
                  color={"inherit"}
                  disabled={addDisabled}
                  onClick={() => setActiveTab(1)}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              ) : (
                <IconButton
                  aria-label="add"
                  color="inherit"
                  style={{ marginRight: 10 }}
                  disabled={addDisabled}
                  onClick={() => setActiveTab(1)}
                >
                  <AddIcon />
                </IconButton>
              )
            ) : undefined}

            {showEdit && hasSomeEditRights && !readOnly ? (
              screenSize > SCREEN.SMALL ? (
                <Button
                  size={"medium"}
                  variant={"text"}
                  color={"inherit"}
                  disabled={editDisabled}
                  onClick={() => setActiveTab(2)}
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              ) : (
                <IconButton
                  aria-label="edit"
                  color="inherit"
                  style={{ marginRight: 10 }}
                  disabled={editDisabled}
                  onClick={() => setActiveTab(2)}
                >
                  <EditIcon />
                </IconButton>
              )
            ) : undefined}

            {showDelete && hasSomeDeleteRights && !readOnly ? (
              screenSize > SCREEN.SMALL ? (
                <Button
                  size={"medium"}
                  variant={"text"}
                  color={"inherit"}
                  disabled={deleteDisabled}
                  onClick={() => {
                    return onDelete(
                      definition,
                      primaryCollection,
                      update,
                      isWidget,
                      deleteItems,
                      toggleAllRowsSelected,
                      setIsDeleting,
                      selectedFlatRows
                    );
                  }}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              ) : (
                <IconButton
                  aria-label="edit"
                  color="inherit"
                  style={{ marginRight: 10 }}
                  disabled={deleteDisabled}
                  onClick={() => {
                    return onDelete(
                      definition,
                      primaryCollection,
                      update,
                      isWidget,
                      deleteItems,
                      toggleAllRowsSelected,
                      setIsDeleting,
                      selectedFlatRows
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )
            ) : undefined}
            {showExport ? (
              <ExportExcelDataSet
                key={"gt_exportef"}
                screenSize={screenSize}
                layoutGraph={layoutGraph}
                allEntityFactories={allEntityFactories}
                currentUser={currentUser}
                definition={definition}
                collection={selectedFlatRows}
                allowAdditionalFields={true}
                glyph={"download"}
                element={"Export"}
                pluralName={definition.pluralName}
                allowEmpty={true}
              />
            ) : undefined}
            {showActions ? (
              screenSize > SCREEN.SMALL ? (
                <Button
                  key={"gab-" + entityFactory?.definition?.entityName}
                  size={"medium"}
                  variant={"text"}
                  color={"inherit"}
                  disabled={
                    selectedFlatRows.length !== 1 || selectedFlatRows[0].id < 1
                  }
                  aria-controls={actionId}
                  aria-haspopup={true}
                  onClick={(event) => {
                    setAnchor(event.currentTarget);
                  }}
                  style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
                  startIcon={<PlayCircleOutlineIcon />}
                  endIcon={<ArrowDropUpIcon />}
                >
                  Actions
                </Button>
              ) : (
                <IconButton
                  key={"gib-" + entityFactory?.definition?.entityName}
                  aria-label="actions"
                  aria-controls={actionId}
                  aria-haspopup={true}
                  color="inherit"
                  style={{ marginRight: 10 }}
                  disabled={
                    selectedFlatRows.length !== 1 || selectedFlatRows[0].id < 1
                  }
                  onClick={(event) => {
                    setAnchor(event.currentTarget);
                  }}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              )
            ) : undefined}
            {showActions ? (
              <Popover
                id={actionId}
                anchorEl={anchor}
                keepMounted
                open={isOpen}
                onClose={() => {
                  setAnchor(null);
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <View>
                  <List
                    style={{
                      background: theme.palette.background.paper,
                    }}
                  >
                    {actionButtons.map((actionButton) => {
                      let key =
                        entityFactory?.definition?.entityName +
                        ":" +
                        actionButton.buttonName;
                      return (
                        <ListItem
                          button
                          onClick={() => {
                            executeAction(
                              actionButton.action.workflow,
                              null,
                              onSubmit,
                              entityFactory,
                              selectedFlatRows[0].id
                            );
                          }}
                          key={key}
                          style={{
                            paddingLeft: theme.spacing(1),
                          }}
                        >
                          <ListItemText
                            key={"lit-" + key}
                            primary={actionButton.buttonName}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </View>
              </Popover>
            ) : undefined}
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            {pageCount > 1 ? (
              <div>
                {screenSize > SCREEN.SMALL ? (
                  <>
                    <GridButton
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </GridButton>{" "}
                  </>
                ) : undefined}
                <GridButton
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </GridButton>{" "}
                {screenSize > SCREEN.SMALL ? (
                  <>
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                    {"  "}
                  </>
                ) : undefined}
                <GridButton onClick={() => nextPage()} disabled={!canNextPage}>
                  {">"}
                </GridButton>{" "}
                {screenSize > SCREEN.SMALL ? (
                  <>
                    <GridButton
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </GridButton>{" "}
                  </>
                ) : undefined}
              </div>
            ) : (
              <div />
            )}
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            {isNull(onGridSubmit) &&
            (Card || geoFieldResolver) &&
            !hideViewSwitcher ? (
              <ToggleButtonGroup
                size="small"
                value={modes}
                onChange={handleModeChange}
              >
                <StyledToggleButton
                  color={
                    theme.palette.secondary.contrastText === "#000000"
                      ? "primary"
                      : "secondary"
                  }
                  value="grid"
                  aria-label="grid view"
                >
                  <GridOnIcon fontSize="small" />
                </StyledToggleButton>
                {Card ? (
                  <StyledToggleButton
                    color={
                      theme.palette.secondary.contrastText === "#000000"
                        ? "primary"
                        : "secondary"
                    }
                    value="card"
                    aria-label="card view"
                  >
                    <ListIcon fontSize="small" />
                  </StyledToggleButton>
                ) : undefined}
                {geoFieldResolver ? (
                  <StyledToggleButton
                    color={
                      theme.palette.secondary.contrastText === "#000000"
                        ? "primary"
                        : "secondary"
                    }
                    value="map"
                    aria-label="map view"
                  >
                    <ExploreIcon fontSize="small" />
                  </StyledToggleButton>
                ) : undefined}
              </ToggleButtonGroup>
            ) : undefined}
            {!isNull(onGridSubmit) ? (
              <View style={{ flexDirection: "row" }}>
                {screenSize > SCREEN.SMALL ? (
                  <Button
                    size={"medium"}
                    variant={"contained"}
                    color={"primary"}
                    disabled={isSubmitting || !isDirty}
                    onClick={onGridDiscard}
                    style={{ marginRight: 5 }}
                    startIcon={<UndoIcon />}
                  >
                    Discard
                  </Button>
                ) : (
                  <IconButton
                    aria-label="edit"
                    color="inherit"
                    style={{ marginRight: 5 }}
                    disabled={isSubmitting || !isDirty}
                    onClick={() => {
                      return onDelete(
                        definition,
                        primaryCollection,
                        update,
                        isWidget,
                        deleteItems,
                        toggleAllRowsSelected,
                        setIsDeleting,
                        selectedFlatRows
                      );
                    }}
                  >
                    <UndoIcon />
                  </IconButton>
                )}
                {screenSize > SCREEN.SMALL ? (
                  <Button
                    size={"medium"}
                    variant={"contained"}
                    color={"primary"}
                    disabled={
                      isSubmitting || hasErrors || isEmpty(primaryCollection)
                    }
                    style={{ marginRight: 5 }}
                    onClick={onGridSubmit}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                ) : (
                  <IconButton
                    aria-label="edit"
                    color="inherit"
                    style={{ marginRight: 5 }}
                    disabled={
                      isSubmitting || hasErrors || isEmpty(primaryCollection)
                    }
                    onClick={() => {
                      return onDelete(
                        definition,
                        primaryCollection,
                        update,
                        isWidget,
                        deleteItems,
                        toggleAllRowsSelected,
                        setIsDeleting,
                        selectedFlatRows
                      );
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                )}
              </View>
            ) : undefined}
          </View>
        </View>
      </AppBar>
    </View>
  );
}
