/* eslint-disable react/prop-types */
import React from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
//import { View } from 'react-native';

const DropZoneNoData = ({ disabled, disabledText, onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    id: "import",
    name: "import",
    accept: null,
    onDrop,
    disabled: disabled,
    multiple: false,
  });

  let className = null;
  let placeholder =
    "Drag and drop a file here or click to select a file to parse.";
  let zoneActiveText = "Drop here ...";
  return (
    <div
      className={cx("form-element", "dropzone", { hasError: false })}
      style={{
        overflow: "visible",
        zIndex: 1,
        marginTop: 12,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 5,
        width: "100%",
      }}
    >
      <div
        className={cx(`dropzone-wrapper`, { isDisabled: disabled })}
        style={{ overflow: "visible", zIndex: 1, width: "100%" }}
      >
        <section>
          <div
            {...getRootProps()}
            className={cx("dropzone", className, {
              "dropzone--isActive": isDragActive,
              "dropzone--isDisabled": disabled,
            })}
          >
            {disabled ? (
              <p className="text">{disabledText}</p>
            ) : (
              <>
                <input {...getInputProps()} disabled={disabled} />
                {isDragActive ? (
                  <p className="text">{zoneActiveText}</p>
                ) : (
                  <p className="text">{placeholder}</p>
                )}
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default DropZoneNoData;
