/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback } from "react";
import { SafeAreaView, StyleSheet, View, Image } from "react-native";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Constants from "expo-constants";
import { scale, scaleVertical } from "../util/scale";
import { Platform } from "react-native";
import LogoutButton from "../components/authentication/LogoutButton";
import { withRouter } from "../ReactRouter";
import { withCache } from "../hoc/withCache";
import { isLoggedIn } from "../components/authentication/authUtils";
import { getFetchOptionsForAccessToken } from "../AccessToken";
import { navigateAndDisplayMessage } from "../util/msgHandler";
import getEnvironment from "../environment/env";
import { getFCMToken } from "../messagingDummy";

const styles = StyleSheet.create({
  buttonContainer: {
    height: 50,
    flexDirection: "row",
    paddingTop: 8,
  },
  rightButton: {
    marginLeft: 5,
    height: 20,
    flex: 1,
    flexGrow: 1,
  },
  nestedButtonView: {
    flexDirection: "row",
    alignItems: "center",
  },

  iconLeft: {
    width: "40px",
  },

  buttonText: {
    flex: 1,
    textAlign: "center",
  },

  container: {
    flex: 1,
    width: Platform.OS === "web" ? 400 : "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignSelf: "center",
    paddingTop: Constants.statusBarHeight,
  },
  main: {
    flexGrow: 1,
    borderBottomWidth: 1,
  },
  screen: {
    paddingTop: Constants.statusBarHeight,
    paddingBottom: scaleVertical(24),
    paddingHorizontal: scale(16),
    flex: 1,
    backgroundColor: "rgb(245, 245, 245)",
  },
  close: {
    position: "absolute",
    top: Constants.statusBarHeight + 4,
    left: 16,
    zIndex: 1,
  },
  header: {
    alignItems: Platform.OS === "web" ? undefined : "center",
    justifyContent: "center",
  },
  image: {
    height: scaleVertical(100),
    resizeMode: "contain",
  },
  all: {
    marginTop: scaleVertical(24),
    flex: 1,
    paddingLeft: 50,
    paddingRight: 50,
    justifyContent: "center",
  },
  content: {
    justifyContent: "space-between",
    paddingRight: 15,
    borderWidth: 1,
    borderColor: "lightgray",
    paddingVertical: scaleVertical(12),
  },
  input: {
    borderWidth: 0.5,
    borderColor: "#D3D3D3",
    borderRadius: 50,
    marginVertical: scaleVertical(6),
    fontWeight: "bold",
  },
  OR: {
    marginVertical: scaleVertical(12),
    fontSize: 16,
    fontWeight: "bold",
    color: "#4A4A4A",
  },
  socialLogin: {
    height: 50,
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  footer: {
    justifyContent: "space-between",
    marginTop: scaleVertical(28),
    paddingHorizontal: 8,
    paddingVertical: scaleVertical(12),
  },
  textRow: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
const TwoFactor = ({ changeLoginState, twoFactorToken, cache, history }) => {
  const [code, setCode] = useState("");

  const handleTextFieldChange = useCallback((event) => {
    event.preventDefault();
    setCode({
      code: event.target.value,
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    let url = new URL(getEnvironment().API_URL + "/login_two_factor");
    let pushToken = await getFCMToken();
    let params = {
      uiversion: Constants.manifest.version,
      code,
      accessToken: twoFactorToken,
      pushToken,
    };
    let result = await fetch(url, {
      ...getFetchOptionsForAccessToken(),
      body: JSON.stringify(params),
    });
    let data = result ? await result.json() : null;
    if (data && data.ok) {
      isLoggedIn(data, changeLoginState);
    } else {
      changeLoginState(false, data);
      navigateAndDisplayMessage(
        data,
        null,
        history,
        "Invalid code",
        "Login Failed"
      );
    }
  }, [code, changeLoginState, history, twoFactorToken]);

  const renderIcon = useCallback(() => {
    return (
      <Image
        style={styles.image}
        // eslint-disable-next-line no-undef
        source={require("../assets/images/logo.png")}
      />
    );
  }, []);

  const { current: containerStyle } = useRef({
    flex: 1,
    margin: 5,
    justifyContent: "center",
  });

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.main}>
        <View style={styles.header}>{renderIcon()}</View>
        <View style={styles.all}>
          <View style={styles.content}>
            <Typography
              key={"jpt"}
              variant="h6"
              display="block"
              color={"inherit"}
              style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
            >
              Two Factor Authentication
            </Typography>
            <TextField
              id="code"
              label="Verification Code"
              onChange={(e) => handleTextFieldChange(e)}
            />
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                width: "100%",
                paddingLeft: 15,
              }}
            >
              <Button
                size={"small"}
                variant={"contained"}
                color={"secondary"}
                onClick={handleSubmit}
                style={{ justifyContent: "center", margin: 5, flex: 1 }}
              >
                {"Verify"}
              </Button>
              <LogoutButton
                containerStyle={containerStyle}
                changeLoginState={changeLoginState}
              />
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default withCache(withRouter(TwoFactor));
