import React from "react";
import { TouchableOpacity, View } from "react-native";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";

const OuterDrawerItem = ({ extraStyles, label, onPress, isFolder }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        paddingTop: 21,
        paddingBottom: 16,
        paddingLeft: 15,
        paddingRight: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" display="block" color={"inherit"}>
          {label}
        </Typography>
        {isFolder ? <ChevronRightIcon /> : undefined}
      </View>
    </TouchableOpacity>
  );
};

export default OuterDrawerItem;
