import definitionHelper from "./definitions.js";
import {
  isEmpty,
  isNull,
  getLookupDefinition,
  renderFieldValue,
} from "./common";
import Constants from "expo-constants";

export function getTokenResolversFromTemplate(props, rootDefinition, template) {
  const re = /{[\w!? :"-]*\}/g; // match initial regex
  let templateStr = isEmpty(template) ? "" : template;
  let rawTokens = templateStr.match(re);
  let stringTokens = [];
  if (!isEmpty(rawTokens)) {
    for (let i = 0; i < rawTokens.length; i++) {
      let rawToken = rawTokens[i];
      if (rawToken.indexOf("!") > -1) {
        let fixedToken = rawToken.substring(1, rawToken.length - 1);
        if (stringTokens.indexOf(fixedToken) === -1) {
          stringTokens.push(fixedToken);
        }
      }
      if (rawToken.indexOf("?") > -1) {
        let fixedToken = rawToken.substring(1, rawToken.length - 1);
        if (stringTokens.indexOf(fixedToken) === -1) {
          stringTokens.push(fixedToken);
        }
      }
    }
  }
  return getTokenResolvers(props, rootDefinition, stringTokens);
}
export function getTokenResolverArray(props, rootDefinition, stringToken) {
  let baseFieldNames = stringToken.split("?");
  let lookupFieldNames = baseFieldNames[0].split("!");
  let resolvers = [];
  if (baseFieldNames[0].indexOf("!") >= 0) {
    resolvers = getResolverArray(
      props,
      true,
      rootDefinition,
      lookupFieldNames[0],
      lookupFieldNames.slice(1)
    );
  }
  if (baseFieldNames.length > 1) {
    let parts = baseFieldNames[1].split(":");
    let tResolvers = [];
    if (!parts[0].startsWith('"') && parts[0].indexOf("!") >= 0) {
      let lookupFieldNames = parts[0].split("!");
      tResolvers = getResolverArray(
        props,
        true,
        rootDefinition,
        lookupFieldNames[0],
        lookupFieldNames.slice(1)
      );
    }
    let fResolvers = [];
    if (
      parts &&
      parts.length > 1 &&
      !parts[1].startsWith('"') &&
      parts[1].indexOf("!") >= 0
    ) {
      let lookupFieldNames = parts[1].split("!");
      fResolvers = getResolverArray(
        props,
        true,
        rootDefinition,
        lookupFieldNames[0],
        lookupFieldNames.slice(1)
      );
    }
    resolvers.push({
      triggerToken: baseFieldNames[0],
      tValue: parts[0],
      tResolvers: tResolvers,
      fResolvers: fResolvers,
      fValue: parts.length > 1 ? parts[1] : "",
      conditional: true,
    });
  }
  return resolvers;
}

export function getTokenResolvers(props, rootDefinition, stringTokens) {
  let tokenResolvers = [];
  if (!isEmpty(stringTokens)) {
    for (let i = 0; i < stringTokens.length; i++) {
      let tokenResolver = getTokenResolver(
        props,
        rootDefinition,
        stringTokens[i]
      );
      if (!isNull(tokenResolver)) {
        tokenResolvers.push(tokenResolver);
      }
    }
  }
  return tokenResolvers;
}

export function getTokenResolver(props, rootDefinition, stringToken) {
  let tokenResolver = undefined;
  let resolvers = getTokenResolverArray(props, rootDefinition, stringToken);
  if (resolvers.length > 0) {
    tokenResolver = { token: stringToken, resolvers };
  }
  return tokenResolver;
}

function getBaseTokens(rootDefinition, item) {
  let tokens = {};
  tokens["%%version"] = Constants.manifest.version;
  if (!isNull(item.id)) tokens["id"] = item.id;
  let allFields = definitionHelper.findAllDisplayFields(rootDefinition.fields);
  if (allFields) {
    for (let i = 0; i < allFields.length; i++) {
      let field = allFields[i];
      if (field) {
        let value = item[field.fieldName];
        if (field.type === "file" && !isNull(value)) {
          value = JSON.parse(value);
          if (value && value.id) {
            tokens[field.fieldName] = value.id;
            tokens[field.fieldName + "!fileName"] = value.fileName;
            tokens[field.fieldName + "!fileSize"] = value.fileSize;
            tokens[field.fieldName + "!mimeType"] = value.mimeType;
          } else {
            value = undefined;
            tokens[field.fieldName] = value;
          }
        } else {
          if (
            !isNull(value) &&
            (field.widget === "currency" ||
              field.format === "date" ||
              field.format === "date-time")
          ) {
            tokens[field.fieldName] = renderFieldValue(
              {},
              field,
              value,
              null,
              true,
              null,
              false,
              null,
              false
            );
          } else {
            tokens[field.fieldName] = value;
          }
        }
      }
    }
  }
  return tokens;
}
export function resolveTokens(
  layoutGraph,
  rootDefinition,
  item,
  tokenResolvers
) {
  let tokens = getBaseTokens(rootDefinition, item);
  if (!isEmpty(tokenResolvers)) {
    for (let i = 0; i < tokenResolvers.length; i++) {
      let tokenResolver = tokenResolvers[i];
      tokens[tokenResolver.token] = resolveToken(
        layoutGraph,
        item,
        [...tokenResolver.resolvers],
        tokens
      );
    }
  }
  return tokens;
}

export function resolveToken(layoutGraph, item, tokenResolvers, tokens = null) {
  if (isNull(tokens)) tokens = item;
  if (!isEmpty(tokenResolvers) && !isNull(layoutGraph)) {
    let step = tokenResolvers[0];

    if (step.conditional) {
      let value = "";
      let conditionalResolvers = [];

      if (tokens[step.triggerToken]) {
        value = step.tValue;
        if (step.tResolvers && step.tResolvers.length > 0) {
          conditionalResolvers = step.tResolvers;
        }
      } else {
        value = step.fValue;
        if (step.fResolvers && step.fResolvers.length > 0) {
          conditionalResolvers = step.fResolvers;
        }
      }
      if (!value) value = "";
      if (value.length > 1 && value.startsWith('"') && value.endsWith('"')) {
        return value.substring(1, value.length - 1);
      } else {
        if (conditionalResolvers && conditionalResolvers.length > 0) {
          return resolveToken(
            layoutGraph,
            item,
            [...conditionalResolvers],
            tokens
          );
        }
        value = tokens[value];
        if (!value) value = "";
      }
      return value;
    } else {
      let value = undefined;
      if (step.graph === null) {
        if (item !== undefined) {
          value = item[step.field.fieldName];
          if (step.field.type === "file" && !isNull(value)) {
            value = JSON.parse(value);
            if (value && value.id) {
              value = value.id;
            } else {
              value = undefined;
            }
          }
        }
      } else {
        let collection = layoutGraph[step.graph];
        if (!isEmpty(collection)) {
          for (let i = 0; i < collection.length; i++) {
            let row = collection[i];
            if (!isNull(row) && row.id === item.id) {
              value = row[step.field.fieldName];
              if (step.field.type === "file" && !isNull(value)) {
                value = JSON.parse(value);
                if (value && value.id) {
                  value = value.id;
                } else {
                  value = undefined;
                }
              }
              break;
            }
          }
        }
      }
      if (value !== undefined) {
        if (isNull(value)) {
          return null;
        } else {
          if (tokenResolvers.length === 1) {
            if (step.field.widget === "currency") {
              return "$" + value;
            } else {
              return value;
            }
          } else {
            return resolveToken(
              layoutGraph,
              { id: value },
              tokenResolvers.splice(1)
            );
          }
        }
      } else {
        return undefined;
      }
    }
  } else {
    return undefined;
  }
}

function getResolverArray(
  props,
  isRoot,
  definition,
  fieldName,
  lookupFieldNames
) {
  let field = definitionHelper.findField(definition.fields, fieldName);
  if (!isNull(field)) {
    let tokenResolver = {
      graph: isRoot ? null : "all" + definition.pluralName,
      field: field,
      conditional: false,
    };
    if (lookupFieldNames.length > 0) {
      if (!isEmpty(field.lookup)) {
        let lookupDefinition = getLookupDefinition(props, field);
        if (!isNull(lookupDefinition)) {
          let children = getResolverArray(
            props,
            false,
            lookupDefinition,
            lookupFieldNames[0],
            lookupFieldNames.slice(1)
          );
          if (!isEmpty(children)) {
            return [tokenResolver, ...children];
          } else {
            return [];
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [tokenResolver];
    }
  } else {
    return [];
  }
}
