/* eslint-disable react/prop-types */
import React, { Suspense, useRef, useState, useMemo, useCallback } from "react";
import { View, TouchableOpacity, Platform } from "react-native";
import Skeleton from "@material-ui/lab/Skeleton";
import Checkbox from "@material-ui/core/Checkbox";
import * as cloneDeep from "lodash/cloneDeep";
import { FontAwesome } from "@expo/vector-icons";
import { Table, TableWrapper, Rows, Col } from "../table";
import { Link } from "../ReactRouter";
import definitionHelper from "../util/definitions.js";
import { SCREEN, SUBDEFINITIONS } from "../util/constants";
import {
  isNull,
  isEmpty,
  renderFieldValue,
  getLookupDefinition,
  getEntityCollection,
} from "../util/common.js";
import EntityTable from "./EntityTable";
import { lowerCasePluralName } from "./tableCommon";
import { getWidget } from "../form/helper";
import TabBar from "../components/TabBar";
import DropZoneNoData from "../components/dropZoneNoData";
import { ConfirmAlert } from "../components/ConfirmAlert";
import Toast from "../components/Toast";
import {
  DefaultColumnFilter,
  dateComparator,
  BlackTypography,
  BlueTypography,
  fieldViewSecured,
  filterRowCheck,
  gridFieldAccessor,
  renderValue,
  renderValueAsJSX,
  findField,
  getFormatField,
  getGeoField,
  getDefaultFilter,
} from "./gridCommon";
import { useWorkflow } from "../hooks/useWorkflow";

const isWeb = Platform.OS === "web";

const GridBase = (props) => {
  const rootDefinition = useRef(
    definitionHelper.fixDefinition(
      cloneDeep(
        props.definition
          ? props.definition
          : props.entityFactory
          ? props.entityFactory.definition
          : definition
      )
    )
  );
  const formatField = useRef(getFormatField(rootDefinition.current));
  const definition = useRef(
    definitionHelper.findSubDefinition(
      rootDefinition.current,
      props.gridSubDef ? props.gridSubDef : SUBDEFINITIONS.GRID.NAME
    )
  );
  const geoFieldResolver = useRef(
    getGeoField(props, rootDefinition.current, props.geoFieldName)
  );
  const { executeAction } = useWorkflow();

  const [state, setState] = useState(() => {
    return {
      showEditForm: false,
      showModal: false,
      showViewModal: false,
      showFilter: isNull(props.showFilter) || !props.showFilter ? [] : [1],
      defaultSort: isEmpty(props.defaultSort) ? [] : props.defaultSort,
      defaultFilter: getDefaultFilter(props.defaultFilter),
      selectAll: false,
      dirty: false,
      deleting: false,
      saving: false,
      processing: false,
      gridMode: 0,
      selection: [],
      isSubmitting: false,
    };
  });

  const renderCell = (cell, field) => {
    let idArray = cell.column.id.split(".");
    let fieldName = idArray[0];
    let backgroundColor =
      formatField.current.hasFormatField &&
      cell.row &&
      cell.row.original.raw[formatField.current.formatFieldName] &&
      cell.row.original.raw[formatField.current.formatFieldName][fieldName] &&
      cell.row.original.raw[formatField.current.formatFieldName][fieldName].bg
        ? cell.row.original.raw[formatField.current.formatFieldName][fieldName]
            .bg
        : undefined;
    if (props.isGridArray) {
      let index = -cell.row.original.id - 1;
      let Widget = getWidget(false, field);
      let fieldTitle = isNull(field.title) ? field.fieldName : field.title;
      //isFocused should be passed in somehow.
      let {
        parentName,
        screenSize,
        disabled,
        allEntityFactories,
        layoutGraph,
        currentUser,
        setFocus,
      } = props;
      if (field.type === "button" || field.type === "login") {
        //let formAction = { name: "unknown", style: undefined, title: "unknown" };
        return <View />; //<Widget formAction={formAction} key={"workflow_" + formAction.name} onClick={undefined} disabled={false} bsStyle={!isEmpty(formAction.style) ? formAction.style : "default"} title={formAction.title} />
      } else {
        return (
          <Suspense
            fallback={<Skeleton animation="wave" width="100%" height={40} />}
          >
            <Widget
              screensize={screenSize}
              disabled={disabled}
              definitionfield={field}
              allentityfactories={allEntityFactories}
              layoutgraph={isNull(layoutGraph) ? {} : layoutGraph}
              rootdefinition={rootDefinition.current}
              currentuser={currentUser}
              setfocus={setFocus}
              placeholder={field.tooltip}
              name={`${parentName}.${index}.${field.fieldName}`}
              label={fieldTitle}
              required={field.required}
              publicstyle={props.publicStyle}
            />
          </Suspense>
        );
      }
    } else {
      if (isWeb) {
        return (
          <div
            style={{
              flex: 1,
              cursor: "default",
              margin: 0,
              padding: 0,
              textAlign: cell.column.textAlign
                ? cell.column.textAlign
                : undefined,
              background: backgroundColor,
            }}
          >
            {renderValueAsJSX(
              props,
              field,
              cell,
              fieldName,
              cell.cell.value,
              cell.column.lookupField,
              cell.column.lookup ? cell.row.original.lookups[fieldName] : null
            )}
          </div>
        );
      } else {
        return (
          <View
            style={{
              flex: 1,
              cursor: "default",
              margin: 0,
              padding: 0,
              textAlign: cell.column.textAlign
                ? cell.column.textAlign
                : undefined,
              background: backgroundColor,
            }}
          >
            {renderValueAsJSX(
              props,
              field,
              cell,
              fieldName,
              cell.cell.value,
              cell.column.lookupField,
              cell.column.lookup ? cell.row.original.lookups[fieldName] : null
            )}
          </View>
        );
      }
    }
  };

  const lookupComparator = (a, b, lookupDef, lookupField) => {
    let collection = getEntityCollection(props, lookupDef);
    let rowA = null;
    let rowB = null;
    if (collection) {
      for (let i = 0; i < collection.length; i++) {
        let item = collection[i];
        if (item.id === a) {
          rowA = item;
          if (!isNull(rowB)) break;
        }
        if (item.id === b) {
          rowB = item;
          if (!isNull(rowA)) break;
        }
      }
    }
    let valA = rowA ? rowA[lookupField.fieldName] : null;
    let valB = rowB ? rowB[lookupField.fieldName] : null;
    if (
      lookupField.type === "string" &&
      (lookupField.format === "date" || lookupField.format === "date-time")
    ) {
      return dateComparator(valA, valB);
    } else {
      if (isEmpty(valA) && isEmpty(valB)) {
        return 1;
      } else if (isEmpty(a)) {
        return 1;
      } else if (isEmpty(b)) {
        return -1;
      } else {
        return valA > valB ? -1 : 1;
      }
    }
  };

  const substringFilter = (rows, id, filterValue) => {
    if (props.substringFilterOverride)
      return props.substringFilterOverride(
        props,
        definition.current,
        rows,
        id,
        filterValue
      );

    let idName = id[0];
    let idArray = idName.split(".");
    let fieldName = idArray[0];
    let lookupField = null;
    if (idArray.length > 1) {
      lookupField = idArray[1];
    }
    let field = findField(definition.current.fields, fieldName);

    return rows.filter((row) => {
      let value = "";
      //const rowValue = row.values[id]
      if (idName.length === 1) {
        value = renderFieldValue(
          props,
          field,
          row.original.raw[fieldName],
          null,
          false,
          null,
          false,
          "     "
        );
      } else {
        value = renderFieldValue(
          props,
          field,
          row.original.raw[fieldName],
          lookupField,
          false,
          row.original.lookups[fieldName],
          false,
          "     "
        );
      }
      return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
    });
  };

  const fieldAccessor = (row, field) => {
    let result = { success: false };
    if (props.fieldAccessorOverride) {
      result = props.fieldAccessorOverride(props, row, field);
    }
    if (result.success) {
      return result.data;
    } else {
      return gridFieldAccessor(row, field);
    }
  };

  const addColumns = (columns, hiddenColumns, fields, showField) => {
    let { minimal } = definition.current;
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (
        !fieldViewSecured(props.layoutGraph, field) &&
        !field.hidden &&
        field.type !== "button"
      ) {
        if (field.type === "object") {
          addColumns(
            columns,
            hiddenColumns,
            field.definition.fields,
            showField
          );
        } else {
          if (field.lookup) {
            let lookupDef = getLookupDefinition(props, field);
            let lookupFields = definitionHelper.findPrimaryFields(
              lookupDef,
              field.lookupFields,
              field.additionalOnly
            );
            if (lookupFields.length > 0) {
              for (let j = 0; j < lookupFields.length; j++) {
                let lookupField = lookupFields[j];
                if (!showField) {
                  hiddenColumns.push(
                    field.fieldName + "." + lookupField.fieldName
                  );
                }
                columns.push({
                  id: field.fieldName + "." + lookupField.fieldName,
                  Header:
                    lookupFields.length === 1
                      ? field.title
                      : field.title + ": " + lookupField.title,
                  primaryField: field.primaryField,
                  lookup: field.lookup,
                  lookupField: lookupField.fieldName,
                  textAlign: field.textAlign
                    ? field.textAlign
                    : definition.current.textAlign
                    ? definition.current.textAlign
                    : undefined,
                  accessor: (row) => {
                    return fieldAccessor(row, field);
                  },
                  Cell: (cell) => {
                    return renderCell(cell, field);
                  },
                  sortMethod: (a, b) => {
                    return lookupComparator(a, b, lookupDef, lookupField);
                  },
                  Filter: DefaultColumnFilter,
                  filter: substringFilter,
                });
              }
            } else {
              if (!showField) {
                hiddenColumns.push(field.fieldName);
              }
              columns.push({
                id: field.fieldName,
                Header: field.title,
                primaryField: field.primaryField,
                lookup: field.lookup,
                lookupField: null,
                textAlign: field.textAlign
                  ? field.textAlign
                  : definition.current.textAlign
                  ? definition.current.textAlign
                  : undefined,
                accessor: (row) => {
                  return fieldAccessor(row, field);
                },
                Cell: (cell) => {
                  return renderCell(cell, field);
                },
                Filter: DefaultColumnFilter,
                filter: substringFilter,
              });
            }
          } else {
            let col = {
              id: field.fieldName,
              Header: field.title,
              primaryField: field.primaryField,
              lookup: null,
              lookupField: null,
              textAlign: field.textAlign
                ? field.textAlign
                : definition.current.textAlign
                ? definition.current.textAlign
                : undefined,
              accessor: (row) => {
                return fieldAccessor(row, field);
              },
              Cell: (cell) => {
                return renderCell(cell, field);
              },
              Filter: DefaultColumnFilter,
              filter: substringFilter,
            };
            if (
              field.type === "string" &&
              (field.format === "date" || field.format === "date-time")
            ) {
              col.sortType = "customDateComparator";
            }
            if (!showField) {
              hiddenColumns.push(field.fieldName);
            }
            columns.push(col);
          }
        }
      }
      if (
        columns.length > 1 &&
        (props.screenSize === SCREEN.SMALL || minimal)
      ) {
        showField = false;
      }
    }
  };

  const onSelectedChanged = (selectedRowIds) => {
    if (props.onSelectedChangedOverride) {
      return;
      //return props.onSelectedChangedOverride(selectedRowIds, state, setState);
    }

    if (props.Form) {
      let newIds = !isNull(selectedRowIds) ? Object.keys(selectedRowIds) : [];
      if (isNull(newIds)) newIds = [];
      for (let i = 0; i < newIds.length; i++) {
        newIds[i] = parseInt(newIds[i]);
      }
      let newState = {};
      if (state.selection.toString() !== newIds.toString()) {
        newState.selection = newIds;
        // if (props.gridMode === 0) {
        //   if (newIds.length === 1) {
        //     newState.selectedItem = props.primaryCollection.find(
        //       (x) => x.id === newIds[0]
        //     );
        //   } else {
        //     newState.selectedItem = null;
        //   }
        // }
        setState({ ...state, ...newState });
        if (props.setGridSelection) {
          props.setGridSelection(newIds);
        }
      }
    }
  };

  const { setSelection } = props;
  const onManagedRowSelect = useCallback(
    (row, onChange, args) => {
      if (row && !isNull(row.id)) {
        if (setSelection) {
          setSelection(row.id);
        } else {
          onChange(args);
        }
      }
    },
    [setSelection]
  );

  const onWafelClick = (script, entityId) => {
    const { layoutGraph } = props;
    let allWorkflows =
      layoutGraph && !isEmpty(layoutGraph.allWorkflows)
        ? layoutGraph.allWorkflows
        : [];
    let workflow = undefined;
    if (
      !isNull(entityId) &&
      entityId > 0 &&
      definition.current &&
      !isEmpty(allWorkflows)
    ) {
      for (let j = 0; j < allWorkflows.length; j++) {
        let workflowIter = allWorkflows[j];
        if (
          workflowIter.entityName === definition.current.entityName &&
          workflowIter.requiresEntityId
        ) {
          if (workflowIter.name === script) {
            workflow = workflowIter;
            break;
          }
        }
      }
    }
    if (!isNull(workflow)) {
      executeAction(
        workflow,
        null,
        props.onSubmit,
        props.entityFactory,
        entityId
      );
    }
  };

  const allFieldsSubstringFilter = (rows, id, filterValue) => {
    return rows.filter((row) => {
      return filterRowCheck(
        props,
        definition.current.fields,
        row,
        filterValue.toLowerCase()
      );
    });
  };

  const getColumns = () => {
    let fields = definitionHelper.findAllDisplayFields(
      definition.current.fields,
      false
    );
    let hiddenColumns = [];
    let columns = [
      {
        id: "selection",
        disableResizing: true,
        minWidth: 35,
        width: 35,
        maxWidth: 35,
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => {
          let headerProps = getToggleAllRowsSelectedProps();
          let newProps = { ...headerProps };
          newProps.onChange = (args) => {
            onManagedRowSelect({ id: 0 }, headerProps.onChange, args);
          };
          if (isWeb) {
            delete newProps.indeterminate;
            return (
              <input
                type="checkbox"
                disabled={props.checkBoxDisabled ? true : false}
                {...newProps}
              />
            );
          } else {
            return (
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "flex-start",
                }}
              >
                <Checkbox
                  disabled={props.checkBoxDisabled ? true : false}
                  style={{ marginLeft: -10, paddingLeft: 0 }}
                  onChange={newProps.onChange}
                  checked={newProps.checked}
                />
              </View>
            );
          }
        },
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => {
          let rowProps = row.getToggleRowSelectedProps();
          if (isWeb) {
            let newProps = { ...rowProps };
            newProps.onChange = (args) => {
              onManagedRowSelect(row, rowProps.onChange, args);
            };
            delete newProps.indeterminate;
            return (
              <input
                type="checkbox"
                disabled={props.checkBoxDisabled ? true : false}
                {...newProps}
              />
            );
          } else {
            return (
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "flex-start",
                }}
              >
                <Checkbox
                  disabled={props.checkBoxDisabled ? true : false}
                  style={{ marginLeft: -10, paddingLeft: 0 }}
                  onChange={rowProps.onChange}
                  checked={rowProps.checked}
                />
              </View>
            );
          }
        },
      },
    ];

    addColumns(columns, hiddenColumns, fields, true);

    columns.push({
      // Make an expander cell
      disableResizing: true,
      minWidth: !isEmpty(props.defaultFilter) || props.showFilter ? 150 : 35,
      width: !isEmpty(props.defaultFilter) || props.showFilter ? 150 : 35,
      maxWidth: !isEmpty(props.defaultFilter) || props.showFilter ? 150 : 35,
      Header: function FA() {
        <FontAwesome name="filter" style={{ fontSize: 18, color: "white" }} />;
      }, // No header
      id: "expander", // It needs an ID
      Cell: ({ row }) => {
        // Use Cell to render an expander for each row.
        // We can use the getExpandedToggleProps prop-getter
        // to build the expander. //'👇' : '👉'}
        let expanderProps = row.getToggleRowExpandedProps();
        if (isWeb) {
          return (
            <div>
              {row.isExpanded ? (
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: 25,
                    padding: "0",
                    textAlign: "center",
                    userSelect: "none",
                  }}
                  onClick={expanderProps.onClick}
                >
                  &#x2299;
                </span>
              ) : (
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: 25,
                    padding: "0",
                    textAlign: "center",
                    userSelect: "none",
                  }}
                  onClick={expanderProps.onClick}
                >
                  &#x2295;
                </span>
              )}
            </div>
          );
        } else {
          return (
            <TouchableOpacity onPress={expanderProps.onClick}>
              {row.isExpanded ? (
                <FontAwesome
                  name="dot-circle-o"
                  style={{ color: "blue", fontSize: 18 }}
                />
              ) : (
                <FontAwesome
                  name="plus-circle"
                  style={{ color: "blue", fontSize: 18 }}
                />
              )}
            </TouchableOpacity>
          );
        }
      },
      Filter: DefaultColumnFilter,
      filter: allFieldsSubstringFilter,
    });
    return { columns, hiddenColumns };
  };

  const showFullPageLink = (row) => {
    if (props.noFullPageLink) return false;
    return (
      (isNull(props.showFullPageLink) ? true : props.showFullPageLink) &&
      ((row && row.original && row.original.id > 0) ||
        (definition.current.guidId &&
          row &&
          row.original &&
          !isNull(row.original.id)))
    );
  };

  const getSubComponentFields = (fields) => {
    let fieldItems = [];
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (field.type === "object") {
        fieldItems.push(
          ...getSubComponentFields(field.definition.fields, fieldItems)
        );
      } else {
        if (field.showInMore && !fieldViewSecured(props.layoutGraph, field)) {
          fieldItems.push(field);
        }
      }
    }
    return fieldItems;
  };

  const getBaseEntityItemURL = () => {
    if (props.getBaseEntityItemURLOverride)
      return props.getBaseEntityItemURLOverride();
    return "/entities/" + lowerCasePluralName(definition.current);
  };

  const getSubComponent = ({ row }) => {
    let fields = definitionHelper.findAllDisplayFields(
      definition.current.fields
    );
    let Fields = ({ fieldItems }) => {
      let titles = [];
      let data = [];
      let heightArr = [];
      for (let i = 0; i < fieldItems.length; i++) {
        let field = fieldItems[i];
        titles.push(field.title);
        heightArr.push(28);
        data.push([
          renderValue(
            props,
            field,
            field.fieldName,
            fieldAccessor(row.original, field),
            null,
            field.lookup ? row.original.lookups[field.fieldName] : null,
            true
          ),
        ]);
      }
      if (showFullPageLink(row)) {
        titles.push("");
        data.push([
          <TouchableOpacity
            style={{ flex: 1 }}
            key={"link"}
            onPress={() => {
              props.history.push(
                getBaseEntityItemURL() + "/" + row.original.id.toString()
              );
            }}
          >
            <BlueTypography variant="body1">Full Page View</BlueTypography>
          </TouchableOpacity>,
        ]);
      }
      if (props.screenSize === SCREEN.SMALL) {
        return (
          <TableWrapper
            style={{ flexDirection: "column", flex: 1, borderWidth: 1 }}
          >
            {titles.map((title, index) => {
              return (
                <View
                  key={"tbli" + index.toString()}
                  style={{
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Col
                    data={[title]}
                    style={{
                      backgroundColor: "#f6f8fa",
                      flex: Platform.OS === "web" ? 1 : 10,
                    }}
                    textStyle={{ textAlign: "left", fontWeight: "bold" }}
                  />
                  <Rows
                    data={[data[index]]}
                    flexArr={[10]}
                    style={{ height: 28 }}
                    textStyle={{ textAlign: "left" }}
                  />
                </View>
              );
            })}
          </TableWrapper>
        );
      } else {
        return (
          <TableWrapper
            style={{ flexDirection: "row", flex: 1, borderWidth: 1 }}
          >
            <Col
              data={titles}
              style={{
                backgroundColor: "#f6f8fa",
                flex: Platform.OS === "web" ? 1 : 10,
              }}
              textStyle={{ textAlign: "left" }}
            />
            <Rows
              data={data}
              flexArr={[10]}
              style={{ height: 28 }}
              textStyle={{ textAlign: "left" }}
            />
          </TableWrapper>
        );
      }
    };
    let fieldItems = getSubComponentFields(fields);
    return (
      <View
        style={{
          flex: 1,
          padding: 16,
          paddingTop: 30,
          backgroundColor: "#fff",
          flexDirection: "column",
        }}
      >
        <Table
          borderStyle={{
            borderWidth: 1,
            borderColor: "#c8e1ff",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Fields fieldItems={fieldItems} />
        </Table>
      </View>
    );
  };

  const hasSomeEditRights = () => {
    const { entityFactory, layoutGraph } = props;
    let currentUser = layoutGraph ? layoutGraph.currentUser : null;
    return entityFactory && entityFactory.hasSomeEditRights(currentUser);
  };

  const hasSomeDeleteRights = () => {
    const { entityFactory, layoutGraph } = props;
    let currentUser = layoutGraph ? layoutGraph.currentUser : null;
    return entityFactory && entityFactory.hasSomeDeleteRights(currentUser);
  };

  const hasSomeAddRights = () => {
    const { entityFactory, layoutGraph } = props;
    let currentUser = layoutGraph ? layoutGraph.currentUser : null;
    return entityFactory && entityFactory.hasSomeAddRights(currentUser);
  };

  const wrapWithLink = (definition, cell, value) => {
    let showLink = definition.guidId;
    if (!showLink) {
      showLink = cell.row.original.id > 0;
    }
    if (!definition.readOnly && (showLink || props.onSelectedChangedOverride)) {
      if (props.onSelectedChangedOverride) {
        return (
          <TouchableOpacity
            onPress={() => {
              let ids = {};
              ids[cell.row.original.id.toString()] = true;
              props.onSelectedChangedOverride(ids, state, setState);
            }}
          >
            <View style={{ flex: 1, height: "100%" }}>{value}</View>
          </TouchableOpacity>
        );
      } else {
        return (
          <Link
            style={{ flex: 1, textDecoration: "none" }}
            key={cell.column.id}
            to={getBaseEntityItemURL() + "/" + cell.row.original.id.toString()}
          >
            <View style={{ flex: 1, height: "100%" }}>{value}</View>
          </Link>
        );
      }
    } else {
      return value;
    }
  };

  const setActiveTab = (index) => {
    if (props.Form) {
      if (props.setGridMode) {
        props.setGridMode(props.formName, index);
      } else {
        setState({ ...state, gridMode: index });
      }
    }
  };

  const onGridSubmitConfirm = () => {
    setState({ ...state, isSubmitting: true });
    let fields = definitionHelper.findAllDisplayFields(props.definition.fields);

    let queryParams = [];
    let updateFields = props.updateFields;
    for (let i = 0; i < props.primaryCollection.length; i++) {
      let row = props.primaryCollection[i];
      let updateRow = {};
      if (row.id > 0) {
        updateRow.ids = [row.id];
      } else {
        updateRow.ids = [];
      }
      updateRow.versionId = row.versionId > 0 ? row.versionId : null;
      updateRow.updateFields = updateFields;
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j];
        if (updateFields.indexOf(field.fieldName) >= 0) {
          if (!isNull(row[field.fieldName])) {
            updateRow[field.fieldName] = row[field.fieldName];
          } else {
            updateRow[field.fieldName] = null;
          }
        }
      }
      queryParams.push(updateRow);
    }

    props
      .update(queryParams)
      .catch((res) => {
        if (res.graphQLErrors) {
          const errors = res.graphQLErrors.map((error) => {
            return error.message;
          });
          for (let i = 0; i < errors.length; i++) {
            Toast.toast(`[Update Error]: ` + errors[i].message, 5000);
            console.log("ERROR!", errors[i].message);
          }
        }
        setState({ ...state, isSubmitting: false });
        throw res;
      })
      .then(() => {
        if (props.onGridSubmit) {
          props.onGridSubmit();
        }
        setState({ ...state, isSubmitting: false });

        console.log("Update Successful!");
      })
      .catch((res) => {
        Toast.toast(`[Update Error]: ` + res.toString(), 5000);
        return;
      });
  };

  const onGridSubmit = () => {
    if (props.update) {
      let skipConfirm = false;
      if (!skipConfirm) {
        let noConfirm = window.NOCONFIRM;
        if (!isEmpty(noConfirm)) {
          let first = noConfirm.substring(0, 1).toLowerCase();
          if (first === "t" || first === "1") {
            skipConfirm = true;
          }
        }
      }
      if (!skipConfirm) {
        ConfirmAlert({
          title: "Confirm Action",
          message: "Are you sure to import/update these records?",
          buttons: [
            {
              label: "Confirm",
              onClick: () => onGridSubmitConfirm(),
            },
            {
              label: "Cancel",
              onClick: () => {},
            },
          ],
        });
      } else {
        onGridSubmitConfirm();
      }
    }
  };

  const renderNoRowsMessage = () => {
    if (props.renderNoRowsMessageOverride)
      return props.renderNoRowsMessageOverride();

    let noRowsMessage = undefined;
    if (!isNull(props.onDrop)) {
      noRowsMessage = (
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DropZoneNoData onDrop={props.onDrop} />
        </View>
      );
    } else if (!isEmpty(props.fts)) {
      noRowsMessage = (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BlackTypography variant="body1">
            {"No rows found for search: '" + props.fts + "'."}
          </BlackTypography>
        </View>
      );
    } else if (!isEmpty(props.filters)) {
      noRowsMessage = (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BlackTypography variant="body1">
            No rows found for search.
          </BlackTypography>
        </View>
      );
    } else {
      noRowsMessage = (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BlackTypography variant="body1">No rows found.</BlackTypography>
        </View>
      );
    }
    return noRowsMessage;
  };

  const { defaultFilter, isSubmitting, selection, showFilter, defaultSort } =
    state;
  const {
    screenSize,
    outerScroll,
    Card,
    layoutGraph,
    mapViewPort,
    setMapViewPort,
    primaryCollection,
    allEntityFactories,
    readOnly,
    update,
    isWidget,
    entityFactory,
    publicStyle,
    viewMode,
    onSubmit,
  } = props;

  const selectedItem = useMemo(() => {
    if (selection && selection.length === 1) {
      return props.primaryCollection.find((x) => x.id === selection[0]);
    }
    return null;
  }, [selection, props.primaryCollection]);

  let currentUser = layoutGraph ? layoutGraph.currentUser : null;
  const { columns, hiddenColumns } = useMemo(
    () => getColumns(),
    [setSelection]
  );
  let AddForm = props.Form;
  let gridMode = props.setGridMode ? props.gridMode : state.gridMode;
  let noRowsMessage = renderNoRowsMessage();
  let ariaLabel = isEmpty(rootDefinition.current?.title)
    ? isEmpty(entityFactory)
      ? "Unknown"
      : entityFactory.lowerCaseEntityName()
    : rootDefinition.current.title;
  return (
    <TabBar
      ariaLabel={ariaLabel}
      activeKey={gridMode}
      style={{ flex: 4 }}
      type="none"
      publicStyle={publicStyle}
      screenSize={screenSize}
    >
      <TabBar.Item style={{ flex: 1 }} publicStyle={publicStyle}>
        <EntityTable
          defaultSort={defaultSort}
          showFilter={showFilter}
          columns={columns}
          hiddenColumns={hiddenColumns}
          outerScroll={outerScroll}
          screenSize={screenSize}
          definition={definition.current}
          getSubComponent={getSubComponent}
          geoFieldResolver={geoFieldResolver.current}
          initViewMode={
            isEmpty(viewMode) || ["grid", "card", "map"].indexOf(viewMode) < 0
              ? "grid"
              : viewMode
          }
          Card={Card}
          defaultFilter={defaultFilter}
          publicStyle={publicStyle}
          layoutGraph={layoutGraph}
          onSubmit={onSubmit}
          allEntityFactories={allEntityFactories}
          primaryCollection={primaryCollection}
          mapViewPort={mapViewPort}
          setMapViewPort={setMapViewPort}
          readOnly={readOnly}
          deleteItems={props.delete}
          update={update}
          isWidget={isWidget}
          currentUser={currentUser}
          hasSomeEditRights={
            hasSomeEditRights() &&
            rootDefinition.current.leftActions.indexOf("edit") >= 0
          }
          hasSomeDeleteRights={
            hasSomeDeleteRights() &&
            rootDefinition.current.leftActions.indexOf("delete") >= 0
          }
          hasSomeAddRights={
            hasSomeAddRights() &&
            rootDefinition.current.leftActions.indexOf("add") >= 0
          }
          setSelection={setSelection}
          wrapWithLink={wrapWithLink}
          fieldAccessor={fieldAccessor}
          entityFactory={entityFactory}
          cacheRoot={props.cacheRoot}
          setActiveTab={setActiveTab}
          onSelectedChanged={onSelectedChanged}
          noRowsMessage={noRowsMessage}
          isSubmitting={isSubmitting}
          onGridSubmit={
            !isNull(props.onGridSubmit) && !isWidget ? onGridSubmit : undefined
          }
          onGridDiscard={props.onGridSubmit}
          onWafelClick={onWafelClick}
          hasErrors={!isNull(props.onGridSubmit) ? props.hasErrors : false}
          hideViewSwitcher={props.hideViewSwitcher}
        />
      </TabBar.Item>
      <TabBar.Item
        style={{ flex: 1, flexDirection: "column" }}
        publicStyle={publicStyle}
      >
        {AddForm ? (
          <View style={{ flex: 1 }}>
            <AddForm
              subDefinition={props.subDefinition}
              isNew={true}
              onSubmit={props.onSubmit}
              cacheRoot={props.cacheRoot}
              navigation={props.navigation}
              renderLevel={props.renderLevel}
              setFormState={gridMode === 1 ? props.setFormState : undefined}
              formName={props.formName}
              hideActionButton={!isNull(props.setGridSelection)}
              entityFactory={props.entityFactory}
              entityId={null}
              readOnly={false}
              screenSize={props.cellScreenSize}
              allEntityFactories={props.allEntityFactories}
              layoutGraph={props.layoutGraph}
              navigationDefaults={props.navigationDefaults}
            />
          </View>
        ) : undefined}
      </TabBar.Item>
      <TabBar.Item
        style={{ flex: 1, flexDirection: "column" }}
        publicStyle={publicStyle}
      >
        {AddForm && selectedItem ? (
          <View style={{ flex: 1 }}>
            <AddForm
              subDefinition={props.subDefinition}
              onSubmit={props.onSubmit}
              cacheRoot={props.cacheRoot}
              navigation={props.navigation}
              renderLevel={props.renderLevel}
              setFormState={gridMode === 2 ? props.setFormState : undefined}
              formName={props.formName}
              hideActionButton={!isNull(props.setGridSelection)}
              entityFactory={props.entityFactory}
              entityId={null}
              item={selectedItem}
              readOnly={false}
              screenSize={props.cellScreenSize}
              allEntityFactories={props.allEntityFactories}
              layoutGraph={props.layoutGraph}
              navigationDefaults={props.navigationDefaults}
            />
          </View>
        ) : undefined}
      </TabBar.Item>
    </TabBar>
  );
};

export default GridBase;
