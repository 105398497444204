import React from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import FormBase from "../formBase";
import withUpdate from "./update.js";

const TaskForm = (props) => {
  return <FormBase {...props} />;
};

export default withStyles(
  (theme) => {
    return {
      root: {
        transform: "translateZ(0px)",
        flexGrow: 1,
      },
      exampleWrapper: {
        position: "relative",
        marginTop: theme.spacing(3),
        height: 380,
        backgroundColor: "green",
      },
      radioGroup: {
        margin: theme.spacing(1, 0),
      },
      speedDial: {
        position: "absolute",
        "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
          bottom: theme.spacing(12),
          right: theme.spacing(2),
        },
        "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
          top: theme.spacing(2),
          left: theme.spacing(2),
        },
      },
    };
  },
  { withTheme: true }
)(withTheme(withUpdate(TaskForm)));
