/* eslint-disable react/prop-types */
import React from "react";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Home from "./Home";
import TwoFactor from "./TwoFactor";
import Constants from "expo-constants";
import firebase from "firebase/compat/app";
import { View } from "react-native";
import withTaskInterrupt from "../hoc/withTaskInterrupt.js";
import Loading from "../components/loading";
import { isNull } from "../util/common.js";
import getEnvironment from "../environment/env";
import { getFCMToken } from "../messagingDummy";
import { TOKENS } from "../theme";

let WrappedHome = withTaskInterrupt(Home);

const Landing = ({
  currentUser,
  pageName,
  twoFactorToken,
  commonProps,
  changeLoginState,
  menu,
}) => {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#fff",
        paddingTop: Constants.statusBarHeight,
      }}
    >
      {commonProps.loggedIn ? (
        isNull(twoFactorToken) ? (
          <WrappedHome
            changeLoginState={changeLoginState}
            pageName={pageName}
            menu={menu}
            commonProps={commonProps}
            {...commonProps}
          />
        ) : (
          <TwoFactor
            twoFactorToken={twoFactorToken}
            currentUser={currentUser}
            changeLoginState={changeLoginState}
          />
        )
      ) : (
        <View style={{ flex: 1, flexDirection: "column", width: "100%" }}>
          <AppBar position="static" color="primary">
            <View
              style={{
                flexDirection: "row",
                height: 50,
              }}
            ></View>
          </AppBar>
          <View
            style={{
              flex: 1,
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
              backgroundImage: "linear-gradient(white 20%, DarkGray 100%)",
            }}
          >
            <View
              style={{
                flex: 1,
                height: "100%",
                maxWidth: 600,
                flexDirection: "column",
                backgroundColor: TOKENS?.backgroundColor,
                boxShadow: "0px 0px 10px 5px rgb(190,190,190)",
                border: "1px solid rgb(170,170,170)",
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Button
                  size={"small"}
                  variant={"outlined"}
                  color={"secondary"}
                  onClick={async () => {
                    let pushToken = await getFCMToken();
                    let params = {
                      pushToken,
                    };
                    await fetch(getEnvironment().API_URL + "/log_out", {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(params),
                    });
                    localStorage.clear();
                    firebase.auth().signOut();
                    if (changeLoginState) changeLoginState(false);
                    window.location.replace(getEnvironment().SITE_URL);
                  }}
                  style={{
                    width: 200,
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 10,
                  }}
                >
                  {"Reset"}
                </Button>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default Landing;
