import React, { useCallback, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { isNull } from "../util/common.js";

function getTaskQuery(BaseComponent, task, refetchNo) {
  //Ensure that we're not using a cached query. Force Apollo to requery by adding unused timestamp to query variables.
  let taskTimeStamp = task.timeStamp;
  let taskId = task.id;
  let taskQuery = graphql(
    gql(
      `query getTaskData($taskId: ID!) { getTaskData(taskId: $taskId) { id taskId taskStateData timeStamp } }`
    ),
    {
      options() {
        let variables = {
          taskId: taskId,
          taskTimeStamp: taskTimeStamp,
          refetchNo: refetchNo,
        };
        return {
          fetchPolicy: "network-only",
          fragments: {},
          variables: variables,
        };
      },
      props({ data }) {
        console.log(
          "=================== REFETCHING ... ",
          refetchNo,
          taskTimeStamp
        );
        let taskData = data["getTaskData"];
        let queryResult = isNull(taskData) ? null : taskData;
        return {
          queryResult: queryResult,
          subscribeToMore: data.subscribeToMore,
          queryLoading: data.loading,
        };
      },
    }
  );
  return taskQuery(BaseComponent);
}

function getStateFromProps(BaseComponent, currentUser, task, refetchNo) {
  if (!currentUser || !task)
    return {
      BaseComponentWithQuery: null,
      taskId: null,
      timeStamp: null,
      userId: null,
    };
  return {
    BaseComponentWithQuery: getTaskQuery(BaseComponent, task, refetchNo),
    taskId: task.id,
    timeStamp: task.timeStamp,
    userId: currentUser.id,
  };
}
export default function withTask(BaseComponent) {
  const withTaskFn = function WithTaskInner(props) {
    useEffect(() => {
      console.log("withTask CONSTRUCTOR");
    }, []);
    const { layoutGraph, task } = props;
    const [refetchNo, setRefetchNo] = useState(0);

    const handleRefetch = useCallback(() => {
      setRefetchNo((previousRefetchNo) => {
        if (!task) return previousRefetchNo;
        let nextRefetchNo = previousRefetchNo + 1;
        setState(
          getStateFromProps(BaseComponent, currentUser, task, nextRefetchNo)
        );
        return nextRefetchNo;
      });
    }, [currentUser, task]);

    const currentUser = layoutGraph?.currentUser;
    const [state, setState] = useState(() => {
      return getStateFromProps(BaseComponent, currentUser, task, refetchNo);
    });

    useEffect(() => {
      setState((previousState) => {
        if (
          previousState?.taskId === task?.id &&
          previousState?.userId === currentUser?.id &&
          previousState.BaseComponentWithQuery
        )
          return previousState;
        return getStateFromProps(BaseComponent, currentUser, task, refetchNo);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task?.id, task?.timeStamp, currentUser?.id]);

    const { BaseComponentWithQuery } = state;
    if (BaseComponentWithQuery) {
      return (
        <BaseComponentWithQuery {...props} handleRefetch={handleRefetch} />
      );
    }
    return <BaseComponent {...props} />;
  };
  return withTaskFn;
}
