import { gql } from "@apollo/client";

export default gql`
  subscription onThreadsChanged {
    threadsChanged {
      taskId
      stepId
      workflowName
      display
      userId
      userName
      timeStamp
      processing
      priority
    }
  }
`;
