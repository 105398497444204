import getEnvironment from "../environment/env";
import Toast from "../components/Toast";
import { isEmpty } from "./common";

export function navigateAndDisplayMessage(
  data,
  cache,
  history,
  message = "Operation Failed",
  subject,
  forceToast = false
) {
  let msg = data && data.message ? data.message : message;
  if (data && data.redirect) {
    let params = {};
    let handleRedirect = true;
    if (data.redirect === "/page/versioncheck") {
      // if ("serviceWorker" in navigator) {
      //   handleRedirect = false;
      //   navigator.serviceWorker
      //     .getRegistrations()
      //     .then(function (registrations) {
      //       for (let registration of registrations) {
      //         //registration.update();
      //         registration.unregister();
      //       }
      //       //Make sure redirection happens after unregister
      //       const version = isEmpty(data.version) ? "Unknown" : data.version;
      //       const params = { version };
      //       window.location.replace(
      //         getEnvironment().SITE_URL +
      //           data.redirect +
      //           "?" +
      //           new URLSearchParams(params).toString()
      //       );
      //     });
      // } else {
      params.version = isEmpty(data.version) ? "Unknown" : data.version;
      // }
    } else {
      params.message = msg;
    }
    if (handleRedirect) {
      window.location.replace(
        getEnvironment().SITE_URL +
          data.redirect +
          "?" +
          new URLSearchParams(params).toString()
      );
      if (data.redirect === "/page/versioncheck") {
        window.location.reload();
      }
    }
  } else {
    let subj = data && data.subject ? data.subject : subject;
    if (!forceToast && cache && history) {
      cache.forms["MessageForm"] = JSON.stringify({
        values: { subject: subj, message: msg },
      });
      history.push("/page/messageresult");
      console.log("Redirecting Message Result");
    } else if (!forceToast) {
      const params = { message: msg };
      window.location.replace(
        getEnvironment().SITE_URL +
          "/page/messageresult?" +
          new URLSearchParams(params).toString()
      );
    } else {
      subj = isEmpty(subj) ? "" : subj + ": ";
      Toast.toast(subj + msg);
      console.log("WARNING! no cache or history");
    }
  }
}
