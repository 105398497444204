/* eslint-disable react/prop-types */
import React from "react";
import { ActivityIndicator, View } from "react-native";
import Typography from "@material-ui/core/Typography";

const Loading = ({
  direction,
  color,
  message,
  messageOnly,
  messageVariant,
}) => {
  return (
    <View
      style={{
        flexDirection: direction ? direction : "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {messageOnly ? undefined : (
        <ActivityIndicator size="large" color={color ? color : "#0000ff"} />
      )}
      <Typography
        color={"inherit"}
        variant={messageVariant ? messageVariant : "h6"}
      >
        {message ? message : "Loading..."}
      </Typography>
    </View>
  );
};

export default Loading;
