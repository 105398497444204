/* eslint-disable react/prop-types */
import { useRef, useEffect } from "react";
import { useFormContext } from "./formContext";
import * as debounce from "lodash/debounce";
import isEqual from "react-fast-compare";
import { useCache } from "../hoc/withCache";

function PersistForm(formCache, name, data) {
  formCache[name] = JSON.stringify(data);
}
const debouncedPersistForm = debounce(PersistForm, 300);

const Persist = ({ name }) => {
  const lastValue = useRef(null);
  const { formCache } = useCache();
  const { getValues } = useFormContext();

  // useEffect(() => {
  //   const maybeState = formCache[name];
  //   lastValue.current = maybeState;
  //   if (maybeState && maybeState !== null) {
  //     reset(JSON.parse(maybeState), {
  //       keepErrors: true,
  //       keepDirty: true,
  //       keepIsSubmitted: false,
  //       keepTouched: true,
  //       keepIsValid: true,
  //       keepSubmitCount: false,
  //     });
  //     console.log("RESTORING:", JSON.parse(maybeState));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!isEqual(lastValue.current, getValues())) {
      lastValue.current = getValues();
      debouncedPersistForm(formCache, name, lastValue.current);
    }
  });

  return null;
};

export default Persist;
