export const ROLETYPES = {
  UNAUTHORIZED: 0,
  READONLY: 1,
  READWRITE: 2,
  ADMIN: 3,
};

export const SCREEN = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
  HUGE: 3,
};

export const GRID_OPTIONS = {
  GRID: 0,
  MAP: 1,
  CARD: 2,
};

export const MAX_SEARCHBAR_WIDGETS = 4;

//mobile --> default:s
//detail --> default:m
//form   --> default:l
export const SUBDEFINITIONS = {
  DEFAULT: { NAME: "default", TITLE: "Default" },
  SMALL: { NAME: "default:s", TITLE: "Small" },
  MEDIUM: { NAME: "default:m", TITLE: "Medium" },
  LARGE: { NAME: "default:l", TITLE: "Large" },
  ADD: { NAME: "add", TITLE: "Add Form" },
  SEARCH: { NAME: "__search", TITLE: "Search Form" },
  GRID: { NAME: "grid", TITLE: "Grid" },
};

export const SEARCHTYPES = {
  EXACT: 0,
  GREATERTHAN: 1,
  LESSTHAN: 2,
  MACTHBEGINNING: 3,
  SUBSTRING: 4,
  RANGE: 5,
};

export const SELECT_FIELD = "!!select";
export const FTS_FIELD = "!!fts";
