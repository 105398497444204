/* eslint-disable react/prop-types */
import React, { createContext, useContext } from "react";

export const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children, ...props }) => {
  return (
    <FormContext.Provider value={{ ...props }}>{children}</FormContext.Provider>
  );
};
