/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import definitionHelper from "../util/definitions.js";
import XLSX from "xlsx";
import {
  isNull,
  isEmpty,
  getLookup,
  renderFieldValue,
  fieldAccessor,
  parseCSV,
  getLookupDefinition,
  getLookupFactory,
} from "../util/common.js";
import { View } from "react-native";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const FIX_FORMS = false;
const CURRENT_TOKEN = "::";
const NEW_TOKEN = "!";

const INITIAL_STATE = {
  importCollection: [],
  errorCollection: [],
  updateFields: [],
};

function checkUnique(
  fieldValues,
  fields,
  primaryCollection,
  importCollection,
  errorCollection
) {
  let collections = [primaryCollection, importCollection, errorCollection];
  let count = 0;
  for (let i = 0; i < fieldValues.length; i++) {
    if (isNull(fieldValues[i])) {
      return count;
    }
  }

  for (let i = 0; i < collections.length; i++) {
    let collection = collections[i];
    if (collection) {
      for (let j = 0; j < collection.length; j++) {
        let row = collection[j];
        let hasNull = false;
        for (let k = 0; k < fields.length; k++) {
          let field = fields[k];
          if (isNull(row[field.fieldName])) {
            hasNull = true;
            break;
          }
        }
        if (!hasNull) {
          let isSame = true;
          for (let k = 0; k < fields.length; k++) {
            let field = fields[k];
            let fieldValue = fieldValues[k];
            let rowValue = row[field.fieldName];
            if (
              field.type === "string" ||
              field.type === "json" ||
              field.type === "chart"
            ) {
              isSame = rowValue.toLowerCase() === fieldValue.toLowerCase();
            } else {
              isSame = rowValue === fieldValue;
            }
            if (!isSame) {
              break;
            }
          }
          if (isSame) {
            count += 1;
          }
        }
      }
    }
  }

  return count;
}

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateUrl(url) {
  let res = url.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
}

function getUniqueUpdateFields(columns) {
  let updateFields = [];
  for (let i = 0; i < columns.length; i++) {
    let column = columns[i];
    if (!isNull(column)) {
      if (updateFields.indexOf(column.fieldName) < 0) {
        updateFields.push(column.fieldName);
      }
    }
  }
  return updateFields;
}

function getJSONRow(jsonRow, columns) {
  let rowValues = [];
  for (let j = 0; j < columns.length; j++) {
    let field = columns[j];
    let value = null;
    let cell = { t: "s" }; //This is the "type".  For simplicity use the same as the excel import library.  "s" string "b" boolean "n" number
    if (!isEmpty(jsonRow[field.fieldName])) {
      value = jsonRow[field.fieldName];
    } else if (!isEmpty(jsonRow[field.title])) {
      value = jsonRow[field.title];
    }
    if (!isEmpty(value)) {
      if (typeof value === "boolean") {
        cell.t = "b";
      } else if (typeof value === "number") {
        cell.t = "n";
      }
    }
    rowValues.push({ cell: cell, value: value });
  }
  return rowValues;
}

function getJSONRows(jsonValue, columns) {
  let importRows = [];
  for (let i = 0; i < jsonValue.length; i++) {
    importRows.push(getJSONRow(jsonValue[i], columns));
  }
  return importRows;
}

function getColumns(columnFields) {
  let columns = [];
  for (let i = 0; i < columnFields.length; i++) {
    if (isNull(columnFields[i])) {
      columns.push(null);
    } else {
      columns.push(columnFields[i][0]);
    }
  }
  return columns;
}

function getJSONColumnNames(fields) {
  let columnNames = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    columnNames.push(field.fieldName);
  }
  return columnNames;
}

function getExcelRow(startColumn, sheet, columns, row) {
  let rowValues = [];
  let hasData = false;
  let fixedStartColumn = startColumn
    ? startColumn - 1 > -1
      ? startColumn - 1
      : 0
    : 0;
  for (let j = 0; j < columns.length; j++) {
    let field = columns[j];
    let value = null;
    let cell = null;
    if (field) {
      var cell_address = { c: j + fixedStartColumn, r: row };
      let cell_ref = XLSX.utils.encode_cell(cell_address);
      cell = sheet[cell_ref];
      if (!(isNull(cell) || isEmpty(cell.v))) {
        hasData = true;
      }

      if (
        !isEmpty(cell) &&
        cell.t !== "e" &&
        cell.t !== "z" &&
        !isEmpty(cell.v)
      ) {
        value = cell.v;
      }
    }
    rowValues.push({ cell: cell, value: value });
  }
  return { rowValues, hasData };
}

function getExcelRows(startLine, blankRows, startColumn, sheet, columns) {
  let i = !isNull(startLine) && startLine >= 0 ? startLine : 1;
  let emptyCount = 0;
  let fixedBlankRows = !isNull(blankRows) && blankRows > 0 ? blankRows : 1;
  let importRows = [];
  while (emptyCount < fixedBlankRows) {
    let excelRow = getExcelRow(startColumn, sheet, columns, i);
    if (!excelRow.hasData) {
      emptyCount += 1;
    } else {
      emptyCount = 0;
    }
    importRows.push(excelRow.rowValues);
    i += 1;
  }
  if (importRows.length > fixedBlankRows) {
    importRows = importRows.slice(0, importRows.length - fixedBlankRows);
  } else if (importRows.length === fixedBlankRows) {
    importRows = [];
  }
  return importRows;
}

function getExcelColumnNames(
  startLine,
  startColumn,
  numColumns,
  sheet,
  fields
) {
  let columnNames = [];
  let headerLine = !isNull(startLine) ? startLine - 1 : 0;
  if (headerLine < 0) {
    //Implied
    if (!isEmpty(fields)) {
      for (let i = 0; i < fields.length; i++) {
        columnNames.push(fields[i].fieldName);
      }
    }
  } else {
    let i = startColumn ? (startColumn - 1 > -1 ? startColumn - 1 : 0) : 0;
    let nextColumn = true;
    let fixedNumColumns = numColumns
      ? numColumns > 0
        ? numColumns
        : null
      : null;
    let count = 0;
    while (nextColumn) {
      count += 1;
      let cell_address = { c: i, r: headerLine };
      let cell_ref = XLSX.utils.encode_cell(cell_address);
      let cell = sheet[cell_ref];
      if (isNull(cell) || isEmpty(cell.v) || cell.t !== "s") {
        if (isNull(fixedNumColumns)) {
          nextColumn = false;
        } else {
          columnNames.push(null);
        }
      } else {
        columnNames.push(cell.v);
      }
      if (!isNull(fixedNumColumns)) {
        if (fixedNumColumns === count) {
          nextColumn = false;
        }
      }
      i += 1;
    }
  }
  return columnNames;
}

function checkMandatoryFields(columns, fields, newField) {
  let isNew = newField.id < 0;
  let fieldErrors = newField["__errors"];
  for (let j = 0; j < fields.length; j++) {
    let field = fields[j];
    if (
      isEmpty(newField[field.fieldName]) &&
      field.required &&
      (isNew || columns.indexOf(field) >= 0) &&
      isNull(field.default)
    ) {
      fieldErrors.push({
        item: newField,
        field: field,
        error: "Mandatory field error.",
      });
    }
  }
}

function fixForms(entityFactory, newImportItems, fields) {
  if (
    entityFactory &&
    entityFactory.definition &&
    entityFactory.definition.entityName === "Form"
  ) {
    if (newImportItems.length > 0) {
      for (let j = newImportItems.length - 1; j >= 0; j--) {
        let currentRow = newImportItems[j];
        for (let i = 0; i < fields.length; i++) {
          let field = fields[i];
          let value = fieldAccessor(currentRow, field);
          if (field.fieldName === "name") {
            currentRow[field.fieldName] = value
              .split(CURRENT_TOKEN)
              .join(NEW_TOKEN);
          } else if (field.fieldName === "definition") {
            let formDefinition = JSON.parse(value);
            let formFields = definitionHelper.findAllFields(
              formDefinition.fields
            );
            for (let k = 0; k < formFields.length; k++) {
              let formField = formFields[k];
              formField.fieldName = formField.fieldName
                .split(CURRENT_TOKEN)
                .join(NEW_TOKEN);
              if (!isEmpty(formField.template)) {
                formField.template = formField.template
                  .split(CURRENT_TOKEN)
                  .join(NEW_TOKEN);
              }
              if (
                !isEmpty(formField.definition) &&
                !isEmpty(formField.definition.template)
              ) {
                formField.definition.template = formField.definition.template
                  .split(CURRENT_TOKEN)
                  .join(NEW_TOKEN);
              }
            }
            if (!isEmpty(formDefinition.template)) {
              formDefinition.template = formDefinition.template
                .split(CURRENT_TOKEN)
                .join(NEW_TOKEN);
            }
            currentRow[field.fieldName] = JSON.stringify(formDefinition);
          }
        }
      }
    }
  }
  return newImportItems;
}

function assignId(props, primaryCollection, fields, newField) {
  //Check whether a keyField match exists.
  if (!isNull(props.keyFieldName)) {
    if (props.keyFieldName === "Primary Key") {
      let primaryFields = [];
      let matchKeyValues = [];
      let hasNulls = false;
      for (let j = 0; j < fields.length; j++) {
        let field = fields[j];
        if (field.primaryField) {
          primaryFields.push(field);
          hasNulls = isNull(newField[field.fieldName]);
          matchKeyValues.push(newField[field.fieldName]);
          if (hasNulls) {
            break;
          }
        }
      }
      console.log(
        ">>Primary Key Matching",
        primaryFields,
        hasNulls,
        matchKeyValues,
        primaryCollection
      );
      if (primaryFields.length > 0) {
        if (!hasNulls) {
          for (let j = 0; j < primaryCollection.length; j++) {
            let row = primaryCollection[j];
            let match = true;
            for (let k = 0; k < primaryFields.length; k++) {
              let field = primaryFields[k];
              let matchValue = matchKeyValues[k];
              if (
                field.type === "string" ||
                field.type === "json" ||
                field.type === "chart"
              ) {
                match =
                  row[field.fieldName].toLowerCase() ===
                  matchValue.toLowerCase();
              } else {
                match = row[field.fieldName] === matchValue;
              }
              console.log("match", match, row[field.fieldName], matchValue);
              if (!match) {
                break;
              }
            }
            if (match) {
              newField.id = row.id;
              newField.versionId = row.versionId;
              console.log("Found and set key match to id " + row.id);
              break;
            }
          }
        }
      }
    } else {
      let matchKeyValue = newField[props.keyFieldName];
      if (!isEmpty(matchKeyValue)) {
        for (let j = 0; j < primaryCollection.length; j++) {
          let row = primaryCollection[j];
          if (row[props.keyFieldName] === matchKeyValue) {
            newField.id = row.id;
            newField.versionId = row.versionId;
            console.log("Found and set key match to id " + row.id);
            break;
          }
        }
      }
    }
  }
}

function getColumnFields(props, columnNames, fields) {
  //Each column in the spreadsheet is presumeably associated with a field.
  //If that field was a lookup it's possible that there are multiple columns
  //in the spreadsheet linked to that field.  Either because it's a multi-part
  //primary key on the lookup or that simply additional export fields were selected.
  //As a result each column can be associated with up to two fields currently.
  //The first being the field on the table being imported (e.g. statusId) and the
  //second being the field on the lookup table (e.g. statusName or statusCode).
  //If the field on the lookup table is not part of the primary key the entire
  //column can be ignored from an import perspective.
  //
  // The columns array stores the field this excel column is associated with.  (exactly 1)
  // The columnFields array stores a more information, drilling down to include the lookup field.
  // Note though that the lookup field is only actually required if it's a multipart key.
  // If the key is just one field, we actually don't need it, it can be found from existing
  // lookup calls.  In that case columnFields[i][1] will simply be set to [i][0] and the lookup
  // field ignored.
  //
  // Actually columnFields[i][0] ALWAYS equals columns[i]
  // Currently it's redundant and possibly should be refactored out.

  let columnFields = [];
  for (let i = 0; i < columnNames.length; i++) {
    let columnName = columnNames[i];
    let column = undefined;
    let parts = [];
    if (!isEmpty(columnName)) {
      parts = columnName.toString().split(".");
      if (!isEmpty(parts)) {
        let firstPart = parts[0].trim();
        for (let j = 0; j < fields.length; j++) {
          let field = fields[j];
          if (
            !isNull(firstPart) &&
            (firstPart === field.fieldName ||
              (!isNull(field.title) &&
                !isEmpty(field.title.trim()) &&
                firstPart === field.title.trim()))
          ) {
            column = field;
            break;
          }
        }
      }
    }
    if (!isNull(column)) {
      if (column.lookup) {
        if (parts.length > 1) {
          let lookupDef = getLookupDefinition(props, column);
          let primaryFields = definitionHelper.findPrimaryFields(lookupDef);
          //This could be an extra field.  Only select it if it is a primaryField
          let lookupField = null;
          for (let j = 0; j < primaryFields.length; j++) {
            let primaryField = primaryFields[j];
            if (
              primaryField.fieldName === parts[1] ||
              primaryField.title === parts[1]
            ) {
              lookupField = primaryField;
              break;
            }
          }
          if (lookupField) {
            if (primaryFields.length === 1) {
              columnFields.push([column, column]);
            } else {
              columnFields.push([column, lookupField]);
            }
          } else {
            columnFields.push(null);
          }
        } else {
          columnFields.push([column, column]);
        }
      } else {
        columnFields.push([column]);
      }
    } else {
      columnFields.push(null);
    }
  }

  return columnFields;
}

function validateUniqueFields(
  props,
  columns,
  primaryCollection,
  newImportItems,
  newErrorItems
) {
  //is unique?
  let converions = [];
  let primaryFields = [];
  for (let i = 0; i < columns.length; i++) {
    let field = columns[i];
    if (field) {
      if (field.primaryField) {
        primaryFields.push(field);
      }
      if (field.unique) {
        if (newImportItems.length > 0) {
          for (let j = newImportItems.length - 1; j >= 0; j--) {
            let currentRow = newImportItems[j];
            let value = fieldAccessor(currentRow, field);
            let ucCount = checkUnique(
              [value],
              [field],
              primaryCollection,
              newImportItems,
              newErrorItems
            );
            if (currentRow.id > 0) {
              //We are matching and updating the key field.  Expect a row in both the primaryCollection and newImportItems.
              ucCount = ucCount - 1;
            }
            if (ucCount > 1) {
              //currentRow[field.fieldName] = renderFieldValue(props, field, value);  //Convert it to a string like all other errors.
              //currentRow["__errors"].push({ item: currentRow, field: field, error: "Unique constraint violation: " + value });
              newErrorItems.push(currentRow);
              newImportItems.splice(j, 1);
            }
          }
          for (let j = newErrorItems.length - 1; j >= 0; j--) {
            let currentRow = newErrorItems[j];
            let value = fieldAccessor(currentRow, field);
            let uceCount = checkUnique(
              [value],
              [field],
              primaryCollection,
              newImportItems,
              newErrorItems
            );
            if (currentRow.id > 0) {
              //We are matching and updating the key field.  Expect a row in both the primaryCollection and newErrorItems.
              uceCount = uceCount - 1;
            }
            if (uceCount > 1) {
              converions.push({
                row: currentRow,
                fieldName: field.fieldName,
                value: renderFieldValue(props, field, value, null, false, null),
              });
              //currentRow[field.fieldName] = renderFieldValue(props, field, value);
              currentRow["__errors"].push({
                item: currentRow,
                field: field,
                error: "Unique constraint violation: " + value,
              });
            }
          }
        }
      }
    }
  }
  if (primaryFields.length > 0) {
    if (newImportItems.length > 0) {
      for (let i = newImportItems.length - 1; i >= 0; i--) {
        let currentRow = newImportItems[i];
        let values = [];
        for (let j = 0; j < primaryFields.length; j++) {
          let field = primaryFields[j];
          values.push(fieldAccessor(currentRow, field));
        }
        let pkCount = checkUnique(
          values,
          primaryFields,
          primaryCollection,
          newImportItems,
          newErrorItems
        );
        if (currentRow.id > 0) {
          //We are matching and updating the key field.  Expect a row in both the primaryCollection and newImportItems.
          pkCount = pkCount - 1;
        }
        if (pkCount > 1) {
          //currentRow[field.fieldName] = renderFieldValue(props, field, value);  //Convert it to a string like all other errors.
          //currentRow["__errors"].push({ item: currentRow, field: field, error: "Unique constraint violation: " + value });
          newErrorItems.push(currentRow);
          newImportItems.splice(i, 1);
        }
      }
      for (let i = newErrorItems.length - 1; i >= 0; i--) {
        let currentRow = newErrorItems[i];
        let values = [];
        for (let j = 0; j < primaryFields.length; j++) {
          let field = primaryFields[j];
          values.push(fieldAccessor(currentRow, field));
        }
        let count = checkUnique(
          values,
          primaryFields,
          primaryCollection,
          newImportItems,
          newErrorItems
        );
        if (currentRow.id > 0) {
          //We are matching and updating the key field.  Expect a row in both the primaryCollection and newImportItems.
          count = count - 1;
        }
        if (count > 1) {
          for (let j = 0; j < primaryFields.length; j++) {
            let field = primaryFields[j];
            converions.push({
              row: currentRow,
              fieldName: field.fieldName,
              value: renderFieldValue(
                props,
                field,
                values[j],
                null,
                false,
                null
              ),
            });
            //currentRow[field.fieldName] = renderFieldValue(props, field, values[j]);
            currentRow["__errors"].push({
              item: currentRow,
              field: field,
              error: "Primary Key constraint violation: " + values[j],
            });
          }
        }
      }
    }
  }
  return converions;
}

function convertValidFieldsToStringOnErrorRows(props, newErrorItems, fields) {
  for (let i = 0; i < newErrorItems.length; i++) {
    let currentRow = newErrorItems[i];
    let errors = currentRow["__errors"];
    for (let j = 0; j < fields.length; j++) {
      let field = fields[j];
      let value = fieldAccessor(currentRow, field);
      let fieldHasError = false;
      for (let k = 0; k < errors.length; k++) {
        let error = errors[k];
        if (error.field === field) {
          fieldHasError = true;
          break;
        }
      }
      if (!fieldHasError) {
        //This field has not been converted to a string yet.  It successfully loaded but now need to convert it back to a string.
        //Convert it to a string like all other error fields.
        if (
          field.format !== "date" &&
          field.format !== "date-time" &&
          field.format !== "date-time-range" &&
          field.format !== "geog"
        ) {
          //Don't re-render dates.
          currentRow[field.fieldName] = renderFieldValue(
            props,
            field,
            value,
            null,
            false,
            null
          );
        }
      } else {
        //The field should have it's original value (or already have been stringified)
        //But to be sure ....
        if (isNull(currentRow[field.fieldName])) {
          currentRow[field.fieldName] = "";
        } else {
          currentRow[field.fieldName] = currentRow[field.fieldName].toString();
        }
      }
    }
    currentRow["_errors"] = [];
    let stringErrors = currentRow["_errors"];
    for (let k = 0; k < errors.length; k++) {
      let error = errors[k];
      let errorId = k + 1;
      stringErrors.push({
        id: errorId,
        entityName: error.item.name ? error.item.name : i.toString(),
        name: error.field.fieldName,
        message: error.error,
      });
    }
    delete currentRow["__errors"];
  }
}

function parseRow(props, newId, is1904Date, columns, columnFields, rowValues) {
  let newField = {};
  newField["__errors"] = [];
  let fieldErrors = newField["__errors"];
  newField.id = newId;

  for (let j = 0; j < columns.length; j++) {
    let field = columns[j];
    if (field) {
      let value = rowValues[j].value;
      let cell = rowValues[j].cell;
      let origValue = value;
      if (!isEmpty(value)) {
        //Need to convert type and covert from lookup if need be.
        //type: b Boolean, e Error, n Number, d Date, s Text, z Stub
        if (field.type === "id") {
          if (cell.t === "s") {
            if (
              parseInt(value.toString().trim()).toString() ===
              value.toString().trim()
            ) {
              newField.id = parseInt(value.toString());
            } else {
              fieldErrors.push({
                item: newField,
                field: field,
                error:
                  "Integer conversion failed. " +
                  value.toString().trim() +
                  " does not equal " +
                  parseInt(value.toString().trim()).toString(),
              });
            }
          } else {
            //Could still be a decimal.
            if (Math.floor(value) === Math.ceil(value)) {
              newField.id = Math.floor(value);
            } else {
              fieldErrors.push({
                item: newField,
                field: field,
                error:
                  "The import value '" +
                  value.toString() +
                  "' is a decimal and not an integer.",
              });
            }
          }
          continue;
        } else if (field.type === "string") {
          if (field.format === "date") {
            //Need to convert to our correct date format.
            //2018-03-25
            if (cell.t === "n") {
              try {
                let date = new Date(Date.UTC(1899, 11, 30));
                date.setTime(cell.v * (24 * 60 * 60 * 1000));
                let date1899 = new Date(Date.UTC(1899, 11, 30));
                date.setTime(date.getTime() + date1899.getTime());
                console.log("date cell", cell);
                console.log("date", date);
                let month = (date.getUTCMonth() + 1).toString();
                if (month.length < 2) month = "0" + month;
                let day = date.getUTCDate().toString();
                if (day.length < 2) day = "0" + day;
                value =
                  date.getUTCFullYear().toString() + "-" + month + "-" + day;
              } catch (e) {
                fieldErrors.push({
                  item: newField,
                  field: field,
                  error: "Failed to convert date " + origValue,
                });
              }
            } else if (cell.t === "d") {
              try {
                console.log("converting from moment ...");
                let parts = isEmpty(value) ? [] : value.split("-");
                if (parts.length === 1) {
                  parts = value.split("/");
                }
                if (parts && parts.length === 3) {
                  if (parts[0].length === 4) {
                    value = parts[0] + "-" + parts[1] + "-" + parts[2];
                  } else {
                    value = parts[2] + "-" + parts[1] + "-" + parts[0];
                  }
                }
                let momentValue = moment(value);
                if (momentValue.isValid()) {
                  value = moment(value).format("YYYY-MM-DD");
                  console.log("converting from moment done");
                } else {
                  fieldErrors.push({
                    item: newField,
                    field: field,
                    error: "Failed to convert date " + origValue,
                  });
                }
              } catch (e) {
                fieldErrors.push({
                  item: newField,
                  field: field,
                  error: "Failed to convert date " + origValue,
                });
              }
            } else if (cell.t === "s") {
              try {
                console.log("converting from moment ...");
                let parts = isEmpty(value) ? [] : value.split("-");
                if (parts && parts.length === 1) {
                  parts = value.split("/");
                }
                if (parts && parts.length === 3) {
                  if (parts[0].length === 4) {
                    value = parts[0] + "-" + parts[1] + "-" + parts[2];
                  } else {
                    value = parts[2] + "-" + parts[1] + "-" + parts[0];
                  }
                }
                let momentValue = moment(value);
                if (momentValue.isValid()) {
                  value = moment(value).format("YYYY-MM-DD");
                  console.log("converting from moment done");
                } else {
                  fieldErrors.push({
                    item: newField,
                    field: field,
                    error: "Failed to convert date " + origValue,
                  });
                }
              } catch (e) {
                fieldErrors.push({
                  item: newField,
                  field: field,
                  error: "Failed to convert date " + origValue,
                });
              }
            }
          } else if (field.format === "email") {
            if (validateEmail(value.trim())) {
              value = value.trim();
            } else {
              fieldErrors.push({
                item: newField,
                field: field,
                error: "Invalid email address " + origValue,
              });
            }
          } else if (field.format === "uri") {
            if (validateUrl(value.trim())) {
              value = value.trim();
            } else {
              fieldErrors.push({
                item: newField,
                field: field,
                error: "Invalid URL address " + origValue,
              });
            }
          } else {
            if (cell.t !== "s") {
              //Convert to string
              value = value.toString();
            }
          }
        } else if (field.type === "number") {
          if (cell.t !== "n") {
            try {
              if (
                parseFloat(value.toString()).toString() === value.toString()
              ) {
                value = parseFloat(value.toString());
              } else {
                fieldErrors.push({
                  item: newField,
                  field: field,
                  error:
                    "The value '" + value.toString() + "' is not a decimal.",
                });
              }
            } catch (e) {
              fieldErrors.push({
                item: newField,
                field: field,
                error: "The value '" + value.toString() + "' is not a decimal.",
              });
            }
          }
        } else if (field.type === "integer" || field.type === "number") {
          //Check if a lookup field first ....
          if (field.lookup) {
            let found = false;
            let lookupFactory = getLookupFactory(props, field);
            let lookupDef = getLookupDefinition(props, field);
            let primaryFields = definitionHelper.findPrimaryFields(lookupDef);
            if (columnFields[j][0] !== columnFields[j][1]) {
              //Need to actually check other possible fields.
              let lookup = getLookup(
                props,
                field,
                true,
                null,
                true,
                false,
                null,
                true
              );
              if (primaryFields.length === 0) {
                //The only way to match would be on ID.
                for (let k = 0; k < lookup.length; k++) {
                  let lookupRow = lookup[k];
                  if (lookupRow.id === parseInt(value.toString())) {
                    found = true;
                    value = lookupRow.id;
                    break;
                  }
                }
                if (!found) {
                  //Don't attempt a SQL lookup search here ... obscure edge case
                  fieldErrors.push({
                    item: newField,
                    field: field,
                    error: "Lookup check failed.  Could not find " + value,
                  });
                }
              } else {
                for (let k = 0; k < lookup.length; k++) {
                  let lookupRow = lookup[k];
                  let match = true;

                  for (let l = 0; l < primaryFields.length; l++) {
                    let lookupPrimaryField = primaryFields[l];
                    let columnIndex = -1;
                    for (let m = 0; m < columnFields.length; m++) {
                      let columnField = columnFields[m];
                      if (
                        columnField &&
                        columnField[1] === lookupPrimaryField
                      ) {
                        columnIndex = m;
                        break;
                      }
                    }
                    if (columnIndex === -1) {
                      match = false;
                      break;
                    } else {
                      //rowValues[columnIndex].value, rowValues[columnIndex].cell
                      match =
                        !isNull(rowValues[columnIndex].value) &&
                        !isNull(lookupRow[lookupPrimaryField.fieldName]);
                      if (match) {
                        match =
                          rowValues[columnIndex].value.toString() ===
                          lookupRow[lookupPrimaryField.fieldName].toString();
                      }
                    }
                  }

                  if (match) {
                    found = true;
                    value = lookupRow.id;
                    break;
                  }
                }
                if (!found) {
                  //This will be an error.  Easiest way to go is to concat fields into one value.
                  value = "";
                  let filterValue = {};
                  for (let l = 0; l < primaryFields.length; l++) {
                    let lookupPrimaryField = primaryFields[l];
                    let columnIndex = -1;
                    for (let m = 0; m < columnFields.length; m++) {
                      let columnField = columnFields[m];
                      if (
                        columnField &&
                        columnField[1] === lookupPrimaryField
                      ) {
                        columnIndex = m;
                        break;
                      }
                    }
                    if (columnIndex > -1) {
                      if (value.length > 0) {
                        value += " - ";
                      }
                      if (!isEmpty(rowValues[columnIndex].value)) {
                        filterValue[lookupPrimaryField.fieldName] =
                          rowValues[columnIndex].value;
                      } else {
                        filterValue = null;
                      }
                      value += rowValues[columnIndex].value.toString();
                    } else {
                      filterValue = null;
                    }
                  }

                  let fieldChecked = false;
                  for (let k = 0; k < j; k++) {
                    let checkField = columns[k];
                    if (checkField === field) {
                      fieldChecked = true;
                      break;
                    }
                  }
                  if (!fieldChecked) {
                    fieldErrors.push({
                      item: newField,
                      field: field,
                      error: "Lookup check failed.  Could not find " + value,
                      value: filterValue,
                      isLookup: !isNull(filterValue),
                      lookupDef: lookupDef,
                    });
                  }
                }
              }
            } else {
              let lookups = [
                getLookup(props, field, false, null, false, false, null, true),
                getLookup(props, field, false, null, true, false, null, true),
              ];
              if (cell.t === "s") {
                for (let m = 0; m < lookups.length; m++) {
                  let lookup = lookups[m];
                  for (let k = 0; k < lookup.length; k++) {
                    let lookupRow = lookup[k];
                    if (
                      lookupRow.name.toString().trim() ===
                        value.toString().trim() &&
                      lookupRow.name.indexOf("<Unknown>") < 0
                    ) {
                      found = true;
                      value = lookupRow.id;
                      break;
                    }
                  }
                  if (found) {
                    break;
                  }
                }

                if (!found) {
                  //Try as an integer ID
                  let lookup = lookups[0];
                  if (
                    parseInt(value.toString().trim()).toString() ===
                    value.toString().trim()
                  ) {
                    value = parseInt(value.toString().trim());
                    console.log(">>> value", value, lookup);
                    if (!lookupFactory.cacheable()) found = true;
                    for (let k = 0; k < lookup.length; k++) {
                      let lookupRow = lookup[k];
                      if (value === lookupRow.id) {
                        found = true;
                        value = lookupRow.id;
                        break;
                      }
                    }
                  }
                }
              } else {
                //Could still be a decimal.
                if (Math.floor(value) === Math.ceil(value)) {
                  value = Math.floor(value);
                  let lookup = lookups[0];
                  if (!lookupFactory.cacheable()) found = true;
                  for (let k = 0; k < lookup.length; k++) {
                    let lookupRow = lookup[k];
                    if (value === lookupRow.id) {
                      found = true;
                      value = lookupRow.id;
                      break;
                    }
                  }
                }

                if (!found) {
                  for (let m = 0; m < lookups.length; m++) {
                    let lookup = lookups[m];
                    for (let k = 0; k < lookup.length; k++) {
                      let lookupRow = lookup[k];
                      if (
                        lookupRow.name.toString().trim() ===
                          value.toString().trim() &&
                        lookupRow.name.indexOf("<Unknown>") < 0
                      ) {
                        found = true;
                        value = lookupRow.id;
                        break;
                      }
                    }
                    if (found) {
                      break;
                    }
                  }
                  if (!found) {
                    fieldErrors.push({
                      item: newField,
                      field: field,
                      error:
                        "The import value '" +
                        value.toString() +
                        "' is a decimal and not an integer.",
                    });
                  }
                }
              }
              if (!found) {
                let filterValue = null;
                if (primaryFields.length === 1) {
                  if (!isEmpty(value)) {
                    filterValue = {};
                    filterValue[primaryFields[0].fieldName] = value.toString();
                  }
                } else if (primaryFields.length > 1) {
                  //value (split with " - ")
                  if (!isEmpty(value)) {
                    let parts = value.toString().split(" - ");
                    if (parts.length === primaryFields.length) {
                      filterValue = {};
                      for (let l = 0; l < primaryFields.length; l++) {
                        filterValue[primaryFields[l].fieldName] =
                          parts[l].trim();
                      }
                    }
                  }
                }
                fieldErrors.push({
                  item: newField,
                  field: field,
                  error: "Lookup check failed.  Could not find " + origValue,
                  value: filterValue,
                  isLookup: !isNull(filterValue),
                  lookupDef: lookupDef,
                });
              }
            }
          } else {
            if (cell.t === "s") {
              if (
                parseInt(value.toString().trim()).toString() ===
                value.toString().trim()
              ) {
                value = parseInt(value.toString());
              } else {
                fieldErrors.push({
                  item: newField,
                  field: field,
                  error:
                    "Integer conversion failed. " +
                    value.toString().trim() +
                    " does not equal " +
                    parseInt(value.toString().trim()).toString(),
                });
              }
            } else {
              //Could still be a decimal.
              if (Math.floor(value) === Math.ceil(value)) {
                value = Math.floor(value);
              } else {
                fieldErrors.push({
                  item: newField,
                  field: field,
                  error:
                    "The import value '" +
                    value.toString() +
                    "' is a decimal and not an integer.",
                });
              }
            }
          }

          if (!isNull(field.minimum) && value < field.minimum) {
            fieldErrors.push({
              item: newField,
              field: field,
              error:
                "The import value '" +
                value.toString() +
                "' is less than the defined minimum '" +
                value.toString() +
                "'",
            });
            value = origValue;
          }
          if (!isNull(field.maximum) && value > field.maximum) {
            fieldErrors.push({
              item: newField,
              field: field,
              error:
                "The import value '" +
                value.toString() +
                "' is greater than the defined maximum '" +
                value.toString() +
                "'",
            });
            value = origValue;
          }
          if (!isNull(field.multipleOf) && value % field.multipleOf !== 0) {
            fieldErrors.push({
              item: newField,
              field: field,
              error:
                "The import value '" +
                value.toString() +
                "' is not a multiple of '" +
                field.multipleOf.toString() +
                "'",
            });
            value = origValue;
          }
        } else if (field.type === "json" || field.type === "chart") {
          // Currently only support COMMENTS as CSV for JSON field.
          // All other JSON fields must be valid JSON.
          try {
            JSON.parse(value);
            //If we didn't error it's valid JSON ... whether it's the CORRECT valid json is another matter!
            //Assume it is for now.
          } catch (ex) {
            //Could be a CSV name array for comments.
            //But this will likely need a more complex converter latter from CSV to JSON.
            //Assume comments for now.
            if (
              field.widget === "JSONCommentWidget" ||
              field.widget === "JSONCommentAddWidget"
            ) {
              /*
              try {
                let names = parseCSV(value)[0];
                let commentArray = [];
                let userName = layoutGraph && layoutGraph.currentUser && layoutGraph.currentUser.name ? layoutGraph.currentUser.name : "";
                let date = moment();

                for (let k = 0; k < names.length; k++) {
                  let name = names[k];
                  commentArray.push({
                    "name": name,
                    "userName": userName,
                    "date": date,
                  });
                }
                value = JSON.stringify(commentArray);
              } catch (ex) {
                fieldErrors.push({ item: newField, field: field, error: "Invalid Comment field (Neither JSON nor CSV): " + value.toString() });
              }
              */
              fieldErrors.push({
                item: newField,
                field: field,
                error: "Invalid JSON field: " + value.toString(),
              });
            } else {
              fieldErrors.push({
                item: newField,
                field: field,
                error: "Invalid JSON field: " + value.toString(),
              });
            }
          }
        } else if (field.type === "array") {
          try {
            //Only support CSV
            let valueArray = undefined;
            try {
              valueArray = parseCSV(value.toString())[0]; //Make sure it is a string.
            } catch (e) {
              fieldErrors.push({
                item: newField,
                field: field,
                error: "Value is not a valid CSV format: " + value.toString(),
              });
            }
            if (field.inPlace) {
              if (value.length > 0) {
                if (value.substring(0, 1) !== "[") {
                  value = "[" + value + "]";
                }
                let jsonValue = JSON.parse(value);
                let childFields = definitionHelper.findAllDisplayFields(
                  field.definition.fields
                );
                let childColumnNames = getJSONColumnNames(childFields);
                let childColumnFields = getColumnFields(
                  props,
                  childColumnNames,
                  childFields
                );
                let childColumns = getColumns(childColumnFields);
                let importRows = getJSONRows(jsonValue, childColumns);
                console.log("jsonValue", jsonValue);
                console.log("childFields", childFields);
                console.log("childColumnNames", childColumnNames);
                console.log("childColumnFields", childColumnFields);
                console.log("childColumns", childColumns);
                console.log("importRows", importRows);
                let newChildImportItems = [];
                let newChildErrorItems = [];
                let newChildId = 0;
                for (let k = 0; k < importRows.length; k++) {
                  newChildId = newChildId - 1;
                  let childRowValues = importRows[k];
                  console.log("childRowValues", childRowValues);
                  let newChildField = parseRow(
                    props,
                    newChildId,
                    is1904Date,
                    childColumns,
                    childColumnFields,
                    childRowValues
                  );
                  console.log("newChildField", newChildField);
                  assignId(props, [], childFields, newChildField);
                  console.log("newChildField 2", newChildField);
                  checkMandatoryFields(
                    childColumns,
                    childFields,
                    newChildField
                  );
                  console.log("newChildField 3", newChildField);

                  if (newChildField["__errors"].length > 0) {
                    newChildErrorItems.push(newChildField);
                  } else {
                    newChildImportItems.push(newChildField);
                  }
                }
                validateUniqueFields(
                  props,
                  childColumns,
                  [],
                  newChildImportItems,
                  newChildErrorItems
                );
                console.log("newChildImportItems", newChildImportItems);
                console.log("newChildErrorItems", newChildErrorItems);

                if (newChildErrorItems.length === 0) {
                  //Convert to proper JSON array.
                  let newValueArray = [];
                  for (let k = 0; k < newChildImportItems.length; k++) {
                    let row = newChildImportItems[k];
                    let newItem = {};
                    for (let l = 0; l < childColumns.length; l++) {
                      let field = childColumns[l];
                      if (!isEmpty(row[field.fieldName])) {
                        newItem[field.fieldName] = row[field.fieldName];
                      }
                    }
                    console.log("newItem", newItem);
                    newValueArray.push(newItem);
                  }
                  console.log("newValueArray", newValueArray);
                  value = JSON.stringify(newValueArray);
                  console.log("value", value);
                } else {
                  let msg = "";
                  for (let k = 0; k < newChildErrorItems.length; k++) {
                    if (msg.length > 0) {
                      msg += ", ";
                    }
                    msg += newChildErrorItems[k].error;
                    fieldErrors.push(...newChildErrorItems[k]["__errors"]);
                  }
                  //fieldErrors.push({ item: newField, field: field, error: "Array Errors: " + msg });
                  value = origValue;
                }
                console.log("INPLACE ARRAY: ", value);
              } else {
                value = null;
              }
            } else {
              if (!isNull(valueArray)) {
                let resultArray = [];
                let lookups = [];
                let lookupDef = null;
                let lookupPrimaryFields = null;
                if (field.lookup) {
                  lookups = [
                    getLookup(
                      props,
                      field,
                      false,
                      null,
                      false,
                      false,
                      null,
                      true
                    ),
                    getLookup(
                      props,
                      field,
                      false,
                      null,
                      true,
                      false,
                      null,
                      true
                    ),
                  ];
                  lookupDef = getLookupDefinition(props, field);
                  lookupPrimaryFields =
                    definitionHelper.findPrimaryFields(lookupDef);
                }
                let hasErrors = false;
                for (let k = 0; k < valueArray.length; k++) {
                  let valueItem = valueArray[k];
                  let found = false;
                  if (field.lookup) {
                    for (let m = 0; m < lookups.length; m++) {
                      let lookup = lookups[m];
                      for (let l = 0; l < lookup.length; l++) {
                        let lookupRow = lookup[l];
                        if (
                          lookupRow.name === valueItem &&
                          lookupRow.name.indexOf("<Unknown>") < 0
                        ) {
                          found = true;
                          if (resultArray.indexOf(lookupRow.id) < 0) {
                            resultArray.push(lookupRow.id);
                          } else {
                            hasErrors = true;
                            fieldErrors.push({
                              item: newField,
                              field: field,
                              error:
                                "Array should NOT have duplicate items: " +
                                valueItem,
                            });
                          }
                          break;
                        }
                      }
                      if (found) {
                        break;
                      }
                    }
                  }
                  if (!found) {
                    //Check for an array of integers rather than array of names.
                    let itemAsInt = parseInt(valueItem.trim());
                    if (itemAsInt.toString() === valueItem.trim()) {
                      found = true;
                    }
                    //OK Have a integer ... but if it's a lookup table is it a valid integer?
                    if (field.lookup) {
                      found = false;
                      let lookup = lookups[0];
                      for (let l = 0; l < lookup.length; l++) {
                        let lookupRow = lookup[l];
                        if (lookupRow.id === itemAsInt) {
                          found = true;
                          break;
                        }
                      }
                    }
                    if (found) {
                      if (resultArray.indexOf(itemAsInt) < 0) {
                        resultArray.push(itemAsInt);
                      } else {
                        hasErrors = true;
                        fieldErrors.push({
                          item: newField,
                          field: field,
                          error:
                            "Array should NOT have duplicate items: " +
                            valueItem,
                        });
                      }
                    }
                  }
                  if (!found) {
                    hasErrors = true;
                    let filterValue = null;
                    if (lookupPrimaryFields.length === 1) {
                      if (!isEmpty(valueItem)) {
                        filterValue = {};
                        filterValue[lookupPrimaryFields[0].fieldName] =
                          valueItem.toString();
                      }
                    } else if (lookupPrimaryFields.length > 1) {
                      //value (split with " - ")
                      if (!isEmpty(valueItem)) {
                        let parts = valueItem.toString().split(" - ");
                        if (parts.length === lookupPrimaryFields.length) {
                          filterValue = {};
                          for (let l = 0; l < lookupPrimaryFields.length; l++) {
                            filterValue[lookupPrimaryFields[l].fieldName] =
                              parts[l].trim();
                          }
                        }
                      }
                    }
                    fieldErrors.push({
                      item: newField,
                      field: field,
                      error:
                        "CSV Parsed successfully however invalid CSV Element found (value displayed here between quotes): '" +
                        valueItem +
                        "'",
                      value: filterValue,
                      isLookup: !isNull(field.lookup) && !isNull(filterValue),
                      lookupDef: lookupDef,
                    });
                  }
                }
                if (!hasErrors) {
                  value = resultArray;
                }
              }
            }
          } catch (ex) {
            fieldErrors.push({
              item: newField,
              field: field,
              error: "Unexpected error parsing CSV : " + value,
            });
          }
        } else if (field.type === "boolean") {
          if (cell.t !== "b") {
            value = value.toString().toLowerCase();
            value =
              value === "yes" ||
              value === "true" ||
              value === "y" ||
              value === "t" ||
              value === "1" ||
              value === "-1";
          }
        }
      }
      if (field.type !== "id") newField[field.fieldName] = value;
    }
  }
  return newField;
}

function processFile(props, fileContents, existingLookupErrors) {
  let {
    entityFactory,
    layoutGraph,
    startLine,
    blankRows,
    startColumn,
    numColumns,
  } = props;
  let entityFactoryView = entityFactory.getView();
  let definition = entityFactoryView.definition;
  let fields = definitionHelper.findAllDisplayFields(definition.fields, false);
  let primaryCollection = entityFactory.getPrimaryCollection(layoutGraph);

  //Clear the errorCollection.  The importCollection must be empty by definition.
  /*
  if (errorCollection.length > 0) {
    errorCollection.splice(0, errorCollection.length);
  }
  if (updateFields.length > 0) {
    updateFields.splice(0, updateFields.length);
  }
  */
  let newImportItems = [];
  let newErrorItems = [];
  const fileAsBinaryString = fileContents;
  let workbook = XLSX.read(fileAsBinaryString, { type: "binary" });
  let sheetName = workbook.SheetNames[0];
  let sheet = workbook.Sheets[sheetName];
  let is1904Date = !!((workbook.Workbook || {}).WBProps || {}).date1904;

  let columnNames = getExcelColumnNames(
    startLine,
    startColumn,
    numColumns,
    sheet,
    fields
  );
  let columnFields = getColumnFields(props, columnNames, fields);
  let columns = getColumns(columnFields);
  let newUpdateFields = getUniqueUpdateFields(columns);
  let importRows = getExcelRows(
    startLine,
    blankRows,
    startColumn,
    sheet,
    columns
  );
  let primaryFields = definitionHelper.findPrimaryFields(definition);

  let newId = 0;
  let lookupErrors = { ...existingLookupErrors };
  let hasNewErrors = false;
  for (let i = 0; i < importRows.length; i++) {
    newId = newId - 1;
    let rowValues = importRows[i];
    let newField = parseRow(
      props,
      newId,
      is1904Date,
      columns,
      columnFields,
      rowValues
    );
    if (newField["__errors"].length === 0) {
      if (isNull(primaryCollection)) {
        //We have iterated enough to be able to get the primarykey.
        //Try and match on that to get the primaryCollection for next iteration.
        let filterValue = null;
        if (primaryFields.length > 0) {
          filterValue = {};
          for (let j = 0; j < primaryFields.length; j++) {
            filterValue[primaryFields[j].fieldName] =
              newField[primaryFields[j].fieldName];
          }
          newField["__errors"].push({
            item: newField,
            field: primaryFields[0],
            error: "UKC Violation",
            value: filterValue,
            isLookup: true,
            lookupDef: definition,
          });
        }
      } else {
        assignId(props, primaryCollection, fields, newField);
      }
    }
    checkMandatoryFields(columns, fields, newField);

    if (newField["__errors"].length > 0) {
      for (let j = 0; j < newField["__errors"].length; j++) {
        let error = newField["__errors"][j];
        console.log("ERROR", error);
        if (error.isLookup) {
          let cacheable = isNull(error.lookupDef.cacheable)
            ? true
            : error.lookupDef.cacheable;
          if (!cacheable) {
            if (isNull(lookupErrors[error.lookupDef.entityName])) {
              lookupErrors[error.lookupDef.entityName] = [];
            }
            let exists = false;
            for (
              let k = 0;
              k < lookupErrors[error.lookupDef.entityName].length;
              k++
            ) {
              let existingError = lookupErrors[error.lookupDef.entityName][k];
              let match = true;
              for (let key in existingError) {
                if (Object.prototype.hasOwnProperty.call(existingError, key)) {
                  if (Object.prototype.hasOwnProperty.call(error.value, key)) {
                    if (error.value[key] !== existingError[key]) {
                      match = false;
                      break;
                    }
                  }
                }
              }
              if (match) {
                exists = true;
                break;
              }
            }
            if (!exists) {
              lookupErrors[error.lookupDef.entityName].push(error.value);
              hasNewErrors = true;
            }
          }
        }
      }
      if (isEmpty(newField.name)) {
        newField.name = "Unknown.  Cannot resolve key.";
      }
      newErrorItems.push(newField);
    } else {
      newImportItems.push(newField);
    }
  }

  let converions = validateUniqueFields(
    props,
    columns,
    primaryCollection,
    newImportItems,
    newErrorItems
  );
  //Any error field should be converted to a string.  (other error fields will be converted later)
  //Convert UKC error fields to string values.
  for (let i = 0; i < converions.length; i++) {
    converions[i].row[converions[i].fieldName] = converions[i].value;
  }

  //At this point if there were errors any error field has already been converted to (or left as) a string.
  //But we will need to convert every other field to string representation for export in the error list if it did error.
  convertValidFieldsToStringOnErrorRows(props, newErrorItems, fields);
  if (FIX_FORMS)
    newImportItems = fixForms(entityFactory, newImportItems, fields);

  return {
    lookupErrors: lookupErrors,
    fileContents: fileContents,
    importCollection: newImportItems,
    errorCollection: newErrorItems,
    updateFields: newUpdateFields,
    hasNewErrors: hasNewErrors,
  };
}

function getStateFromProps(props) {
  if (props.fileContents) {
    //We should have the query results now.
    //So rerun
    let loadResults = processFile(
      props,
      props.fileContents,
      props.lookupErrors
    );
    if (loadResults.hasNewErrors) {
      props.findLookups(loadResults.lookupErrors, loadResults.fileContents);
      return INITIAL_STATE;
    } else {
      return {
        importCollection: loadResults.importCollection,
        errorCollection: loadResults.errorCollection,
        updateFields: loadResults.updateFields,
      };
    }
  } else {
    return INITIAL_STATE;
  }
}
const BulkGrid = (props) => {
  const {
    layoutGraph,
    notificationSystem,
    screenSize,
    allEntityFactories,
    publicStyle,
    fileContents,
    entityFactory,
    onDrop,
    isWidget,
    showFullPageLink,
  } = props;
  const { currentUser, layoutLoading } = layoutGraph;

  const [state, setState] = useState(() => getStateFromProps(props));
  const { importCollection, errorCollection, updateFields } = state;

  const onGridSubmit = useCallback(() => {
    setState(INITIAL_STATE);
  }, []);

  useEffect(() => {
    if (props.importCollection !== undefined) {
      setState(() => ({
        importCollection: isNull(props.importCollection)
          ? []
          : props.importCollection,
        errorCollection: isNull(props.errorCollection)
          ? []
          : props.errorCollection,
        updateFields: isNull(props.updateFields) ? [] : props.updateFields,
      }));
    } else {
      setState(() => getStateFromProps(props));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fileContents,
    props.importCollection,
    props.errorCollection,
    props.updateFields,
  ]);

  const update = useCallback(
    (queryParams) => {
      //let primaryCollection = entityFactory.getPrimaryCollection(layoutGraph);
      return new Promise(function (resolve) {
        let entityFactoryView = entityFactory.getView();
        let definition = entityFactoryView.definition;
        let fields = definitionHelper.findAllDisplayFields(definition.fields);
        if (queryParams.ids === null) {
          let newField = {};
          let newId = 0;
          for (let i = 0; i < importCollection.length; i++) {
            if (importCollection[i].id < newId) {
              newId = importCollection[i].id;
            }
          }
          newId = newId - 1;
          newField.id = newId;
          for (let i = 0; i < fields.length; i++) {
            let fieldName = fields[i].fieldName;
            if (!isNull(queryParams[fieldName])) {
              newField[fieldName] = queryParams[fieldName];
            } else {
              newField[fieldName] = null;
            }
          }
          importCollection.push(newField);
        } else {
          for (let i = importCollection.length - 1; i >= 0; i--) {
            let currentItem = importCollection[i];
            if (queryParams.ids.indexOf(currentItem.id) >= 0) {
              for (let j = 0; j < fields.length; j++) {
                let fieldName = fields[j].fieldName;
                if (!isNull(queryParams[fieldName])) {
                  currentItem[fieldName] = queryParams[fieldName];
                } else {
                  currentItem[fieldName] = null;
                }
              }
            }
          }
        }
        return resolve();
      });
    },
    [entityFactory, importCollection]
  );

  const onDelete = useCallback(
    (queryParams) => {
      return new Promise((resolve) => {
        for (let i = importCollection.length - 1; i >= 0; i--) {
          let currentItem = importCollection[i];
          if (queryParams.ids.indexOf(currentItem.id) >= 0) {
            importCollection.splice(i, 1);
          }
        }
        if (onDrop) {
          onDrop({ importCollection: importCollection });
        }
        setState((prevState) => {
          return {
            importCollection,
            errorCollection: [],
            updateFields: prevState.updateFields,
          };
        });
        return resolve();
      });
    },
    [onDrop, importCollection]
  );

  const onDropInternal = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        let loadResults = processFile(props, reader.result, {});
        if (loadResults.hasNewErrors) {
          props.findLookups(loadResults.lookupErrors, loadResults.fileContents);
        } else {
          setState({
            importCollection: isNull(loadResults.importCollection)
              ? []
              : loadResults.importCollection,
            errorCollection: isNull(loadResults.errorCollection)
              ? []
              : loadResults.errorCollection,
            updateFields: isNull(loadResults.updateFields)
              ? []
              : loadResults.updateFields,
          });
          if (onDrop) {
            onDrop({
              importCollection: isNull(loadResults.importCollection)
                ? []
                : loadResults.importCollection,
              errorCollection: isNull(loadResults.errorCollection)
                ? []
                : loadResults.errorCollection,
              updateFields: isNull(loadResults.updateFields)
                ? []
                : loadResults.updateFields,
            });
          }
        }
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  let entityFactoryView = entityFactory.getView();
  let EntityForm = entityFactoryView.getBulkForm(update);
  let EntityGrid = entityFactoryView.getBulkGrid(onDelete, isWidget);
  let ErrorGrid = entityFactoryView.getErrorGrid();
  if (
    !layoutLoading &&
    entityFactory &&
    (entityFactory.hasAddAllRights(currentUser) ||
      (entityFactory.lowerCaseEntityName() === "entity" &&
        entityFactory.hasEditAllRights(currentUser)))
  ) {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <EntityGrid
            publicStyle={publicStyle}
            showFullPageLink={showFullPageLink}
            hasErrors={!isEmpty(errorCollection)}
            isWidget={isWidget}
            onGridSubmit={onGridSubmit}
            updateFields={updateFields}
            onDrop={onDropInternal}
            allEntityFactories={allEntityFactories}
            Form={EntityForm}
            screenSize={screenSize}
            layoutGraph={layoutGraph}
            primaryCollection={importCollection}
            notificationSystem={notificationSystem}
          />
        </View>
        {errorCollection &&
        errorCollection.length > 0 &&
        !layoutLoading &&
        entityFactory &&
        (entityFactory.hasAddAllRights(currentUser) ||
          (entityFactory.lowerCaseEntityName() === "entity" &&
            entityFactory.hasEditAllRights(currentUser))) ? (
          <View style={{ flex: 1 }}>
            <Typography
              variant="h5"
              display="block"
              color={"inherit"}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              ERRORS
            </Typography>
            <View style={{ flex: 1 }}>
              <ErrorGrid
                publicStyle={publicStyle}
                allEntityFactories={allEntityFactories}
                screenSize={screenSize}
                layoutGraph={layoutGraph}
                primaryCollection={errorCollection}
                notificationSystem={notificationSystem}
              />
            </View>
          </View>
        ) : undefined}
      </View>
    );
  } else {
    return <View style={{ flex: 1 }} />;
  }
};

export default BulkGrid;
