import getEnvironment from "../../environment/env";
import { isEmpty } from "../../util/types";

export function handleLogin(payload, changeLoginState) {
  //console.log(">>>> login payload", payload)
  if (payload && !isEmpty(payload.message)) {
    throw payload;
  } else if (!isEmpty(payload.twoFactorToken)) {
    return changeLoginState(true, payload);
  } else {
    return isLoggedIn(payload, changeLoginState);
  }
}

export function isLoggedIn(payload, changeLoginState) {
  return fetch(getEnvironment().API_URL + "/is_logged_in", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response) {
        return response.json();
      } else {
        throw {
          message:
            "Valid account login success, however unable to verify your session.  It is likely your browser is not allowing cookies or the cookie has been blocked.",
        };
      }
    })
    .then((verifiedResult) => {
      if (verifiedResult && verifiedResult.ok) {
        return changeLoginState(true, payload);
      } else {
        if (verifiedResult) {
          throw verifiedResult;
        } else {
          throw { message: "ERROR: Could not verify login" };
        }
      }
    });
}
