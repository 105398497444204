export default {
  API_URL: "http://localhost:3010",
  SITE_URL: "http://localhost:19006",
  WEBSOCKET_URL: "ws://localhost:4010",
  FIREBASE_APIKEY: "AIzaSyCUEzDQkTtJiHh17LM8ybTaIcCpXChVBoQ",
  FIREBASE_AUTHDOMAIN: "ashz-52424.firebaseapp.com",
  FIREBASE_PROJECTID: "ashz-52424",
  FIREBASE_STORAGEBUCKET: "ashz-52424.appspot.com",
  FIREBASE_MESSAGINGSENDERID: "481365830081",
  FIREBASE_APPID: "1:481365830081:web:fe4de44829f9832d7f2740",
  XERO_CLIENT_ID: "08580270F9DB4913816C7BECFCCCA84D",
};
