import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const CURRENTUSER_QUERY = gql`
  query CurrentUserQuery {
    currentUser {
      id
      userName
      name
      first
      middle
      surname
      roles
      groups
      authSource
      authState
      parentId
      userEntityName
      parentEntityName
      pageId
      menuId
      uiVersion
    }
    cacheVersion
  }
`;

export default graphql(CURRENTUSER_QUERY, {
  options() {
    let variables = { timeStamp: Date.now() };
    return {
      fetchPolicy: "no-cache",
      fragments: {},
      variables: variables,
    };
  },
  props({ data }) {
    return {
      userGraph: {
        cacheVersion: data.cacheVersion,
        currentUser: data.currentUser,
        loading: data.loading,
      },
    };
  },
});
