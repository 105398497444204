import Alert from "./Alert";

function ConfirmAlert(msg) {
  let buttons = [];

  for (let i = 0; i < msg.buttons.length; i++) {
    let orig = msg.buttons[i];
    let button = {};
    button.text = orig.label;
    button.onPress = orig.onClick;
    if (orig.label.toLowerCase() === "cancel") {
      button.style = "cancel";
    }
    buttons.push(button);
  }

  return Alert.alert(msg.title, msg.message, buttons, { cancelable: false });
}

export { ConfirmAlert };
