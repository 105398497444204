import firebase from "firebase/compat/app";
import getEnvironment from "./environment/env";
import "firebase/compat/auth";
//import "firebase/messaging";

export function getFirebase() {
  let firebaseConfig = {
    apiKey: getEnvironment().FIREBASE_APIKEY,
    authDomain: getEnvironment().FIREBASE_AUTHDOMAIN,
    projectId: getEnvironment().FIREBASE_PROJECTID,
    storageBucket: getEnvironment().FIREBASE_STORAGEBUCKET,
    messagingSenderId: getEnvironment().FIREBASE_MESSAGINGSENDERID,
    appId: getEnvironment().FIREBASE_APPID,
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  return firebase;
}
