/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { Platform, TextInput } from "react-native";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getTokenResolver } from "../util/tokens";
import definitionHelper from "../util/definitions.js";
import {
  isNull,
  isEmpty,
  renderFieldValue,
  fieldAccessor,
  getLookupDefinition,
  hasCommonElement,
} from "../util/common.js";

const isWeb = Platform.OS === "web";

const FORMAT_FIELD = "!!format";

export function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <TextInput
      style={{
        borderRadius: 10,
        flex: 1,
        width: "100%",
        height: 40,
        backgroundColor: "white",
      }}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

export function dateComparator(a, b) {
  if (isEmpty(a) && isEmpty(b)) {
    return 1;
  } else if (isEmpty(a)) {
    return 1;
  } else if (isEmpty(b)) {
    return -1;
  } else {
    return moment(a) > moment(b) ? 1 : -1;
  }
}

export const BlackTypography = withStyles(
  () => {
    return {
      root: {
        color: "black",
      },
    };
  },
  { withTheme: true }
)(Typography);

export const BlueTypography = withStyles(
  () => {
    return {
      root: {
        color: "blue",
      },
    };
  },
  { withTheme: true }
)(Typography);

export function fieldViewSecured(layoutGraph, userField) {
  let currentUser = layoutGraph ? layoutGraph.currentUser : null;
  let roles = userField.viewRoles;
  if (isNull(roles)) {
    return false;
  } else {
    return currentUser ? !hasCommonElement(roles, currentUser.roles) : true;
  }
}

export function filterRowCheck(props, fields, currentRow, filter) {
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (!fieldViewSecured(props.layoutGraph, field) && !field.hidden) {
      if (field.type === "object") {
        if (
          filterRowCheck(props, field.definition.fields, currentRow, filter)
        ) {
          return true;
        }
      } else {
        if (isEmpty(field.lookup)) {
          let value = renderFieldValue(
            props,
            field,
            currentRow.original.raw[field.fieldName],
            null,
            false,
            null,
            false,
            "   "
          );
          value = isNull(value) ? "" : value.toString().toLowerCase();
          if (value.indexOf(filter) >= 0) {
            return true;
          }
        } else {
          let lookupDef = getLookupDefinition(props, field);
          let lookupFields = definitionHelper.findPrimaryFields(
            lookupDef,
            field.lookupFields
          );
          if (lookupFields.length > 0) {
            for (let j = 0; j < lookupFields.length; j++) {
              let lookupField = lookupFields[j];
              let value = renderFieldValue(
                props,
                field,
                currentRow.original.raw[field.fieldName],
                lookupField.fieldName,
                false,
                currentRow.original.lookups[field.fieldName],
                false,
                "   "
              );
              value = isNull(value) ? "" : value.toString().toLowerCase();
              if (value.indexOf(filter) >= 0) {
                return true;
              }
            }
          }
        }
      }
    }
  }
  return false;
}
export function gridFieldAccessor(row, field) {
  if (isNull(field)) {
    return row.raw.id;
  } else {
    return fieldAccessor(row.raw, field);
  }
}

export function renderValue(
  props,
  field,
  column,
  value,
  lookupField,
  lookupValues,
  inHTML = false,
  nullToken = "",
  forExport = false
) {
  if (isNull(field) && column !== "id") {
    console.log("WARNING Field not found: ", column);
  }
  let retVal = renderFieldValue(
    props,
    field,
    value,
    lookupField,
    lookupField === null,
    lookupValues,
    inHTML,
    nullToken,
    forExport
  );
  return retVal;
}

export function renderValueAsJSX(
  props,
  field,
  cell,
  column,
  value,
  lookupField,
  lookupValues,
  inHTML = false,
  nullToken = "",
  forExport = false
) {
  let retVal = renderValue(
    props,
    field,
    column,
    value,
    lookupField,
    lookupValues,
    inHTML,
    nullToken,
    forExport
  );
  let val = isNull(retVal) ? "" : retVal.toString();
  if (isWeb) {
    return (
      <div
        uppercase={null}
        style={{ color: cell && cell.column.primaryField ? "blue" : "black" }}
      >
        {val}
      </div>
    );
  } else {
    if (cell && cell.column.primaryField) {
      return <BlackTypography variant="body1">{title}</BlackTypography>;
    } else {
      return <BlueTypography variant="body1">{title}</BlueTypography>;
    }
  }
}

export function findField(fields, fieldName) {
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.fieldName === fieldName) {
      return field;
    }
    if (field.type === "object") {
      field = findField(field.definition.fields, fieldName);
      if (field !== null) return field;
    }
  }
  return null;
}

export function getFormatField(rootDefinition) {
  let hasFormatField = false;
  let formatFieldName = undefined;
  if (rootDefinition) {
    let fields = definitionHelper.findAllDisplayFields(rootDefinition.fields);
    if (fields) {
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (field.fieldName.startsWith(FORMAT_FIELD)) {
          field.hidden = true;
          field.showInMore = false;
          hasFormatField = true;
          formatFieldName = field.fieldName;
          break;
        }
      }
    }
  }
  return { hasFormatField, formatFieldName };
}

export function getGeoField(props, rootDefinition, geoFieldName) {
  if (!isNull(geoFieldName)) {
    let tokenResolver = getTokenResolver(props, rootDefinition, geoFieldName);
    if (!isNull(tokenResolver) && !isEmpty(tokenResolver.resolvers)) {
      let finalResolver =
        tokenResolver.resolvers[tokenResolver.resolvers.length - 1];
      if (finalResolver.field.type === "geog") {
        return tokenResolver;
      }
    }
  }
  return undefined;
}

export function getDefaultFilter(filter) {
  let initialGridFilters = isEmpty(filter) ? [] : filter;
  let defaultFilter = {};
  initialGridFilters.map((item) => (defaultFilter[item.id] = item.value));
  return defaultFilter;
}
