import update from "immutability-helper";
import { addWorkflowLog } from "../../hoc/withCache";

export function deleteThreadLayout(layout, threads) {
  //Loop backwards for splice
  if (threads.length > 0) {
    let ids = threads.map((a) => a.id);
    let count = 0;
    if (layout?.getAllThreads) {
      for (let i = layout.getAllThreads.length - 1; i >= 0; i--) {
        if (ids.indexOf(layout.getAllThreads[i].id) >= 0) {
          //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
          //console.log("++++++++++++++ DELETING", layout.getAllThreads[i].id, timeStampInMs)
          addWorkflowLog(
            layout.getAllThreads[i].id,
            layout.getAllThreads[i].id,
            layout.getAllThreads[i].timeStamp,
            false
          );
          layout = update(layout, { getAllThreads: { $splice: [[i, 1]] } });
          count += 1;
          if (count === threads.length) break;
        }
      }
    }
  }
  return layout;
}
