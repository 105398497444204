/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import OuterDrawerItem from "./OuterDrawerItem";
import DrawerHeader from "./DrawerHeader";
import { withRouter } from "../../ReactRouter";
import LogoutButton from "../../components/authentication/LogoutButton";
import { isEmpty, isNull } from "../../util/types";
import { clearCache } from "../../hoc/withCache";

const styles = StyleSheet.create({
  customDrawerTouch: {
    paddingLeft: 13,
    paddingTop: 15,
  },
  customDrawerIcon: { paddingRight: 10 },
  backButtonRow: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingBottom: 17,
    paddingLeft: 3,
    borderBottomColor: "#F0F0F0",
    borderBottomWidth: 1,
  },
});

const MainDrawer = ({
  history,
  item,
  executeAction,
  closeDrawer,
  changeLoginState,
  drawerMenu,
  title,
  renderLogo,
  publicStyle,
  currentUser,
}) => {
  const [mainDrawer, setMainDrawer] = useState(true);
  const [currentComponent, setCurrentComponent] = useState("");

  const toggleMainDrawer = () => {
    setMainDrawer(!mainDrawer);
  };

  const renderMainDrawerComponents = (drawerMenu) =>
    Object.keys(drawerMenu).map((key) => {
      let menuItem = drawerMenu[key];
      let isFolder = !(
        !isEmpty(menuItem) &&
        menuItem.length === 1 &&
        !isEmpty(menuItem[0]?.menuNames) &&
        menuItem[0].menuNames.length === 1
      );
      return (
        <OuterDrawerItem
          key={key}
          label={key}
          isFolder={isFolder}
          onPress={() => {
            if (isFolder) {
              setCurrentComponent(key);
              setMainDrawer(false);
            } else {
              if (!isEmpty(menuItem)) {
                if (isNull(menuItem[0]?.workflow)) {
                  if (!isEmpty(menuItem[0]?.url)) {
                    clearCache();
                    history.push(menuItem[0]?.url);
                  }
                } else {
                  executeAction(menuItem[0]?.workflow, item?.id);
                }
              }
            }
          }}
        />
      );
    });

  const navigateHome = () => {
    setMainDrawer(true);
    clearCache();
    history.push("/");
  };

  if (!drawerMenu) return null;
  if (mainDrawer) {
    return (
      <View style={{ flex: 1, flexDirection: "column-reverse" }}>
        {changeLoginState && currentUser?.id !== 21 ? (
          <LogoutButton changeLoginState={changeLoginState} />
        ) : undefined}
        <ScrollView
          contentContainerStyle={{ flex: 1 }}
          nestedScrollEnabled={true}
        >
          {renderMainDrawerComponents(drawerMenu)}
        </ScrollView>
        <DrawerHeader
          publicStyle={publicStyle}
          title={title}
          renderLogo={renderLogo}
          navigateHome={navigateHome}
          closeDrawer={closeDrawer}
        />
      </View>
    );
  }

  const scopedItemsArr = drawerMenu[currentComponent];
  let scoperInnerItemsObject = {};
  if (!isEmpty(scopedItemsArr)) {
    scopedItemsArr.forEach((scopedItem) => {
      if (!isEmpty(scopedItem?.menuNames) && scopedItem.menuNames.length > 1) {
        scoperInnerItemsObject[scopedItem.menuNames[1]] = [
          {
            menuNames: [scopedItem.menuNames[1]],
            url: scopedItem?.url,
            workflow: scopedItem?.workflow,
          },
        ];
      }
    });
  }

  return (
    <View style={{ flex: 1, flexDirection: "column-reverse" }}>
      {changeLoginState && currentUser?.id !== 21 ? (
        <LogoutButton changeLoginState={changeLoginState} />
      ) : undefined}
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        nestedScrollEnabled={true}
      >
        {renderMainDrawerComponents(scoperInnerItemsObject)}
      </ScrollView>
      <TouchableOpacity
        onPress={toggleMainDrawer}
        style={styles.customDrawerTouch}
      >
        <View style={styles.backButtonRow}>
          <ArrowBackIcon />
          <Typography
            variant="body1"
            display="block"
            color={"inherit"}
            style={{ marginLeft: 10 }}
          >
            Back
          </Typography>
        </View>
      </TouchableOpacity>
      <DrawerHeader
        title={title}
        renderLogo={renderLogo}
        navigateHome={navigateHome}
        closeDrawer={closeDrawer}
      />
    </View>
  );
};

export default withRouter(MainDrawer);
