import update from "immutability-helper";
//import { addWorkflowLog } from '../../hoc/withCache';
import moment from "moment";

export function updateThreadLayout(layout, threads, isSubscriptionEvent) {
  if (threads) {
    for (let i = 0; i < threads.length; i++) {
      let thread = threads[i];
      if (thread) {
        let found = false;
        for (let j = 0; j < layout.getAllThreads.length; j++) {
          let oldThread = layout.getAllThreads[j];
          if (oldThread.taskId === thread.taskId) {
            let oldTimeStamp = oldThread.timeStamp;
            let newTimeStamp = thread.timeStamp;
            let oldTS = moment(oldTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
            let newTS = moment(newTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
            if (newTS.isAfter(oldTS)) {
              let realNew = { ...thread };
              let oldId = realNew.id;
              realNew.id = thread.taskId;
              layout = update(layout, {
                getAllThreads: { [j]: { $set: realNew } },
              });
              //addWorkflowLog(realNew.id, oldId, realNew.timeStamp, false);
              //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
              //console.log("++++++++++++++ UPDATING", thread, timeStampInMs, thread.timeStamp, thread.processing, isSubscriptionEvent)
            }
            found = true;
            break;
          }
        }
        if (!found) {
          if (!thread.processing || !isSubscriptionEvent) {
            let realNew = { ...thread };
            realNew.id = thread.taskId;
            layout = update(layout, { getAllThreads: { $push: [realNew] } });
            //addWorkflowLog(realNew.id, realNew.id, realNew.timeStamp, false);
            //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
            //console.log("++++++++++++++ INSERTING", thread, timeStampInMs,  thread.timeStamp, thread.processing, isSubscriptionEvent)
          }
        }
      }
    }
  }
  return layout;
}
