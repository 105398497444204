/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import { View } from "react-native";
import EntityFactory from "./entityFactory";
import GridBase from "./gridBase";
import { isNull } from "../util/common.js";
import ErrorMessageGrid from "./error/grid";
import errorDefinition from "./error/definition";

const ErrorGrid = (props) => {
  const errorEntityFactory = useRef(
    new EntityFactory(null, -1, errorDefinition, errorDefinition, [])
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const { layoutGraph, screenSize, allEntityFactories, primaryCollection } =
    props;

  const onSelectedChangedOverride = (
    selectedRowIds,
    mainState,
    setMainState
  ) => {
    let newIds = !isNull(selectedRowIds) ? Object.keys(selectedRowIds) : [];
    if (isNull(newIds)) newIds = [];
    for (let i = 0; i < newIds.length; i++) {
      newIds[i] = parseInt(newIds[i]);
    }
    let newState = {};
    if (mainState.selection.toString() !== newIds.toString()) {
      newState.selection = newIds;
      if (newIds.length === 1) {
        newState.selectedItem = primaryCollection.find(
          (x) => x.id.toString() === newIds[0].toString()
        );
      } else {
        newState.selectedItem = null;
      }
      setSelectedItem(newState.selectedItem);
      setMainState({ ...mainState, ...newState });
    }
  };

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <View style={{ flex: 2, height: "100%", flexDirection: "column" }}>
        <GridBase
          {...props}
          noFullPageLink={true}
          onSelectedChangedOverride={onSelectedChangedOverride}
        />
      </View>
      <View style={{ flex: 1, height: "100%", flexDirection: "column" }}>
        <ErrorMessageGrid
          entityFactory={errorEntityFactory.current}
          allEntityFactories={allEntityFactories}
          screenSize={screenSize}
          layoutGraph={layoutGraph}
          primaryCollection={
            !isNull(selectedItem) ? selectedItem["_errors"] : []
          }
          noFullPageLink={true}
        />
      </View>
    </View>
  );
};

export default ErrorGrid;
