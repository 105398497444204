/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useEffect, useState } from "react";
import MobileDetect from "mobile-detect";
import { AppState } from "react-native";
//import withNotifications from './hoc/withNotifications.js';
import withCurrentUserQuery from "./hoc/graphql/currentUser.js";
import withStyleQuery from "./hoc/graphql/style.js";
import withEntityQuery from "./hoc/graphql/entity.js";
import withUIQuery from "./hoc/graphql/ui.js";
import withLayoutQuery from "./hoc/graphql/layout.js";
import { isNull } from "./util/common.js";
import BrowserRoutes from "./BrowserRoutes";
import { withApollo } from "@apollo/client/react/hoc";
import useWindowSize from "./hooks/useWindowSize";

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();
/*
let standalone = null;
if (navigator.standalone) {
  standalone = "standalone-ios";
}
if (window.matchMedia("(display-mode: standalone)").matches) {
  standalone = "standalone";
}
*/

// function isMobileDevice() {
//   let ua = navigator.userAgent.toLowerCase();
//   let isAndroid = ua.indexOf("android") > -1;
//   return (
//     isAndroid ||
//     [
//       "iPad Simulator",
//       "iPhone Simulator",
//       "iPod Simulator",
//       "iPad",
//       "iPhone",
//       "iPod",
//     ].includes(navigator.platform) ||
//     // iPad on iOS 13 detection
//     (navigator.userAgent.includes("Mac") && "ontouchend" in document)
//   );
// }
const BrowserRouter = ({
  userGraph,
  layoutGraph,
  allEntityFactories,
  loadingStyles,
  styleGraph,
  twoFactorToken,
  handleChangeLoginState,
  entityGraph,
  uiGraph,
  forwardRef,
}) => {
  const appState = useRef(AppState.currentState);

  const getState = () => {
    if (document.visibilityState === "hidden") {
      return "hidden";
    }
    if (document.hasFocus()) {
      return "active";
    }
    return "passive";
  };

  const displayState = useRef(getState());
  const [foregroundCount, setForegroundCount] = useState(
    displayState.current !== "hidden" ? 2 : 0
  );
  const { windowWidth, windowHeight } = useWindowSize();
  const currentUser = userGraph?.currentUser;

  const _handleAppStateChange = (nextAppState) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      setForegroundCount((prevCount) => prevCount + 1);
    }

    appState.current = nextAppState;
  };

  useEffect(() => {
    const appStateListener = AppState.addEventListener(
      "change",
      _handleAppStateChange
    );

    return () => {
      appStateListener?.remove();
    };
  }, []);

  const _handleVisibilityChange = () => {
    const nextState = getState();
    const prevState = displayState.current;

    if (nextState !== prevState) {
      displayState.current = nextState;
      console.log(`State changed: ${prevState} >>> ${nextState}`);

      //standalone will restrict to only running for an installed PWA on mobile
      if (nextState === "active" && isMobile /* && standalone */) {
        if (layoutGraph) layoutGraph.refetch();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("pageshow", _handleVisibilityChange, {
      capture: true,
    });
    document.addEventListener("focus", _handleVisibilityChange, {
      capture: true,
    });
    document.addEventListener("blur", _handleVisibilityChange, {
      capture: true,
    });
    document.addEventListener("visibilitychange", _handleVisibilityChange, {
      capture: true,
    });
    document.addEventListener("resume", _handleVisibilityChange, {
      capture: true,
    });
    return () => {
      document.removeEventListener("pageshow", _handleVisibilityChange, {
        capture: true,
      });
      document.removeEventListener("focus", _handleVisibilityChange, {
        capture: true,
      });
      document.removeEventListener("blur", _handleVisibilityChange, {
        capture: true,
      });
      document.removeEventListener(
        "visibilitychange",
        _handleVisibilityChange,
        {
          capture: true,
        }
      );
      document.removeEventListener("resume", _handleVisibilityChange, {
        capture: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (foregroundCount !== 0 && layoutGraph) layoutGraph.refetch(); //window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foregroundCount]);

  useEffect(() => {
    if (
      !isMobile &&
      displayState.current === "passive" &&
      foregroundCount === 1
    ) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayState.current]);

  const publicStyle = useMemo(() => {
    return isNull(styleGraph?.publicStyle) ? {} : styleGraph?.publicStyle;
  }, [styleGraph?.publicStyle]);

  const loggedIn = !isNull(currentUser);

  if (loadingStyles || !windowWidth) return null;
  return (
    <BrowserRoutes
      ref={forwardRef}
      twoFactorToken={twoFactorToken}
      loggedIn={loggedIn}
      currentUser={currentUser}
      layoutGraph={layoutGraph}
      allEntityFactories={allEntityFactories}
      publicStyle={publicStyle}
      changeLoginState={handleChangeLoginState}
      entityGraph={entityGraph}
      uiGraph={uiGraph}
      windowHeight={windowHeight}
      windowWidth={windowWidth}
    />
  );
};
export default withCurrentUserQuery(
  withApollo(
    withStyleQuery(withEntityQuery(withUIQuery(withLayoutQuery(BrowserRouter))))
  )
); //withNotifications

//export default withCurrentUserQuery(withApollo(withPublicStyleQuery(withStyleQuery(withEntityQuery(withUIQuery(withLayoutQuery(BrowserRouter))))))); //withNotifications
//export default withNotifications(withCurrentUserQuery(withEntityQuery(Router)));
