/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from "react";
import { withRouter } from "../ReactRouter";
import withApplyPayload from "./withApplyPayload";
import getEnvironment from "../environment/env";

function checkUser(BaseComponent) {
  const checkUserFn = function CheckUserInner(props) {
    const { history, layoutGraph, loggedIn, applyPayload, location } = props;
    const getUrlParams = useCallback((location) => {
      let urlParams = null;
      if (location?.search) {
        let paramString = location.search;
        if (paramString) {
          urlParams = new URLSearchParams(paramString);
        }
      }
      return urlParams;
    }, []);

    const checkAuthentication = useCallback(
      (history, currentUser, loggedIn, applyPayload, location) => {
        if (location?.pathname) {
          let path = location?.pathname;
          if (path === "/page/login" && loggedIn && currentUser?.id !== 21) {
            if (applyPayload) {
              let urlParams = getUrlParams(location);
              let key = urlParams ? urlParams.get("key") : null;
              if (key) {
                console.log(
                  "=================================================="
                );
                console.log("Applying payload ...", key);
                console.log(
                  "=================================================="
                );
                applyPayload(key)
                  .catch((res) => {
                    if (res.graphQLErrors) {
                      const errors = res.graphQLErrors.map((error) => {
                        return error.message;
                      });
                      for (let i = 0; i < errors.length; i++) {
                        //Toast.toast(`[Workflow Error]: ` +  errors[i].message, 5000);
                        console.log("ERROR!", errors[i].message);
                      }
                    }
                  })
                  .then(() => {
                    window.location.replace(
                      getEnvironment().SITE_URL + "/home"
                    );
                    //history.push("/home");
                  });
              } else {
                history.push("/home");
              }
            } else {
              history.push("/home");
            }
          }
        }
      },
      [getUrlParams]
    );

    useEffect(() => {
      checkAuthentication(
        history,
        layoutGraph?.currentUser,
        loggedIn,
        applyPayload,
        location
      );
    }, [
      history,
      layoutGraph?.currentUser,
      loggedIn,
      applyPayload,
      location,
      checkAuthentication,
    ]);

    if (
      location?.pathname === "/page/login" &&
      layoutGraph?.currentUser?.id !== 21
    ) {
      return null;
    } else {
      return <BaseComponent {...props} />;
    }
  };

  return withRouter(withApplyPayload(checkUserFn));
}

export default checkUser;
