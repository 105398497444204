export default {
  entityName: "Error",
  pluralName: "Errors",
  references: [],
  viewRoles: [2],
  readOnly: true,
  showDetail: false,
  leftActions: ["export"],
  fields: [
    {
      fieldName: "name",
      title: "Field",
      type: "string",
      required: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
    },
    {
      fieldName: "message",
      title: "Error",
      type: "string",
      required: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: false,
      widget: null,
      bulkAppend: false,
    },
    {
      fieldName: "entityName",
      title: "Entity",
      type: "string",
      required: true,
      default: null,
      format: null,
      lookup: null,
      unique: false,
      tab: null,
      hidden: true,
      widget: null,
      bulkAppend: false,
    },
  ],
  sections: [],
  minimal: false,
};
