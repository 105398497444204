/* eslint-disable react/prop-types */
import React, { Suspense, useState } from "react";
import { View } from "react-native";
import Skeleton from "@material-ui/lab/Skeleton";

import { withRouter } from "../ReactRouter";
import Loading from "../components/loading";
//import SelectEntityForm from '../forms/selectEntityForm';
//import SelectKeyFieldForm from '../forms/selectKeyFieldForm';
import BulkProcessor from "../entities/bulkProcessor.js";
import { isInt, getScreenSize } from "../util/common";
import definitionHelper from "../util/definitions.js";
import FormRoot from "../form/FormRoot";
import PageLayout from "./pageLayout";

let Select = React.lazy(() => import("react-select"));

/*
const Select = React.lazy(async () => {
  await new Promise(resolve => setTimeout(resolve, 5000));
  return import('react-select');
});
*/
const BulkImport = (props) => {
  const [state, setState] = useState(() => {
    let screenSize = getScreenSize(props.windowWidth);

    let loadableEntities = [];
    if (props.allEntityFactories) {
      for (let i = 0; i < props.allEntityFactories.length; i++) {
        if (!props.allEntityFactories[i].definition.readOnly) {
          loadableEntities.push({
            id: props.allEntityFactories[i].id,
            entityFactory: props.allEntityFactories[i],
            name: props.allEntityFactories[i].definition.entityName,
          });
        }
      }
    }
    let entityFormData = null;
    let entityId =
      props.match && props.match.params && props.match.params.entityId
        ? parseInt(props.match.params.entityId)
        : undefined;
    if (!isInt(entityId)) {
      entityId = undefined;
    } else {
      let found = false;
      for (let i = 0; i < loadableEntities.length; i++) {
        if (loadableEntities[i].id === entityId) {
          entityFormData = loadableEntities[i];
          found = true;
          break;
        }
      }
      if (!found) {
        entityId = undefined;
      }
    }

    loadableEntities.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    //const { layoutGraph, allEntityFactories } = props;
    //let definitionfield = { type: "integer", fieldName: "entities", lookup: "Entity" }
    //let options = getFixedOptions(definitionfield, layoutGraph, allEntityFactories, {}, null, null, null, null)
    return {
      screenSize,
      isDirty: false,
      isSubmitting: false,
      hasErrors: false,
      loadableEntities: loadableEntities,
      entityFormData: entityFormData,
      keyFieldFormData: null,
    };
  });

  const onEntityChange = (formData) => {
    setState({ ...state, entityFormData: formData, keyFieldFormData: null });
  };

  const onKeyFieldChange = (formData) => {
    setState({ ...state, keyFieldFormData: formData });
  };

  const renderTitle = () => {
    return props.pageItem && props.pageItem.name
      ? props.pageItem.name
      : "Import";
  };

  const renderBody = () => {
    const { layoutGraph, allEntityFactories, publicStyle } = props;
    const { entityFormData, keyFieldFormData, loadableEntities, screenSize } =
      state;

    let currentUser = layoutGraph ? layoutGraph.currentUser : null;
    const layoutLoading = layoutGraph ? layoutGraph.loading : true;

    let entityFactory = undefined;
    if (entityFormData && entityFormData.id > 0) {
      for (let i = 0; i < loadableEntities.length; i++) {
        if (loadableEntities[i].id === entityFormData.id) {
          entityFactory = loadableEntities[i].entityFactory;
          break;
        }
      }
    }
    let keyFields = [];
    if (entityFactory) {
      let fields = definitionHelper.findAllDisplayFields(
        entityFactory.definition.fields,
        false
      );

      let primaryCount = 0;
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (field.primaryField) {
          primaryCount += 1;
        }
      }
      if (primaryCount > 1) {
        keyFields.push({ id: -1, name: "Primary Key" });
      }
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (field.unique || field.type === "id") {
          keyFields.push({ id: -i - 2, name: field.fieldName });
        }
      }
    }
    return (
      <FormRoot>
        <View style={{ flex: 1, zIndex: 999 }}>
          {layoutLoading ? <Loading /> : undefined}
          {!layoutLoading && currentUser && currentUser.id ? (
            <View style={{ flex: 1, zIndex: 999 }}>
              <View
                style={{ display: "flex", flexDirection: "row", zIndex: 999 }}
              >
                <View style={{ flex: 1, zIndex: 999 }}>
                  <Suspense
                    fallback={
                      <Skeleton animation="wave" width="100%" height={40} />
                    }
                  >
                    <Select
                      aria-label={"Select Entity"}
                      value={entityFormData}
                      style={{ zIndex: 999 }}
                      onChange={(value) => {
                        onEntityChange(value);
                      }}
                      options={loadableEntities}
                      getOptionValue={(option) => option["id"]}
                      getOptionLabel={(option) => option["name"]}
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Entity ..."
                      isSearchable={true}
                      isClearable={true}
                    />
                  </Suspense>
                </View>
                <View style={{ flex: 1 }}>
                  {keyFields.length > 0 ? (
                    <Suspense
                      fallback={
                        <Skeleton animation="wave" width="100%" height={40} />
                      }
                    >
                      <Select
                        aria-label={"Select Matching Field"}
                        value={keyFieldFormData}
                        style={{ zIndex: 999 }}
                        onChange={(value) => {
                          onKeyFieldChange(value);
                        }}
                        options={keyFields}
                        getOptionValue={(option) => option["id"]}
                        getOptionLabel={(option) => option["name"]}
                        isDisabled={false}
                        isMulti={false}
                        isSearchable={true}
                        placeholder="Optionally select a field to match on ..."
                        isClearable={true}
                      />
                    </Suspense>
                  ) : undefined}
                </View>
              </View>
              <View style={{ flex: 1 }}>
                {entityFactory ? (
                  <BulkProcessor
                    publicStyle={publicStyle}
                    viewName={""}
                    keyFieldName={
                      keyFieldFormData && keyFieldFormData.name
                        ? keyFieldFormData.name
                        : undefined
                    }
                    allEntityFactories={allEntityFactories}
                    entityFactory={entityFactory}
                    screenSize={screenSize}
                    layoutGraph={layoutGraph}
                  />
                ) : undefined}
              </View>
            </View>
          ) : undefined}
        </View>
      </FormRoot>
    );
  };

  const {
    layoutGraph,
    hasSideBar,
    changeLoginState,
    menuOnlyHasHome,
    drawerMenu,
  } = props;
  const { screenSize, isDirty, isSubmitting } = state;
  let currentUser = layoutGraph ? layoutGraph.currentUser : null;

  return (
    <PageLayout
      windowHeight={props.windowHeight}
      currentUser={currentUser}
      classes={null}
      theme={null}
      screenSize={screenSize}
      wafelButtons={[]}
      hasSideBar={hasSideBar}
      entityFactory={null}
      entityId={null}
      changeLoginState={changeLoginState}
      drawerMenu={drawerMenu}
      onWorkflowSubmit={null}
      hideTopLeft={isDirty || isSubmitting}
      showNavigation={!hasSideBar && !menuOnlyHasHome}
      title={renderTitle()}
      onLogoPress={null}
      rightContent={undefined}
      bodyContent={renderBody()}
    />
  );
};

export default withRouter(BulkImport);
