/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, useCallback } from "react";
import AppLoading from "expo-app-loading";
import { Asset } from "expo-asset";
import { ErrorBoundary } from "react-error-boundary";
import { Router as ReactRouter } from "./ReactRouter";
import { getFCMToken } from "./messagingDummy";
import OfflineGuard from "./offlineGuard";
import { useServiceWorker } from "./src/useServiceWorker";

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider,
} from "@apollo/client";
import fetch from "node-fetch";

import {
  signIn,
  signOut,
  setDuration,
  getAccessToken,
  getAccessTokenAsync,
  fetchAccessToken,
  setAccessToken,
} from "./AccessToken";

import { isEmpty, isNull } from "./util/common";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwt_decode from "jwt-decode";
import BrowserRouter from "./BrowserRouter";
import getEnvironment from "./environment/env";
import Alert from "./components/Alert";
import Toast from "./components/Toast";
import "./webImports";
import { getFirebase } from "./firebase";
//import { StyleProvider } from 'native-base';
//import getTheme from './native-base-theme/components';
//import material from './native-base-theme/variables/material';
//import platform from './native-base-theme/variables/platform';
//import commonColor from './native-base-theme/variables/commonColor';
import { clearCache } from "./hoc/withCache";
import { onError } from "@apollo/client/link/error";
import Chance from "chance";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { THEME } from "./theme";

const chance = new Chance();
/*
<resources>
<color name="primaryColor">#f50057</color>
<color name="primaryLightColor">#ff5983</color>
<color name="primaryDarkColor">#bb002f</color>
<color name="secondaryColor">#263238</color>
<color name="secondaryLightColor">#4f5b62</color>
<color name="secondaryDarkColor">#000a12</color>
<color name="primaryTextColor">#fafafa</color>
<color name="secondaryTextColor">#ff9800</color>


<color name="primaryColor">#c41061</color>
<color name="primaryLightColor">#fc548e</color>
<color name="primaryDarkColor">#8e0038</color>
<color name="secondaryColor">#263238</color>
<color name="secondaryLightColor">#4f5b62</color>
<color name="secondaryDarkColor">#000a12</color>
<color name="primaryTextColor">#ffffff</color>
<color name="secondaryTextColor">#ff9800</color>

<color name="primaryColor">#6a1b9a</color>
<color name="primaryLightColor">#9c4dcc</color>
<color name="primaryDarkColor">#38006b</color>
<color name="secondaryColor">#263238</color>
<color name="secondaryLightColor">#4f5b62</color>
<color name="secondaryDarkColor">#000a12</color>
<color name="primaryTextColor">#ffffff</color>
<color name="secondaryTextColor">#ff9800</color>
</resources>

<color name="primaryColor">#4a148c</color>
<color name="primaryLightColor">#7c43bd</color>
<color name="primaryDarkColor">#12005e</color>
<color name="secondaryColor">#212121</color>
<color name="secondaryLightColor">#484848</color>
<color name="secondaryDarkColor">#000000</color>
<color name="primaryTextColor">#ffffff</color>
<color name="secondaryTextColor">#ff9800</color>


<resources>
<color name="primaryColor">#b71c1c</color>
<color name="primaryLightColor">#f05545</color>
<color name="primaryDarkColor">#7f0000</color>
<color name="secondaryColor">#212121</color>
<color name="secondaryLightColor">#484848</color>
<color name="secondaryDarkColor">#000000</color>
<color name="primaryTextColor">#ffffff</color>
<color name="secondaryTextColor">#ff9800</color>
</resources>


{
"inactiveColor":"#ffffff",
"activeColor":"#12005e",
"backgroundColor":"#FFFFFF",
"foregroundColor":"#000000",
"borderColor":"#000000",
"primaryColor1":"#212121",
"primaryColor2":"#4a148c",
"primaryColor3":"#7c43bd",
"primaryColor4":"#484848",
"primaryColor5":"#63812c",
"primaryColor6":"#e1d8bb",
"primaryColor7":"#12005e",
"formBackground1":"white",
"formBackground2":"DarkGray",
"formBorder":"rgb(170,170,170)",
"formShadow":"rgb(190,190,190)"
}

<color name="primaryColor">#b71c1c</color>
<color name="primaryLightColor">#f05545</color>
<color name="primaryDarkColor">#7f0000</color>
<color name="secondaryColor">#424242</color>
<color name="secondaryLightColor">#6d6d6d</color>
<color name="secondaryDarkColor">#1b1b1b</color>
<color name="primaryTextColor">#ffffff</color>
<color name="secondaryTextColor">#ff9800</color>


  <color name="primaryColor">#047b4c</color>
  <color name="primaryLightColor">#48ab78</color>
  <color name="primaryDarkColor">#004e23</color>
  <color name="secondaryColor">#9e5a2d</color>
  <color name="secondaryLightColor">#d38758</color>
  <color name="secondaryDarkColor">#6b3002</color>
  <color name="primaryTextColor">#ffffff</color>
  <color name="secondaryTextColor">#ffffff</color>
*/

const defaultTheme = createTheme(THEME);

import {
  queryOrMutationLink,
  getSubscription,
  requestLink,
} from "./ApolloHelper";

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   /*
//   onError receives a callback in the event a GraphQL or network error occurs.
//   This example is a bit contrived, but in the real world, you could connect
//   a logging service to the errorLink or perform a specific action in response
//   to an error.
//   */
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, location, path }) => {
//       Toast.toast(
//         `[API Error1]: Message: ${message}, Location: ${location}, Path: ${path}`,
//         5000
//       );
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`
//       );
//     });
//   if (networkError) {
//     Toast.toast(`[Network Error]: `, networkError, 5000);
//     console.log(`[Network error]: `, networkError);
//   }
// });

let firebase = getFirebase();

/*
 messaging.requestPermission().then(async () => {
  const token = await messaging.getToken()
  // Now we can send this token to the backend
})
*/

//let pushToken;
// const messaging = firebase.messaging();
// messaging
//   .getToken()
//   .then((currentToken) => {
//     if (currentToken) {
//       console.log("FCM token> ", currentToken);
//       pushToken = currentToken;
//     } else {
//       console.log("No Token available");
//     }
//   })
//   .catch((error) => {
//     console.log("An error ocurred while retrieving token. ", error);
//   });

/*
const messaging = firebase.messaging();

messaging.onMessage((payload) => {
  console.log("Message received. ", payload);
  const { title, ...options } = payload.notification;
  navigator.serviceWorker.register("firebase-messaging-sw.js");
  function showNotification() {
    Notification.requestPermission(function (result) {
      if (result === "granted") {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification(payload.notification.title, {
            body: payload.notification.body,
            tag: payload.notification.tag,
          });
        });
      }
    });
  }
  showNotification();
});
*/

const App = ({ skipLoadingScreen }) => {
  const brrouter = useRef(null);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      Toast.toast(
        "A new version of this page is available",
        0,
        "REFRESH",
        reloadPage
      );
    } else Toast.clearMessage();
  }, [waitingWorker, showReload, reloadPage, isLoadingComplete]);

  const [loginState, setLoginState] = useState(() => {
    return { loggedIn: false, twoFactorToken: null, count: 1 };
  });

  const logOut = useCallback((updateState = true) => {
    // if (!isNull(brrouter?.current)) {
    //   brrouter.current.showError([
    //     {
    //       message: JSON.stringify({
    //         toast: true,
    //         message: "TEST MESSAGE",
    //       }),
    //     },
    //   ]);
    // }
    console.log(">>> logOut: LOGGING OUT");
    clearCache(true);
    setLoginState((prevLoginState) => {
      if (prevLoginState.loggedIn) {
        console.log(">>> logOut: closing websocket");
        signOut();
        console.log(
          ">>>> subscriptionLink.subscriptionClient close false false"
        );
        subscriptionClient.current.close(false, false);
        console.log(">>>> updateState", updateState);
        client.current.cache.reset();
        if (updateState) {
          if (prevLoginState.loggedIn) {
            return {
              loggedIn: false,
              twoFactorToken: null,
              count: prevLoginState.count + 1,
            };
          }
        }
      }
      return prevLoginState;
    });
  }, []);

  const handleGraphQLError = useCallback(({ graphQLErrors, networkError }) => {
    /*
    onError receives a callback in the event a GraphQL or network error occurs.
    This example is a bit contrived, but in the real world, you could connect
    a logging service to the errorLink or perform a specific action in response
    to an error. 
    */
    let handled = false;
    if (!isNull(brrouter?.current)) {
      handled = brrouter.current.showError(graphQLErrors, networkError);
    }
    if (!handled) {
      if (graphQLErrors)
        graphQLErrors.map(({ message, location, path }) => {
          let toastMessage = `[API Error1]: Message: ${message}, Location: ${location}, Path: ${path}`;
          let timeOut = 5000;
          if (
            message &&
            message.startsWith('Variable "$filters" got invalid value "%%')
          ) {
            networkError = false;
            return;
          }
          if (message && message === "Insufficient Rights.") {
            toastMessage =
              "You have insufficient rights to perform this operation.";
            timeOut = 10000;
          } else if (
            message &&
            message.indexOf("violates foreign key constraint") > 0
          ) {
            let onTableIndex = message.lastIndexOf("on table");
            toastMessage =
              "You are unable to delete this record as it is still currently referenced on the " +
              message.substring(onTableIndex + 9) +
              " table.  Please delete all references first before retrying this operation.";
            timeOut = 10000;
          }
          Toast.toast(toastMessage, timeOut);
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`
          );
        });
      if (networkError) {
        Toast.toast(`[Network Error]: `, networkError, 5000);
        console.log(`[Network error]: `, networkError);
      }
    }
  }, []);

  const handleTokenRefreshError = useCallback(() => {
    console.warn("Your refresh token is invalid. Try to relogin");
    logOut();
    let message =
      "Your session has timed out.  To continue please login again.";
    handleGraphQLError({
      graphQLErrors: [
        {
          message: JSON.stringify({
            toast: false,
            redirect: "/page/messageresult",
            message,
          }),
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenRefresh = useRef(
    new TokenRefreshLink({
      accessTokenField: "accessToken",
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();
        if (!token) {
          return true;
        }

        try {
          const { exp } = jwt_decode(token);
          let timeSince1970 = Date.now() + 5000;
          if (timeSince1970 >= exp * 1000) {
            console.log(
              "ACCESS TOKEN INVALID ... MUST ATTEMP REFRESH",
              token,
              exp,
              timeSince1970
            );
            return false;
          } else {
            //console.log("TOKEN SUCCESS");
            return true;
          }
        } catch {
          return false;
        }
      },
      fetchAccessToken: () => {
        return fetchAccessToken();
      },
      handleFetch: (accessToken) => {
        setAccessToken(accessToken);
      },
      handleResponse: (operation, accessTokenField) => (response) => {
        // here you can parse response, handle errors, prepare returned token to
        // further operations
        return response.json().then(function (data) {
          console.log(data); // { "userId": 1, "id": 1, "title": "...", "body": "..." }
          if (data && !isEmpty(data[accessTokenField])) {
            setDuration(data.duration);
            return { accessToken: data[accessTokenField] };
          } else {
            return {};
          }
        });
      },
      handleError: handleTokenRefreshError,
    })
  );

  const subscription = useRef(getSubscription(getEnvironment().WEBSOCKET_URL));
  const subscriptionLink = useRef(subscription.current.subscriptionLink);
  const subscriptionClient = useRef(subscription.current.subscriptionClient);
  const client = useRef(
    new ApolloClient({
      ssrForceFetchDelay: 100,
      connectToDevTools: true,
      link: ApolloLink.from([
        tokenRefresh.current,
        onError(handleGraphQLError),
        requestLink(
          {
            queryOrMutationLink: queryOrMutationLink({
              fetch,
              uri: getEnvironment().API_URL + "/graphql",
            }),
            subscriptionLink: subscriptionLink.current,
          },
          logOut
        ),
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              getAllThreads: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
    })
  );

  const checkForAccessToken = useCallback(() => {
    getAccessTokenAsync().then((token) => {
      if (!isEmpty(token)) {
        setLoginState((prevLoginState) => {
          if (!prevLoginState.loggedIn) {
            return {
              loggedIn: true,
              twoFactorToken: null,
              count: prevLoginState.count + 1,
            };
          }
          return prevLoginState;
        });
      }
    });
  }, []);

  useEffect(() => {
    const storageCallback = (e) => {
      //User has logged in or logged out elsewhere ... force a browser refresh
      if (!document.hasFocus()) {
        if (e && e.key === "loginstate") {
          let params = { message: "Login status changed on another window." };
          window.location.replace(
            getEnvironment().SITE_URL +
              "/page/messageresult?" +
              new URLSearchParams(params).toString()
          );
        }
      }
    };

    window.addEventListener("storage", storageCallback);
    checkForAccessToken();
    return () => {
      window.removeEventListener("storage", storageCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLoginState = useCallback((loggedIn = false, tokens) => {
    //debugger;
    console.log(">>> handleChangeLoginState: logging out");
    firebase.auth().signOut();
    let guid = chance.guid();
    localStorage.setItem("loginstate", guid);
    logOut(false);
    console.log(">>>> logOut(false)", loggedIn);
    if (loggedIn) {
      console.log(">>>> signIn");
      signIn(tokens);
      console.log(">>>> subscriptionClient close true");
      subscriptionClient.current.close(true);
      console.log(">>>> set State");
      setLoginState((prevLoginState) => {
        if (
          !prevLoginState.loggedIn ||
          tokens.twoFactorToken !== prevLoginState.twoFactorToken
        ) {
          return {
            loggedIn: true,
            twoFactorToken: tokens.twoFactorToken,
            count: prevLoginState.count + 1,
          };
        } else {
          return prevLoginState;
        }
      });
    } else {
      setLoginState((prevLoginState) => {
        if (prevLoginState.loggedIn) {
          setLoginState({
            loggedIn: false,
            twoFactorToken: null,
            count: prevLoginState.count + 1,
          });
        } else {
          return prevLoginState;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLoadingComplete = useCallback(() => {
    setIsLoadingComplete(true);
  }, []);

  console.log("---------------------------------------------------------");
  console.log("APP RERENDER");
  console.log("---------------------------------------------------------");
  if (!isLoadingComplete && !skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => setLoadingComplete()}
      />
    );
  } else {
    return (
      <ThemeProvider theme={defaultTheme}>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => {
            const errorMsg = error?.message;
            const chunkFailedMessage = /Loading chunk [\d]+ failed/;
            const hasChunkError = errorMsg && chunkFailedMessage.test(errorMsg);

            if (hasChunkError) {
              window.location.reload();
              return null;
            }
            return (
              <div role="alert">
                <div>
                  We apologise however we have not been able to recover from an
                  error. Please click &quot;Reset&quot; before attempting to
                  login again.
                </div>
                <pre>{error.toString()}</pre>
                <button
                  onClick={async () => {
                    let pushToken = await getFCMToken();
                    let params = {
                      pushToken,
                    };

                    try {
                      await fetch(getEnvironment().API_URL + "/log_out", {
                        method: "POST",
                        credentials: "include",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(params),
                      });
                      localStorage.clear();
                      firebase.auth().signOut();
                      handleChangeLoginState(false);
                      window.location.replace(getEnvironment().SITE_URL);
                      resetErrorBoundary();
                      // eslint-disable-next-line no-empty
                    } catch {}
                  }}
                >
                  Reset
                </button>
              </div>
            );
          }}
        >
          <OfflineGuard>
            <ApolloProvider client={client.current}>
              <ReactRouter>
                <BrowserRouter
                  key={loginState.count.toString()}
                  forwardRef={brrouter}
                  twoFactorToken={loginState.twoFactorToken}
                  handleChangeLoginState={handleChangeLoginState}
                />
              </ReactRouter>
              <Alert />
              <Toast />
            </ApolloProvider>
          </OfflineGuard>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }
};

async function loadResourcesAsync() {
  await Promise.all([
    // eslint-disable-next-line no-undef
    Asset.loadAsync([require("./assets/images/logo.png")]),
  ]);
  /*
    Font.loadAsync({
      // This is the font that we are using for our tab bar
      ...Ionicons.font,
      // We include SpaceMono because we use it in HomeScreen.js. Feel free to
      // remove this if you are not using it in your app
      'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
    }),
  */
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

export default App;
