import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { deleteThreadLayout } from "./deleteThreadLayout";
import Constants from "expo-constants";

const DELETE_THREADS_MUTATION = gql`
  mutation deleteThreads($ids: [Int!], $uiVersion: String) {
    deleteThreads(ids: $ids, uiVersion: $uiVersion) {
      id
    }
  }
`;

export default graphql(DELETE_THREADS_MUTATION, {
  options: {},
  props: ({ mutate }) => ({
    delete: ({ ids }) => {
      return mutate({
        variables: { ids, uiVersion: Constants.manifest.version },
        updateQueries: {
          LayoutQuery: (prev, { mutationResult }) => {
            return deleteThreadLayout(prev, mutationResult.data.deleteThreads);
          },
        },
      });
    },
  }),
});
