import React from "react";

let tabs = {};
let state = {};
let searchWidgetCache = {};
let forms = {};
let log = [];

let cache = { tabs, state, forms, searchWidgetCache, log };

export function CACHE() {
  return cache;
}

export function WORKFLOWLOG() {
  return cache.log;
}

export function addWorkflowLog(guid, oldId, serverTimestamp, isUpdate) {
  if (cache.log.length > 99) {
    cache.log.shift();
  }
  let localts = new Date().toISOString();
  let logItem = { guid, serverTimestamp };
  if (isUpdate) {
    logItem.sendTime = localts;
  } else {
    logItem.receiveTime = localts;
  }
  cache.log.push(logItem);
}

export function clearCache(includingLog) {
  if (cache) {
    cache.tabs = {};
    cache.state = {};
    cache.forms = {};
    cache.searchWidgetCache = {};
    if (includingLog) cache.log = [];
  }
}

export function withCache(BaseComponent) {
  const withCacheFn = function WithCacheInner(props) {
    return <BaseComponent {...props} cache={cache} />;
  };

  return withCacheFn;
}

export const useCache = (cacheRoot) => {
  const clearCache = (includingLog) => {
    cache.tabs = {};
    cache.state = {};
    cache.forms = {};
    cache.searchWidgetCache = {};
    if (includingLog) {
      cache.log = [];
    }
  };
  const addWorkflowLog = (guid, serverTimestamp, isUpdate) => {
    if (cache.log.length > 99) {
      cache.log.shift();
    }
    let localts = new Date().toISOString();
    let logItem = { guid, serverTimestamp };
    if (isUpdate) {
      logItem.sendTime = localts;
    } else {
      logItem.receiveTime = localts;
    }
    cache.log.push(logItem);
  };

  const getGeneral = () => {
    if (cacheRoot) {
      if (!cache.state[cacheRoot]) {
        cache.state[cacheRoot] = {};
      }
      return cache.state[cacheRoot];
    }
    return {};
  };

  const getSearch = () => {
    if (cacheRoot) {
      if (!cache.searchWidgetCache[cacheRoot]) {
        cache.searchWidgetCache[cacheRoot] = {};
      }
      return cache.searchWidgetCache[cacheRoot];
    }
    return {};
  };

  return {
    tabCache: cache.tabs,
    generalCache: getGeneral(),
    formCache: cache.forms,
    searchWidgetCache: getSearch(),
    logCache: cache.log,
    clearCache,
    addWorkflowLog,
  };
};
