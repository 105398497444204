// For now this is fine for testing .. however the below commented out section
// where the refreshToken is stored as a httpOnly cookie is probably better.
// The cookie would need to be deleted by the server on logout. (e.g. call a /logout method)
// and change "credentials: 'omit'," to "credentials: 'include'," here and in ApolloHelper.js to send and receive cookies.
// only server can delete an httpOnly cookie.
// However tunneling to localhost in debug makes this version work better.

/*
import { AsyncStorage } from 'react-native';

const REFRESH_TOKEN = 'REFRESH_TOKEN';

let accessToken;

const getFetchOptionsForAccessToken = (refreshToken) => {
  return {
    method: 'POST',
    credentials: 'omit',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refreshToken })
  };
}

export const fetchAccessToken = async () => {
  return AsyncStorage.getItem(REFRESH_TOKEN).then((refreshToken) => {
    if (refreshToken) {
      return fetch('http://localhost:3010/refresh_token', getFetchOptionsForAccessToken(refreshToken));
    } else {
      return null;
    }
  }).catch(e => {
    return null;
  });
}

export const setAccessToken = (token) => {
  accessToken = token;
};


export const getAccessToken = () => {
  return accessToken;
};

export const getAccessTokenAsync = async () => {
  if (accessToken) {
    return Promise.resolve(accessToken);
  } else {
    let refreshQuery = await fetchAccessToken();
    let data = refreshQuery ? await refreshQuery.json() : null;
    if (data) accessToken = data.accessToken;
  }

  return accessToken;
};

export const signIn = (tokens) => {
  accessToken = tokens.accessToken;
  return AsyncStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
};

export const signOut = () => {
  accessToken = undefined;
  //RCTNetworking.clearCookies(() => {});
  return AsyncStorage.removeItem(REFRESH_TOKEN);
};
*/

import Constants from "expo-constants";
import getEnvironment from "./environment/env";
import { clearCache } from "./hoc/withCache";
import { isEmpty, isNull } from "./util/common";
import { getFCMToken } from "./messagingDummy";

let accessToken;
//let refreshToken;
let twoFactorToken;
let duration;
let intervalId;
//let subscriptionLink;

const timeOut = async () => {
  if (intervalId) {
    signOut();
    //if (subscriptionLink) subscriptionLink.subscriptionClient.close(false, false);
    let pushToken = await getFCMToken();
    let params = {
      pushToken,
    };

    fetch(getEnvironment().API_URL + "/log_out", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then(() => {
      let params = { message: "Session time out.  Please login again." };
      window.location.replace(
        getEnvironment().SITE_URL +
          "/page/messageresult?" +
          new URLSearchParams(params).toString()
      );
    });
  }
};

export const getFetchOptionsForAccessToken = () => {
  return {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};

export const fetchAccessToken = async () => {
  let url = new URL(getEnvironment().API_URL + "/refresh_token");
  let params = { uiversion: Constants.manifest.version };
  url.search = new URLSearchParams(params).toString();
  return fetch(url, getFetchOptionsForAccessToken());
};

export const setAccessToken = (token) => {
  accessToken = token;
};

export const setDuration = (timeRemaininginMs) => {
  duration = timeRemaininginMs;
  if (intervalId) clearInterval(intervalId);
  if (!isNull(duration)) {
    let triggerInMs = duration < 5000 ? 0 : duration - 5000;
    intervalId = setInterval(timeOut, triggerInMs);
  }
};

export const getAccessToken = () => {
  return accessToken;
};

export const getTwoFactorToken = () => {
  return twoFactorToken;
};

export const getAccessTokenAsync = async (force = false) => {
  if (accessToken && !force) {
    return Promise.resolve(accessToken);
  } else {
    let hadAccessToken = !isEmpty(accessToken);
    let refreshQuery = await fetchAccessToken();
    let data = refreshQuery ? await refreshQuery.json() : null;
    if (data && !isEmpty(data.accessToken)) {
      accessToken = data.accessToken;
      setDuration(data.duration);
    } else if (isEmpty(twoFactorToken)) {
      signOut();
      if (hadAccessToken) {
        //Whatever way it happened, we no longer have a valid access token
        let msg =
          data && !isEmpty(data.message)
            ? data.message
            : "Your session has timed out.  To continue please login again.";
        let params = {};
        if (isNull(data) || isEmpty(data.version)) {
          params.message = msg;
        } else {
          params.version = data.version;
        }
        let url = isEmpty(data.redirect)
          ? "/page/messageresult"
          : data.redirect;
        window.location.replace(
          getEnvironment().SITE_URL +
            url +
            "?" +
            new URLSearchParams(params).toString()
        );
      }
    }
  }

  return accessToken;
};

export const signIn = (tokens) => {
  accessToken = tokens.accessToken;
  twoFactorToken = tokens.twoFactorToken;
  if (isEmpty(twoFactorToken)) {
    setDuration(tokens.duration);
  } else {
    setDuration(240000);
  }
};

export const signOut = () => {
  accessToken = undefined;
  setDuration(undefined);
  //refreshToken = undefined;
  twoFactorToken = undefined;
  clearCache(true);
  console.log(">>> SIGNOUT");
};

//export const getRefreshToken = () => {
//  return refreshToken;
//};
