/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { isNull, getGlobalFilter } from "../util/common.js";

export default function withCachedFilterQuery(
  BaseComponent,
  graphQuery,
  defaultFilter = null
) {
  const withCachedFilterQueryFn = function WithCachedFilterQueryInner(props) {
    const { layoutGraph, entityFactory, filter } = props;

    const getStateFromProps = useCallback(
      (currentUser, filter, entityFactory) => {
        if (!currentUser) return null;
        let entityQuery = graphql(gql(graphQuery), {
          options() {
            //let timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
            let globalFilter = getGlobalFilter();
            let variables = {
              filters: defaultFilter ? defaultFilter : filter,
              globalFilter,
            };
            return {
              fetchPolicy: "network-only",
              fragments: {},
              variables: variables,
            };
          },
          props({ data }) {
            let items = data["search" + entityFactory.definition.entityName];
            let queryResult = isNull(items) ? [] : items;
            return {
              queryResult: queryResult,
              subscribeToMore: data.subscribeToMore,
              queryLoading: data.loading,
            };
          },
        });
        let BaseComponentWithQuery = entityQuery(BaseComponent);
        return BaseComponentWithQuery;
      },
      []
    );

    const [BaseComponentWithQuery, setBaseComponentWithQuery] = useState(() => {
      return getStateFromProps(layoutGraph?.currentUser, filter, entityFactory);
    });

    useEffect(() => {
      setBaseComponentWithQuery((previousBaseComponentWithQuery) => {
        if (BaseComponentWithQuery) {
          return previousBaseComponentWithQuery;
        } else {
          return getStateFromProps(
            layoutGraph?.currentUser,
            filter,
            entityFactory
          );
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutGraph?.currentUser, entityFactory, filter]);

    if (BaseComponentWithQuery) {
      return <BaseComponentWithQuery {...props} />;
    } else {
      return <BaseComponent {...props} />;
    }
  };

  return withCachedFilterQueryFn;
}
