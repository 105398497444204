/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from "react";
import { SUBDEFINITIONS, FTS_FIELD } from "../util/constants";
import {
  isNull,
  isEmpty,
  hasCommonElement,
  getLookupFactory,
  findLookupFactory,
  fieldHidden,
  getGlobalFilter,
} from "../util/common.js";
import definitionHelper from "../util/definitions.js";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import update from "immutability-helper";
import { withRouter } from "../ReactRouter";
import * as cloneDeep from "lodash/cloneDeep";
import * as yup from "yup";

import withEntityItem from "../hoc/withEntityItem.js";
import withCollection from "../hoc/withCollection.js";
import withQueryToLayout from "../hoc/withQueryToLayout.js";
import withCachedFilterQuery from "../hoc/withCachedFilterQuery.js";
import withFileQuery from "../hoc/withFileQuery.js";
import withLocation from "../hoc/withLocation.js";
import withAdvancedSearch from "../hoc/withAdvancedSearch.js";
import { withCache } from "../hoc/withCache";

import FormBase from "./formBase";
import CardTemplate from "./cardTemplate";
import GridBase from "./gridBase";
import ErrorGrid from "./errorGrid.js";
import FilePage from "../pages/filePage";
import withWorkflowUpdate from "./thread/update.js";
import EntityFormComponent from "./components/entityFormComponent";
import errorDefinition from "./error/definition";
import { formatMinutes } from "../util/format";

let WrappedFormBase = withRouter(withWorkflowUpdate(FormBase));

yup.addMethod(yup.string, "JSONRequired", function (args) {
  const { selectFieldName, errorMessage } = args;
  return this.test(`test-json-required`, errorMessage, function (value) {
    const { path, createError } = this;
    let hasValues = false;
    if (value) {
      let rows = [];
      try {
        rows = JSON.parse(value);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          let row = rows[i];
          let isSelected = row[selectFieldName];
          if (isSelected) {
            hasValues = isSelected;
            break;
          }
        }
      }
    }
    return hasValues || createError({ path, message: errorMessage });
  });
});

class EntityFactory {
  constructor(user, id, definition, origDefinition, primaryCollection = null) {
    this.camelCasePluralName = this.camelCasePluralName.bind(this);
    this.lowerCaseEntityName = this.lowerCaseEntityName.bind(this);
    this.updateSearchGraphAfterUpdate =
      this.updateSearchGraphAfterUpdate.bind(this);
    this.updateLayoutGraphAfterUpdate =
      this.updateLayoutGraphAfterUpdate.bind(this);
    this.updateSearchGraphAfterDelete =
      this.updateSearchGraphAfterDelete.bind(this);
    this.updateLayoutGraphAfterDelete =
      this.updateLayoutGraphAfterDelete.bind(this);
    this.getForm = this.getForm.bind(this);
    this.getFormWithEntity = this.getFormWithEntity.bind(this);
    this.getBulkForm = this.getBulkForm.bind(this);
    this.getGrid = this.getGrid.bind(this);
    this.getBulkGrid = this.getBulkGrid.bind(this);
    this.getErrorGrid = this.getErrorGrid.bind(this);
    //this.getPage = this.getPage.bind(this);
    this.getGraphQueryString = this.getGraphQueryString.bind(this);
    this.getEntityItemQueryString = this.getEntityItemQueryString.bind(this);
    this.getEntityItemFTSQueryString =
      this.getEntityItemFTSQueryString.bind(this);
    this.getGraphUpdateSubscription =
      this.getGraphUpdateSubscription.bind(this);
    this.getGraphUpdate = this.getGraphUpdate.bind(this);
    this.withFactory = this.withFactory.bind(this);
    this.withSearchDefinition = this.withSearchDefinition.bind(this);
    this.withFactoryAndDefinition = this.withFactoryAndDefinition.bind(this);
    this.withUpdate = this.withUpdate.bind(this);
    this.withDelete = this.withDelete.bind(this);
    this.withFilter = this.withFilter.bind(this);
    this.withIdFilter = this.withIdFilter.bind(this);
    this.getGraphDeleteSubscription =
      this.getGraphDeleteSubscription.bind(this);
    this.getGraphDelete = this.getGraphDelete.bind(this);
    this.getPrimaryCollection = this.getPrimaryCollection.bind(this);
    this.upperCaseEntityName = this.upperCaseEntityName.bind(this);
    this.getAllDisplayFields = this.getAllDisplayFields.bind(this);
    this.getAllTopLevelDBFields = this.getAllTopLevelDBFields.bind(this);
    this.hasSomeViewRights = this.hasSomeViewRights.bind(this);
    this.hasEditRights = this.hasEditRights.bind(this);
    this.hasSomeEditRights = this.hasSomeEditRights.bind(this);
    this.hasSomeAddRights = this.hasSomeAddRights.bind(this);
    this.hasAddAllRights = this.hasAddAllRights.bind(this);
    this.hasDeleteAllRights = this.hasDeleteAllRights.bind(this);
    this.hasDeleteRights = this.hasDeleteRights.bind(this);
    this.hasSomeDeleteRights = this.hasSomeDeleteRights.bind(this);
    this.hasViewAllRights = this.hasViewAllRights.bind(this);
    this.hasCacheRole = this.hasCacheRole.bind(this);
    this.hasEditAllRights = this.hasEditAllRights.bind(this);
    this.hasEditGroupRights = this.hasEditGroupRights.bind(this);
    this.cacheable = this.cacheable.bind(this);
    this.setDefinition = this.setDefinition.bind(this);
    this.fieldIsSecured = this.fieldIsSecured.bind(this);
    this.getSubDefinition = this.getSubDefinition.bind(this);
    this.getPlainForm = this.getPlainForm.bind(this);
    this.isUICache = this.isUICache.bind(this);
    this.entityIsValidForLayout = this.entityIsValidForLayout.bind(this);
    this.entityIsValidForUIGraph = this.entityIsValidForUIGraph.bind(this);
    this.getSearchFormWithEntityAsComponent =
      this.getSearchFormWithEntityAsComponent.bind(this);
    this.addValidationFields = this.addValidationFields.bind(this);
    this.getValidationSchema = this.getValidationSchema.bind(this);

    this.id = id;
    this.user = user;
    this.setDefinition(definition, origDefinition);
    let cacheStrategy = isEmpty(definition.cacheStrategy)
      ? "always"
      : definition.cacheStrategy;

    if (
      user &&
      ((cacheStrategy === "groupFilter" &&
        !this.hasViewAllRights(user) &&
        !isEmpty(this.getRuleFilters(user, this.definition.viewGroupRules))) ||
        (cacheStrategy === "role" && this.hasCacheRole(user)))
    ) {
      this.definition.cacheable = true;
    } else {
      this.definition.cacheable = cacheStrategy === "always";
    }
    this.primaryCollection = primaryCollection;
    this.cacheableFixed = false;
  }

  setDefinition(definition, origDefinition) {
    this.definition = definition;
    this.origDefinition = origDefinition;
  }

  cacheableIsFixed() {
    return this.cacheableFixed;
  }

  fixCacheable(allEntityFactories, rootNames) {
    if (this.cacheableFixed) return;
    if (!this.definition.cacheable) {
      this.cacheableFixed = true;
      return;
    }

    let fields = definitionHelper.findAllTopLevelDBFields(
      this.definition.fields
    );
    if (isNull(rootNames)) rootNames = [];
    rootNames.push(this.entityName());
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (field.type === "integer") {
        if (!isEmpty(field.lookup)) {
          let lookupFactory = findLookupFactory(allEntityFactories, field);
          if (
            isNull(lookupFactory) ||
            rootNames.indexOf(lookupFactory.entityName()) >= 0
          ) {
            //Circular reference.  Assume cachestategy correct or set to false?
          } else {
            if (!lookupFactory.cacheableIsFixed()) {
              lookupFactory.fixCacheable(allEntityFactories, rootNames);
            }
            if (!lookupFactory.cacheable()) {
              if (this.definition.cacheable) {
                console.log(
                  "FIXING CACHEABLE FOR ",
                  this.entityName(),
                  " due to ",
                  lookupFactory.entityName()
                );
              }
              this.definition.cacheable = false;
              break;
            }
          }
        }
      }
    }
    this.cacheableFixed = true;
  }

  cacheable() {
    return isNull(this.definition.cacheable) ? true : this.definition.cacheable;
  }

  getView() {
    return this;
  }

  lowerCaseEntityName() {
    let entityName = this.definition.entityName;
    return entityName.toLowerCase();
  }

  lowerCasePluralName() {
    let entityName = this.definition.pluralName;
    return entityName.toLowerCase();
  }

  camelCasePluralName() {
    let entityName = this.definition.pluralName;
    return entityName.charAt(0).toLowerCase() + entityName.substring(1);
  }

  getAllDisplayFields() {
    return definitionHelper.findAllDisplayFields(this.definition.fields);
  }

  getAllTopLevelDBFields() {
    return definitionHelper.findAllTopLevelDBFields(this.definition.fields);
  }

  getAllLinkFields() {
    return definitionHelper.findAllLinkFields(this.definition.fields);
  }

  getSection(sectionName) {
    if (isNull(sectionName)) {
      sectionName = "main";
    }
    return definitionHelper.getSection(this.definition.fields, sectionName);
  }

  getSubDefinition(definitionName) {
    let definition = definitionHelper.findSubDefinition(
      this.definition,
      definitionName
    );
    if (definition === this.definition) {
      definition = cloneDeep(definition);
    }
    return definition;
  }

  getGraphQueryString() {
    let queryArray = [];
    let definition = this.definition;
    let fields = this.getAllDisplayFields();
    queryArray.push(
      "getAll" + definition.pluralName + "(globalFilter: $globalFilter) {"
    );
    queryArray.push("id");
    for (let j = 0; j < fields.length; j++) {
      let field = fields[j];
      if (!this.fieldIsSecured(this.user, field)) {
        queryArray.push(field.fieldName);
      }
    }
    if (!definition.readOnly) {
      queryArray.push("versionId");
    }
    queryArray.push("}");
    return queryArray.join("\n");
  }

  getEntityItemQueryString() {
    let queryArray = [];
    let definition = this.definition;
    let fields = this.getAllDisplayFields();
    queryArray.push(
      "query search" +
        definition.entityName +
        "($filters: [" +
        definition.entityName +
        "SearchFilter!]!, $limit: Int, $globalFilter: String) {"
    );
    queryArray.push(
      "search" +
        definition.entityName +
        "(filters: $filters, limit: $limit, globalFilter: $globalFilter) {"
    );
    queryArray.push("id");
    for (let j = 0; j < fields.length; j++) {
      let field = fields[j];
      if (!this.fieldIsSecured(this.user, field)) {
        queryArray.push(field.fieldName);
      }
    }
    if (!definition.readOnly) {
      queryArray.push("versionId");
    }
    queryArray.push("}");
    queryArray.push("}");
    return queryArray.join("\n");
  }

  getEntityItemFTSQueryString() {
    let queryArray = [];
    let definition = this.definition;
    let fields = this.getAllDisplayFields();
    queryArray.push(
      "query ftsSearch" +
        definition.entityName +
        "($fts: String!, $filters: [" +
        definition.entityName +
        "SearchFilter!], $limit: Int, $globalFilter: String) {"
    );
    queryArray.push(
      "ftsSearch" +
        definition.entityName +
        "(fts: $fts, filters: $filters, limit: $limit, globalFilter: $globalFilter) {"
    );
    queryArray.push("id");
    for (let j = 0; j < fields.length; j++) {
      let field = fields[j];
      if (!this.fieldIsSecured(this.user, field)) {
        queryArray.push(field.fieldName);
      }
    }
    if (!definition.readOnly) {
      queryArray.push("versionId");
    }
    queryArray.push("}");
    queryArray.push("}");
    return queryArray.join("\n");
  }

  getGraphDeleteSubscription() {
    if (!this.definition.readOnly) {
      let queryArray = [];
      queryArray.push(
        "subscription on" + this.definition.pluralName + "Deleted{"
      );
      queryArray.push(this.camelCasePluralName() + "Deleted {");
      queryArray.push("id");
      queryArray.push("}");
      queryArray.push("}");
      let subscriptionQuery = queryArray.join("\n");
      return gql(subscriptionQuery);
    } else {
      return null;
    }
  }

  getGraphDelete() {
    if (!this.definition.readOnly) {
      let queryArray = [];
      queryArray.push(
        "mutation delete" + this.definition.pluralName + "($ids: [Int!]) {"
      );
      queryArray.push("delete" + this.definition.pluralName + "(ids: $ids) {");
      queryArray.push("id");
      queryArray.push("}");
      queryArray.push("}");
      let deleteQuery = queryArray.join("\n");

      return gql(deleteQuery);
    } else {
      return null;
    }
  }

  fieldIsSecured(currentUser, userField) {
    let roles = userField.viewRoles;
    if (isNull(roles)) {
      return false;
    } else {
      return currentUser ? !hasCommonElement(roles, currentUser.roles) : true;
    }
  }

  updateSearchGraphAfterDelete(layout, entities) {
    //Loop backwards for splice
    if (entities.length > 0) {
      let ids = entities.map((a) => a.id);
      let count = 0;
      let entityCollection = "search" + this.definition.entityName;
      if (!isEmpty(layout[entityCollection])) {
        for (let i = layout[entityCollection].length - 1; i >= 0; i--) {
          if (ids.indexOf(layout[entityCollection][i].id) >= 0) {
            layout = update(layout, {
              [entityCollection]: { $splice: [[i, 1]] },
            });
            count += 1;
            if (count === entities.length) break;
          }
        }
      }
      entityCollection = "ftsSearch" + this.definition.entityName;
      if (!isEmpty(layout[entityCollection])) {
        for (let i = layout[entityCollection].length - 1; i >= 0; i--) {
          if (ids.indexOf(layout[entityCollection][i].id) >= 0) {
            layout = update(layout, {
              [entityCollection]: { $splice: [[i, 1]] },
            });
            count += 1;
            if (count === entities.length) break;
          }
        }
      }
    }
    return layout;
  }

  updateLayoutGraphAfterDelete(layout, isUI, entities) {
    let entityCollection = "getAll" + this.definition.pluralName;
    //Loop backwards for splice
    if (entities.length > 0) {
      let ids = entities.map((a) => a.id);
      let count = 0;
      if (!isNull(layout[entityCollection])) {
        for (let i = layout[entityCollection].length - 1; i >= 0; i--) {
          if (ids.indexOf(layout[entityCollection][i].id) >= 0) {
            layout = update(layout, {
              [entityCollection]: { $splice: [[i, 1]] },
            });
            count += 1;
            if (count === entities.length) break;
          }
        }
      }
    }
    if (isUI) {
      localStorage.clear();
    }
    return layout;
  }

  withDelete() {
    return graphql(this.getGraphDelete(), {
      options: {},
      props: ({ mutate }) => ({
        delete: ({ ids }) => {
          return mutate({
            variables: { ids },
            updateQueries: {
              UIQuery: (prev, { mutationResult }) => {
                return this.updateLayoutGraphAfterDelete(
                  prev,
                  true,
                  mutationResult.data["delete" + this.definition.pluralName]
                );
              },
              EntityQuery: (prev, { mutationResult }) => {
                return this.updateLayoutGraphAfterDelete(
                  prev,
                  true,
                  mutationResult.data["delete" + this.definition.pluralName]
                );
              },
              LayoutQuery: (prev, { mutationResult }) => {
                return this.updateLayoutGraphAfterDelete(
                  prev,
                  false,
                  mutationResult.data["delete" + this.definition.pluralName]
                );
              },
            },
          });
        },
      }),
    });
  }

  withIdFilter(BaseComponent) {
    let WrappedBaseComponent = this.withFilter(withEntityItem(BaseComponent));
    const WrappedWithDefinition = (props) => {
      const { entityFactory, entityId } = props;
      //console.log("WITHIDFILTER", props?.layoutGraph, WrappedBaseComponent);
      const isNew = useMemo(() => {
        return entityId === "new";
      }, [entityId]);
      const filterId = useMemo(() => {
        let filterId = undefined;
        if (!isNew && !isNull(entityId)) {
          if (entityFactory?.definition?.guidId) {
            filterId = entityId.toString();
          } else {
            filterId = parseInt(entityId);
          }
        }
        return filterId;
      }, [entityFactory, entityId, isNew]);
      const filter = useMemo(() => {
        let filterParams = undefined;
        if (filterId) {
          filterParams = [{ id: { val: [filterId] } }];
        }
        return filterParams;
      }, [filterId]);

      if (filterId) {
        return <WrappedBaseComponent {...props} filter={filter} />;
      } else {
        return <BaseComponent isNew={isNew} {...props} />;
      }
    };
    return WrappedWithDefinition;
  }

  withFilter(BaseComponent) {
    if (this.cacheable()) {
      //Yes need to wrap otherwise we don't recursively get uncached lookups referenced on this entity.
      return withQueryToLayout(BaseComponent);
    } else {
      return withCachedFilterQuery(
        withQueryToLayout(BaseComponent),
        this.getEntityItemQueryString()
      );
    }
  }

  // withFTSFilter(BaseComponent) {
  //   if (this.cacheable()) {
  //     //Yes need to wrap otherwise we don't recursively get uncached lookups referenced on this entity.
  //     return withQueryToLayout(BaseComponent);
  //   } else {
  //     let graph = graphql(gql(this.getEntityItemFTSQueryString()), {
  //       options(ownProps) {
  //         let variables = {
  //           fts: isNull(ownProps.fts) ? "" : ownProps.fts,
  //           limit: 100,
  //           ftsTimeStamp: ownProps.ftsTimeStamp,
  //         };
  //         return {
  //           fetchPolicy: "cache-and-network",
  //           fragments: {},
  //           variables: variables,
  //         };
  //       },
  //       props({ ownProps, data }) {
  //         let items =
  //           data["ftsSearch" + ownProps.entityFactory.definition.entityName];
  //         let queryResult = isNull(items) ? [] : items;
  //         return {
  //           queryResult: queryResult,
  //           subscribeToMore: data.subscribeToMore,
  //           queryLoading: data.loading,
  //         };
  //       },
  //     });
  //     return graph(withQueryToLayout(BaseComponent));
  //   }
  // }

  withSearchFilter(BaseComponent) {
    if (this.cacheable()) {
      //Yes need to wrap otherwise we don't recursively get uncached lookups referenced on this entity.
      return withQueryToLayout(BaseComponent);
    } else {
      //ownProps.filters
      let graph = graphql(gql(this.getEntityItemFTSQueryString()), {
        options(ownProps) {
          //console.log("ftsSearch" + ownProps.entityFactory.definition.entityName)
          let globalFilter = getGlobalFilter();
          let variables = {
            fts: isNull(ownProps.fts) ? "" : ownProps.fts,
            filters: ownProps.filters,
            limit: 100,
            globalFilter,
            ftsTimeStamp: ownProps.ftsTimeStamp,
          };
          return {
            fetchPolicy: "cache-and-network",
            fragments: {},
            variables: variables,
          };
        },
        props({ ownProps, data }) {
          //console.log("ftsSearch" + ownProps.entityFactory.definition.entityName)
          let items =
            data["ftsSearch" + ownProps.entityFactory.definition.entityName];
          let queryResult = isNull(items) ? [] : items;
          return {
            queryResult: queryResult,
            subscribeToMore: data.subscribeToMore,
            queryLoading: data.loading,
            filter: undefined,
            hasCriteria: undefined,
          };
        },
      });
      return graph(withQueryToLayout(BaseComponent));
    }
  }

  withFixedFilter(BaseComponent, filter) {
    if (this.cacheable()) {
      //Yes need to wrap otherwise we don't recursively get uncached lookups referenced on this entity.
      return withQueryToLayout(BaseComponent);
    } else {
      return withCachedFilterQuery(
        withQueryToLayout(BaseComponent, filter),
        this.getEntityItemQueryString(),
        filter
      );
    }
  }

  getGraphUpdateSubscription() {
    if (!this.definition.readOnly) {
      let queryArray = [];
      let definition = this.definition;
      let fields = this.getAllDisplayFields();
      queryArray.push(
        "subscription on" + this.definition.pluralName + "Changed{"
      );
      queryArray.push(this.camelCasePluralName() + "Changed {");
      queryArray.push("id");
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (!this.fieldIsSecured(this.user, field)) {
          queryArray.push(field.fieldName);
        }
      }
      if (!definition.readOnly) {
        queryArray.push("versionId");
      }
      queryArray.push("}");
      queryArray.push("}");
      let subscriptionQuery = queryArray.join("\n");
      return gql(subscriptionQuery);
    } else {
      return null;
    }
  }

  upperCaseEntityName() {
    let entityName = this.definition.entityName;
    return entityName.charAt(0).toUpperCase() + entityName.substring(1);
  }

  entityName() {
    return this.definition.entityName;
  }

  getGraphUpdate() {
    if (!this.definition.readOnly) {
      let queryArray = [];
      let definition = this.definition;
      let fields = this.getAllDisplayFields();

      queryArray.push(
        "mutation update" +
          definition.pluralName +
          "($inputs: [" +
          this.upperCaseEntityName() +
          "Input!]!) {"
      );
      queryArray.push("update" + definition.pluralName + "(inputs: $inputs) {");
      queryArray.push("id");
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        queryArray.push(field.fieldName);
      }
      queryArray.push("versionId");
      queryArray.push("}");
      queryArray.push("}");
      let updateQuery = queryArray.join("\n");

      return gql(updateQuery);
    } else {
      return null;
    }
  }

  updateFTSSearchGraphAfterInsert(prev, insertResult) {
    let queryName = "ftsSearch" + this.definition.entityName;
    if (
      prev &&
      !isNull(prev[queryName]) &&
      insertResult &&
      insertResult.data &&
      !isEmpty(insertResult.data["update" + this.definition.pluralName])
    ) {
      let entities = prev[queryName];
      let insertedRows =
        insertResult.data["update" + this.definition.pluralName];
      for (let i = 0; i < insertedRows.length; i++) {
        let insertedRow = insertedRows[i];
        let id = insertedRow.id;
        if (!isNull(id)) {
          let exists = false;
          for (let j = 0; j < entities.length; j++) {
            let existingRow = entities[j];
            if (existingRow.id === id) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            prev = update(prev, { [queryName]: { $push: [insertedRow] } });
          }
        }
      }
    }
    return prev;
  }
  updateFTSSearchGraphAfterUpdate(layout, entities) {
    //Only update rows that are in the search currently.  Don't append.
    //Results are ranked and limited.
    let entityCollection = "ftsSearch" + this.definition.entityName;
    for (let i = 0; i < entities.length; i++) {
      let entity = entities[i];
      if (entity) {
        for (let j = 0; j < layout[entityCollection].length; j++) {
          if (layout[entityCollection][j].id === entity.id) {
            layout = update(layout, {
              [entityCollection]: { [j]: { $set: entity } },
            });
            break;
          }
        }
      }
    }
    return layout;
  }

  updateSearchGraphAfterUpdate(layout, entities, filterArray) {
    //console.log("filter (should be an array)", filterArray)
    let entityCollection = "search" + this.definition.entityName;
    for (let i = 0; i < entities.length; i++) {
      let entity = entities[i];
      if (entity) {
        let match = false;
        if (!isEmpty(filterArray)) {
          for (let i = 0; i < filterArray.length; i++) {
            let filter = filterArray[i];
            let arrayKey = null;
            for (let key in filter) {
              if (Object.prototype.hasOwnProperty.call(filter, key)) {
                if (
                  Array.isArray(filter[key].val) &&
                  filter[key].val.length > 1
                ) {
                  arrayKey = key;
                  break;
                }
              }
            }
            if (!isNull(arrayKey)) {
              match = filter[arrayKey].val.indexOf(entity[arrayKey]) >= 0;
            } else {
              match = true;
              for (let key in filter) {
                if (Object.prototype.hasOwnProperty.call(filter, key)) {
                  let value = filter[key].val;
                  if (Array.isArray(value)) {
                    if (value.length === 1) {
                      value = value[0];
                    } else {
                      value = null;
                    }
                  }
                  if (isNull(value) || entity[key] !== value) {
                    match = false;
                    break;
                  }
                }
              }
            }
            if (match) {
              //Found a filter match (they are or'd together) so break.
              break;
            }
          }
        } else {
          //No filter ... effectively match everything.
          match = true;
        }

        let found = false;
        if (layout[entityCollection]) {
          for (let j = 0; j < layout[entityCollection].length; j++) {
            if (layout[entityCollection][j].id === entity.id) {
              found = true;
              if (match) {
                layout = update(layout, {
                  [entityCollection]: { [j]: { $set: entity } },
                });
              } else {
                //No longer in our filter.
                layout = update(layout, {
                  [entityCollection]: { $splice: [[j, 1]] },
                });
              }
              break;
            }
          }
          if (match) {
            if (!found) {
              layout = update(layout, {
                [entityCollection]: { $push: [entity] },
              });
            }
          }
        }
      }
    }
    return layout;
  }

  isUICache() {
    return isEmpty(this.definition.uicache)
      ? false
      : this.definition.uicache === true;
  }

  entityIsValidForLayout() {
    return (
      this.cacheable() &&
      !this.isUICache() &&
      this.definition.entityName !== "Entity"
    );
  }

  entityIsValidForUIGraph() {
    return (
      this.cacheable() &&
      this.isUICache() &&
      this.definition.entityName !== "Entity"
    );
  }

  updateLayoutGraphAfterUpdate(allEntityFactories, isUI, layout, entities) {
    let entityCollection = "getAll" + this.definition.pluralName;
    let isEntityEntity = this.lowerCaseEntityName() === "entity";
    for (let i = 0; i < entities.length; i++) {
      let entity = entities[i];
      if (entity) {
        let found = false;
        let layoutEntityCollection = layout[entityCollection];
        for (let j = 0; j < layoutEntityCollection.length; j++) {
          let layoutEntityCollectionRow = layoutEntityCollection[j];
          if (layoutEntityCollectionRow.id === entity.id) {
            found = true;
            if (layoutEntityCollectionRow.versionId < entity.versionId) {
              layout = update(layout, {
                [entityCollection]: { [j]: { $set: entity } },
              });
            }
            break;
          }
        }
        if (!found) {
          layout = update(layout, { [entityCollection]: { $push: [entity] } });
        }
      }

      //If this is updating an entity definition we need to propogate changes to factory.
      if (isEntityEntity) {
        let origDefinition = JSON.parse(entity.definition);
        let references = [];
        let definition = definitionHelper.fixDefinition(
          cloneDeep(origDefinition),
          references
        );
        for (let j = 0; j < allEntityFactories.length; j++) {
          let entityFactory = allEntityFactories[j];
          if (entityFactory.id === entity.id) {
            entityFactory.setDefinition(definition, origDefinition);
            break;
          }
        }
      }
    }
    if (isUI) {
      localStorage.clear();
    }
    return layout;
  }

  getPlainForm() {
    return this.withFactory(WrappedFormBase);
  }

  getForm(definition, isReadOnly) {
    if (isReadOnly || this.definition?.readOnly) {
      if (definition) {
        return this.withFactoryAndDefinition(WrappedFormBase, definition);
      } else {
        return this.withFactory(WrappedFormBase);
      }
    } else {
      let withUpdate = this.withUpdate();
      if (definition) {
        return withUpdate(
          this.withFactoryAndDefinition(WrappedFormBase, definition)
        );
      } else {
        return withUpdate(this.withFactory(WrappedFormBase));
      }
    }
  }

  getFormWithEntity(definition) {
    return this.withIdFilter(this.getForm(definition));
  }

  getFormWithEntityAsComponent() {
    return this.withIdFilter(EntityFormComponent);
  }

  getSearchFormWithEntityAsComponent() {
    return withCache(
      this.withFactoryAndDefinition(
        withLocation(this.withSearchDefinition(EntityFormComponent))
      )
    );
  }

  addValidationFields(schema, fields) {
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let title = isEmpty(field.title)
        ? field.type === "boolean" && field.widget === "buttontoggle"
          ? "This"
          : "'" + field.fieldName + "'"
        : "'" + field.title + "'";
      if (field.type === "object") {
        this.addValidationFields(schema, field.definition.fields);
      } else if (!field.hidden) {
        let isRequired = field.required;
        let yupItem = undefined;
        switch (field.type) {
          case "string":
            yupItem = yup.string();
            switch (field.format) {
              case "email":
                yupItem = yupItem.email(title + " must be an email address");
                break;
              case "uri":
                //Don't check link and linkbutton, inapp links.
                if (isEmpty(field.widget)) {
                  yupItem = yupItem.url(title + " must be a URL address");
                }
                break;
              case "date":
                //Yup/react-hook-forms ... replaces the value.  Let controls get it right
                //yupItem = yup.date(title + " must be a date");
                break;
              case "date-time":
                //Yup/react-hook-forms ... replaces the value.  Let controls get it right
                //yupItem = yup.date(title + " must be a date");
                break;
              case "guid":
                yupItem = yupItem.uuid(title + " must be a UUID");
                break;
              case "uppercase":
                yupItem = yupItem.uppercase(title + " must be uppercase");
                break;
              default:
            }
            break;
          case "boolean":
            yupItem = yup.bool();
            if (!isNull(field.forceChoice)) {
              isRequired = false;
              let forceChoiceMessage = title;
              if (field.forceChoice) {
                forceChoiceMessage += " must be selected";
              } else {
                forceChoiceMessage += " must not be selected";
              }
              yupItem = yupItem
                .oneOf([field.forceChoice], forceChoiceMessage)
                .required(title + " is required");
            }
            break;
          case "integer":
            yupItem = yup.number().integer();
            if (!isNull(field.minimum)) {
              if (field.widget === "minutes") {
                yupItem = yupItem.min(
                  field.minimum,
                  title +
                    " must be greater than or equal to " +
                    formatMinutes(field.minimum)
                );
              } else {
                yupItem = yupItem.min(
                  field.minimum,
                  title +
                    " must be greater than or equal to " +
                    field.minimum.toString()
                );
              }
            }
            if (!isNull(field.maximum)) {
              if (field.widget === "minutes") {
                yupItem = yupItem.max(
                  field.maximum,
                  title +
                    " must be less than or equal to " +
                    formatMinutes(field.maximum)
                );
              } else {
                yupItem = yupItem.max(
                  field.maximum,
                  title +
                    " must be less than or equal to " +
                    field.maximum.toString()
                );
              }
            }
            break;
          case "array":
            if (field.inPlace) {
              let childSchema = {};
              this.addValidationFields(childSchema, field.definition.fields);
              yupItem = yup.array().of(yup.object().shape(childSchema));
              if (!isNull(field.maximum)) {
                yupItem = yupItem.max(
                  field.maximum,
                  title +
                    " must be less than or equal to " +
                    field.maximum.toString()
                );
              }
              if (!isNull(field.minimum)) {
                yupItem = yupItem.min(
                  field.minimum,
                  title +
                    " must be greater than or equal to " +
                    field.minimum.toString()
                );
              }
            } else {
              yupItem = yup.array().of(yup.number().integer().min(1));
            }
            break;
          case "number":
            yupItem = yup.number();
            break;
          case "json":
            yupItem = yup.string();
            if (
              field.widget &&
              isRequired &&
              [
                "JSONSingleSelectWidget",
                "JSONGridWidget",
                "JSONGridSingleSelectWidget",
              ].indexOf(field.widget) >= 0
            ) {
              yupItem = yupItem.JSONRequired({
                selectFieldName: definitionHelper.findSelectFieldName(field),
                errorMessage: title + " is required",
              });
            }
            if (
              field.widget !== "AddressFinder" &&
              field.widget !== "ExcelImportWidget"
            )
              isRequired = false;
            break;
          default:
            //calendar, chart, file, json, svg, geog, button
            if (
              field.type !== "button" &&
              field.type !== "id" &&
              field.type !== "login"
            ) {
              yupItem = yup.string();
            }
        }

        if (yupItem) {
          //console.log("adding ", field.fieldName, " to validation schema")
          if (field.type !== "array" || !field.inPlace) {
            yupItem = yupItem.nullable(true);
          }
          if (isRequired) {
            yupItem = yupItem.required(title + " is required");
            if (field.type === "array") {
              yupItem = yupItem.min(1, title + " must have at least one item");
            }
          }

          schema[field.fieldName] = yupItem;
        }
      }
    }
  }

  getValidationSchema(definition = undefined) {
    let schema = {};
    this.addValidationFields(
      schema,
      isNull(definition) ? this.definition.fields : definition.fields
    );
    return yup.object(schema);
  }

  getCard(definition) {
    return this.withFactoryAndDefinition(CardTemplate, definition);
  }

  getShortTitle() {
    let title = isNull(this.definition.short) ? null : this.definition.short;
    if (isNull(title)) {
      title = isNull(this.definition.title) ? null : this.definition.title;
      if (isNull(title)) {
        title = isNull(this.definition.pluralName)
          ? null
          : this.definition.pluralName;
        if (isNull(title)) {
          title = isNull(this.definition.entityName)
            ? ""
            : this.definition.entityName;
        }
      }
    }
    return title;
  }

  getBulkForm(update, definition) {
    if (!definition) {
      definition = cloneDeep(this.definition);
      definition.mode = "import";
      definition.leftActions.push("template");
    }
    return this.withFactoryAndDefinitionAndUpdate(
      WrappedFormBase,
      update,
      definition
    );
  }

  getGrid(definition) {
    if (this.definition.readOnly) {
      if (definition) {
        return withCache(this.withFactoryAndDefinition(GridBase, definition));
      } else {
        return withCache(this.withFactory(GridBase));
      }
    } else {
      let withDelete = this.withDelete();
      if (definition) {
        return withCache(
          withRouter(
            withDelete(this.withFactoryAndDefinition(GridBase, definition))
          )
        );
      } else {
        return withCache(withRouter(withDelete(this.withFactory(GridBase))));
      }
    }
  }

  fieldExists(fieldName) {
    return !isNull(
      definitionHelper.findField(this.definition.fields, fieldName)
    );
  }

  getFilterGrid(currentUser, definition) {
    //Check rule for currentUser
    let securityFieldName = null;
    let securityId = -1;
    definition = definition
      ? cloneDeep(definition)
      : cloneDeep(this.definition);
    let field = definitionHelper.findField(
      definition.fields,
      securityFieldName
    );
    if (field.type === "array") {
      field.default = [securityId];
    } else {
      field.lookupFilter = { id: { val: securityId } };
      field.default = securityId;
    }
    if (isNull(definition.filters)) {
      definition.filters = [];
    }
    definition.filters.push({
      fieldName: securityFieldName,
      operator: "eq",
      values: [securityId],
    });
    let FilteredEntityForm = this.getForm(definition);

    let filter = {};
    filter[securityFieldName] = { val: [securityId] };
    let FilteredEntityGrid = this.getGridWithCollection(this.definition);

    return { filter, FilteredEntityGrid, FilteredEntityForm };
  }

  getGridWithCollection(definition) {
    return this.withFactoryAndDefinition(
      this.withFilter(withCollection(this.getGrid(definition)))
    );
  }

  getGridWithFTSCollection(definition) {
    return withCache(
      this.withFactoryAndDefinition(
        withLocation(this.withSearchDefinition(withAdvancedSearch(definition)))
      )
    );
  }

  withSearchDefinition(BaseComponent) {
    let currentUser = this.user;
    let initialSearchDefinition = this.getSubDefinition(
      SUBDEFINITIONS.SEARCH.NAME
    );

    const WrappedWithSearchDefinition = (props) => {
      //const { entityFactory, entityId } = props;
      const getStateFromProps = useCallback((searchDefinition) => {
        let hasCriteria = false;
        let filter = {};
        if (!isNull(searchDefinition)) {
          let fields = definitionHelper.findAllTopLevelDBFields(
            searchDefinition.fields
          );
          let hasGeography = props.hasGeography;
          searchDefinition.sections = [{ id: "main" }];
          searchDefinition.title = "";
          searchDefinition.displayMode = "None";
          let newfields = [];
          let ftsField = {};
          ftsField.fieldName = FTS_FIELD;
          ftsField.type = "string";
          ftsField.title = "Keywords";
          newfields.push(ftsField);
          for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            field.tab = "main";
            let fieldIsHidden = true;
            if (field.type !== "file" && field.type !== "json") {
              fieldIsHidden = fieldHidden(currentUser, field);
            }
            if (!fieldIsHidden) {
              if (field.type === "integer") {
                if (!isEmpty(field.lookup)) {
                  let lookupFactory = getLookupFactory(props, field);
                  if (isNull(lookupFactory)) {
                    delete field.lookup;
                    fieldIsHidden = true;
                  } else if (lookupFactory.cacheable()) {
                    field.type = "array";
                    if (!isEmpty(field.default)) {
                      try {
                        let parsedJSON = JSON.parse(field.default);
                        field.default = undefined;
                        if (!isEmpty(parsedJSON.val)) {
                          filter[field.fieldName] = parsedJSON;
                          field.default = parsedJSON.val;
                          hasCriteria = true;
                        }
                      } catch {
                        field.default = undefined;
                      }
                    }
                  } else {
                    //Use async widget with no default.
                    if (!isEmpty(field.default)) {
                      try {
                        let parsedJSON = JSON.parse(field.default);
                        field.default = undefined;
                        if (!isEmpty(parsedJSON.val)) {
                          filter[field.fieldName] = parsedJSON;
                          field.default = Array.isArray(parsedJSON.val)
                            ? parsedJSON.val[0]
                            : parsedJSON.val;
                          hasCriteria = true;
                        }
                      } catch {
                        field.default = undefined;
                      }
                    }
                  }
                } else {
                  //if (field.widget === "minutes") field.format = "minutes";
                  if (field.widget === "currency") {
                    field.format = "currency";
                  } else {
                    delete field.format;
                  }
                  field.widget = "searchint";
                  field.type = "json";
                  if (!isEmpty(field.default)) {
                    try {
                      let parsedJSON = JSON.parse(field.default);
                      if (!isEmpty(parsedJSON.val)) {
                        filter[field.fieldName] = parsedJSON;
                        hasCriteria = true;
                      }
                    } catch {
                      field.default = undefined;
                    }
                  }
                }
              } else if (field.type === "number") {
                if (field.widget === "currency") {
                  field.format = "currency";
                } else {
                  delete field.format;
                }
                field.widget = "searchfloat";
                field.type = "json";
                if (!isEmpty(field.default)) {
                  try {
                    let parsedJSON = JSON.parse(field.default);
                    if (!isEmpty(parsedJSON.val)) {
                      filter[field.fieldName] = parsedJSON;
                      hasCriteria = true;
                    }
                  } catch {
                    field.default = undefined;
                  }
                }
              } else if (field.type === "geog") {
                field.widget = "searchgeog";
                field.type = "json";
                //console.log("GEOLOCATION!!", props.geoLocation)
                if (hasGeography && !isNull(props.geoLocation)) {
                  if (!isEmpty(field.default)) {
                    try {
                      let parsedJSON = JSON.parse(field.default);
                      if (!isEmpty(parsedJSON.val)) {
                        //Replace with current location.
                        parsedJSON.val[0] = JSON.stringify(props.geoLocation);
                        filter[field.fieldName] = parsedJSON;
                        hasCriteria = true;
                      }
                    } catch {
                      field.default = undefined;
                    }
                  }
                } else {
                  field.hidden = true;
                }
              } else if (field.type === "string") {
                if (
                  field.format === "date-time" ||
                  field.format === "date" ||
                  field.format === "date-time-range"
                ) {
                  field.widget = "searchdate";
                  field.type = "json";
                  delete field.format;
                  if (field.format === "date-time-range") {
                    field.hidden = true;
                  } else {
                    if (!isEmpty(field.default)) {
                      try {
                        let parsedJSON = JSON.parse(field.default);
                        if (!isEmpty(parsedJSON.val)) {
                          filter[field.fieldName] = parsedJSON;
                          hasCriteria = true;
                        }
                      } catch {
                        field.default = undefined;
                      }
                    }
                  }
                } else {
                  field.widget = "searchtext";
                  field.type = "json";
                  delete field.format;
                  if (!isEmpty(field.default)) {
                    try {
                      let parsedJSON = JSON.parse(field.default);
                      if (!isEmpty(parsedJSON.val)) {
                        filter[field.fieldName] = parsedJSON;
                        hasCriteria = true;
                      }
                    } catch {
                      field.default = undefined;
                    }
                  }
                }
              } else if (field.type === "boolean") {
                if (!isEmpty(field.default)) {
                  try {
                    let parsedJSON = JSON.parse(field.default);
                    field.default = undefined;
                    if (!isEmpty(parsedJSON.val)) {
                      filter[field.fieldName] = parsedJSON;
                      field.default = parsedJSON.val;
                      hasCriteria = true;
                    }
                  } catch {
                    field.default = undefined;
                  }
                }
              } else {
                //Unsupported
                fieldIsHidden = true;
              }
            }
            if (!fieldIsHidden) {
              newfields.push(field);
            }
          }
          searchDefinition.fields = newfields;
          return {
            WrappedBaseComponent: BaseComponent,
            searchDefinition: searchDefinition,
            hasCriteria: hasCriteria,
            filter: filter,
          };
        } else {
          return {
            WrappedBaseComponent: BaseComponent,
            searchDefinition: searchDefinition,
            hasCriteria: hasCriteria,
            filter: filter,
          };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const [{ WrappedBaseComponent, searchDefinition, hasCriteria, filter }] =
        useState(() => {
          return getStateFromProps(cloneDeep(initialSearchDefinition));
        });
      return (
        <WrappedBaseComponent
          {...props}
          searchDefinition={searchDefinition}
          hasCriteria={hasCriteria}
          filter={filter}
        />
      );
    };
    return WrappedWithSearchDefinition;
  }

  // withFTSGrid(definition) {
  //   return this.withFTSFilter(withCollection(this.getGrid(definition)));
  // }

  withSearchGrid(definition) {
    return this.withSearchFilter(withCollection(this.getGrid(definition)));
  }

  getBulkGrid(deleteFn, isWidget = false, definition = null) {
    if (!definition) {
      definition = cloneDeep(this.definition);
      definition.leftActions = ["delete", "export"];
      definition.showDetail = false;
    }
    if (!definition.readOnly && !isWidget) {
      let withUpdate = this.withUpdate();
      return withCache(
        withUpdate(
          this.withFactoryAndDefinitionAndDelete(GridBase, deleteFn, definition)
        )
      );
    } else {
      return withCache(
        this.withFactoryAndDefinitionAndDelete(GridBase, deleteFn, definition)
      );
    }
  }

  getErrorGrid() {
    let definition = cloneDeep(this.definition);
    definition.leftActions = ["export"];
    definition.showDetail = false;
    definition.viewRoles = [2];
    // All fields in the error definition will be stored as strings.
    // This lets us retain data that couldn't be converted and errored.
    // So when we export the data is either identical to what the user imported
    // or (in the case of a successful date import) exported in possibly different but
    // equally correct form.
    let fields = definitionHelper.findAllDisplayFields(definition.fields);
    for (let j = 0; j < fields.length; j++) {
      let field = fields[j];
      field.type = "string";
      field.lookupFields = [];
      delete field.lookup;
    }
    let entityFactory = new EntityFactory(null, -1, definition, definition, []);
    let errorEntityFactory = new EntityFactory(
      null,
      -1,
      errorDefinition,
      errorDefinition,
      []
    );

    const WrappedErrorGrid = (props) => {
      return (
        <ErrorGrid
          {...props}
          errorEntityFactory={errorEntityFactory}
          entityFactory={entityFactory}
          definition={definition}
        />
      );
    };
    return WrappedErrorGrid;
  }

  checkRoles(definitionRoles, roles) {
    definitionRoles = definitionRoles ? definitionRoles : [];
    for (let i = 0; i < definitionRoles.length; i++) {
      let definitionRole = definitionRoles[i];
      for (let j = 0; j < roles.length; j++) {
        if (roles[j] === definitionRole) {
          return true;
        }
      }
    }
    return false;
  }

  getMatchingRules(user, rules) {
    //"viewGroupRules": [{"forUserRole": "coreuser", "checkEntityIdFrom": "coreuser", "againstField": "id"}],
    rules = rules ? rules : [];
    let matchedRules = [];
    for (let i = 0; i < rules.length; i++) {
      let rule = rules[i];
      let entityName = user.userEntityName.toLowerCase();
      if (entityName === rule.forUserRole.toLowerCase()) {
        let id = null;
        if (entityName === rule.checkEntityIdFrom.toLowerCase()) {
          id = user.id;
        } else if (
          !isNull(user.parentEntityName) &&
          user.parentEntityName.toLowerCase() ===
            rule.checkEntityIdFrom.toLowerCase()
        ) {
          id = user.parentId;
        }
        if (!isNull(id)) {
          matchedRules.push(rule);
        }
      }
    }
    return matchedRules;
  }

  getRuleFilters(user, rules) {
    //"viewGroupRules": [{"forUserRole": "coreuser", "checkEntityIdFrom": "coreuser", "againstField": "id"}],

    rules = rules ? rules : [];
    let filters = [];
    for (let i = 0; i < rules.length; i++) {
      let rule = rules[i];
      let entityName = user.userEntityName.toLowerCase();
      if (entityName === rule.forUserRole.toLowerCase()) {
        let id = null;
        if ("%%true" === rule.checkEntityIdFrom.toLowerCase()) {
          id = true;
        } else if (entityName === rule.checkEntityIdFrom.toLowerCase()) {
          id = user.id;
        } else if (
          !isNull(user.parentEntityName) &&
          user.parentEntityName.toLowerCase() ===
            rule.checkEntityIdFrom.toLowerCase()
        ) {
          id = user.parentId;
        }
        if (!isNull(id)) {
          let filter = {};
          filter[rule.againstField] = id;
          filters.push(filter);
        }
      }
    }
    return filters;
  }

  groupRightsDirectUrl(user, ignoreAllRights = false) {
    let url = "";
    if (!isNull(user) && (!this.hasViewAllRights(user) || ignoreAllRights)) {
      let rules = this.getMatchingRules(user, this.definition.viewGroupRules);
      if (!isEmpty(rules)) {
        if (rules.length === 1) {
          let rule = rules[0];
          if (rule.againstField === "id" && isEmpty(rule.proxyTable)) {
            let entityName = user.userEntityName.toLowerCase();
            let ruleEntity = rule.checkEntityIdFrom.toLowerCase();
            let parentEntity = isNull(user.parentEntityName)
              ? null
              : user.parentEntityName.toLowerCase();
            let id = null;
            if (entityName === ruleEntity) {
              id = user.id;
            } else if (parentEntity === ruleEntity) {
              id = user.parentId;
            }
            if (!isNull(id)) {
              url += "/" + id.toString();
            }
          }
        }
      }
    }
    return url;
  }

  hasEditGroupRights(user, rules, ids, rows) {
    //NOTE: This is only used currently for EDIT rights.
    //      As a result this does not suport "_vr.coreuser" style againstField syntax because that is only
    //      supported in viewGroupRules.
    let hasRights = false;
    let filters = this.getRuleFilters(user, rules);
    if (!isEmpty(filters)) {
      hasRights = true;
      for (let i = 0; i < ids.length; i++) {
        let loopId = ids[i];
        let row = null;
        for (let j = 0; j < rows.length; j++) {
          let currentRow = rows[j];
          if (currentRow.id === loopId) {
            row = currentRow;
            break;
          }
        }
        if (isNull(row)) {
          hasRights = false;
          break;
        } else {
          let hasFilterRights = false;
          for (let j = 0; j < filters.length; j++) {
            let filter = filters[j];
            let filterFieldName = null;
            let filterCheckId = null;
            for (let key in filter) {
              if (Object.prototype.hasOwnProperty.call(filter, key)) {
                filterFieldName = key;
                filterCheckId = filter[key];
                break;
              }
            }
            if (!isNull(filterFieldName) && !isNull(filterCheckId)) {
              if (row[filterFieldName] === filterCheckId) {
                hasFilterRights = true;
                break;
              }
            }
          }
          if (!hasFilterRights) {
            hasRights = false;
            break;
          }
        }
      }
    }
    return hasRights;
  }

  hasViewAllRights(currentUser) {
    let definitionRoles = this.definition.viewRoles;
    return currentUser
      ? this.checkRoles(definitionRoles, currentUser.roles)
      : false;
  }

  hasCacheRole(currentUser) {
    let definitionRoles = this.definition.cacheRoles;
    return currentUser
      ? this.checkRoles(definitionRoles, currentUser.roles)
      : false;
  }

  hasSomeViewRights(currentUser) {
    return (
      this.hasViewAllRights(currentUser) ||
      !isEmpty(this.getRuleFilters(currentUser, this.definition.viewGroupRules))
    );
  }

  hasSomeEditRights(currentUser) {
    return (
      this.hasEditAllRights(currentUser) ||
      !isEmpty(this.getRuleFilters(currentUser, this.definition.editGroupRules))
    );
  }

  hasEditAllRights(currentUser) {
    let definitionRoles = this.definition.editRoles;
    return currentUser
      ? this.checkRoles(definitionRoles, currentUser.roles)
      : false;
  }

  hasEditRights(currentUser, ids, rows) {
    if (!Array.isArray(rows)) {
      if (isNull(rows)) {
        rows = [];
      } else {
        rows = [rows];
      }
    }
    if (!Array.isArray(ids)) {
      if (isNull(ids)) {
        ids = [];
      } else {
        ids = [ids];
      }
    }
    return (
      this.hasEditAllRights(currentUser) ||
      this.hasEditGroupRights(
        currentUser,
        this.definition.editGroupRules,
        ids,
        rows
      )
    );
  }

  hasAddAllRights(currentUser) {
    let definitionRoles = this.definition.addRoles;
    return currentUser
      ? this.checkRoles(definitionRoles, currentUser.roles)
      : false;
  }

  hasDeleteAllRights(currentUser) {
    let definitionRoles = this.definition.deleteRoles;
    return currentUser
      ? this.checkRoles(definitionRoles, currentUser.roles)
      : false;
  }

  hasSomeAddRights(currentUser) {
    return (
      this.hasAddAllRights(currentUser) ||
      !isEmpty(this.getRuleFilters(currentUser, this.definition.addGroupRules))
    );
  }

  hasDeleteRights(currentUser, ids, rows) {
    if (!Array.isArray(rows)) {
      if (isNull(rows)) {
        rows = [];
      } else {
        rows = [rows];
      }
    }
    if (!Array.isArray(ids)) {
      if (isNull(ids)) {
        ids = [];
      } else {
        ids = [ids];
      }
    }
    let definitionRoles = this.definition.deleteRoles;
    let canDelete = currentUser
      ? this.checkRoles(definitionRoles, currentUser.roles)
      : false;
    if (!canDelete) {
      //Simple Check ... only for ID of entity user
      let rules = this.getMatchingRules(
        currentUser,
        this.definition.deleteGroupRules
      );
      if (rules.length > 0) {
        for (let i = 0; i < rules.length; i++) {
          let rule = rules[i];
          if (
            isEmpty(rule.proxyTable) &&
            (rule.checkEntityIdFrom === currentUser.userEntityName ||
              rule.checkEntityIdFrom === currentUser.parentEntityName) &&
            rule.forUserRole === currentUser.userEntityName
          ) {
            let isParent =
              rule.checkEntityIdFrom === currentUser.parentEntityName;
            canDelete = true;
            for (let j = 0; j < rows.length; j++) {
              let row = rows[j];
              if (!row) {
                canDelete = false;
                break;
              }
              let match = isParent
                ? row[rule.againstField] === currentUser.parentId
                : row[rule.againstField] === currentUser.id;
              if (!match) {
                canDelete = false;
                break;
              }
            }
          }
          if (canDelete) break;
        }
      }
    }
    return canDelete;
  }

  hasSomeDeleteRights(currentUser) {
    return (
      this.hasDeleteAllRights(currentUser) ||
      !isEmpty(
        this.getRuleFilters(currentUser, this.definition.deleteGroupRules)
      )
    );
  }

  getFilePage() {
    return this.withFactory(withFileQuery(FilePage));
  }

  //getPage(pageName) {
  //  let page = null;
  //  if (pageName === "grid") {
  //    page = this.withFactory(GridPage);
  //  } else {
  //    page = this.withFactory(GridPage);
  //  }
  //  return page;
  //}

  //getPageWithEntity(pageName) {
  //  return this.withFactoryAndDefinition(this.withIdFilter(this.getPage(pageName)));
  //}

  getComponentWithEntity(component) {
    return this.withFactoryAndDefinition(this.withIdFilter(component));
  }

  //getHomePage(override = null) {
  //  let homePage = isEmpty(override) ? (this.definition ? this.definition.homePage : "User View") : override;
  //  if (homePage !== "User View" && homePage !== "User View With Side Navigation" && homePage !== "User View With Tasks" && homePage !== "User View With Navigation") homePage = "User View";
  //  return this.withFactoryAndDefinition(this.withIdFilter(this.getPage(homePage)));
  //}

  withUpdate() {
    return graphql(this.getGraphUpdate(), {
      options: {},
      props: ({ mutate }) => ({
        update: (inputs) => {
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            for (let key in input) {
              if (Object.prototype.hasOwnProperty.call(input, key)) {
                if (input[key] === undefined) {
                  input[key] = null;
                }
              }
            }
          }

          let mutationParams = {
            variables: { inputs },
            updateQueries: {
              LayoutQuery: (prev) => {
                //Not doing optimistic updates currently and the update will come on the subscription anyway and be processed there.
                //updateFTSSearchGraphAfterUpdate(layout, entities)
                return prev; //this.updateLayoutGraphAfterUpdate(prev, mutationResult.data["update" + this.definition.pluralName]);
              },
            },
          };

          if (!this.cacheable()) {
            let ftsUpdateQuery = "ftsSearch" + this.definition.entityName;
            mutationParams.updateQueries[ftsUpdateQuery] = (
              prev,
              { mutationResult }
            ) => {
              if (inputs && inputs.length === 1) {
                let input = inputs[0];
                let ids = input.ids;
                if (isNull(ids)) {
                  prev = this.updateFTSSearchGraphAfterInsert(
                    prev,
                    mutationResult
                  );
                }
              }
              return prev; //this.updateLayoutGraphAfterUpdate(prev, mutationResult.data["update" + this.definition.pluralName]);
            };
          }

          return mutate(mutationParams);
        },
      }),
    });
  }

  withFactory(BaseComponent) {
    let entityFactory = this;
    const WrappedWithDefinition = (props) => {
      return <BaseComponent {...props} entityFactory={entityFactory} />;
    };
    return WrappedWithDefinition;
  }

  withFactoryAndDefinition(BaseComponent, definition) {
    let entityFactory = this;
    const WrappedWithDefinition = (props) => {
      //console.log("withFactoryAndDefinition", props?.layoutGraph);
      return (
        <BaseComponent
          {...props}
          entityFactory={entityFactory}
          definition={definition}
        />
      );
    };
    return WrappedWithDefinition;
  }

  withFactoryAndDefinitionAndUpdate(BaseComponent, update, definition) {
    let entityFactory = this;
    const WrappedWithDefinition = (props) => {
      return (
        <BaseComponent
          {...props}
          entityFactory={entityFactory}
          definition={definition}
          update={update}
        />
      );
    };
    return WrappedWithDefinition;
  }

  withFactoryAndDefinitionAndDelete(BaseComponent, deleteFn, definition) {
    let entityFactory = this;
    const WrappedWithDefinition = (props) => {
      return (
        <BaseComponent
          {...props}
          entityFactory={entityFactory}
          definition={definition}
          delete={deleteFn}
        />
      );
    };
    return WrappedWithDefinition;
  }

  getRowById(layoutGraph, id) {
    let primaryCollection = this.getPrimaryCollection(layoutGraph);
    if (!isEmpty(primaryCollection)) {
      for (let i = 0; i < primaryCollection.length; i++) {
        let row = primaryCollection[i];
        if (!isNull(row) && row.id === id) {
          return row;
        }
      }
    }
    return null;
  }

  getRowByName(layoutGraph, name) {
    if (!isEmpty(name)) {
      let lowerCaseName = name.toLowerCase();
      let primaryCollection = this.getPrimaryCollection(layoutGraph);
      if (!isEmpty(primaryCollection)) {
        for (let i = 0; i < primaryCollection.length; i++) {
          let row = primaryCollection[i];
          if (
            !isNull(row) &&
            !isNull(row.name) &&
            row.name.toLowerCase() === lowerCaseName
          ) {
            return row;
          }
        }
      }
    }
    return null;
  }

  getPrimaryCollection(layoutGraph) {
    //console.log("LAYOUTGRAPH getPrimaryCollection", this.primaryCollection, this.definition, layoutGraph["all" + this.definition.pluralName])
    if (isNull(this.primaryCollection)) {
      let layoutPath = "all" + this.definition.pluralName;
      return layoutGraph[layoutPath];
    } else {
      return this.primaryCollection;
    }
  }
}

export default EntityFactory;
