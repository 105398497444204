import React from "react";
import { FontAwesome } from "@expo/vector-icons";
import { NavItem, NavIcon } from "@trendmicro/react-sidenav";
import { View } from "react-native";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "../../ReactRouter";
import getEnvironment from "../../environment/env";
import { getFCMToken } from "../../messagingDummy";

const NavTypography = withStyles(
  (theme) => {
    return {
      root: {
        color:
          theme.palette.secondary.contrastText === "#000000"
            ? theme.palette.primary.contrastText
            : theme.palette.secondary.contrastText,
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        height: "100%",
      },
    };
  },
  { withTheme: true }
)(Typography);

const LogoutButton = ({
  history,
  changeLoginState,
  useIcon,
  extraStyles,
  expanded,
  containerStyle,
  variant,
  color,
}) => {
  const handleLogout = async () => {
    let pushToken = await getFCMToken();
    let params = {
      pushToken,
    };

    try {
      await fetch(getEnvironment().API_URL + "/log_out", {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      history.push("/home");
      return changeLoginState(false);
    } catch (ex) {
      console.log("LOGOUT ERROR!");
    }
  };

  if (useIcon) {
    return (
      <NavItem>
        <NavIcon>
          <FontAwesome
            name="sign-out"
            onPress={handleLogout}
            style={{ color: extraStyles.foregroundColor, fontSize: 18 }}
          />
        </NavIcon>
        {expanded ? (
          <View
            onClick={handleLogout}
            style={{
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <NavTypography variant="body1">{"Logout"}</NavTypography>
          </View>
        ) : undefined}
      </NavItem>
    );
  } else {
    let style = containerStyle ? containerStyle : { justifyContent: "center" };
    return (
      <Button
        size={"small"}
        variant={variant ? variant : variant === null ? undefined : "contained"}
        color={color ? color : "secondary"}
        onClick={handleLogout}
        style={style}
      >
        {"Logout"}
      </Button>
    );
  }
};

export default withRouter(LogoutButton);
