/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import ReactExport from "react-data-export";
import definitionHelper from "../util/definitions.js";
import {
  isNull,
  isEmpty,
  renderFieldValue,
  fieldAccessor,
  getLookupDefinition,
  hasCommonElement,
} from "../util/common.js";
import * as cloneDeep from "lodash/cloneDeep";
import Loading from "./loading";
import { withApollo } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import { SCREEN } from "../util/constants";
import Toast from "./Toast";

const EMPTY_SET = { exportDataSet: null, exporting: false };
const ExportExcelDataSet = (props) => {
  const { element, screenSize, collection, allowEmpty, pluralName } = props;
  const [{ exporting, exportDataSet }, setState] = useState(EMPTY_SET);
  const retrieveFile = (file) => {
    let { fileId, entityId, entityName } = file;
    let variables = { fileId: fileId };
    variables.id = entityId;
    let idType = isNull(props.definition.guidId) ? "ID! " : "Int ";
    return props.client
      .query({
        query: gql(
          `query fileQuery($fileId: ID!, $id: ` +
            idType +
            `) { get` +
            entityName +
            `File(fileId: $fileId, id: $id) { id fileName fileSize mimeType encoding data } }`
        ),
        variables: variables,
      })
      .then((results) => {
        if (
          !isNull(results) &&
          !isNull(results.data) &&
          !isNull(results.data["get" + entityName + "File"])
        ) {
          return results.data["get" + entityName + "File"];
        } else {
          return null;
        }
      })
      .catch((exception) => {
        Toast.toast(
          `[Export Error]: ` +
            (isNull(exception) ? "Unknown" : exception.toString()),
          5000
        );
        console.log(exception);
        return null;
      });
  };

  const retrieveFiles = (files) => {
    return Promise.all([].map.call(files, retrieveFile));
  };

  const findFilesInRootFields = (props, row, includeHidden = false) => {
    let files = {};
    let fields = findAllTopLevelDBFields(props.definition.fields);
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let fieldValue = wrappedFieldAccessor(row, field);
      if (
        !isNull(fieldValue) &&
        !fieldViewSecured(props, field) &&
        (!field.hidden || includeHidden)
      ) {
        if (field.type === "file") {
          let fileMetaData = JSON.parse(fieldValue);
          files[fileMetaData.id] = wrappedFieldAccessor(row, null);
        }
      }
    }
    return files;
  };

  const replaceFilesInRootFiles = (
    props,
    row,
    includeHidden = false,
    files
  ) => {
    let fields = findAllTopLevelDBFields(props.definition.fields);
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let fieldValue = wrappedFieldAccessor(row, field);
      if (
        !isNull(fieldValue) &&
        !fieldViewSecured(props, field) &&
        (!field.hidden || includeHidden)
      ) {
        if (field.type === "file") {
          let fileMetaData = JSON.parse(fieldValue);
          if (!isNull(files[fileMetaData.id])) {
            if (!isNull(row.raw)) {
              row.raw[field.fieldName] = files[fileMetaData.id];
            } else {
              row[field.fieldName] = files[fileMetaData.id];
            }
          } else {
            throw new Error("File not found: ", fileMetaData.id);
          }
        }
      }
    }
    return files;
  };

  const replaceFilesInInPlaceFields = (
    props,
    row,
    includeHidden = false,
    files
  ) => {
    let fields = getLinkFields(props.definition);
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let fieldValue = wrappedFieldAccessor(row, field);
      let fieldValueParsed = null;
      let updated = false;
      if (
        field.inPlace &&
        !isNull(fieldValue) &&
        !fieldViewSecured(props, field) &&
        (!field.hidden || includeHidden)
      ) {
        let childFields = findAllTopLevelDBFields(field.definition.fields);
        for (let j = 0; j < childFields.length; j++) {
          let childField = childFields[j];
          if (
            childField.type === "file" &&
            !fieldViewSecured(props, field) &&
            (!field.hidden || includeHidden)
          ) {
            if (isNull(fieldValueParsed))
              fieldValueParsed = JSON.parse(fieldValue);
            if (!isEmpty(fieldValueParsed)) {
              for (let k = 0; k < fieldValueParsed.length; k++) {
                let childRow = fieldValueParsed[k];
                if (!isNull(childRow)) {
                  let rawFileData = childRow[childField.fieldName];
                  if (!isNull(rawFileData)) {
                    let fileMetaData = JSON.parse(rawFileData);
                    if (!isNull(files[fileMetaData.id])) {
                      updated = true;
                      childRow[childField.fieldName] = files[fileMetaData.id];
                    } else {
                      throw Error(
                        "File not found: " +
                          fileMetaData.id +
                          " --- " +
                          rawFileData
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (updated) {
        if (!isNull(row.raw)) {
          row.raw[field.fieldName] = JSON.stringify(fieldValueParsed);
        } else {
          row[field.fieldName] = JSON.stringify(fieldValueParsed);
        }
      }
    }
  };

  const findFilesInInPlaceFields = (props, row, includeHidden = false) => {
    let files = {};
    let fields = getLinkFields(props.definition);
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let fieldValue = wrappedFieldAccessor(row, field);
      let fieldValueParsed = null;
      if (
        field.inPlace &&
        !isNull(fieldValue) &&
        !fieldViewSecured(props, field) &&
        (!field.hidden || includeHidden)
      ) {
        let childFields = findAllTopLevelDBFields(field.definition.fields);
        for (let j = 0; j < childFields.length; j++) {
          let childField = childFields[j];
          if (
            childField.type === "file" &&
            !fieldViewSecured(props, field) &&
            (!field.hidden || includeHidden)
          ) {
            if (isNull(fieldValueParsed))
              fieldValueParsed = JSON.parse(fieldValue);
            if (!isEmpty(fieldValueParsed)) {
              for (let k = 0; k < fieldValueParsed.length; k++) {
                let childRow = fieldValueParsed[k];
                if (!isNull(childRow)) {
                  let rawFileData = childRow[childField.fieldName];
                  if (!isNull(rawFileData)) {
                    let fileMetaData = JSON.parse(rawFileData);
                    files[fileMetaData.id] = wrappedFieldAccessor(row, null);
                  }
                }
              }
            }
          }
        }
      }
    }
    return files;
  };

  const findAllTopLevelDBFields = (fields) => {
    return definitionHelper.findAllTopLevelDBFields(fields);
  };

  const getLinkFields = (definition) => {
    return definitionHelper.findAllLinkFields(definition.fields);
  };

  const getFileIdsFromCollection = (props, collection, definition) => {
    let fileIds = {};
    if (collection) {
      for (let i = 0; i < collection.length; i++) {
        let row = collection[i].original;
        let rootFileIds = findFilesInRootFields(props, row, true);
        let inplaceFileIds = findFilesInInPlaceFields(props, row, true);
        fileIds = { ...fileIds, ...rootFileIds, ...inplaceFileIds };
      }
    }

    var files = [];
    for (var key in fileIds) {
      if (Object.prototype.hasOwnProperty.call(fileIds, key)) {
        files.push({
          fileId: key,
          entityId: fileIds[key],
          entityName: definition.entityName,
        });
      }
    }
    return files;
  };

  const updateCollectionFiles = (props, collection, filesAsObject) => {
    if (collection) {
      for (let i = 0; i < collection.length; i++) {
        let row = collection[i].original;
        replaceFilesInRootFiles(props, row, true, filesAsObject);
        replaceFilesInInPlaceFields(props, row, true, filesAsObject);
      }
    }
  };

  const copyCollection = (collection) => {
    let newCol = null;
    if (!isEmpty(collection)) {
      newCol = [];
      for (let i = 0; i < collection.length; i++) {
        let row = collection[i];
        newCol.push({ original: row.original });
      }
    }
    return newCol;
  };

  const onClick = () => {
    let collection = null;
    let definition = props.definition;
    let allowAdditionalFields = false; //props.allowAdditionalFields;
    setState({ exporting: true, exportDataSet: null });
    let files = getFileIdsFromCollection(props, props.collection, definition);
    if (files.length > 0) {
      //We're going to have to modify the collection.
      collection = JSON.parse(JSON.stringify(copyCollection(props.collection)));
    } else {
      collection = props.collection;
    }
    retrieveFiles(files).then((fileArray) => {
      if (fileArray.length > 0) {
        let filesAsObject = {};
        for (let i = 0; i < fileArray.length; i++) {
          let file = cloneDeep(fileArray[i]);
          let fileId = file.id;
          delete file.__typename;
          delete file.entityName;
          delete file.entityId;
          filesAsObject[fileId] = JSON.stringify(file);
        }
        updateCollectionFiles(props, collection, filesAsObject);
      }
      let exportDataSet = getExcelDataSet(
        props,
        collection,
        definition,
        allowAdditionalFields
      );
      setState({ exportDataSet, exporting: false });
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   setState(EMPTY_SET);
  //   console.log("CLEARING!!!");
  // }, [props]);

  const wrappedFieldAccessor = (row, field) => {
    if (isNull(row)) {
      return null;
    } else {
      if (isNull(field)) {
        return isNull(row.raw) ? row.id : row.raw.id;
      } else {
        return isNull(row.raw)
          ? fieldAccessor(row, field)
          : fieldAccessor(row.raw, field);
      }
    }
  };

  const renderValue = (
    props,
    field,
    column,
    value,
    lookupField,
    lookupValues,
    inHTML = false,
    nullToken = "",
    forExport = false
  ) => {
    if (isNull(field)) {
      console.log("WARNING Field not found: ", column);
    }
    let retVal = renderFieldValue(
      props,
      field,
      value,
      lookupField,
      lookupField === null,
      lookupValues,
      inHTML,
      nullToken,
      forExport
    );
    return retVal;
  };

  const fieldViewSecured = (props, userField) => {
    let { currentUser } = props;
    let roles = userField.viewRoles;
    if (isNull(roles)) {
      return false;
    } else {
      return currentUser ? !hasCommonElement(roles, currentUser.roles) : true;
    }
  };

  const dateToNumber = (v) => {
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
  };

  const getRowValues = (props, fields, item, includeHidden = false) => {
    let values = [];
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (!fieldViewSecured(props, field) && (!field.hidden || includeHidden)) {
        if (field.type === "object") {
          values.push(
            ...getRowValues(props, field.definition.fields, item, includeHidden)
          );
        } else {
          //values.push(renderValue(field.fieldName, item[field.fieldName]));
          if (field.lookup) {
            let lookupDef = getLookupDefinition(props, field);
            let lookupFields = definitionHelper.findPrimaryFields(
              lookupDef,
              field.lookupFields
            );
            if (lookupFields.length > 1) {
              for (let j = 0; j < lookupFields.length; j++) {
                let lookupField = lookupFields[j];
                values.push(
                  renderValue(
                    props,
                    field,
                    field,
                    wrappedFieldAccessor(item, field),
                    lookupField.fieldName,
                    item.lookups[field.fieldName],
                    false,
                    "",
                    true
                  )
                );
              }
            } else {
              values.push(
                renderValue(
                  props,
                  field,
                  field,
                  wrappedFieldAccessor(item, field),
                  null,
                  null,
                  false,
                  "",
                  true
                )
              );
            }
          } else {
            if (field.type === "integer" || field.type === "number") {
              values.push(wrappedFieldAccessor(item, field));
            } else if (field.type === "string" && field.format === "date") {
              let stringDate = renderValue(
                props,
                field,
                field,
                wrappedFieldAccessor(item, field),
                null,
                null,
                false,
                "",
                true
              );
              let num = isEmpty(stringDate) ? "" : dateToNumber(stringDate);
              values.push({ value: num, style: { numFmt: "DD/MM/YYYY" } });
            } else {
              values.push(
                renderValue(
                  props,
                  field,
                  field,
                  wrappedFieldAccessor(item, field),
                  null,
                  null,
                  false,
                  "",
                  true
                )
              );
            }
          }
        }
      }
    }
    return values;
  };

  const getColumnTitles = (props, allFields, fields, includeHidden = false) => {
    let columns = [];
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let title = field.title;
      let count = 0;
      for (let j = 0; j < allFields.length; j++) {
        if (title === allFields[j].title || title === allFields[j].fieldName) {
          count += 1;
        }
      }
      if (count > 1 || isNull(title)) {
        title = field.fieldName;
      }
      if (!fieldViewSecured(props, field) && (!field.hidden || includeHidden)) {
        if (field.type === "object") {
          columns.push(
            ...getColumnTitles(
              props,
              allFields,
              field.definition.fields,
              includeHidden
            )
          );
        } else {
          if (field.lookup) {
            let lookupDef = getLookupDefinition(props, field);
            let lookupFields = definitionHelper.findPrimaryFields(
              lookupDef,
              field.lookupFields
            );
            if (lookupFields.length > 1) {
              for (let j = 0; j < lookupFields.length; j++) {
                let lookupField = lookupFields[j];
                columns.push(title + "." + lookupField.title);
              }
            } else {
              columns.push(title);
            }
          } else {
            columns.push(title);
          }
        }
      }
    }
    return columns;
  };

  const getExcelDataSet = (
    props,
    collection,
    definition,
    allowAdditionalFields
  ) => {
    if (!definition) return undefined;

    if (!allowAdditionalFields) {
      definition = cloneDeep(definition);
      let rootFields = findAllTopLevelDBFields(definition.fields);
      for (let j = 0; j < rootFields.length; j++) {
        let rootField = rootFields[j];
        rootField.lookupFields = [];
        rootField.additionalOnly = false;
      }
      let linkFields = getLinkFields(definition);
      for (let i = 0; i < linkFields.length; i++) {
        let linkField = linkFields[i];
        if (linkField.inPlace) {
          let childFields = findAllTopLevelDBFields(
            linkField.definition.fields
          );
          for (let j = 0; j < childFields.length; j++) {
            let childField = childFields[j];
            childField.lookupFields = [];
          }
        }
      }
    }
    let { fields } = definition;

    let allFields = definitionHelper.findAllDisplayFields(fields);
    let columns = getColumnTitles(props, allFields, fields, true);
    let dataSet = [
      {
        columns: columns,
        data: [],
      },
    ];

    if (collection) {
      for (let i = 0; i < collection.length; i++) {
        let item = collection[i].original;
        let row = getRowValues(props, fields, item, true);
        dataSet[0].data.push(row);
      }
    }

    if (dataSet[0].data.length == 0) {
      //Fix due to ExcelFile bug ... doesn't print out whole header with no data.
      let dummyRow = [];
      for (let i = 0; i < columns.length; i++) {
        dummyRow.push("");
      }
      dataSet[0].data.push(dummyRow);
    }
    return dataSet;
  };

  const renderPreparedDataToFile = useMemo(() => {
    if (!isNull(exportDataSet)) {
      const ExcelFile = ReactExport.ExcelFile;
      const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
      return (
        <ExcelFile hideElement={true}>
          <ExcelSheet dataSet={exportDataSet} name={pluralName} />
        </ExcelFile>
      );
    }
    return null;
  }, [exportDataSet, pluralName]);

  let button = null;
  let disabled = exporting || (!allowEmpty && collection.length === 0);
  if (exporting) {
    button = <Loading style={{ float: "right" }} message={"Exporting ..."} />;
  } else {
    if (screenSize > SCREEN.SMALL) {
      button = (
        <Button
          size={"medium"}
          variant={"text"}
          color={"inherit"}
          disabled={disabled}
          onClick={onClick}
          startIcon={<GetAppIcon />}
        >
          {element}
        </Button>
      );
    } else {
      button = (
        <IconButton
          aria-label="download"
          color="inherit"
          style={{ marginRight: 10 }}
          disabled={disabled}
          onClick={onClick}
        >
          <GetAppIcon />
        </IconButton>
      );
    }
  }

  return (
    <>
      {button}
      {renderPreparedDataToFile}
    </>
  );
};

export default withApollo(ExportExcelDataSet);
