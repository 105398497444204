import { SCREEN, FTS_FIELD } from "../util/constants";
import { isNull, isEmpty, hasCommonElement } from "../util/common.js";

export function calcRelativeCellScreenSize(columns, currentScreenSize) {
  if (columns === 1) return currentScreenSize;
  if (columns === 2) {
    let screenSize = SCREEN.SMALL;
    if (currentScreenSize === SCREEN.LARGE) {
      screenSize = SCREEN.MEDIUM;
    } else if (currentScreenSize === SCREEN.HUGE) {
      screenSize = SCREEN.LARGE;
    }
    return screenSize;
  }
  if (columns === 3) {
    let screenSize = SCREEN.SMALL;
    if (currentScreenSize === SCREEN.HUGE) {
      screenSize = SCREEN.MEDIUM;
    }
    return screenSize;
  }
  return SCREEN.SMALL;
}

function fieldHiddenByRule(definition, fieldName, hideRuleState) {
  let rules = isNull(definition.rules) ? [] : definition.rules;
  let applicableRules = rules.filter((rule) => !isEmpty(rule.hide));
  for (let i = 0; i < applicableRules.length; i++) {
    let applicableRule = applicableRules[i];
    let triggerField = applicableRule.fieldName;
    let triggerCheck = null;
    if (hideRuleState) {
      if (isNull(applicableRule.trigger)) {
        triggerCheck = hideRuleState[triggerField + "_null"];
      } else {
        triggerCheck =
          hideRuleState[triggerField + "_" + applicableRule.trigger.toString()];
      }
    }
    if (triggerCheck && !isEmpty(applicableRule.hide)) {
      for (let j = 0; j < applicableRule.hide.length; j++) {
        let targetField = applicableRule.hide[j];
        if (targetField === fieldName) {
          return true;
        }
      }
    }
  }
  return false;
}
export function fieldHidden(
  currentUser,
  definition,
  userField,
  hideRuleState,
  searchBarTop,
  screenSize
) {
  if (
    userField.hidden ||
    (userField.fieldName !== FTS_FIELD &&
      searchBarTop &&
      screenSize === SCREEN.SMALL)
  )
    return true;
  if (userField.type === "object") {
    let isHidden = true;
    for (let i = 0; i < userField.definition.fields.length; i++) {
      let childField = userField.definition.fields[i];
      if (
        !fieldHidden(
          currentUser,
          definition,
          childField,
          hideRuleState,
          searchBarTop,
          screenSize
        )
      ) {
        isHidden = fieldHiddenByRule(
          definition,
          userField.fieldName,
          hideRuleState
        );
        if (!isHidden) break;
      }
    }

    return isHidden;
  } else {
    let roles = userField.viewRoles;
    if (isNull(roles)) {
      return fieldHiddenByRule(definition, userField.fieldName, hideRuleState);
    } else {
      return currentUser
        ? !hasCommonElement(roles, currentUser.roles) ||
            fieldHiddenByRule(definition, userField.fieldName, hideRuleState)
        : true;
    }
  }
}

function hasEditRights(currentUser, entityFactory, item) {
  return (
    entityFactory &&
    currentUser &&
    item &&
    entityFactory.hasEditRights(currentUser, [item.id], item)
  );
}

export function fieldIsReadonly(
  entityFactory,
  layoutGraph,
  item,
  bulkItems,
  thread,
  currentUser,
  isUpdate,
  userField
) {
  if (!userField) return true;
  if (userField.type === "object") return false;
  if (userField.readOnly) return true;
  if (isNull(thread)) {
    if (isNull(entityFactory)) return true;

    if (bulkItems && bulkItems.length > 0) {
      let currentUser = layoutGraph ? layoutGraph.currentUser : null;
      if (layoutGraph) {
        let primaryCollection = entityFactory.getPrimaryCollection(layoutGraph);
        if (primaryCollection) {
          let hasRights =
            entityFactory &&
            currentUser &&
            entityFactory.hasEditRights(
              currentUser,
              bulkItems,
              primaryCollection
            );
          if (!hasRights) return true;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      let isExisting = item && item.id > 0 ? true : false;
      if (!isExisting) {
        if (!entityFactory.hasSomeAddRights(currentUser)) return true;
      } else {
        if (!hasEditRights(currentUser, entityFactory, item)) return true;
      }
    }
  }
  let roles = isUpdate ? userField.editRoles : userField.addRoles;
  if (isNull(roles)) {
    return false;
  } else {
    return currentUser ? !hasCommonElement(roles, currentUser.roles) : true;
  }
}
