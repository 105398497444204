/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useState } from "react";
import WrapperPage from "../entities/thread/wrapperPage";
import withTask from "../hoc/withTask.js";
import withTaskToItem from "../hoc/withTaskToItem.js";
import { withRouter } from "../ReactRouter";
import { SafeAreaView, StyleSheet } from "react-native";
import { KeyboardAvoidingView, StatusBar } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#424242",
  },
});

const TaskWrapper = withRouter(withTask(withTaskToItem(WrapperPage)));

const WizardPage = (props) => {
  const { layoutGraph, task, match, drawerMenu } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  const taskId = useMemo(() => {
    return task ? task.id : match && match.params ? match.params.taskId : null;
  }, [task, match]);
  const resolvedTask = useMemo(() => {
    if (task) return task;
    if (layoutGraph?.allThreads)
      return layoutGraph?.allThreads.find((item) => item.id === taskId);
    return null;
  }, [layoutGraph?.allThreads, task, taskId]);

  let keys = drawerMenu ? Object.keys(drawerMenu) : [];
  let menuOnlyHasHome =
    keys.length === 1 &&
    drawerMenu[keys[0]].length === 1 &&
    drawerMenu[keys[0]][0].url === "/home";
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="dark" />
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: "#fff" }}
        behavior="padding"
      >
        <TaskWrapper
          {...props}
          task={resolvedTask}
          queryLoading={loading}
          menuOnlyHasHome={menuOnlyHasHome}
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default WizardPage;
