import { gql } from "@apollo/client";

export default gql`
  subscription onTaskDatasChanged {
    taskDatasChanged {
      taskId
      taskStateData
      timeStamp
    }
  }
`;
