import { isNull } from "./types";

export default function mask(
  value,
  precision = 2,
  decimalSeparator = ".",
  thousandSeparator = ",",
  allowNegative = false,
  prefix = "",
  suffix = ""
) {
  // provide some default values and arg validation.
  if (precision < 0) {
    precision = 0;
  } // precision cannot be negative
  if (precision > 20) {
    precision = 20;
  } // precision cannot be greater than 20

  if (value === null || value === undefined) {
    return {
      value: 0,
      maskedValue: "",
    };
  }

  value = String(value); //if the given value is a Number, let's convert into String to manipulate that

  if (value.length == 0) {
    return {
      value: 0,
      maskedValue: "",
    };
  }
  let numberIsNegative = false;
  if (allowNegative) {
    let negativeSignCount = (value.match(/-/g) || []).length;
    // number will be negative if we have an odd number of "-"
    // ideally, we should only ever have 0, 1 or 2 (positive number, making a number negative
    // and making a negative number positive, respectively)
    numberIsNegative = negativeSignCount % 2 === 1;
  }

  // extract digits. if no digits, fill in a zero.
  let digits = undefined;
  if (precision === 0) {
    digits = value.match(/\d/g);
  } else {
    let pos = value.indexOf(decimalSeparator);
    if (pos > 0) {
      while (value.split(decimalSeparator).length - 1 > 1) {
        value = value.replace(decimalSeparator, "");
      }
      pos = value.indexOf(decimalSeparator);
      if (value.length > pos + precision + 1) {
        value = pos = value.substring(0, pos + precision + 1);
      }
    }
    digits = value.match(new RegExp("[\\d" + decimalSeparator + "]", "g")); // /[\d.]/g
  }

  if (digits === null || digits === undefined || digits.length === 0) {
    return {
      value: 0,
      maskedValue: "",
    };
  }

  while (
    digits.length > 1 &&
    digits[0] === "0" &&
    digits[1] !== decimalSeparator
  ) {
    digits.shift();
  }

  if (numberIsNegative) {
    let allDigitsAreZero = true;
    for (let idx = 0; idx < digits.length; idx += 1) {
      if (digits[idx] !== "0" && digits[idx] !== decimalSeparator) {
        allDigitsAreZero = false;
        break;
      }
    }
    if (allDigitsAreZero) {
      numberIsNegative = false;
    }
  }

  // zero-pad a input

  /*
  while (digits.length <= precision) { digits.unshift('0'); }

  if (precision > 0) {
      // add the decimal separator
      digits.splice(digits.length - precision, 0, decimalSeparator);
  }
  // clean up extraneous digits like leading zeros.
  digits = Number(digits.join('')).toFixed(precision).split('');
  */

  let decimalpos = digits.indexOf(decimalSeparator);
  if (decimalpos < 0) {
    decimalpos = digits.length; // -1 needed to position the decimal separator before the digits.
  } else {
    digits[decimalpos] = decimalSeparator;
  }

  // add in any thousand separators
  for (let x = decimalpos - 3; x > 0; x = x - 3) {
    digits.splice(x, 0, thousandSeparator);
  }

  // if we have a prefix or suffix, add them in.
  if (prefix.length > 0) {
    digits.unshift(prefix);
  }
  if (suffix.length > 0) {
    digits.push(suffix);
  }

  // if the number is negative, insert a "-" to
  // the front of the array and negate the raw value
  if (allowNegative && numberIsNegative) {
    digits.unshift("-");
  }

  return digits.join("").trim();
}

export function formatNumber(props) {
  let val = mask(
    props.value,
    props.decimalScale,
    ".",
    props.thousandSeparator,
    props.allowNegative,
    props.prefix
  );
  val = val.replace(".", props.decimalSeparator);
  if (props.fixedDecimalScale) {
    let pos = val.indexOf(props.decimalSeparator);
    if (pos < 0) {
      val += props.decimalSeparator;
      pos = val.indexOf(props.decimalSeparator);
    }
    while (pos + props.decimalScale + 1 > val.length) {
      val += "0";
    }
  }
  return val;
}

export function formatDollars(currencyValue) {
  if (isNull(currencyValue)) {
    return null;
  } else {
    return formatNumber({
      value: currencyValue,
      prefix: "$",
      thousandSeparator: ",",
      decimalScale: 0,
      fixedDecimalScale: false,
      allowNegative: true,
    });
  }
}

export function formatDollarsAndCents(currencyValue) {
  if (isNull(currencyValue)) {
    return null;
  } else {
    return formatNumber({
      value: currencyValue,
      prefix: "$",
      thousandSeparator: ",",
      decimalScale: 2,
      fixedDecimalScale: true,
      decimalSeparator: ".",
      allowNegative: true,
    });
  }
}

export function formatMinutes(minutes) {
  if (isNull(minutes)) {
    return null;
  } else {
    let asDecimal = Math.floor(minutes / 60) + (minutes % 60) / 100;
    let val = formatNumber({
      value: asDecimal,
      thousandSeparator: ",",
      decimalScale: 2,
      fixedDecimalScale: true,
      decimalSeparator: ":",
      allowNegative: false,
    });
    return val;
  }
}
