/* Can check here to improve later: https://www.apollographql.com/docs/react/migrating/boost-migration/ */
import { ApolloLink, HttpLink } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { getAccessTokenAsync, getTwoFactorToken } from "./AccessToken";
import { setContext } from "@apollo/client/link/context";
import { isEmpty } from "./util/common";
import { sha256 } from "crypto-hash";

//const credentials = Platform && (Platform.OS === "ios" || Platform.OS === "android") ? 'omit' : 'include';

/*
export const subscriptionLink = (config = {}) =>
  new WebSocketLink({
    uri: 
      process.env.NODE_ENV !== 'production'
        ? 'ws://localhost:3010/subscriptions'
        : 'wss://api.githunt.com/subscriptions',
    options: { reconnect: true },
    ...config,
  });
*/
/*
Possibly should be reformulated as:
  let subscriptionClient = new SubscriptionClient(websocketUrl, {
    reconnect: true
  });
  return new WebSocketLink(subscriptionClient);
*/

//          Authorization: token ? `Bearer ${token}` : "",

//Lazy Reconnect should be fine.
//use onConnect on server ... check that it remains valid when auth token changes (should)
//Need to resetLink on logOut. e.g.  AuthorizedWsLink.resetLink();
//Although I don't think we need to wait for the token.  Last/reconnect should do it I think?
/*
class AuthorizedWsLink {
  constructor (tokenProvider, wsSubscriptionsServer) {
    this.wsSubscriptionsServer = wsSubscriptionsServer
    this.tokenProvider = tokenProvider

    tokenProvider.getToken().then(token => {
      this.token = token
    })

    this.wsLink = new WebSocketLink({
      uri: this.wsSubscriptionsServer,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: () => {
          return ({
            authorization: this.token
          })
        }
      }
    })
  }

  resetLink = async () => {
    // get a new token
    this.token = await this.tokenProvider.getToken()
    // Reset the WS connection for it to carry the new JWT.
    this.wsLink.subscriptionClient.close(false, false)
  }

  createLink = () => this.wsLink
}
*/

export const getSubscription = (websocketUrl) => {
  let config = {
    uri: websocketUrl,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        let token = await getAccessTokenAsync(true);
        return {
          headers: {
            authorization:
              token !== null && token !== undefined
                ? "Bearer " + token
                : "Bearer ",
          },
        };
      },
    },
  };
  let subscriptionClient = new SubscriptionClient(config.uri, config.options);

  return {
    subscriptionLink: new WebSocketLink(subscriptionClient),
    subscriptionClient,
  };
};
export const queryOrMutationLink = (config = {}) =>
  // turn on CDN support via GET
  createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
    new HttpLink({
      ...config,
      credentials: "same-origin",
    })
  );

export const requestLink = (
  { queryOrMutationLink, subscriptionLink },
  logOut
) =>
  /*
    This link checks if the operation is a subscription.
    If it is, we use our subscription link to retrieve data over WebSockets.
    If it is a query or mutation, we retrieve data over HTTP.
  */
  //console.log("subscriptionLink", subscriptionLink)
  ApolloLink.split(
    ({ query }) => {
      //console.log("query", query)
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    subscriptionLink,
    setContext(async (req, { headers }) => {
      let token = await getAccessTokenAsync();
      let twoFactorToken = getTwoFactorToken();
      if (isEmpty(token) && isEmpty(twoFactorToken)) {
        console.log(">>> requestLink: LOGGING OUT");
        logOut();
      }
      return {
        headers: {
          ...headers,
          authorization:
            token !== null && token !== undefined
              ? "Bearer " + token
              : "Bearer ",
        },
      };
    }).concat(queryOrMutationLink)
  );
