/* eslint-disable react/prop-types */
import React from "react";
import GridBase from "../gridBase";
import withDelete from "./delete.js";
import definition from "./definition";
import { isNull, isEmpty, renderFieldValue } from "../../util/common.js";
import { findField } from "../gridCommon";

function getBaseEntityItemURLOverride() {
  return "/threads";
}

function hasSomeViewRightsOverride() {
  return true;
}

function substringFilterOverride(props, def, rows, id, filterValue) {
  let idName = id[0];
  let idArray = idName.split(".");
  let fieldName = idArray[0];
  let lookupField = null;
  if (idArray.length > 1) {
    lookupField = idArray[1];
  }
  let field = findField(def.fields, fieldName);
  let allWorkflows =
    props.layoutGraph && !isEmpty(props.layoutGraph.allWorkflows)
      ? props.layoutGraph.allWorkflows
      : [];

  return rows.filter((row) => {
    let value = "";
    let currentStep = null;
    let currentWorkflow = null;
    for (let j = 0; j < allWorkflows.length; j++) {
      let workflow = allWorkflows[j];
      if (workflow.name === row.original.raw.workflowName) {
        for (let k = 0; k < workflow.steps.length; k++) {
          let step = workflow.steps[k];
          if (step.id === row.original.raw.stepId) {
            currentStep = step;
            break;
          }
        }
        currentWorkflow = workflow;
        break;
      }
    }

    if (fieldName === "step") {
      if (currentStep) {
        value = isEmpty(currentStep.title)
          ? currentStep.name
          : currentStep.title;
      }
    } else if (fieldName === "workflowTitle") {
      if (currentWorkflow) {
        value = isEmpty(currentWorkflow.title)
          ? currentWorkflow.name
          : currentWorkflow.title;
      }
    } else if (fieldName === "name") {
      if (currentWorkflow) {
        value = currentWorkflow.name;
      }
    } else {
      if (idName.length === 1) {
        value = renderFieldValue(
          props,
          field,
          row.original.raw[fieldName],
          null,
          false,
          null,
          false,
          "     "
        );
      } else {
        value = renderFieldValue(
          props,
          field,
          row.original.raw[fieldName],
          lookupField,
          false,
          row.original.lookups[fieldName],
          false,
          "     "
        );
      }
    }

    return value.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
  });
}

function fieldAccessorOverride(props, row, field) {
  if (isNull(field)) {
    return { data: row.raw.id, success: true };
  }
  let currentStep = null;
  let currentWorkflow = null;
  let allWorkflows =
    props.layoutGraph && !isEmpty(props.layoutGraph.allWorkflows)
      ? props.layoutGraph.allWorkflows
      : [];
  for (let j = 0; j < allWorkflows.length; j++) {
    let workflow = allWorkflows[j];
    if (workflow.name === row.raw.workflowName) {
      for (let k = 0; k < workflow.steps.length; k++) {
        let step = workflow.steps[k];
        if (step.id === row.raw.stepId) {
          currentStep = step;
          break;
        }
      }
      currentWorkflow = workflow;
      break;
    }
  }
  if (field.fieldName === "step") {
    if (currentStep) {
      return {
        data: isEmpty(currentStep.title) ? currentStep.name : currentStep.title,
        success: true,
      };
    }
    return { data: null, success: true };
  }
  if (field.fieldName === "workflowTitle") {
    if (currentWorkflow) {
      return {
        data: isEmpty(currentWorkflow.title)
          ? currentWorkflow.name
          : currentWorkflow.title,
        success: true,
      };
    }
    return { data: null, success: true };
  }
  if (field.fieldName === "name") {
    if (currentWorkflow) {
      return { data: currentWorkflow.name, success: true };
    }
    return { data: null, success: true };
  }
  if (field.fieldName === "threadStateData") {
    if (currentStep) {
      let template = currentStep.taskDisplay;
      if (!isNull(currentStep.formId)) {
        let formId = currentStep.formId;
        let allForms =
          props.layoutGraph && !isEmpty(props.layoutGraph.allForms)
            ? props.layoutGraph.allForms
            : [];
        for (let j = 0; j < allForms.length; j++) {
          let form = allForms[j];
          if (form.id === formId) {
            if (!isNull(form.definition)) {
              let definition = JSON.parse(form.definition);
              if (!isEmpty(definition.taskDisplay)) {
                template = definition.taskDisplay;
              } else {
                template = definition.title;
                if (isEmpty(template)) {
                  template = form.name;
                }
              }
            }
            break;
          }
        }
      }
      if (!isEmpty(template)) {
        //e.g. "{id} - {_guid}"
        let result = template; //e.g "{id} - {_guid}"
        if (!isEmpty(result)) {
          if (row && row.raw) {
            let stringifiedThreadData = row.raw.threadStateData;
            if (!isEmpty(stringifiedThreadData)) {
              let threadData = JSON.parse(stringifiedThreadData);
              for (let key in threadData) {
                if (Object.prototype.hasOwnProperty.call(threadData, key)) {
                  let val = "<Unknown>";
                  if (!isEmpty(threadData[key])) {
                    val = threadData[key].toString();
                  }
                  result = result.split("{" + key + "}").join(val);
                }
              }
            }
          }
          return { data: result, success: true };
        }
      }
    }
    return { data: null, success: true };
  }

  return { success: false };
  //return super.fieldAccessor(row, field);
}

const EntityGrid = (props) => {
  return (
    <GridBase
      {...props}
      definition={props.definition ? props.definition : definition}
      getBaseEntityItemURLOverride={getBaseEntityItemURLOverride}
      hasSomeViewRightsOverride={hasSomeViewRightsOverride}
      substringFilterOverride={substringFilterOverride}
      fieldAccessorOverride={fieldAccessorOverride}
    />
  );
};

export default withDelete(EntityGrid);
