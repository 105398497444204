/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import {
  SafeAreaView,
  StyleSheet,
  KeyboardAvoidingView,
  StatusBar,
} from "react-native";
import { stringIsNumeric, isNull, isString, isEmpty } from "../util/types";
import { getLookupFactory } from "../util/common.js";
import JSONPage from "./jsonPage";
import BulkImport from "./bulkImport";

function getPageItem(
  currentUser,
  autoGridPages,
  autoFormPages,
  pagesByName,
  pagesById,
  pageId
) {
  let pageItem = undefined;
  let pageIdentifier = pageId + "";
  if (pageIdentifier.toString().endsWith("__grid")) {
    pageItem =
      autoGridPages[pageIdentifier.substring(0, pageIdentifier.length - 6)];
  } else if (pageIdentifier.toString().endsWith("__form")) {
    pageItem =
      autoFormPages[pageIdentifier.substring(0, pageIdentifier.length - 6)];
  } else if (pageIdentifier.toString().endsWith("__tasks")) {
    pageItem = pagesByName["tasklist"];
  } else {
    if (!isNull(pageIdentifier)) {
      if (!isNull(pagesById)) {
        if (!isString(pageIdentifier) || stringIsNumeric(pageIdentifier)) {
          if (isString(pageIdentifier)) {
            pageIdentifier = parseInt(pageIdentifier, 10);
          }
          console.log("GETTING PAGE BY ID", pageIdentifier);
          pageItem = pagesById[pageIdentifier.toString()];
        } else {
          console.log("GETTING PAGE BY NAME", pageIdentifier);
          pageItem = pagesByName[pageIdentifier.toString().toLowerCase()];
        }
      }
    }
  }

  if (
    isNull(pageItem) &&
    (isNull(currentUser) || (currentUser && currentUser.id === 21))
  ) {
    //Can't find page ... revert to login for PublicUser
    pageItem = pagesByName["login"];
  }
  return pageItem;
}

function getPageState(
  currentUser,
  commonProps,
  autoGridPages,
  autoFormPages,
  pagesByName,
  pagesById,
  pageId
) {
  let pageItem = getPageItem(
    currentUser,
    autoGridPages,
    autoFormPages,
    pagesByName,
    pagesById,
    pageId
  );
  let PageComponent = null;
  let page = null;
  let formEntityFactory = null;
  if (isNull(pageItem)) {
    page = BulkImport;
  } else {
    //console.log(">>> pageItem", pageItem);
    if (pageItem.name === "Tasklist") {
      page = JSONPage;
    } else if (pageItem.name === "Bulk Import") {
      page = BulkImport;
    } else {
      page = JSONPage;
      if (!isEmpty(pageItem.definition)) {
        let definition = pageItem.definition;
        formEntityFactory = getLookupFactory(commonProps, definition);
        if (formEntityFactory) {
          page = formEntityFactory.getComponentWithEntity(page);
        }
      }
    }
  }
  PageComponent = page;
  return { PageComponent, formEntityFactory, pageItem };
}

const DBPage = (props) => {
  const {
    commonProps,
    changeLoginState,
    pageId,
    entityId,
    match,
    windowHeight,
  } = props;
  const { autoGridPages, autoFormPages, pagesById, pagesByName, currentUser } =
    commonProps;
  const resolvedPageId = useMemo(
    () => (pageId ? pageId : match?.params?.pageId),
    [pageId, match?.params?.pageId]
  );
  const resolvedEntityId = useMemo(
    () => (entityId ? entityId : match?.params?.entityId),
    [entityId, match?.params?.entityId]
  );
  const { PageComponent, formEntityFactory, pageItem } = useMemo(
    () =>
      getPageState(
        currentUser,
        commonProps,
        autoGridPages,
        autoFormPages,
        pagesByName,
        pagesById,
        resolvedPageId
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resolvedPageId]
  );
  //console.log("DBPage Render", commonProps?.layoutGraph);
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="dark" />
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: "#fff" }}
        behavior="padding"
      >
        {PageComponent ? (
          <PageComponent
            applyTransitionHeader={true}
            entityId={resolvedEntityId}
            entityFactory={formEntityFactory}
            pageItem={pageItem}
            changeLoginState={changeLoginState}
            {...commonProps}
            windowHeight={windowHeight}
          />
        ) : undefined}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#424242",
  },
});

export default DBPage;
