/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Animated, StyleSheet, View } from "react-native";
import Button from "@material-ui/core/Button";

export default class Toast extends Component {
  static toastInstance = null;
  static timerID = null;
  static animateOpacityValue = new Animated.Value(0);

  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
    this.state = {
      message: null,
      btnTitle: null,
      btnOnClick: null,
    };
  }

  onClick() {
    clearTimeout(Toast.timerID);
    Toast.toastInstance.setState({ message: null });
  }

  componentDidMount() {
    Toast.toastInstance = this;
  }

  componentWillUnmount() {
    Toast.timerID && clearTimeout(Toast.timerID);
  }

  static toast(
    message = "Custom React Native Toast",
    duration = 2000,
    btnTitle = null,
    btnOnClick = null
  ) {
    if (Toast.toastInstance && !Toast.toastInstance?.state?.message) {
      Toast.toastInstance.setState({ message, btnTitle, btnOnClick }, () => {
        Animated.timing(Toast.animateOpacityValue, {
          toValue: 1,
          duration: 200,
        }).start(Toast.hideToastFunction(duration));
      });
    }
  }

  static hideToastFunction(duration) {
    if (duration) {
      Toast.timerID = setTimeout(() => {
        Animated.timing(Toast.animateOpacityValue, {
          toValue: 0,
          duration: 200,
        }).start(() => {
          clearTimeout(Toast.timerID);
          Toast.toastInstance.setState({ message: null });
        });
      }, duration);
    }
  }

  static clearMessage() {
    if (Toast.toastInstance) {
      Toast.toastInstance.setState({
        message: null,
        btnTitle: null,
        btnOnClick: null,
      });
    }
  }

  render() {
    const { message, btnOnClick, btnTitle } = this.state;
    if (message) {
      return (
        <View style={styles.wrapper}>
          <Animated.View
            style={[
              styles.animatedToastView,
              {
                opacity: this.animateOpacityValue,
                top: this.props.position == "top" ? "10%" : "80%",
                backgroundColor: this.props.backgroundColor,
              },
            ]}
          >
            <Animated.Text
              numberOfLines={1}
              onClick={this.onClick}
              style={[
                styles.ToastBoxInsideText,
                { color: this.props.textColor },
              ]}
            >
              {message}
            </Animated.Text>
            {btnOnClick ? (
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={false}
                key={"tbtn"}
                onClick={btnOnClick}
              >
                {btnTitle}
              </Button>
            ) : null}
          </Animated.View>
        </View>
      );
    } else {
      return null;
    }
  }
}

Toast.defaultProps = {
  backgroundColor: "#666666",
  textColor: "#fff",
};

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },

  animatedToastView: {
    marginHorizontal: 30,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 25,
    zIndex: 9999,
    position: "absolute",
    justifyContent: "center",
  },

  ToastBoxInsideText: {
    fontSize: 15,
    alignSelf: "stretch",
    textAlign: "center",
  },
});
