import React, { useMemo } from "react";
import { Ionicons, FontAwesome } from "@expo/vector-icons";

const TabBarIcon = ({ icon, size, style, color }) => {
  const isFA = useMemo(() => {
    return icon && icon.startsWith("fa-");
  }, [icon]);
  const fixedIcon = useMemo(() => {
    let ic = icon;
    if (isFA) {
      ic = ic.substring(3);
    }
    return ic;
  }, [isFA, icon]);

  return fixedIcon ? (
    isFA ? (
      <FontAwesome name={fixedIcon} size={size} style={style} color={color} />
    ) : (
      <Ionicons name={fixedIcon} size={size} style={style} color={color} />
    )
  ) : null;
};

export default TabBarIcon;

/*
import React from 'react';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { withRouter } from '../ReactRouter';

const TabBarIcon = props => {
  let icon = props.icon;
  let isFA = icon && icon.startsWith("fa-");
  if (isFA) {
    icon = icon.substring(3);
  }
  return (
    props.icon ? (isFA ? <FontAwesome
      name={icon}
      size={props.size}
      style={props.style}
      color={props.color}
    /> : <Ionicons
        name={props.icon}
        size={props.size}
        style={props.style}
        color={props.color}
      />) : undefined
  );
};

export default withRouter(TabBarIcon);
*/
