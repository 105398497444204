/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {
  isNull,
  isEmpty,
  filterCollection,
  updateSubQueriesWithLookupIds,
  getScreenSize,
} from "../util/common.js";
import definitionHelper from "../util/definitions.js";
import withCollection from "./withCollection.js";
import { renderLogo } from "../navigation/utils";
import { SCREEN } from "../util/constants";

export default function withQueryToLayout(BaseComponent, fixedFilter) {
  class WithQueryToLayout extends React.Component {
    constructor(props) {
      super(props);
      this["onChanged"] = null;
      this["onDeleted"] = null;
      this.getStateFromProps = this.getStateFromProps.bind(this);
      this.getBaseComponent = this.getBaseComponent.bind(this);
      this.getFilter = this.getFilter.bind(this);
      this.state = this.getStateFromProps(null, props);
      this.fixedFilter = fixedFilter;
    }

    getFilter(props) {
      return this.fixedFilter ? this.fixedFilter : props.filter;
    }

    updateSubQueriesWithArrayIds(
      props,
      queryResults,
      linkArrayFields,
      subQueries
    ) {
      for (let i = 0; i < linkArrayFields.length; i++) {
        let linkArrayField = linkArrayFields[i];
        if (linkArrayField.inPlace) {
          let lookupFields = definitionHelper.findAllTopLevelLookupFields(
            linkArrayField.definition.fields
          );
          updateSubQueriesWithLookupIds(
            props,
            queryResults,
            lookupFields,
            subQueries,
            true,
            true,
            linkArrayField
          );
        } else {
          updateSubQueriesWithLookupIds(
            props,
            queryResults,
            [linkArrayField],
            subQueries,
            true,
            false,
            null
          );
        }
      }
    }

    getStateFromProps(oldProps, nextProps) {
      let same = false;
      if (!isNull(oldProps) && !isNull(oldProps.queryResult)) {
        if (!isNull(nextProps.queryResult)) {
          if (nextProps.queryResult.length === oldProps.queryResult.length) {
            same = true;
            for (let i = 0; i < nextProps.queryResult.length; i++) {
              let oldItem = oldProps.queryResult[i];
              let newItem = nextProps.queryResult[i];
              if (
                isNull(oldItem) ||
                isNull(newItem) ||
                oldItem.id !== newItem.id ||
                oldItem.versionId !== newItem.versionId
              ) {
                same = false;
                break;
              }
            }
          }
        }
      }
      if (!same) {
        //Layout
        //Add the Query results.
        if (
          !isNull(nextProps.entityFactory) &&
          !isNull(nextProps.entityFactory.definition)
        ) {
          let combinedLayout = { ...nextProps.layoutGraph };
          let layoutPath =
            "all" + nextProps.entityFactory.definition.pluralName;

          //Do a merge
          let queryResults = nextProps.queryResult;
          let alteredLayout = false;
          if (!isEmpty(queryResults)) {
            alteredLayout = true;
            if (isEmpty(combinedLayout[layoutPath])) {
              combinedLayout[layoutPath] = queryResults;
            } else {
              let filteredItems = combinedLayout[layoutPath].concat(
                queryResults
              );
              for (let i = 0; i < filteredItems.length; i++) {
                for (let j = i + 1; j < filteredItems.length; j++) {
                  if (filteredItems[i].id === filteredItems[j].id) {
                    filteredItems.splice(j--, 1);
                  }
                }
              }
              combinedLayout[layoutPath] = filteredItems;
            }
          } else {
            if (isNull(combinedLayout[layoutPath])) {
              combinedLayout[layoutPath] = [];
            }
            if (queryResults === undefined) {
              //console.log("filtering collection ...", combinedLayout[layoutPath], nextProps.filter)
              queryResults = filterCollection(
                combinedLayout[layoutPath],
                nextProps.filter
              );
              //console.log("filtering result ...", queryResults)
            }
          }

          // Lookup Fields
          let subQueries = [];
          let lookupFields = definitionHelper.findAllTopLevelLookupFields(
            nextProps.entityFactory.definition.fields
          );
          updateSubQueriesWithLookupIds(
            nextProps,
            queryResults,
            lookupFields,
            subQueries,
            false,
            false,
            null
          );

          // Link Fields
          let linkArrayFields = definitionHelper.findAllLinkFields(
            nextProps.entityFactory.definition.fields
          );

          // Inplace JSON Fields
          let inPlaceFields = definitionHelper.findAllInPlaceFields(
            nextProps.entityFactory.definition.fields
          );
          for (let i = 0; i < inPlaceFields.length; i++) {
            let inPlaceField = inPlaceFields[i];
            if (inPlaceField.type === "json") {
              linkArrayFields.push(inPlaceField);
            }
          }
          this.updateSubQueriesWithArrayIds(
            nextProps,
            queryResults,
            linkArrayFields,
            subQueries
          );
          //console.log("subQueries  ...", subQueries)

          //Add item to layout in using entityFactory
          let nextState = {};
          nextState.layoutGraph = combinedLayout;
          nextState.subQueries = subQueries;
          nextState.alteredLayout = alteredLayout;
          nextState.wrappedBaseComponent = this.getBaseComponent(subQueries);
          return nextState;
        } else {
          //console.log("COMBINING LAYOUT ANYWAY IN CASE OF CHANGE 1", nextProps.layoutGraph)
          //let combinedLayout = { ...nextProps.layoutGraph }
          //nextState.layoutGraph = combinedLayout;
        }
      } else {
        //let nextState = {};
        //console.log("COMBINING LAYOUT ANYWAY IN CASE OF CHANGE 2", nextProps.layoutGraph)
        //let combinedLayout = { ...nextProps.layoutGraph }
        //nextState.layoutGraph = combinedLayout;
        //return nextState;
      }
    }

    componentWillUnmount() {
      if (this["onChanged"]) {
        this["onChanged"]();
      }
      if (this["onDeleted"]) {
        this["onDeleted"]();
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      let filterArray = this.getFilter(nextProps);
      let isFTS = !isNull(nextProps.fts) && isEmpty(filterArray);

      if (!isNull(nextProps.subscribeToMore)) {
        if (
          this["onChanged"] &&
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onChanged"]();
          delete this["onChanged"];
        }

        if (
          (!this["onChanged"] && !nextProps.queryLoading) ||
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onChanged"] = nextProps.subscribeToMore({
            document: nextProps.entityFactory.getGraphUpdateSubscription(),
            variables: {},
            updateQuery: (prev, { subscriptionData }) => {
              if (isFTS) {
                return nextProps.entityFactory.updateFTSSearchGraphAfterUpdate(
                  prev,
                  subscriptionData.data[
                    nextProps.entityFactory.camelCasePluralName() + "Changed"
                  ]
                );
              } else {
                return nextProps.entityFactory.updateSearchGraphAfterUpdate(
                  prev,
                  subscriptionData.data[
                    nextProps.entityFactory.camelCasePluralName() + "Changed"
                  ],
                  filterArray
                );
              }
            },
          });
        }
        if (
          this["onDeleted"] &&
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onDeleted"]();
          delete this["onDeleted"];
        }
        if (
          (!this["onDeleted"] && !nextProps.queryLoading) ||
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onDeleted"] = nextProps.subscribeToMore({
            document: nextProps.entityFactory.getGraphDeleteSubscription(),
            variables: {},
            updateQuery: (prev, { subscriptionData }) => {
              return nextProps.entityFactory.updateSearchGraphAfterDelete(
                prev,
                subscriptionData.data[
                  nextProps.entityFactory.camelCasePluralName() + "Deleted"
                ],
                isFTS
              );
            },
          });
        }
      }
      let newState = this.getStateFromProps(this.props, nextProps);
      if (!isNull(newState)) {
        this.setState(newState);
      }
    }

    getBaseComponent(subQueries) {
      let currentComponent = BaseComponent;
      for (let i = 0; i < subQueries.length; i++) {
        let subQuery = subQueries[i];
        currentComponent = subQuery.entityFactory.withFactoryAndDefinition(
          subQuery.entityFactory.withFixedFilter(
            withCollection(
              this.props.entityFactory.withFactoryAndDefinition(
                currentComponent
              ),
              subQuery.filter
            ),
            subQuery.filter
          )
        );
      }
      return currentComponent;
    }

    render() {
      let newProps = { ...this.props };
      newProps.layoutGraph = this.state.layoutGraph;
      let queryLoading = newProps.queryLoading;
      newProps.entityId = this.props.entityId
        ? this.props.entityId
        : this.props.match &&
          this.props.match.params &&
          this.props.match.params.entityId
        ? Math.floor(this.props.match.params.entityId)
        : undefined;
      delete newProps.subscribeToMore;
      delete newProps.queryLoading;
      //newProps.layoutGraph = this.state.layoutGraph;
      let WrappedBaseComponent = this.state.wrappedBaseComponent;

      let title =
        getScreenSize(this.props.windowWidth) !== SCREEN.SMALL ||
        isNull(this.props.pageItem?.definition?.short)
          ? this.props.pageItem?.definition?.title
          : this.props.pageItem?.definition?.short;
      if (queryLoading || isNull(WrappedBaseComponent)) {
        return (
          <View
            style={{
              flex: 4,
            }}
          >
            {this.props.applyTransitionHeader ? (
              <AppBar position="static" color="primary">
                <View
                  style={{
                    flexDirection: "row",
                    paddingLeft: this.props.hasSideBar ? 64 : 0,
                    height: 50,
                    justifyContent: "left",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      textAlign: "center",
                      justifyContent: "left",
                      alignItems: "center",
                      marginLeft: !this.props.hasSideBar ? 58 : 10,
                    }}
                  >
                    <View style={{ width: 42 }}>{renderLogo(32)}</View>
                    <Typography
                      variant="h6"
                      display="block"
                      color={"inherit"}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      {title
                        ? title
                        : this.props.pageItem?.definition?.entityName
                        ? this.props.pageItem?.definition?.entityName
                        : this.props.pageItem?.name}
                    </Typography>
                  </View>
                </View>
              </AppBar>
            ) : undefined}
          </View>
        );
      } else {
        return <WrappedBaseComponent {...newProps} />;
      }
    }
  }
  return WithQueryToLayout;
}
