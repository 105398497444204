import React from "react";
import Papa from "papaparse";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { View, TouchableOpacity, Platform } from "react-native";
import * as cloneDeep from "lodash/cloneDeep";
import Constants from "expo-constants";
import moment from "moment";

import definitionHelper from "./definitions.js";
import { Link } from "../ReactRouter";
import { formatDollars, formatDollarsAndCents, formatMinutes } from "./format";
import {
  isNull,
  isEmpty,
  isInt,
  isNumber,
  isString,
  isObject,
  isObjectNotArray,
} from "./types";
import { SCREEN } from "./constants";

const BlackTypography = withStyles(
  () => {
    return {
      root: {
        color: "black",
      },
    };
  },
  { withTheme: true }
)(Typography);

const BlueTypography = withStyles(
  () => {
    return {
      root: {
        color: "blue",
      },
    };
  },
  { withTheme: true }
)(Typography);

const isWeb = Platform.OS === "web";

export {
  isNull,
  isEmpty,
  isInt,
  isNumber,
  isString,
  isObject,
  isObjectNotArray,
};

export function getScreenSize(windowWidth) {
  let screenSize = SCREEN.SMALL;
  if (windowWidth >= 768 && windowWidth <= 992) {
    screenSize = SCREEN.MEDIUM;
  } else if (windowWidth > 992 && windowWidth <= 1200) {
    screenSize = SCREEN.LARGE;
  } else if (windowWidth > 1200) {
    screenSize = SCREEN.HUGE;
  }
  return screenSize;
}

export function lowerCasePluralName(definition) {
  let entityName = definition.pluralName;
  return entityName.toLowerCase();
}

export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function renameKey(obj, oldKey, newKey) {
  const keyValues = Object.keys(obj).map((key) => {
    const itemKey = key.localeCompare(oldKey) === 0 ? newKey : key;
    return { [itemKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function formatFTSText(ftsTextValue) {
  //replace | with "Space"
  //Split based on &
  //For each "&" section ... split based on Space.
  //For each part reduce multiple whitespace to a single space.
  //Replace " " for each part with " | "
  //Rejoin parts with " | " and " & "
  if (isEmpty(ftsTextValue)) return "";

  ftsTextValue = ftsTextValue.split("!").join(" !");
  ftsTextValue = ftsTextValue.split("|").join(" ");

  let andSections = ftsTextValue.split("&");
  let result = "";
  for (let i = 0; i < andSections.length; i++) {
    if (result.length > 0) {
      result += " & ";
    }
    result += andSections[i]
      .trim()
      .replace(/\s\s+/g, " ")
      .split(" ")
      .join(" | ");
  }
  return result;
}

export function hasCommonElement(arr1, arr2) {
  if (isNull(arr1)) arr1 = [];
  if (isNull(arr2)) arr2 = [];
  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      if (arr1[i] === arr2[j]) {
        return true;
      }
    }
  }
  return false;
}

export function replaceAndDecode(json, variables) {
  if (isString(json)) {
    for (let key in variables) {
      if (Object.prototype.hasOwnProperty.call(variables, key)) {
        let value = variables[key];
        if (!isNull(value)) {
          let token = "%%" + key;
          json = json.replace(token, JSON.stringify(value));
        }
      }
    }
    try {
      json = JSON.parse(json);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return json;
  } else if (isObjectNotArray(json)) {
    if (json.id && isString(json.id) && json.id.startsWith("%%")) {
      let token = json.id;
      let key = token.substring(2);
      if (Object.prototype.hasOwnProperty.call(variables, key)) {
        let value = variables[key];
        if (!isNull(value)) {
          json.id = value;
        }
      }
    } else if (json.val && isString(json.val) && json.val.startsWith("%%")) {
      let token = json.val;
      let key = token.substring(2);
      if (Object.prototype.hasOwnProperty.call(variables, key)) {
        let value = variables[key];
        if (!isNull(value)) {
          json.val = value;
        }
      }
    }
  }
  return json;
}

export function arrayToCSV(lineArray) {
  let retVal = Papa.unparse(lineArray);
  //console.log("arrayToCsv: ", retVal, lineArray)
  return retVal;
}

export function parseCSV(str) {
  let retVal = Papa.parse(str);
  //console.log("parseCSV: ", retVal, str)
  return retVal.data;
}

export function fieldAccessor(row, field) {
  if (field.type === "id") return row.id;
  if (field.type === "json" || field.type === "chart") {
    let value = row[field.fieldName];
    if (isNull(value)) {
      return null;
    } else {
      let parsedValue = null;
      try {
        parsedValue = JSON.parse(value);
        // eslint-disable-next-line no-empty
      } catch (ex) {}
      if (Array.isArray(parsedValue)) {
        if (parsedValue.length > 0 && parsedValue[0].name) {
          return arrayToCSV([parsedValue.map((a) => a.name)]);
        } else {
          return value;
        }
      } else {
        return parsedValue ? JSON.stringify(parsedValue) : null;
      }
    }
  } else {
    return row[field.fieldName];
  }
}

function renderLink(relativePath, itemAsString) {
  let path = relativePath ? relativePath.toLowerCase() : "";
  let title = itemAsString + "";
  if (window.location.pathname.toLowerCase().endsWith(path)) {
    return <div style={{ flex: 1 }}>{title}</div>;
  } else {
    return <Link to={path}>{title}</Link>;
  }
}

export function renderFieldValue(
  props,
  field,
  value,
  lookupField,
  includeAdditional = true,
  lookupHints = null,
  inHTML = false,
  nullToken = "",
  forExport = false
) {
  let retVal = value;
  if (!isNull(field)) {
    if (field.lookup) {
      let definition = getLookupDefinition(props, field);
      if (lookupHints) {
        if (field.type === "array") {
          retVal = [];
          for (let i = 0; i < lookupHints.length; i++) {
            let itemAsString = convertLookup(
              props,
              definition,
              [lookupHints[i]],
              field,
              lookupField,
              includeAdditional
            )[0].name;
            if (inHTML) {
              if (isWeb) {
                retVal.push(
                  renderLink(
                    "/entities/" +
                      lowerCasePluralName(definition) +
                      "/" +
                      lookupHints[i].id.toString(),
                    itemAsString
                  )
                );
              } else {
                retVal.push(
                  <TouchableOpacity
                    style={{ flex: 1 }}
                    key={"link"}
                    onPress={() => {
                      props.history.push(
                        "/entities/" +
                          lowerCasePluralName(definition) +
                          "/" +
                          lookupHints[i].id.toString()
                      );
                    }}
                  >
                    <BlueTypography variant="body1">
                      {itemAsString + ""}
                    </BlueTypography>
                  </TouchableOpacity>
                );
              }
            } else {
              retVal.push(itemAsString);
            }
          }
          if (retVal.length === 0) {
            retVal = "";
          } else {
            if (inHTML) {
              if (isWeb) {
                return <div style={{ flex: 1 }}>{retVal}</div>;
              } else {
                return <View style={{ flex: 1 }}>{retVal}</View>;
              }
            } else {
              retVal = arrayToCSV([retVal]);
            }
          }
        } else {
          let itemAsString = convertLookup(
            props,
            definition,
            [lookupHints],
            field,
            lookupField,
            includeAdditional
          )[0].name;
          if (inHTML) {
            if (isWeb) {
              return renderLink(
                "/entities/" +
                  lowerCasePluralName(definition) +
                  "/" +
                  lookupHints.id.toString(),
                itemAsString
              );
            } else {
              return (
                <TouchableOpacity
                  style={{ flex: 1 }}
                  key={"link_" + field.fieldName}
                  onPress={() => {
                    props.history.push(
                      "/entities/" +
                        lowerCasePluralName(definition) +
                        "/" +
                        lookupHints.id.toString()
                    );
                  }}
                >
                  <BlueTypography variant="body1">
                    {itemAsString + ""}
                  </BlueTypography>
                </TouchableOpacity>
              );
            }
          } else {
            retVal = itemAsString;
          }
        }
      } else {
        let lookup = getLookup(
          props,
          field,
          false,
          lookupField,
          includeAdditional
        );
        if (field.type === "array") {
          retVal = [];
          if (!isNull(value)) {
            for (let i = 0; i < lookup.length; i++) {
              let lookupValue = lookup[i];
              for (let j = 0; j < value.length; j++) {
                if (lookupValue.id === value[j]) {
                  if (inHTML) {
                    if (isWeb) {
                      retVal.push(
                        renderLink(
                          "/entities/" +
                            lowerCasePluralName(definition) +
                            "/" +
                            lookupValue.id.toString(),
                          lookupValue.name
                        )
                      );
                    } else {
                      retVal.push(
                        <TouchableOpacity
                          style={{ flex: 1 }}
                          key={"link"}
                          onPress={() => {
                            props.history.push(
                              "/entities/" +
                                lowerCasePluralName(definition) +
                                "/" +
                                lookupValue.id.toString()
                            );
                          }}
                        >
                          <BlueTypography variant="body1">
                            {lookupValue.name}
                          </BlueTypography>
                        </TouchableOpacity>
                      );
                    }
                  } else {
                    retVal.push(lookupValue.name);
                  }
                }
              }
            }
          }
          if (retVal.length === 0) {
            retVal = "";
          } else {
            if (inHTML) {
              if (isWeb) {
                return <div style={{ flex: 1 }}>{retVal}</div>;
              } else {
                return <View style={{ flex: 1 }}>{retVal}</View>;
              }
            } else {
              retVal = arrayToCSV([retVal]);
            }
          }
        } else {
          //console.log(">>> props ", props)
          //console.log(">>> lookup ", lookup)
          for (let i = 0; i < lookup.length; i++) {
            if (lookup[i].id === value) {
              retVal = lookup[i].name;
              if (inHTML) {
                if (isWeb) {
                  return renderLink(
                    "/entities/" +
                      lowerCasePluralName(definition) +
                      "/" +
                      value.toString(),
                    retVal
                  );
                } else {
                  return (
                    <TouchableOpacity
                      style={{ flex: 1 }}
                      key={"link"}
                      onPress={() => {
                        props.history.push(
                          "/entities/" +
                            lowerCasePluralName(definition) +
                            "/" +
                            value.toString()
                        );
                      }}
                    >
                      <BlueTypography variant="body1">{retVal}</BlueTypography>
                    </TouchableOpacity>
                  );
                }
              }
              break;
            }
          }
        }
      }
    } else {
      if (field.type === "boolean") {
        if (retVal === true) {
          retVal = "Yes";
        } else if (retVal === false) {
          retVal = "No";
        }
        if (inHTML) {
          if (!isWeb) {
            retVal = (
              <BlackTypography variant="body1">{retVal}</BlackTypography>
            );
          }
        }
      } else if (
        field.type === "string" &&
        (field.format === "date" || field.format === "date-time")
      ) {
        if (!isNull(retVal) && !forExport) {
          if (field.format === "date") {
            retVal = moment(retVal).format("DD/MM/YYYY");
          } else {
            retVal = moment(retVal).format("DD/MM/YYYY HH:mm:ss.SSS A");
          }
        }
        if (inHTML) {
          if (!isWeb) {
            retVal = (
              <BlackTypography variant="body1">{retVal + ""}</BlackTypography>
            );
          }
        }
      } else if (
        field.type === "string" &&
        field.format === "date-time-range"
      ) {
        //[2020-08-07 13:00:00,2020-08-07 13:30:00)
        //5/8/20 11:30 - 1:00pm
        if (!isEmpty(retVal)) {
          let parts = retVal.split(",");
          if (parts.length === 2) {
            let fromDate = parts[0].length > 1 ? parts[0].substring(1) : "";
            let toDate =
              parts[1].length > 1
                ? parts[1].substring(0, parts[1].length - 1)
                : "";
            let fromDateMoment = isEmpty(fromDate) ? null : moment(fromDate);
            let toDateMoment = isEmpty(toDate) ? null : moment(toDate);
            if (isNull(toDateMoment) && isNull(fromDateMoment)) {
              retVal = "";
            } else if (isNull(fromDateMoment)) {
              retVal = " - " + toDateMoment.format("D/M/YYYY h:mm A");
            } else if (isNull(toDateMoment)) {
              retVal = fromDateMoment.format("D/M/YYYY h:mm A") + " - ";
            } else {
              if (
                fromDateMoment.format("D/M/YYYY") ===
                toDateMoment.format("D/M/YYYY")
              ) {
                retVal =
                  fromDateMoment.format("D/M/YYYY h:mm A") +
                  " - " +
                  toDateMoment.format("h:mm A");
              } else {
                retVal =
                  fromDateMoment.format("D/M/YYYY h:mm A") +
                  " - " +
                  toDateMoment.format("D/M/YYYY h:mm A");
              }
            }
          }
        }
        if (inHTML) {
          if (!isWeb) {
            retVal = (
              <BlackTypography variant="body1">{retVal + ""}</BlackTypography>
            );
          }
        }
      } else if (field.widget === "currency") {
        if (!forExport) {
          if (!isNull(retVal)) {
            try {
              retVal =
                field.type === "integer"
                  ? formatDollars(retVal)
                  : formatDollarsAndCents(retVal);
            } catch {
              console.log("Error on formatDollars");
            }
          }
        }
        if (inHTML) {
          if (!isWeb) {
            retVal = (
              <BlackTypography variant="body1">{retVal + ""}</BlackTypography>
            );
          }
        }
      } else if (field.widget === "minutes") {
        if (!forExport) {
          if (!isNull(retVal)) {
            try {
              retVal = formatMinutes(retVal);
            } catch {
              console.log("Error on formatMinutes");
            }
          }
        }
        if (inHTML) {
          if (!isWeb) {
            retVal = (
              <BlackTypography variant="body1">{retVal + ""}</BlackTypography>
            );
          }
        }
      } else if (field.type === "file") {
        if (!isNull(retVal)) {
          if (forExport) {
            retVal = value;
          } else {
            //let jsonValue = JSON.parse(value);
            //retVal = jsonValue.fileName + " (" + jsonValue.data + ")";

            let fileVal = JSON.parse(value);
            let fileTitle = isEmpty(fileVal.fileName)
              ? "<Unknown>"
              : fileVal.fileName;
            if (fileVal.fileSize > 0) {
              fileTitle += " (" + fileVal.fileSize.toString() + " bytes)";
            }
            if (!isEmpty(fileVal.entityName) && inHTML) {
              let fileEntityFactory = getEntityFactory(
                props?.allEntityFactories,
                fileVal.entityName
              );
              if (
                fileEntityFactory &&
                fileEntityFactory.definition &&
                fileEntityFactory.definition.pluralName &&
                !isEmpty(fileEntityFactory.definition.pluralName.toLowerCase())
              ) {
                if (isWeb) {
                  retVal = (
                    <Link
                      to={
                        "/files/" +
                        lowerCasePluralName(fileEntityFactory.definition) +
                        "/" +
                        fileVal.id
                      }
                    >
                      {fileTitle}
                    </Link>
                  );
                } else {
                  retVal = (
                    <TouchableOpacity
                      style={{ flex: 1 }}
                      key={"link"}
                      onPress={() => {
                        props.history.push(
                          "/files/" +
                            lowerCasePluralName(fileEntityFactory.definition) +
                            "/" +
                            fileVal.id.toString()
                        );
                      }}
                    >
                      <BlueTypography variant="body1">
                        {fileTitle}
                      </BlueTypography>
                      ;
                    </TouchableOpacity>
                  );
                }
              } else {
                retVal = fileTitle;
                if (!isWeb) {
                  retVal = (
                    <BlackTypography variant="body1">
                      {retVal + ""}
                    </BlackTypography>
                  );
                }
              }

              return retVal;
            } else {
              retVal = fileTitle;
              if (inHTML) {
                if (!isWeb) {
                  retVal = (
                    <BlackTypography variant="body1">
                      {retVal + ""}
                    </BlackTypography>
                  );
                }
              }
            }
          }
        }
      } else if (
        field.type === "array" &&
        field.inPlace &&
        field.definition &&
        field.definition.fields.length > 0 &&
        !isEmpty(value)
      ) {
        let jsonValue = JSON.parse(value);

        retVal = [];
        let titles = [];
        let childFields = definitionHelper.findAllDisplayFields(
          field.definition.fields
        );
        for (let i = 0; i < jsonValue.length; i++) {
          let current = jsonValue[i];
          let val = {};
          for (let j = 0; j < childFields.length; j++) {
            let childField = childFields[j];
            //Try and use title ... but if there is a conflict use fieldName
            let name = !isEmpty(childField.title)
              ? isNull(val[childField.title])
                ? childField.title
                : childField.fieldName
              : childField.fieldName;
            if (i === 0) titles.push(name);
            if (!isEmpty(current[childField.fieldName])) {
              if (
                childField.type === "array" ||
                childField.type === "boolean" ||
                !isEmpty(childField.lookup)
              ) {
                val[name] = renderFieldValue(
                  props,
                  childField,
                  current[childField.fieldName],
                  null,
                  true,
                  null,
                  inHTML,
                  nullToken,
                  forExport
                );
              } else {
                if (childField.type === "file") {
                  if (forExport) {
                    val[name] = current[childField.fieldName];
                  } else {
                    let fileVal = JSON.parse(current[childField.fieldName]);
                    let fileTitle = isEmpty(fileVal.fileName)
                      ? "<Unknown>"
                      : fileVal.fileName;
                    if (fileVal.fileSize > 0) {
                      fileTitle +=
                        " (" + fileVal.fileSize.toString() + " bytes)";
                    }
                    if (!isEmpty(fileVal.entityName)) {
                      let fileEntityFactory = getEntityFactory(
                        props?.allEntityFactories,
                        fileVal.entityName
                      );
                      if (
                        inHTML &&
                        fileEntityFactory &&
                        fileEntityFactory.definition &&
                        fileEntityFactory.definition.pluralName &&
                        !isEmpty(
                          fileEntityFactory.definition.pluralName.toLowerCase()
                        )
                      ) {
                        if (isWeb) {
                          val[name] = (
                            <Link
                              to={
                                "/files/" +
                                lowerCasePluralName(
                                  fileEntityFactory.definition
                                ) +
                                "/" +
                                fileVal.id
                              }
                            >
                              {fileTitle}
                            </Link>
                          );
                        } else {
                          val[name] = (
                            <TouchableOpacity
                              style={{ flex: 1 }}
                              key={"link"}
                              onPress={() => {
                                props.history.push(
                                  "/files/" +
                                    lowerCasePluralName(
                                      fileEntityFactory.definition
                                    ) +
                                    "/" +
                                    fileVal.id.toString()
                                );
                              }}
                            >
                              <BlueTypography variant="body1">
                                {fileTitle}
                              </BlueTypography>
                              ;
                            </TouchableOpacity>
                          );
                        }
                      } else {
                        if (inHTML) {
                          if (!isWeb) {
                            fileTitle = (
                              <BlackTypography variant="body1">
                                {fileTitle + ""}
                              </BlackTypography>
                            );
                          }
                        }
                        val[name] = fileTitle;
                      }
                    } else {
                      if (inHTML) {
                        if (!isWeb) {
                          fileTitle = (
                            <BlackTypography variant="body1">
                              {fileTitle + ""}
                            </BlackTypography>
                          );
                        }
                      }
                      val[name] = fileTitle;
                    }
                  }
                } else {
                  val[name] = current[childField.fieldName];
                  if (inHTML) {
                    if (!isWeb) {
                      val[name] = (
                        <BlackTypography variant="body1">
                          {val[name] + ""}
                        </BlackTypography>
                      );
                    }
                  }
                }
              }
            }
          }
          retVal.push(val);
        }
        if (isEmpty(retVal)) {
          retVal = "";
        } else {
          if (inHTML) {
            let rows = [];
            for (let i = 0; i < retVal.length; i++) {
              let item = retVal[i];
              let columns = [];
              for (let j = 0; j < titles.length; j++) {
                let itemVal = item[titles[j]];
                if (isNull(itemVal)) {
                  if (isWeb) {
                    columns.push(<td key={"item_" + i + "_" + j} />);
                  } else {
                    columns.push(<View key={"item_" + i + "_" + j} />);
                  }
                } else {
                  if (isWeb) {
                    columns.push(
                      <td key={"item_" + i + "_" + j}>{itemVal}</td>
                    );
                  } else {
                    columns.push(
                      <View key={"item_" + i + "_" + j}>{itemVal}</View>
                    );
                  }
                }
              }
              if (isWeb) {
                rows.push(<tr key={"col_" + i}>{columns}</tr>);
              } else {
                rows.push(<View key={"col_" + i}>{columns}</View>);
              }
            }
            if (isWeb) {
              return (
                <table>
                  <thead>
                    <tr>
                      {titles.map((title) => (
                        <th key={title}>{title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              );
            } else {
              return (
                <View style={{ flex: 1 }}>
                  {titles.map((title) => (
                    <BlackTypography key={title} variant="body1">
                      {title}
                    </BlackTypography>
                  ))}
                  {rows}
                </View>
              );
            }
            /*
              FIRST LINE IS HEADER 
              <tbody>
                  <tr>
                    {titles.map(title => (
                      <td>{title}</td>
                    ))}             
                  </tr>    
                  {retVal.map(item => (
                    <tr>
                      <td>{field.title}</td>
                      <td>{this.renderValue(field.fieldName, row.row[field.fieldName] === undefined ? this.fieldAccessor(row.original, field) : row.row[field.fieldName], null, field.lookup ? row.original.lookups[field.fieldName] : null)}</td>
                    </tr>
                  ))}                 
              </tbody>
              */
          } else {
            retVal = JSON.stringify(retVal);
            retVal = retVal.substring(1, retVal.length - 1);
          }
        }
      }
    }
  }
  if (isNull(retVal)) {
    retVal = nullToken;
  } else {
    retVal = retVal.toString();
  }
  return retVal;
}

export function getLookupDefinition(props, field) {
  let factory = getLookupFactory(props, field);
  return isNull(factory) ? null : factory.definition;
}

export function filterCollection(primaryCollection, filterArray) {
  if (!isNull(filterArray)) {
    let filteredCollection = [];
    if (primaryCollection) {
      for (let i = 0; i < primaryCollection.length; i++) {
        let entity = primaryCollection[i];

        let match = false;
        for (let i = 0; i < filterArray.length; i++) {
          let filter = filterArray[i];
          let arrayKey = null;
          for (let key in filter) {
            if (Object.prototype.hasOwnProperty.call(filter, key)) {
              if (
                Array.isArray(filter[key].val) &&
                filter[key].val.length > 1
              ) {
                arrayKey = key;
                break;
              }
            }
          }
          if (!isNull(arrayKey)) {
            match = filter[arrayKey].val.indexOf(entity[arrayKey]) >= 0;
          } else {
            match = true;
            for (let key in filter) {
              if (Object.prototype.hasOwnProperty.call(filter, key)) {
                let value = filter[key].val;
                if (Array.isArray(value)) {
                  if (value.length === 1) {
                    value = value[0];
                  } else {
                    value = null;
                  }
                }
                if (isNull(value) || entity[key] !== value) {
                  match = false;
                  break;
                }
              }
            }
          }
          if (match) {
            //Found a filter match (they are or'd together) so break.
            break;
          }
        }
        if (match) {
          filteredCollection.push(entity);
        }
      }
    }
    return filteredCollection;
  } else {
    return isNull(primaryCollection) ? [] : primaryCollection;
  }
}

export function getLookupFactory(props, field) {
  let factory = null;
  if (props?.allEntityFactories) {
    factory = findLookupFactory(props.allEntityFactories, field);
    if (factory === undefined) factory = null;
  }
  return factory;
}
export function findLookupFactory(allEntityFactories, field) {
  let factory = undefined;
  if (allEntityFactories && field) {
    let lookupName = field.lookup;
    factory = allEntityFactories.find(
      (entityFactory) => entityFactory.entityName() === lookupName
    );
  }
  return factory;
}

export function getEntityFactory(allEntityFactories, entityName) {
  let factory = null;
  if (allEntityFactories) {
    factory = findEntityFactory(allEntityFactories, entityName);
    if (factory === undefined) factory = null;
  }
  return factory;
}

export function findEntityFactory(allEntityFactories, entityName) {
  let factory = undefined;
  if (allEntityFactories && entityName) {
    let lcaseName = entityName.toLowerCase();
    factory = allEntityFactories.find(
      (entityFactory) => entityFactory.entityName().toLowerCase() === lcaseName
    );
  }
  return factory;
}

export function updateSubQueriesWithLookupIds(
  props,
  queryResults,
  lookupFields,
  subQueries,
  isArray = false,
  inPlace = false,
  parentField = null
) {
  for (let i = 0; i < lookupFields.length; i++) {
    let lookupField = lookupFields[i];
    let lookupFactory = getLookupFactory(props, lookupField);
    if (lookupFactory && !lookupFactory.cacheable()) {
      let lookup = getLookup(
        props,
        lookupField,
        true,
        null,
        true,
        false,
        null,
        true
      );
      let lookupIds = [];
      for (let j = 0; j < queryResults.length; j++) {
        let row = queryResults[j];
        let ids = [];
        if (isArray) {
          if (inPlace) {
            if (!isEmpty(row[parentField.fieldName])) {
              let fieldArray = JSON.parse(row[parentField.fieldName]);
              for (let k = 0; k < fieldArray.length; k++) {
                let lookupValue = fieldArray[k][lookupField.fieldName];
                if (!isEmpty(lookupValue)) {
                  if (lookupField.type === "array") {
                    //Don't attempt to check for another inplace.  This is good enough.
                    ids.push(...lookupValue);
                  } else {
                    ids.push(lookupValue);
                  }
                }
              }
            }
          } else {
            let lookupIds = row[lookupField.fieldName];
            if (!isEmpty(lookupIds)) {
              ids.push(...lookupIds);
            }
          }
        } else {
          let lookupId = row[lookupField.fieldName];
          if (!isNull(lookupId)) {
            ids.push(lookupId);
          }
        }
        for (let k = 0; k < ids.length; k++) {
          if (lookupIds.indexOf(ids[k]) < 0) {
            let exists = false;
            for (let l = 0; l < lookup.length; l++) {
              if (lookup[l].id === ids[k]) {
                exists = true;
                break;
              }
            }
            if (!exists) {
              lookupIds.push(ids[k]);
            }
          }
        }
      }
      if (lookupIds.length > 0) {
        let subQuery = null;
        for (let j = 0; j < subQueries.length; j++) {
          if (subQueries[j].entityFactory === lookupFactory) {
            subQuery = subQueries[j];
            break;
          }
        }
        if (isNull(subQuery)) {
          let subQuery = {};
          let filter = {};
          filter["id"] = { val: lookupIds };
          subQuery.filter = [filter];
          subQuery.entityFactory = lookupFactory;
          subQueries.push(subQuery);
        } else {
          let currentFilter = null;
          for (let j = 0; j < subQuery.filter.length; j++) {
            let filter = subQuery.filter[j];
            if (Object.prototype.hasOwnProperty.call(filter, "id")) {
              currentFilter = filter;
              break;
            }
          }
          if (currentFilter) {
            let filter = {};
            filter["id"] = { val: lookupIds };
            subQuery.filter.push(filter);
          } else {
            //Merge the arrays
            let newFilterArray = currentFilter["id"].val.concat(lookupIds);
            for (let j = 0; j < newFilterArray.length; j++) {
              for (let k = j + 1; k < newFilterArray.length; k++) {
                if (newFilterArray[j] === newFilterArray[k]) {
                  newFilterArray.splice(k--, 1);
                }
              }
            }
            currentFilter["id"].val = newFilterArray;
          }
        }
      }
    }
  }
}

export function groupAndConvertLookup(props, field, lookup, groupFieldName) {
  let lookupDefinition = getLookupDefinition(props, field);
  if (isEmpty(groupFieldName)) {
    return convertLookup(props, lookupDefinition, lookup, field, null, true);
  } else {
    let groupField = definitionHelper.findField(
      lookupDefinition.fields,
      groupFieldName
    );
    if (isNull(groupField)) {
      return convertLookup(props, lookupDefinition, lookup, field, null, true);
    } else {
      let groupItems = getLookup(
        props,
        groupField,
        false,
        null,
        true,
        false,
        null,
        true,
        true
      );
      let grouping = {};
      grouping["-1"] = [];
      for (let i = 0; i < groupItems.length; i++) {
        let groupItem = groupItems[i];
        grouping[groupItem.id.toString()] = [];
      }
      for (let i = 0; i < lookup.length; i++) {
        let row = lookup[i];
        let groupVal = row[groupFieldName];
        if (isNull(groupVal)) {
          grouping["-1"].push(row);
        } else {
          if (isNull(grouping[groupVal.toString()])) {
            console.log(
              "ERROR!  MISSING ITEM FROM LOOKUP!!!!",
              groupVal.toString(),
              field
            );
            groupVal = "-1";
          }
          grouping[groupVal.toString()].push(row);
        }
      }
      let groupedFixedOptions = [];
      for (let i = 0; i < groupItems.length; i++) {
        let groupItem = groupItems[i];
        let groupIdString = groupItem.id.toString();
        let groupedItems = grouping[groupIdString];
        if (!isEmpty(groupedItems)) {
          groupedFixedOptions.push({
            label: groupItem.name,
            options: convertLookup(
              props,
              lookupDefinition,
              grouping[groupIdString],
              field,
              null,
              true,
              groupFieldName
            ),
          });
        }
      }
      if (!isEmpty(grouping["-1"])) {
        groupedFixedOptions.push({
          label: "UNKNOWN",
          options: convertLookup(
            props,
            lookupDefinition,
            grouping["-1"],
            field,
            null,
            true,
            groupFieldName
          ),
        });
      }
      return groupedFixedOptions;
    }
  }
}

export function filterLookup(props, lookup, field, currentItem) {
  for (let key in field.lookupFilter) {
    let newLookup = [];
    if (Object.prototype.hasOwnProperty.call(field.lookupFilter, key)) {
      let value = cloneDeep(field.lookupFilter[key]);
      if (currentItem) {
        value = replaceAndDecode(value, currentItem);
      } else if (props.item) {
        value = replaceAndDecode(value, props.item);
      }
      if (!isNull(value)) {
        if (value.val !== undefined) {
          let filterValue = value.val;
          if (isNull(filterValue) || filterValue.toString().indexOf("%%") < 0) {
            for (let i = 0; i < lookup.length; i++) {
              let row = lookup[i];
              if (isNull(filterValue)) {
                if (isNull(row[key])) {
                  newLookup.push(row);
                }
              } else {
                if (Array.isArray(filterValue)) {
                  if (filterValue.indexOf(row[key]) >= 0) {
                    newLookup.push(row);
                  }
                } else {
                  if (row[key] === filterValue) {
                    newLookup.push(row);
                  }
                }
              }
            }
          } else if (filterValue.toString().indexOf("%%") === 0) {
            //Later,ignore for now.  It's OK you have rights.
            newLookup = lookup;
          }
        } else if (value.id !== undefined) {
          let filterValue = value.id;
          if (isNull(filterValue) || filterValue.toString().indexOf("%%") < 0) {
            let proxyLayout = value.proxyTable;
            let proxyEntityField = value.proxyEntityField;
            let proxyAgainstField = value.proxyAgainstField;
            let proxyDefinition = getEntityFactory(
              props?.allEntityFactories,
              proxyLayout
            );
            if (proxyDefinition) {
              proxyDefinition = proxyDefinition.definition;
              proxyLayout = "layoutGraph.all" + proxyDefinition.pluralName;
            }
            let proxyParts = proxyLayout.split(".");
            let proxyLookup = props; //Yes technically correct!  Layoutgraph is in props.
            for (let i = 0; i < proxyParts.length; i++) {
              proxyLookup = proxyLookup[proxyParts[i]];
            }
            if (proxyLookup && proxyLookup.length > 0) {
              for (let i = 0; i < lookup.length; i++) {
                let row = lookup[i];
                let match = false;
                for (let j = 0; j < proxyLookup.length; j++) {
                  let proxyRow = proxyLookup[j];
                  if (
                    proxyRow[proxyEntityField] === filterValue &&
                    proxyRow[proxyAgainstField] === row[key]
                  ) {
                    match = true;
                    break;
                  }
                }
                if (match) {
                  newLookup.push(row);
                }
              }
            }
          } else if (filterValue.toString().indexOf("%%") === 0) {
            //Later,ignore for now.  It's OK you have rights.
            newLookup = lookup;
          }
        }
      }
    }
    lookup = newLookup;
  }
  return lookup;
}

export function getEntityCollection(props, definition) {
  let lookup = null;
  if (definition) {
    let lookupPathString = "layoutGraph.all" + definition.pluralName;

    let parts = lookupPathString.split(".");
    lookup = props; //Yes technically correct!  Layoutgraph is in props.
    for (let i = 0; i < parts.length; i++) {
      lookup = lookup[parts[i]];
    }
  }
  return lookup;
}

function sortLookup(lookup, sortFields) {
  let newLookup = lookup.slice();
  newLookup.sort(function (a, b) {
    let result = 0;
    for (let i = 0; i < sortFields.length; i++) {
      let lookupField = sortFields[i];
      let aVal = a[lookupField.fieldName];
      let bVal = b[lookupField.fieldName];
      if (isNull(aVal)) {
        if (isNull(bVal)) {
          //Do nothing ... go to next field;
        } else {
          return 1;
        }
      } else if (isNull(bVal)) {
        return -1;
      } else {
        if (lookupField.type === "string") {
          result = aVal.localeCompare(bVal);
          if (result !== 0) {
            return result;
          }
        } else if (
          lookupField.type === "integer" ||
          lookupField.type === "number"
        ) {
          result = aVal - bVal;
          if (result !== 0) {
            return result;
          }
        }
      }
    }

    return result;
  });
  return newLookup;
}

export function getLookup(
  props,
  field,
  rawLookup = false,
  lookupField = null,
  includeAdditional = true,
  silent = false,
  currentItem = null,
  ignoreFilter = false,
  sort = false,
  ignoreField = undefined
) {
  let lookupPathString = field.lookup;
  let definition = getLookupDefinition(props, field);
  //if (!silent) {
  //  console.log("getting lookup");
  //}
  if (definition) {
    lookupPathString = "layoutGraph.all" + definition.pluralName;
  }

  let parts = lookupPathString.split(".");
  let lookup = props; //Yes technically correct!  Layoutgraph is in props.
  for (let i = 0; i < parts.length; i++) {
    lookup = lookup[parts[i]];
  }
  if (lookup && field.lookupFilter && !ignoreFilter) {
    lookup = filterLookup(props, lookup, field, currentItem);
  }
  if (isNull(lookup)) {
    lookup = [];
  }

  if (sort) {
    let sortFields = null;
    if (isEmpty(field.sortFields)) {
      sortFields = getPrimaryFieldsFromLookupField(
        definition,
        field,
        lookupField,
        includeAdditional,
        true
      );
    } else {
      sortFields = [];
      if (!isEmpty(field.sortFields)) {
        for (let i = 0; i < field.sortFields.length; i++) {
          let fieldName = field.sortFields[i];
          if (
            !isNull(definition) &&
            !isEmpty(definition.fields) &&
            !isEmpty(fieldName)
          ) {
            let lfield = definitionHelper.findField(
              definition.fields,
              fieldName
            );
            if (!isNull(lfield)) {
              sortFields.push(lfield);
            } else {
              sortFields.push({ fieldName: fieldName });
            }
          }
        }
      }
    }
    lookup = sortLookup(lookup, sortFields);
  }
  if (rawLookup) {
    return lookup;
  } else {
    return convertLookup(
      props,
      definition,
      lookup,
      field,
      lookupField,
      includeAdditional,
      ignoreField
    );
  }
}

export function getPrimaryFieldsFromLookupField(
  definition,
  field,
  overridePrimaryField,
  includeAdditional,
  addIdIfNone = false,
  ignoreField = null
) {
  let primaryFields = [];
  if (isNull(overridePrimaryField)) {
    if (isNull(definition)) {
      primaryFields.push({ fieldName: "name" });
    } else {
      primaryFields = definitionHelper.findPrimaryFields(definition, []);
      if (primaryFields.length === 0) {
        if (addIdIfNone) {
          primaryFields.push({
            fieldName: "id",
            type: definition.guidId ? "string" : "integer",
          });
        }
        if (includeAdditional) {
          if (field.additionalOnly && !isEmpty(field.lookupFields)) {
            primaryFields = [
              ...definitionHelper.findFields(definition, field.lookupFields),
            ];
          } else {
            primaryFields.push(
              ...definitionHelper.findPrimaryFields(
                definition,
                field.lookupFields
              )
            );
          }
        }
      } else if (includeAdditional) {
        if (field.additionalOnly && !isEmpty(field.lookupFields)) {
          primaryFields = [
            ...definitionHelper.findFields(definition, field.lookupFields),
          ];
        } else {
          primaryFields = definitionHelper.findPrimaryFields(
            definition,
            field.lookupFields
          );
        }
      }
    }
  } else {
    if (!isNull(definition) && !isEmpty(definition.fields)) {
      let lfield = definitionHelper.findField(
        definition.fields,
        overridePrimaryField
      );
      if (!isNull(lfield)) {
        primaryFields.push(lfield);
      } else {
        primaryFields.push({ fieldName: overridePrimaryField });
      }
    } else {
      primaryFields.push({ fieldName: overridePrimaryField });
    }
  }
  if (!isEmpty(ignoreField)) {
    let fixedPrimaryFields = [];
    for (let i = 0; i < primaryFields.length; i++) {
      let primaryField = primaryFields[i];
      if (primaryField.fieldName !== ignoreField) {
        fixedPrimaryFields.push(primaryField);
      }
    }
    primaryFields = fixedPrimaryFields;
  }
  return primaryFields;
}

export function includeIDInLookupConversion(
  field,
  primaryFields,
  includeAdditional
) {
  if (field.additionalOnly) {
    return false;
  } else {
    let lookupFields = isNull(field.lookupFields) ? [] : field.lookupFields;
    return (
      primaryFields.length === 0 ||
      (includeAdditional && primaryFields.length === lookupFields.length)
    );
  }
}

export function buildLookup(
  props,
  lookupCollection,
  primaryFields,
  startWithId,
  includeAdditional
) {
  let convertedLookup = [];
  for (let i = 0; i < lookupCollection.length; i++) {
    let row = lookupCollection[i];
    let name = "";
    if (startWithId) {
      name = row.id.toString();
    }
    for (let j = 0; j < primaryFields.length; j++) {
      let primaryField = primaryFields[j];
      if (name.length > 0) {
        name += " - ";
      }
      if (isNull(row[primaryField.fieldName])) {
        name += "<Unknown>";
      } else {
        //lookupField ?
        //console.log("Rendering field ... ", primaryField)
        //console.log("Raw ... ", row[primaryField.fieldName])
        name += renderFieldValue(
          props,
          primaryField,
          row[primaryField.fieldName],
          null,
          includeAdditional,
          null,
          false
        );
        //console.log("Rendered ... ", renderFieldValue(props, primaryField, row[primaryField.fieldName], null, includeAdditional, null, false))
        //name += row[primaryField.fieldName].toString();
      }
    }

    convertedLookup.push({ id: row.id, name: name });
  }
  return convertedLookup;
}

export function convertLookup(
  props,
  definition,
  lookup,
  field,
  lookupField,
  includeAdditional,
  ignoreField
) {
  let primaryFields = getPrimaryFieldsFromLookupField(
    definition,
    field,
    lookupField,
    includeAdditional,
    false,
    ignoreField
  );
  let startWithId = includeIDInLookupConversion(
    field,
    primaryFields,
    includeAdditional
  );
  return buildLookup(
    props,
    lookup,
    primaryFields,
    startWithId,
    includeAdditional
  );
}

export function fieldHidden(currentUser, userField) {
  if (userField.hidden) return true;
  if (userField.type === "object") {
    let isHidden = true;
    for (let i = 0; i < userField.definition.fields.length; i++) {
      let childField = userField.definition.fields[i];
      if (!fieldHidden(currentUser, childField)) {
        isHidden = false;
        break;
      }
    }

    return isHidden;
  } else {
    let roles = userField.viewRoles;
    if (isNull(roles)) {
      return false;
    } else {
      return currentUser ? !hasCommonElement(roles, currentUser.roles) : true;
    }
  }
}

function getTriggerValue(currentUser, values, triggerField) {
  if (triggerField.startsWith("%%")) {
    if (triggerField === "%%isLoggedIn") {
      return currentUser && !isNull(currentUser.id) && currentUser.id !== 21;
    }
  } else {
    return values[triggerField];
  }
}

export function calcRuleState(
  currentUser,
  definition,
  values,
  applicableRules
) {
  let ruleState = {};
  applicableRules.forEach((applicableRule) => {
    if (isNull(applicableRule.trigger)) {
      ruleState[applicableRule.fieldName + "_null"] = evaluateTriggerState(
        currentUser,
        definition,
        values,
        applicableRule
      );
    } else {
      ruleState[
        applicableRule.fieldName + "_" + applicableRule.trigger.toString()
      ] = evaluateTriggerState(currentUser, definition, values, applicableRule);
    }
  });
  return ruleState;
}

export function getHideRuleState(currentUser, definition, values) {
  let rules = isNull(definition.rules) ? [] : definition.rules;
  let applicableRules = rules.filter((rule) => !isEmpty(rule.hide));
  return calcRuleState(currentUser, definition, values, applicableRules);
}

export function evaluateTriggerState(
  currentUser,
  definition,
  values,
  applicableRule
) {
  let triggerField = applicableRule.fieldName;
  let currentTriggerValue = getTriggerValue(currentUser, values, triggerField);
  let triggerOn = applicableRule.trigger;
  let triggerCheck = false;
  if (triggerOn === false) {
    triggerCheck = currentTriggerValue === false;
  } else if (triggerOn === true) {
    triggerCheck = currentTriggerValue === true;
  } else if (isNull(triggerOn)) {
    triggerCheck = isNull(currentTriggerValue);
  } else {
    let triggerFieldType = "string";
    if (!triggerField.startsWith("%%")) {
      let field = definitionHelper.findField(definition.fields, triggerField);
      if (field) {
        triggerFieldType = field.type;
      }
    }
    if (triggerFieldType === "integer") {
      let value = null;
      if (!isNull(triggerOn)) {
        triggerOn = triggerOn.toString();
        if (value.endsWith("%%not")) {
          triggerOn = triggerOn.substring(0, value.length - 5);
          if (!isEmpty(triggerOn)) {
            value = parseInt(triggerOn.toString(), 10);
            triggerCheck = currentTriggerValue !== value;
          }
        } else {
          if (!isEmpty(triggerOn)) {
            value = parseInt(triggerOn.toString(), 10);
            triggerCheck = currentTriggerValue === value;
          }
        }
      }
    } else if (triggerFieldType === "string") {
      let value = null;
      if (!isNull(triggerOn)) {
        value = triggerOn.toString();
        if (!isEmpty(value)) {
          if (value.endsWith("%%not")) {
            value = value.substring(0, value.length - 5);
            if (value === "%%version") {
              value = Constants.manifest.version;
            }
            triggerCheck = currentTriggerValue !== value;
          } else {
            if (value === "%%version") {
              value = Constants.manifest.version;
            }
            triggerCheck = currentTriggerValue === value;
          }
        }
      }
    }
  }
  return triggerCheck;
}

export function getWafelButtons(
  layoutGraph,
  entityFactory,
  location,
  hideWorkflowButtons
) {
  let workflows = [];
  let allWorkflows =
    layoutGraph && !isEmpty(layoutGraph.allWorkflows)
      ? layoutGraph.allWorkflows
      : [];
  for (let j = 0; j < allWorkflows.length; j++) {
    let workflow = allWorkflows[j];
    let add = false;
    let parent = null;
    let priority = 0;
    if (workflow.isEntityWorkflow) {
      if (
        entityFactory &&
        entityFactory.definition &&
        workflow.entityName === entityFactory.definition.entityName
      ) {
        add = !hideWorkflowButtons;
        if (add) {
          if (!isEmpty(workflow.locationHints)) {
            //Only makes sense currently to have one ... it's for the entity.
            priority = isNull(workflow.locationHints[0].priority)
              ? 0
              : workflow.locationHints[0].priority;
            let hintArray = workflow.locationHints[0].location;
            if (hintArray && hintArray.length > 1) {
              parent = isEmpty(hintArray[1]) ? null : hintArray[1];
            }
          }
        }
      }
    }
    if (!add && !workflow.isEntityWorkflow && !isEmpty(location)) {
      location = location.toLowerCase();
      for (let k = 0; k < workflow.locationHints.length; k++) {
        let hintArray = workflow.locationHints[k].location;
        if (hintArray && hintArray.length > 0) {
          let hint = hintArray[0].toLowerCase();
          if (hint === location) {
            if (hintArray.length > 1) {
              parent = hintArray[1];
            }
            priority = workflow.locationHints[k].priority;
            add = true;
            break;
          }
        }
      }
    }
    if (add) {
      workflows.push({ workflow, parent, priority, children: null });
    }
  }
  workflows.sort((a, b) => a.priority - b.priority);
  let root = {};

  for (let i = 0; i < workflows.length; i++) {
    let workflow = workflows[i].workflow;
    let parent = workflows[i].parent;
    if (!isEmpty(parent)) {
      let child = null;
      let title = isEmpty(workflow.title) ? workflow.name : workflow.title;
      child = { workflow: workflow, title };

      if (isNull(root[parent])) {
        root[parent] = [];
        workflows[i].children = root[parent];
      }
      root[parent].push(child);
    }
  }
  let wafelButtons = [];
  for (let i = 0; i < workflows.length; i++) {
    let workflow = workflows[i].workflow;
    let title = isEmpty(workflow.title) ? workflow.name : workflow.title;
    let parent = workflows[i].parent;
    let children = workflows[i].children;
    if (!isEmpty(parent)) {
      if (!isEmpty(children)) {
        let menuItems = [];
        let buttonMenu = {};
        for (let j = 0; j < children.length; j++) {
          let child = children[j];
          menuItems.push({
            url: "",
            menuNames: [parent, child.title],
            workflow: child.workflow,
          });
          buttonMenu[child.title] = [
            { url: "", menuNames: [child.title], workflow: child.workflow },
          ];
        }
        wafelButtons.push({
          buttonName: parent,
          action: undefined,
          buttonMenu: buttonMenu,
        });
      }
    } else {
      let action = { url: "", menuNames: [title], workflow: workflow };
      wafelButtons.push({
        icon: workflow.icon,
        buttonName: title,
        action: action,
        buttonMenu: undefined,
      });
    }
  }
  return wafelButtons;
}

export function getGlobalFilter() {
  let globalFilter = document.cookie
    ? document.cookie.replace(
        /(?:(?:^|.*;\s*)GlobalFilter\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      )
    : null;
  return globalFilter;
}
