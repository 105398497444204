import { isNull, isEmpty } from "../util/types.js";
import {
  AddressFinder,
  Alert,
  HTMLTemplate,
  TextInput,
  QRCode,
  SearchText,
  SearchNumber,
  SearchDate,
  SearchGeog,
  DatePicker,
  Checkbox,
  Toggle,
  ButtonToggle,
  Currency,
  Minutes,
  Radio,
  Calendar,
  PDF,
  SingleSelect,
  StringLabel,
  MultiSelect,
  FilePicker,
  ExcelPicker,
  MonthPicker,
  TimePicker,
  Chart,
  MapPicker,
  SVG,
  JSONMultiGrid,
  JSONSingleGrid,
  JSONGridImport,
  GridArray,
  RelativeLink,
  LinkButton,
  WafelButton,
  FirebaseLoginButton,
  JSONEditor,
  HTMLEditor,
  WYSIWYGEditor,
  YouTubePlayer,
} from "./widgets/index";

function getStringWidget(field) {
  if (field.widget === "label") return StringLabel;
  let Widget = undefined;
  //FORMATS: 'date', 'date-time', 'uppercase', 'email', 'date-time-range'
  //WIDGETS: date has alt-date
  //     date-time has alt-datetime
  //     no format: 'alert', 'textarea', 'password', 'qrcode', 'html', 'label'
  //
  // TODO: date-time-range
  //     alt-date
  //     alt-datetime
  //     textarea, qrcode, html, alert, label
  if (
    field.format === "email" ||
    (field.format === "uri" && isEmpty(field.widget)) ||
    field.widget === "password" ||
    field.format === "uppercase"
  ) {
    Widget = TextInput;
  } else if (field.format === "uri" && !isEmpty(field.widget)) {
    if (field.widget === "link") {
      Widget = RelativeLink;
    } else if (field.widget === "linkbutton") {
      Widget = LinkButton;
    } else {
      Widget = TextInput;
    }
  } else if (field.format === "date-time-range") {
    Widget = Alert; //TODO
  } else if (field.format === "date" || field.format === "date-time") {
    // 'date' (default === normal and alt-date === month picker),
    switch (field.widget) {
      case "alt-date":
        Widget = MonthPicker;
        break;
      case "alt-datetime":
        Widget = TimePicker;
        break;
      default:
        Widget = DatePicker;
    }
  } else {
    //'textarea', 'password', 'qrcode', 'html', 'alert'
    switch (field.widget) {
      case "password":
        Widget = TextInput;
        break;
      case "pdf":
        Widget = PDF;
        break;
      case "alert":
        Widget = Alert;
        break;
      case "label":
        Widget = StringLabel;
        break;
      case "textarea":
        Widget = TextInput;
        break;
      case "htmltemplate":
        Widget = HTMLTemplate;
        break;
      case "qrcode":
        Widget = QRCode;
        break;
      case "html":
        Widget = HTMLEditor;
        break;
      case "wysiwyg":
        Widget = WYSIWYGEditor;
        break;
      case "youtube":
        Widget = YouTubePlayer;
        break;
      default:
        Widget = TextInput;
    }
  }
  return Widget;
}

function getBooleanWidget(field) {
  let Widget = undefined;
  if (field.widget === "toggle") {
    Widget = Toggle;
  } else if (field.widget === "buttontoggle") {
    Widget = ButtonToggle;
  } else {
    Widget = Checkbox;
  }
  return Widget;
}

function getNumberWidget(field) {
  let Widget = undefined;
  if (field.widget === "currency") {
    Widget = Currency;
  } else if (field.widget === "label") {
    Widget = StringLabel;
  } else {
    if (field.format === "currency") {
      Widget = Currency;
    } else {
      //TextField "updown" widget
      Widget = TextInput;
    }
  }
  return Widget;
}

function getIntegerWidget(field) {
  let Widget = undefined;
  if (field.widget === "currency") {
    Widget = Currency;
  } else if (field.widget === "minutes") {
    Widget = Minutes;
  } else if (field.widget === "label") {
    Widget = StringLabel;
  } else if (field.widget === "radio" || field.widget === "inlineradio") {
    Widget = Radio;
  } else if (!isNull(field.lookup)) {
    Widget = SingleSelect;
  } else {
    //TextField does "range" and "updown" and "time" widget
    Widget = TextInput;
  }
  return Widget;
}

function getInPlaceJSONWidget(field) {
  if (field.widget === "JSONSingleSelectWidget") {
    return SingleSelect;
  } else if (field.widget === "JSONGridSingleSelectWidget") {
    return JSONSingleGrid;
  } else if (field.widget === "JSONImportWidget") {
    return JSONGridImport;
  } else {
    return JSONMultiGrid;
  }
}

function getSearchJSONWidget(field) {
  if (field.widget === "searchtext") {
    return SearchText;
  } else if (field.widget === "searchint") {
    return SearchNumber;
  } else if (field.widget === "searchfloat") {
    return SearchNumber;
  } else if (field.widget === "searchdate") {
    return SearchDate;
  } else if (field.widget === "searchgeog") {
    return SearchGeog;
  } else {
    return SearchText;
  }
}

export function getFormWidget(field) {
  return getWidget(true, field);
}

export function getWidget(isSearch, field) {
  let Widget = undefined;

  switch (field.type) {
    case "string":
      Widget = getStringWidget(field);
      break;
    case "boolean":
      Widget = getBooleanWidget(field);
      break;
    case "number":
      Widget = getNumberWidget(field);
      break;
    case "integer":
      Widget = getIntegerWidget(field);
      break;
    case "id":
      Widget = TextInput;
      break;
    case "file":
      Widget = FilePicker;
      break;
    case "chart":
      Widget = Chart;
      break;
    case "geog":
      Widget = MapPicker;
      break;
    case "calendar":
      Widget = Calendar;
      break;
    case "svg":
      Widget = SVG;
      break;
    case "array":
      if (field.inPlace) {
        Widget = GridArray;
      } else {
        //Only currently support multiselect ... must fix.
        if (field.widget === "checkboxes") {
          Widget = MultiSelect;
        } else if (field.widget === "inlinecheckboxes") {
          Widget = MultiSelect;
        } else {
          Widget = MultiSelect;
        }
      }
      break;
    case "json":
      if (field.inPlace) {
        Widget = getInPlaceJSONWidget(field);
      } else {
        if (isSearch) {
          Widget = getSearchJSONWidget(field);
        } else {
          if (field.widget === "ExcelImportWidget") {
            Widget = ExcelPicker;
          } else if (field.widget === "AddressFinder") {
            Widget = AddressFinder;
          } else {
            Widget = JSONEditor;
          }
        }
      }
      break;
    case "login":
      Widget = FirebaseLoginButton;
      break;
    case "button":
      Widget = WafelButton;
      break;
    default:
      Widget = TextInput;
  }
  return Widget;
}
