import { useState, useEffect, useCallback } from "react";

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function detectSafariOnIOS() {
  // https://stackoverflow.com/q/9038625/3569421
  if (navigator.userAgent.match("CriOS")) return false;
  return iOS();
}

// const isIOSOrSafari = detectIOSOrSafari();

// function adjustWindowSize() {
//   let widthCss = window.innerWidth,
//     heightCss = window.innerHeight;

//   if (document.documentElement && "clientWidth" in document.documentElement) {
//     widthCss = document.documentElement.clientWidth;
//     heightCss = document.documentElement.clientHeight;
//   }

//   if (isIOSOrSafari) {
//     let bodyRect = null;

//     // Another act out of desperation...
//     if (
//       document.documentElement &&
//       "getBoundingClientRect" in document.documentElement
//     )
//       bodyRect = document.documentElement.getBoundingClientRect();
//     else if ("getBoundingClientRect" in document.body)
//       bodyRect = document.body.getBoundingClientRect();

//     if (bodyRect) {
//       widthCss = bodyRect.right - bodyRect.left;
//       heightCss = bodyRect.bottom - bodyRect.top;
//     }
//   }

//   return { widthCss, heightCss };
//   // Rest of the code, where I use widthCss and heightCss to compute my canvas' size
// }

function useWindowSize() {
  //const isSafari = useMemo(() => detectSafariOnIOS(), []);
  const [state, setState] = useState({
    windowWidth: undefined,
    windowHeight: undefined,
  });

  const handleResize = useCallback(() => {
    let height = document.documentElement.clientHeight;
    let width = document.documentElement.clientWidth;
    //if (isSafari) height = height - 50;
    if (width !== state.windowWidth || height !== state.windowHeight) {
      setState({
        windowWidth: width,
        windowHeight: height,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    //window.addEventListener("orientationchange", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      //window.removeEventListener("orientationchange", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
}

export default useWindowSize;
