/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";

import definitionHelper from "../util/definitions.js";
import { isNull, getEntityFactory } from "../util/common.js";
import withCollection from "../hoc/withCollection.js";
import BulkGrid from "./bulkGrid";

const INITIAL_STATE = {
  WrappedBulkGrid: BulkGrid,
  fileContents: null,
  lookupErrors: [],
};

const BulkProcessor = ({
  layoutGraph,
  screenSize,
  allEntityFactories,
  keyFieldName,
  entityFactory,
  startLine,
  blankRows,
  startColumn,
  numColumns,
  onDrop,
  isWidget,
  publicStyle,
  showFullPageLink,
  importCollection,
  errorCollection,
  updateFields,
  height,
}) => {
  const [state, setState] = useState(INITIAL_STATE);
  const { WrappedBulkGrid, fileContents, lookupErrors } = state;

  const findLookups = useCallback(
    (lookupErrors, fileContents) => {
      //parentEntity.withFactoryAndDefinition(parentEntity.withFixedFilter(withCollection(BulkGrid), FIXEDFILTER))
      //Wrap this.state.bulkGrid with search.
      let bulkGrid = BulkGrid;
      console.log(">> FINDLOOKUP lookupErrors", lookupErrors);
      let hasKeys = false;
      for (let key in lookupErrors) {
        if (Object.prototype.hasOwnProperty.call(lookupErrors, key)) {
          hasKeys = true;
          break;
        }
      }
      if (hasKeys) {
        bulkGrid = entityFactory.withFactoryAndDefinition(bulkGrid);
        for (let entityKey in lookupErrors) {
          if (Object.prototype.hasOwnProperty.call(lookupErrors, entityKey)) {
            let entityName = entityKey;
            let entityErrors = lookupErrors[entityKey];
            let lookupFactory = getEntityFactory(
              allEntityFactories,
              entityName
            );
            let primaryFields = definitionHelper.findPrimaryFields(
              lookupFactory.definition
            );

            //TODO Type checking ... filter could be strings.  May need to convert.
            if (primaryFields.length === 1) {
              //Format this filter as a simple "in" query filter
              let filter = {};
              let fieldName = primaryFields[0].fieldName;
              for (let i = 0; i < entityErrors.length; i++) {
                let entityFilter = entityErrors[i];
                if (
                  Object.prototype.hasOwnProperty.call(entityFilter, fieldName)
                ) {
                  if (
                    !Object.prototype.hasOwnProperty.call(filter, fieldName)
                  ) {
                    filter[fieldName] = {};
                    filter[fieldName].val = [entityFilter[fieldName]];
                  } else {
                    filter[fieldName].val.push(entityFilter[fieldName]);
                  }
                }
              }
              console.log(">> FINDLOOKUP entity", entityName, lookupFactory);
              console.log(">> FINDLOOKUP filter", filter);
              bulkGrid = lookupFactory.withFactoryAndDefinition(
                lookupFactory.withFixedFilter(withCollection(bulkGrid), [
                  filter,
                ])
              );
            } else if (primaryFields.length > 1) {
              //Need to OR together multiple "AND" filters.
              let filters = [];
              for (let i = 0; i < entityErrors.length; i++) {
                let entityFilter = entityErrors[i];
                let valid = true;
                for (let j = 0; j < primaryFields.length; j++) {
                  let fieldName = primaryFields[j].fieldName;
                  if (
                    !Object.prototype.hasOwnProperty.call(
                      entityFilter,
                      fieldName
                    ) &&
                    !isNull(entityFilter[fieldName])
                  ) {
                    valid = false;
                    break;
                  }
                }
                if (valid) {
                  let filter = {};
                  //Should be OK ... be let's make sure we only take the PK Fields.
                  for (let j = 0; j < primaryFields.length; j++) {
                    let fieldName = primaryFields[j].fieldName;
                    filter[fieldName] = {};
                    filter[fieldName].val = entityFilter[fieldName];
                  }
                  filters.push(filter);
                }
              }
              if (filters.length > 0) {
                console.log(">> FINDLOOKUP entity", entityName, lookupFactory);
                console.log(">> FINDLOOKUP filter", filters);
                bulkGrid = lookupFactory.withFactoryAndDefinition(
                  lookupFactory.withFixedFilter(
                    withCollection(bulkGrid),
                    filters
                  )
                );
              }
            }
          }
        }
        setState({ fileContents, WrappedBulkGrid: bulkGrid, lookupErrors });
      }
    },
    [allEntityFactories, entityFactory]
  );

  useEffect(() => setState(INITIAL_STATE), [entityFactory, keyFieldName]);

  return (
    <WrappedBulkGrid
      publicStyle={publicStyle}
      showFullPageLink={showFullPageLink}
      height={height}
      importCollection={importCollection}
      errorCollection={errorCollection}
      updateFields={updateFields}
      startLine={startLine}
      blankRows={blankRows}
      startColumn={startColumn}
      numColumns={numColumns}
      onDrop={onDrop}
      isWidget={isWidget}
      fileContents={fileContents}
      lookupErrors={lookupErrors}
      findLookups={findLookups}
      viewName={""}
      keyFieldName={keyFieldName}
      allEntityFactories={allEntityFactories}
      entityFactory={entityFactory}
      screenSize={screenSize}
      layoutGraph={layoutGraph}
    />
  );
};

export default BulkProcessor;
