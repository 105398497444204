import React from "react";
import { Image } from "react-native";
import { isNull } from "../util/types";
import { TouchableOpacity } from "react-native";
import { clearCache } from "../hoc/withCache";

/*
arrays of items" { key: "XXX_YYYYY" }
*/
export const evaluateOuterDrawerListItems = (items) => {
  const drawerItems = {};
  items.forEach((item, index) => {
    let { key } = item;
    // Delimiter _
    // key => DataSearch_Basic to DataSearch
    let firstPart = key.substr(0, key.indexOf("_"));
    if (!firstPart.length) {
      firstPart = key;
    } else {
      firstPart = firstPart + "_";
    }
    if (Object.prototype.hasOwnProperty.call(drawerItems, firstPart)) {
      drawerItems[firstPart].end = index + 1;
    } else {
      drawerItems[firstPart] = {
        start: index,
        end: index + 1,
      };
    }
  });
  return drawerItems;
};

export const evaluateChildDrawerTitle = ({ navigation }) => ({
  title: navigation.state.key.substr(navigation.state.key.indexOf("_") + 1),
});

export const renderLogo = (size = 32, history = undefined, onLongPress) => {
  if (!isNull(history)) {
    return (
      <TouchableOpacity
        key={"rlt"}
        onLongPress={onLongPress}
        onPress={() => {
          clearCache();
          history.push("/");
        }}
      >
        <Image
          source={require("../assets/images/logo.png")}
          accessibilityLabel={"logo"}
          style={{ width: size, height: size }}
        />
      </TouchableOpacity>
    );
  } else if (!isNull(onLongPress)) {
    return (
      <TouchableOpacity key={"rlt"} onLongPress={onLongPress}>
        <Image
          source={require("../assets/images/logo.png")}
          accessibilityLabel={"logo"}
          style={{ width: size, height: size }}
        />
      </TouchableOpacity>
    );
  } else {
    return (
      <Image
        key={"rli"}
        source={require("../assets/images/logo.png")}
        accessibilityLabel={"logo"}
        style={{ width: size, height: size }}
      />
    );
  }
};

export const renderHeader = () => {
  return (
    <Image
      // eslint-disable-next-line no-undef
      source={require("../assets/images/logo.png")}
      style={{ width: 32, height: 32 }}
    />
  );
};
