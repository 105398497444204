import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { isEmpty, isNull } from "../../util/common.js";
import Constants from "expo-constants";

const CACHE_PREFIX = "entity_cache_";

const ENTITY_QUERY = gql`
  query EntityQuery {
    currentUser {
      id
    }
    getAllWorkflows {
      id
      name
      title
      icon
      description
      requiresEntityId
      isEntityWorkflow
      entityName
      locationHints {
        priority
        location
      }
      initialStepId
      steps {
        id
        name
        title
        formId
        exits {
          id
          icon
          name
          title
          style
          isDefault
        }
        actions {
          icon
          name
          title
          local
          entity
          style
          dirtyAware
        }
      }
    }
    getAllEntities {
      id
      name
      definition
      isModified
      versionId
    }
  }
`;

function getStateFromProps(
  BaseComponent,
  currentUser,
  userGraph,
  styleGraph,
  client
) {
  let userId = currentUser?.id;
  if (!userId || styleGraph?.loadingStyles !== false)
    return {
      BaseComponentWithLayout: null,
      userId: null,
    };

  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  console.log("ENTITY cacheVersion", userGraph.cacheVersion);
  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  let entity_cache = isNull(userGraph.cacheVersion)
    ? null
    : localStorage.getItem(CACHE_PREFIX + userGraph.currentUser.id);
  let isCached = !isEmpty(entity_cache);
  let cacheValue = null;
  if (isCached) {
    try {
      cacheValue = JSON.parse(entity_cache);
      if (
        cacheValue &&
        cacheValue.data &&
        (cacheValue.data.loading === true ||
          cacheValue.data.networkStatus !== 7)
      ) {
        isCached = false;
        cacheValue = null;
      }
    } catch (error) {
      //Unable to parse ... that's a bad sign.  Get it from network
      isCached = false;
      cacheValue = null;
    }
  }

  if (isCached) {
    if (
      cacheValue.version === Constants.manifest.version &&
      cacheValue.cacheVersion === userGraph.cacheVersion
    ) {
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      console.log(
        "ENTITY RESTORING FROM CACHE!!!!!",
        CACHE_PREFIX + userGraph.currentUser.id
      );
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      client.writeQuery({
        query: ENTITY_QUERY,
        data: cacheValue.data,
      });
    } else {
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      console.log(
        "ENTITY INVALID CACHE ... OLD VERSION",
        CACHE_PREFIX + userGraph.currentUser.id
      );
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      cacheValue = null;
      isCached = false;
    }
  } else {
    console.log(
      "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
    );
    console.log(
      "ENTITY CACHE NOT FOUND",
      CACHE_PREFIX + userGraph.currentUser.id
    );
    console.log(
      "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
    );
  }
  let fetchPolicy = isCached ? "cache-only" : "network-only";
  let variables = { userId: userGraph.currentUser.id };
  let entityQuery = graphql(ENTITY_QUERY, {
    options() {
      return {
        fetchPolicy: fetchPolicy,
        fragments: {},
        variables: variables,
      };
    },
    props({ data }) {
      if (
        !isCached &&
        !isNull(data) &&
        (!isEmpty(data.getAllWorkflows) || userGraph.currentUser.id === 21) &&
        !isNull(userGraph.cacheVersion) &&
        data.loading !== true &&
        data.networkStatus === 7 &&
        data.currentUser?.id === userGraph.currentUser.id
      ) {
        let cacheValue = { data: { ...data } };
        delete cacheValue.data.subscribeToMore;
        cacheValue.version = Constants.manifest.version;
        cacheValue.cacheVersion = userGraph.cacheVersion;
        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        console.log(
          "ENTITY SAVING CACHE!!!!!",
          CACHE_PREFIX + userGraph.currentUser.id
        );
        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        try {
          localStorage.setItem(
            CACHE_PREFIX + userGraph.currentUser.id,
            JSON.stringify(cacheValue)
          );
        } catch (error) {
          //We've run out of cache ... probably too many users logged in on the same machine.  Clear and start again.
          localStorage.clear();
          localStorage.setItem(
            CACHE_PREFIX + userGraph.currentUser.id,
            JSON.stringify(cacheValue)
          );
        }
      }
      return {
        entityGraph: {
          allWorkflows: data.getAllWorkflows,
          allEntities: data.getAllEntities,
          publicStyle: data.publicStyle,
          loading: data.loading,
          subscribeToMore: data.subscribeToMore,
        },
      };
    },
  });
  let BaseComponentWithLayout = entityQuery(BaseComponent);
  return {
    BaseComponentWithLayout: BaseComponentWithLayout,
    userId: userGraph.currentUser.id,
  };
}

export default function withEntityQuery(BaseComponent) {
  const withEntityQueryFn = function WithEntityQueryInner(props) {
    const { userGraph, styleGraph, client } = props;

    const currentUser = userGraph?.currentUser;
    const [state, setState] = useState(() => {
      return getStateFromProps(
        BaseComponent,
        currentUser,
        userGraph,
        styleGraph,
        client
      );
    });

    useEffect(() => {
      setState((previousState) => {
        if (previousState?.userId === currentUser?.id) return previousState;
        return getStateFromProps(
          BaseComponent,
          currentUser,
          userGraph,
          styleGraph,
          client
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.id, styleGraph?.loadingStyles]);

    const { BaseComponentWithLayout } = state;
    if (BaseComponentWithLayout) return <BaseComponentWithLayout {...props} />;
    return <BaseComponent {...props} />;
  };
  return withEntityQueryFn;
}
