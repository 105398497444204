import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { updateThreadLayout } from "./updateThreadLayout";
import { addWorkflowLog } from "../../hoc/withCache";
import Constants from "expo-constants";

const UPDATE_THREADS_MUTATION = gql`
  mutation updateThreads($inputs: [ThreadInput!]!, $uiVersion: String) {
    updateThreads(inputs: $inputs, uiVersion: $uiVersion) {
      taskId
      stepId
      workflowName
      display
      userId
      userName
      timeStamp
      processing
      priority
    }
  }
`;

export default graphql(UPDATE_THREADS_MUTATION, {
  options: {},
  props: ({ mutate }) => ({
    updateThread: (inputs) => {
      //Make sure undefined goes to null.  Only id + comms is optional.
      if (
        inputs &&
        inputs.length > 0 &&
        inputs[0].ids &&
        inputs[0].ids.length > 0
      ) {
        addWorkflowLog(
          inputs[0].ids[0],
          inputs[0].ids[0],
          inputs[0].timeStamp,
          true
        );
      }
      return mutate({
        variables: { inputs, uiVersion: Constants.manifest.version },
      });
    },
    addThread: (inputs) => {
      //Make sure undefined goes to null.  Only id + comms is optional.
      if (
        inputs &&
        inputs.length > 0 &&
        inputs[0].ids &&
        inputs[0].ids.length > 0
      ) {
        addWorkflowLog(inputs[0].ids[0], inputs[0].ids[0], null, true);
      }

      return mutate({
        variables: { inputs, uiVersion: Constants.manifest.version },
        updateQueries: {
          LayoutQuery: (prev, { mutationResult }) => {
            return updateThreadLayout(
              prev,
              mutationResult.data.updateThreads,
              false
            );
          },
        },
      });
    },
  }),
});
