export {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter,
  Prompt,
  useHistory,
  useLocation,
} from "react-router-dom";
