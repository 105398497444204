/* eslint-disable react/prop-types */
import React from "react";
import { isNull } from "../util/common.js";
import update from "immutability-helper";
import moment from "moment";

import TASKSCHANGED_GQL from "../entities/thread/taskDataSub.js";
import THREADSDELETED_GQL from "../entities/thread/deleteSub.js";

function processUpdateTaskSub(layout, tasks) {
  let oldTask = layout ? layout.getTaskData : undefined;
  if (!isNull(oldTask)) {
    for (let i = 0; i < tasks.length; i++) {
      let task = tasks[i];
      if (task) {
        if (oldTask.taskId === task.taskId) {
          let oldTimeStamp = oldTask.timeStamp;
          let newTimeStamp = task.timeStamp;
          let oldTS = moment(oldTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
          let newTS = moment(newTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
          if (newTS.isAfter(oldTS)) {
            let realNew = { ...task };
            realNew.id = task.taskId;
            layout = update(layout, { getTaskData: { $set: realNew } });
            //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
            console.log("++++++++++++++ UPDATING", task); //, timeStampInMs
          }
          break;
        }
      }
    }
  }
  return layout;
}

function processDeleteTaskSub(layout, tasks) {
  //Loop backwards for splice
  let oldTask = layout ? layout.getTaskData : undefined;
  if (!isNull(oldTask)) {
    if (tasks.length > 0) {
      let ids = tasks.map((a) => a.id);
      if (ids.indexOf(oldTask.id) >= 0) {
        //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
        console.log("++++++++++++++ DELETING", oldTask.id); //, timeStampInMs)
        layout = update(layout, { getTaskData: { $set: null } });
      }
    }
  }
  return layout;
}

export default function withTaskToItem(BaseComponent) {
  class WithTaskToItem extends React.Component {
    constructor(props) {
      super(props);
      this.refetch = this.refetch.bind(this);
      this.clearTimer = this.clearTimer.bind(this);
      this["onChanged"] = null;
      this["onDeleted"] = null;
      this.intervalId = 0;
      this.retryCount = 0;
      console.log("++ withTaskToItem constructor");
    }

    refetch() {
      this.retryCount += 1;
      let props = this.props;
      let task = props.task ? props.task : null;
      let threadTimeStamp = task ? task.timeStamp : null;
      let taskProcessing = task ? task.processing : null;
      let dataTimeStamp = props.queryResult
        ? props.queryResult.timeStamp
        : null;
      //let timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
      console.log(
        "++++++++++++++ TESTING REFETCH ... ",
        this.retryCount,
        taskProcessing,
        threadTimeStamp,
        dataTimeStamp,
        props.queryLoading,
        isNull(task),
        isNull(props.handleRefetch),
        this.intervalId
      ); //, timeStampInMs
      if (
        !props.queryLoading &&
        !isNull(props.handleRefetch) &&
        !isNull(task)
      ) {
        if (isNull(props.queryResult)) {
          console.log(
            "++++++++++++++ REFETCHING ... TASKDATA MISSING",
            this.retryCount
          ); //, timeStampInMs
          props.handleRefetch();
        } else {
          let threadTS = moment(threadTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
          let dataTS = moment(dataTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
          if (threadTS.isAfter(dataTS)) {
            console.log(
              "++++++++++++++ REFETCHING ... TASKDATA EARLIER",
              this.retryCount,
              taskProcessing,
              threadTimeStamp,
              dataTimeStamp,
              this.intervalId
            ); //, timeStampInMs
            props.handleRefetch();
          } else {
            console.log(
              "++++++++++++++ UP TO DATE ... IGNORING REFETCH",
              this.retryCount,
              taskProcessing,
              threadTimeStamp,
              dataTimeStamp,
              this.intervalId
            ); //, timeStampInMs
          }
        }
        console.log("refetch 1 timer");
        this.clearTimer();
      } else {
        console.log(
          "++++++++++++++ QUERY RUNNING ... waiting",
          this.retryCount,
          taskProcessing,
          threadTimeStamp,
          dataTimeStamp,
          props.queryLoading,
          isNull(task),
          isNull(props.handleRefetch),
          this.intervalId
        ); //, timeStampInMs
      }
      if (this.retryCount > 100) {
        console.log(
          "++++++++++++++ POLL FAILED ... 100 attempts",
          this.retryCount,
          taskProcessing,
          threadTimeStamp,
          dataTimeStamp,
          props.queryLoading,
          isNull(task),
          isNull(props.handleRefetch),
          this.intervalId
        ); //, timeStampInMs
        console.log("refetch 2 timer");
        this.clearTimer();
      }
      //otherwise let the poll retry ... don't clear timer
    }

    clearTimer() {
      console.log("++++++++++++++ CLEARING INTERVAL 1: ", this.intervalId);
      if (this.intervalId > 0) {
        clearInterval(this.intervalId);
        this.intervalId = 0;
        console.log("++++++++++++++ CLEARING INTERVAL 2");
      }
      this.retryCount = 0;
    }

    componentWillUnmount() {
      if (this["onChanged"]) {
        this["onChanged"]();
      }
      if (this["onDeleted"]) {
        this["onDeleted"]();
      }
      console.log("unmount clear timer");
      this.clearTimer();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      console.log("++++++++++++++ componentWillReceiveProps");
      if (!isNull(nextProps.subscribeToMore) && isNull(this["onChanged"])) {
        if (
          this["onChanged"] &&
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onChanged"]();
          delete this["onChanged"];
        }

        if (
          (!this["onChanged"] && !nextProps.queryLoading) ||
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onChanged"] = nextProps.subscribeToMore({
            document: TASKSCHANGED_GQL,
            variables: {},
            updateQuery: (prev, { subscriptionData }) => {
              //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
              console.log(
                "++++++++++++++ onChanged",
                subscriptionData.data["taskDatasChanged"]
              ); //, timeStampInMs
              return processUpdateTaskSub(
                prev,
                subscriptionData.data["taskDatasChanged"]
              );
            },
          });
        }

        if (
          this["onDeleted"] &&
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onDeleted"]();
          delete this["onDeleted"];
        }

        if (
          (!this["onDeleted"] && !nextProps.queryLoading) ||
          nextProps.subscribeToMore !== this.props.subscribeToMore
        ) {
          this["onDeleted"] = nextProps.subscribeToMore({
            document: THREADSDELETED_GQL,
            variables: {},
            updateQuery: (prev, { subscriptionData }) => {
              //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
              console.log(
                "++++++++++++++ onDeleted",
                subscriptionData.data["threadsDeleted"]
              ); //, timeStampInMs
              return processDeleteTaskSub(
                prev,
                subscriptionData.data["threadsDeleted"]
              );
            },
          });
        }
      }
      let task = nextProps.task ? nextProps.task : null;
      //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
      if (
        !nextProps.queryLoading &&
        !isNull(nextProps.handleRefetch) &&
        !isNull(task) &&
        !task.processing &&
        this.intervalId === 0
      ) {
        if (isNull(nextProps.queryResult)) {
          console.log("++++++++++++++ QUEUEING REFETCH ... TASKDATA MISSING"); //, timeStampInMs
          this.intervalId = setInterval(this.refetch, 1000);
          console.log("++++++++++++++ INTERVAL SET:", this.intervalId);
        } else {
          let threadTimeStamp = task.timeStamp;
          let dataTimeStamp = nextProps.queryResult.timeStamp;
          let threadTS = moment(threadTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
          let dataTS = moment(dataTimeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
          if (threadTS.isAfter(dataTS)) {
            console.log(
              "++++++++++++++ QUEUEING REFETCH ... TASKDATA EARLIER",
              threadTimeStamp,
              dataTimeStamp
            ); //, timeStampInMs
            this.intervalId = setInterval(this.refetch, 1000);
            console.log("++++++++++++++ INTERVAL SET:", this.intervalId);
          } else {
            console.log("will receive props 1 timer");
            this.clearTimer();
          }
        }
      }
      if (
        task &&
        nextProps.queryResult &&
        task.timeStamp === nextProps.queryResult.timeStamp
      ) {
        console.log("will receive props 2 timer");
        this.clearTimer();
      }
    }

    render() {
      let newProps = { ...this.props };
      let queryLoading = newProps.queryLoading;
      newProps.thread = this.props.task;
      newProps.taskData = this.props.queryResult;
      newProps.loading = queryLoading;
      delete newProps.subscribeToMore;
      delete newProps.queryLoading;

      return <BaseComponent {...newProps} />;
    }
  }
  return WithTaskToItem;
}
