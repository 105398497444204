/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { isNull } from "../util/common.js";

function getFileId(fileId, match) {
  if (fileId) return fileId;
  if (match?.params?.fileId) return match?.params?.fileId;
  return null;
}

function getEntityId(entityId, match) {
  if (entityId) return entityId;
  if (match?.params?.entityId) return match?.params?.entityId;
  return null;
}

function getStateFromProps(
  BaseComponent,
  currentUser,
  entityFactory,
  resolvedFileId,
  resolvedEntityId
) {
  if (!currentUser || !resolvedFileId) return null;
  if (entityName === "Task" && !resolvedEntityId) return null;

  let entityName = isNull(entityFactory)
    ? "Task"
    : entityFactory.definition.entityName;
  let idType = isNull(entityFactory) ? "ID! " : "Int ";
  let entityQuery = graphql(
    gql(
      `query fileQuery($fileId: ID!, $id: ` +
        idType +
        `) { get` +
        entityName +
        `File(fileId: $fileId, id: $id) { id fileName fileSize mimeType encoding data } }`
    ),
    {
      options() {
        let variables = { fileId: resolvedFileId };
        if (entityName === "Task") {
          variables.id = resolvedEntityId;
        }
        return {
          fetchPolicy: "no-cache",
          fragments: {},
          variables: variables,
        };
      },
      props({ data }) {
        let file = data["get" + entityName + "File"];
        let queryResult = isNull(file) ? null : file;
        return {
          queryResult: queryResult,
          subscribeToMore: data.subscribeToMore,
          queryLoading: data.loading,
        };
      },
    }
  );
  let BaseComponentWithQuery = entityQuery(BaseComponent);
  return BaseComponentWithQuery;
}

function withFileQuery(BaseComponent) {
  const withFileQueryFn = function WithFileQueryInner(props) {
    const { layoutGraph, entityFactory, match, fileId, entityId } = props;
    const resolvedFileId = useMemo(() => getFileId(fileId, match), [
      fileId,
      match,
    ]);
    const resolvedEntityId = useMemo(() => getEntityId(entityId, match), [
      entityId,
      match,
    ]);

    const currentUser = layoutGraph?.currentUser;
    const [BaseComponentWithQuery, setBaseComponentWithQuery] = useState(() => {
      return getStateFromProps(
        BaseComponent,
        currentUser,
        entityFactory,
        resolvedFileId,
        resolvedEntityId
      );
    });
    useEffect(() => {
      setBaseComponentWithQuery((previousComponent) => {
        if (previousComponent) return previousComponent;
        return getStateFromProps(
          BaseComponent,
          currentUser,
          entityFactory,
          resolvedFileId,
          resolvedEntityId
        );
      });
    }, [currentUser, entityFactory, resolvedFileId, resolvedEntityId]);

    if (BaseComponentWithQuery) return <BaseComponentWithQuery {...props} />;
    return <BaseComponent {...props} />;
  };
  return withFileQueryFn;
}

export default withFileQuery;
