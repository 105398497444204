/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { isEmpty, isNull } from "../../util/common.js";
import Constants from "expo-constants";

const CACHE_PREFIX = "style_cache_";

const STYLE_QUERY = gql`
  query StyleQuery {
    publicStyle
  }
`;

function getStateFromProps(BaseComponent, client, userId, cacheVersion) {
  if (!userId) return {};

  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  console.log("STYLE cacheVersion", cacheVersion);
  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  let style_cache = isNull(cacheVersion)
    ? null
    : localStorage.getItem(CACHE_PREFIX + userId);
  let isCached = !isEmpty(style_cache);
  let cacheValue = null;
  if (isCached) {
    try {
      cacheValue = JSON.parse(style_cache);
      if (
        cacheValue &&
        cacheValue.data &&
        (cacheValue.data.loading === true ||
          cacheValue.data.networkStatus !== 7)
      ) {
        isCached = false;
        cacheValue = null;
      }
    } catch (error) {
      //Unable to parse ... that's a bad sign.  Get it from network
      isCached = false;
      cacheValue = null;
    }
  }
  if (isCached) {
    if (
      cacheValue.version === Constants.manifest.version &&
      cacheValue.cacheVersion === cacheVersion
    ) {
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      console.log("STYLE RESTORING FROM CACHE!!!!!", CACHE_PREFIX + userId);
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      client.writeQuery({
        query: STYLE_QUERY,
        data: cacheValue.data,
      });
    } else {
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      console.log("STYLE INVALID CACHE ... OLD VERSION", CACHE_PREFIX + userId);
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      style_cache = null;
      isCached = false;
    }
  } else {
    console.log(
      "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
    );
    console.log("STYLE CACHE NOT FOUND", CACHE_PREFIX + userId);
    console.log(
      "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
    );
  }
  let fetchPolicy = isCached ? "cache-only" : "network-only";

  let variables = { userId };
  let entityQuery = graphql(STYLE_QUERY, {
    options() {
      return {
        fetchPolicy: fetchPolicy,
        fragments: {},
        variables: variables,
      };
    },
    props({ data }) {
      if (
        !isCached &&
        !isNull(data) &&
        (!isEmpty(data.publicStyle) || userId === 21) &&
        !isNull(cacheVersion) &&
        data.loading !== true &&
        data.networkStatus === 7
      ) {
        let cacheValue = { data: { ...data } };
        delete cacheValue.data.subscribeToMore;
        cacheValue.version = Constants.manifest.version;
        cacheValue.cacheVersion = cacheVersion;
        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        console.log("STYLE SAVING CACHE!!!!!", CACHE_PREFIX + userId);
        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        try {
          localStorage.setItem(
            CACHE_PREFIX + userId,
            JSON.stringify(cacheValue)
          );
        } catch (error) {
          //We've run out of cache ... probably too many users logged in on the same machine.  Clear and start again.
          localStorage.clear();
          localStorage.setItem(
            CACHE_PREFIX + userId,
            JSON.stringify(cacheValue)
          );
        }
      }
      let unparsed = null;
      if (isCached && cacheValue?.data?.publicStyle) {
        unparsed = cacheValue?.data?.publicStyle;
      } else {
        unparsed = data?.publicStyle;
      }
      let parsedStyle = null;
      if (!isEmpty(unparsed)) {
        try {
          parsedStyle = JSON.parse(unparsed);
        } catch (error) {
          console.log("ERROR!!! UNABLE TO PARSE STYLE!", unparsed);
          parsedStyle = null;
        }
      }
      return {
        styleGraph: {
          loadingStyles: data.loading,
          publicStyle: parsedStyle,
        },
      };
    },
  });
  return { BaseComponentWithLayout: entityQuery(BaseComponent), userId };
}

export default function withStyleQuery(BaseComponent) {
  const withStyleQueryFn = function WithStyleQueryInner(props) {
    const { userGraph, client } = props;

    const currentUserId = userGraph?.currentUser?.id;
    const cacheVersion = userGraph?.cacheVersion;

    const [state, setState] = useState(() => {
      return getStateFromProps(
        BaseComponent,
        client,
        currentUserId,
        cacheVersion
      );
    });

    useEffect(() => {
      setState((previousState) => {
        if (previousState?.userId === currentUserId) return previousState;
        return getStateFromProps(
          BaseComponent,
          client,
          currentUserId,
          cacheVersion
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserId]);

    const { BaseComponentWithLayout } = state;
    if (BaseComponentWithLayout) return <BaseComponentWithLayout {...props} />;
    return <BaseComponent {...props} />;
  };
  return withStyleQueryFn;
}
