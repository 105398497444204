import { getLookup } from "../util/common";
import { isNull, isEmpty, isInt } from "../util/types";
import definitionHelper from "../util/definitions";
import { ConfirmAlert } from "../components/ConfirmAlert";
import Toast from "../components/Toast";

function getFixedFilter(definition, primaryCollection) {
  let { filters } = definition;
  if (filters.length > 0) {
    let currentCollection = primaryCollection;
    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i];
      let fieldName = filter.fieldName;
      let operator = filter.operator;
      let values = filter.values;
      let filteredResults = [];
      for (let j = 0; j < currentCollection.length; j++) {
        let row = currentCollection[j];
        let rowVal = row[fieldName];
        let match = false;
        if (operator === "eq" || operator === "in") {
          for (let k = 0; k < values.length; k++) {
            let value = values[k];
            if (value === null) {
              match = isNull(rowVal);
            } else {
              if (Array.isArray(rowVal)) {
                match = rowVal.indexOf(value) >= 0;
              } else {
                match = rowVal === value;
              }
            }
            if (match) break;
          }
        } else if (operator === "neq" || operator === "notin") {
          match = true;
          for (let k = 0; k < values.length; k++) {
            let value = values[k];
            if (value === null) {
              match = !isNull(rowVal);
            } else {
              if (Array.isArray(rowVal)) {
                match = rowVal.indexOf(value) < 0;
              } else {
                match = rowVal !== value;
              }
            }
            if (!match) break;
          }
        }
        if (match) {
          filteredResults.push(row);
        }
      }
      currentCollection = filteredResults;
    }
    return currentCollection;
  } else {
    return primaryCollection;
  }
}

/*
function tableFieldAccessor(row, field) {
  if (isNull(field)) {
    return row.raw.id;
  } else {
    return fieldAccessor(row.raw, field);
  }
}
*/

export function lowerCasePluralName(definition) {
  let entityName = definition.pluralName;
  return entityName.toLowerCase();
}

/*
export function wrapWithLink(definition, cell, value) {
  let showLink = definition.guidId;
  if (!showLink) {
    showLink = cell.row.original.id > 0;
  }
  if (cell.column.primaryField && !definition.readOnly && showLink) {
    return <Link style={{ flex: 1 }} key={cell.column.id} to={"/entities/" + lowerCasePluralName(definition) + "/" + cell.row.original.id.toString()}>{value}</Link>
  } else {
    return value;
  }
}
*/
export function addRow(history, definition) {
  history.push("/entities/" + lowerCasePluralName(definition) + "/new");
}

export function editRows(history, definition, selectedFlatRows) {
  console.log("selectedFlatRows", selectedFlatRows);
  if (selectedFlatRows && selectedFlatRows.length > 0) {
    let ids = [];
    for (let i = 0; i < selectedFlatRows.length; i++) {
      let id = selectedFlatRows[i].original.id;
      console.log("id", id);
      if (isInt(id)) {
        if (id > 0) {
          ids.push(id.toString());
        }
      } else {
        ids.push(id.toString());
      }
    }
    console.log("ids", ids);
    if (ids.length === 1) {
      history.push(
        "/entities/" + lowerCasePluralName(definition) + "/" + ids[0]
      );
    } else if (ids.length > 1) {
      history.push(
        "/entities/" + lowerCasePluralName(definition) + "/" + ids.join()
      );
    }
  }
}

export function hasEditRights(entityFactory, currentUser, selectedFlatRows) {
  if (selectedFlatRows && selectedFlatRows.length > 0) {
    let ids = selectedFlatRows.map((item) => item.original.id);
    let rows = selectedFlatRows.map((item) => item.original.raw);
    return (
      entityFactory &&
      currentUser &&
      entityFactory.hasEditRights(currentUser, ids, rows)
    );
  } else {
    return false;
  }
}

export function hasDeleteRights(entityFactory, currentUser, selectedFlatRows) {
  if (selectedFlatRows && selectedFlatRows.length > 0) {
    let ids = selectedFlatRows.map((item) => item.original.id);
    let rows = selectedFlatRows.map((item) => item.original.raw);
    return (
      entityFactory &&
      currentUser &&
      entityFactory.hasDeleteRights(currentUser, ids, rows)
    );
  } else {
    return false;
  }
}

export function getFilteredData(
  definition,
  tableFieldAccessor,
  primaryCollection,
  layoutGraph,
  allEntityFactories
) {
  if (primaryCollection) {
    let fields = definitionHelper.findAllDisplayFields(definition.fields);
    let fixedFilter = getFixedFilter(definition, primaryCollection);

    let rawCollection = [];
    for (let i = 0, l = fixedFilter.length; i < l; i++) {
      let currentRow = fixedFilter[i];
      rawCollection.push({ id: currentRow.id, raw: currentRow, lookups: {} });
    }
    for (let i = 0, l = fields.length; i < l; i++) {
      let field = fields[i];
      if (!isNull(field)) {
        if (field.lookup) {
          let lookup = getLookup(
            { layoutGraph, allEntityFactories },
            field,
            true,
            null,
            true,
            false,
            null,
            true
          );
          for (let j = 0, ll = rawCollection.length; j < ll; j++) {
            let filteredRow = rawCollection[j];
            let fieldValue = tableFieldAccessor(filteredRow, field);
            let lookupValue = null;
            if (Array.isArray(fieldValue)) {
              lookupValue = [];
              for (let k = 0, lll = fieldValue.length; k < lll; k++) {
                let valueId = fieldValue[k];
                for (let m = 0, llll = lookup.length; m < llll; m++) {
                  let lookupVal = lookup[m];
                  if (lookupVal.id === valueId) {
                    lookupValue.push(lookupVal);
                    break;
                  }
                }
              }
            } else {
              for (let k = 0, lll = lookup.length; k < lll; k++) {
                let lookupVal = lookup[k];
                if (lookupVal.id === fieldValue) {
                  lookupValue = lookupVal;
                  break;
                }
              }
            }
            filteredRow.lookups[field.fieldName] = lookupValue;
          }
        }
      }
    }
    return rawCollection;
  } else {
    return [];
  }
}

export function onDelete(
  definition,
  primaryCollection,
  update,
  isWidget,
  deleteItems,
  toggleAllRowsSelected,
  setIsDeleting,
  selectedFlatRows
) {
  if (update || isWidget) {
    onDeleteConfirm(
      primaryCollection,
      update,
      deleteItems,
      toggleAllRowsSelected,
      setIsDeleting,
      selectedFlatRows
    );
  } else {
    ConfirmAlert({
      title: "Delete " + definition.entityName, // Title dialog
      message:
        "This operation cannot be undone.  Are you sure to delete this record?", // Message dialog
      buttons: [
        {
          label: "Confirm",
          onClick: () =>
            onDeleteConfirm(
              primaryCollection,
              update,
              deleteItems,
              toggleAllRowsSelected,
              setIsDeleting,
              selectedFlatRows
            ),
        },
        {
          label: "Cancel",
          onClick: () => {},
        },
      ],
    });
  }
}

function onDeleteConfirm(
  primaryCollection,
  update,
  deleteItems,
  toggleAllRowsSelected,
  setIsDeleting,
  selectedFlatRows,
  deleteAll = false
) {
  console.log("selectedFlatRows", selectedFlatRows, deleteAll);
  if (!isEmpty(selectedFlatRows) || deleteAll) {
    let ids = null;
    if (deleteAll) {
      if (primaryCollection && primaryCollection.length > 0) {
        ids = primaryCollection.map((a) => a.id);
      }
    } else {
      ids = selectedFlatRows.map((item) => item.original.id);
    }
    setIsDeleting(true);
    deleteItems({ ids: ids })
      .catch((res) => {
        if (res.graphQLErrors) {
          console.log(">>> res.networkError", res.networkError);
          const errors = res.graphQLErrors.map((error) => {
            return error.message;
          });
          if (!update) {
            console.log("ERROR!1", errors);
            Toast.toast(`[Delete Error]: ` + errors.toString(), 5000);
            console.log("ERROR!", errors, res);
          } else {
            console.log(">>> errors", errors);
            for (let i = 0; i < errors.length; i++) {
              Toast.toast(`[Delete Error]: ` + errors[i].toString(), 5000);
              console.log("ERROR!", errors[i]);
            }
          }
        }
        setIsDeleting(false);
        throw res;
      })
      .then(() => {
        setIsDeleting(false);
        toggleAllRowsSelected(false);
        if (!update) {
          Toast.toast("Deletion Successful!");
          /*
          this.props.notificationSystem.addNotification({
            title: 'Deletion Successful!',
            message: "",
            autoDismiss: 1,
            level: 'success'
          });
          */
        } else {
          console.log("Delete Successful!");
        }
      })
      .catch((res) => {
        Toast.toast(`[Delete Error]: ` + res.toString(), 5000);
        console.log("ERROR!", res);
        return;
      });
  }
}
