/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { View } from "react-native";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Loading from "../../components/loading";
import EntityForm from "./form";
import { isNull, isEmpty, getScreenSize } from "../../util/common.js";
import definitionHelper from "../../util/definitions.js";
import withQueryToLayout from "../../hoc/withQueryToLayout.js";
import EntityFactory from "../entityFactory";
import { renderLogo } from "../../navigation/utils";
import { Link } from "../../ReactRouter";
import Chance from "chance";
const chance = new Chance();

const WrapperForm = (props) => {
  const {
    layoutGraph,
    allEntityFactories,
    navigationDefaults,
    drawerMenu,
    changeLoginState,
    history,
    publicStyle,
    hasSideBar,
    menuOnlyHasHome,
    thread,
    taskData,
    windowWidth,
    isPage,
    loading,
    buttonsTop,
  } = props;

  const getThreadFormData = useCallback((taskData) => {
    let threadFormData = null;
    if (taskData?.taskStateData) {
      try {
        threadFormData = JSON.parse(taskData.taskStateData);
        // eslint-disable-next-line no-empty
      } catch (ex) {}
    }
    return threadFormData;
  }, []);

  const getStateFromProps = useCallback(
    (oldState) => {
      let nextState = {};

      let screenSize = getScreenSize(windowWidth);

      if (!isNull(thread)) {
        console.log("thread: ", thread.id, thread.timeStamp);
      } else {
        console.log("NO THREAD");
      }

      if (!isNull(taskData)) {
        console.log("taskdata: ", taskData.id, taskData.timeStamp);
      } else {
        console.log("NO TASKDATA");
      }

      if (!oldState || oldState.screenSize !== screenSize) {
        nextState.screenSize = screenSize;
      }
      if (isNull(thread) || isNull(taskData)) {
        nextState.task = null;
        nextState.step = null;
        nextState.form = null;
        nextState.definition = null;
        nextState.stateEntityFactory = null;
        nextState.item = null;
        nextState.threadFormData = null;
        return nextState;
      }

      if (
        thread.id !== taskData.id ||
        isNull(thread.timeStamp) ||
        isNull(taskData.timeStamp) ||
        thread.timeStamp.toUpperCase() !== taskData.timeStamp.toUpperCase()
      ) {
        //Just wait until we have received both pubsub events
        if (!isNull(oldState.task)) {
          return { task: { ...oldState.task, processing: true } };
        } else {
          return oldState;
        }
      }

      let olderVersion = false;
      let oldTS = null;
      let newTS = null;
      if (
        oldState &&
        oldState.task &&
        thread.id === oldState.task.id &&
        !isNull(oldState.task.timeStamp) &&
        oldState.task.timeStamp !== thread.timeStamp
      ) {
        oldTS = moment(oldState.task.timeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
        newTS = moment(thread.timeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
        if (oldTS.isAfter(newTS)) {
          olderVersion = true;
        }
      }
      if (!olderVersion) {
        let form = null;
        if (
          !oldState ||
          !oldState.task ||
          thread.id !== oldState.task.id ||
          thread.timeStamp !== oldState.task.timeStamp
        ) {
          nextState.task = { ...thread };
          nextState.task.threadStateData = taskData.taskStateData;
          let stepId = thread.stepId;

          if (
            !oldState ||
            !oldState.step ||
            stepId !== oldState.step.id ||
            (thread && thread.processing)
          ) {
            let step = null;
            let allWorkflows = layoutGraph?.allWorkflows
              ? layoutGraph?.allWorkflows
              : [];
            for (let i = 0; i < allWorkflows.length; i++) {
              let workflow = allWorkflows[i];
              for (let j = 0; j < workflow.steps.length; j++) {
                let currentStep = workflow.steps[j];
                if (currentStep.id === stepId) {
                  step = currentStep;
                  break;
                }
              }
              if (step !== null) {
                break;
              }
            }
            nextState.step = step;
            if (step) {
              let formId = step.formId;
              if (!formId) formId = -1;
              if (
                !oldState ||
                !oldState.form ||
                formId !== oldState.form.id ||
                formId === -1 ||
                (thread && thread.processing)
              ) {
                if (formId > 0) {
                  let allForms = layoutGraph?.allForms
                    ? layoutGraph?.allForms
                    : [];
                  for (let i = 0; i < allForms.length; i++) {
                    let frm = allForms[i];
                    if (frm.id === formId) {
                      form = frm;
                      break;
                    }
                  }
                } else {
                  form = { id: -1, name: chance.guid() };
                }
                nextState.form = form;
              }
            }
          } else if (
            stepId === oldState?.step?.id &&
            oldState?.step?.formId === -1
          ) {
            form = { id: -1, name: chance.guid() };
          }
        }

        if (!oldState || oldState.screenSize !== screenSize) {
          nextState.screenSize = screenSize;
        }

        let threadFormData = null;
        if (!oldState || !isNull(form) || oldState.screenSize !== screenSize) {
          if (isNull(form) && oldState && oldState.form) {
            form = oldState.form;
          }
          let definition = null;
          if (form) {
            if (form.id === -1) {
              threadFormData = getThreadFormData(taskData);
              if (threadFormData) {
                let formDef = threadFormData["!!definition"];
                if (!formDef) {
                  formDef = `{
                      "entityName": "Form",
                      "fields": [
                          {
                              "fieldName": "notFound",
                              "type": "string",
                              "required": false,
                              "inPlace": false,
                              "readOnly": true,
                              "hidden": false,
                              "widget": "label",
                              "default": "Form Not Found"
                          }
                      ],
                      "sections": [
                          {
                              "id": "main"
                          }
                      ],
                      "rules": [],
                      "displayMode": "None",
                      "minimal": true,
                      "warnings": [],
                      "subDefinitions": [],
                      "cacheable": true,
                      "cacheRoles": [],
                      "autoTemplate": true
                  }`;
                }
                if (formDef) {
                  definition = JSON.parse(formDef);
                }
              }
            } else {
              try {
                definition = JSON.parse(form.definition);
                // eslint-disable-next-line no-empty
              } catch (ex) {}
            }
          }
          if (!isNull(definition)) {
            definition = definitionHelper.fixDefinition(definition);
            if (thread && thread.processing) {
              definitionHelper.setNotMandatory(definition.fields);
            }
          }
          if (!isNull(definition)) {
            nextState.definition = definition;
            nextState.formName = form.name;
            nextState.stateEntityFactory = new EntityFactory(
              null,
              -1,
              definition,
              definition,
              []
            );
          }
        }

        if (
          !oldState ||
          !oldState.task ||
          thread.id !== oldState.task.id ||
          thread.timeStamp !== oldState.task.timeStamp
        ) {
          let definition = !isNull(nextState.definition)
            ? nextState.definition
            : oldState && !isNull(oldState.definition)
            ? oldState.definition
            : null;
          if (!threadFormData) threadFormData = getThreadFormData(taskData);
          let item = null;
          if (!isNull(threadFormData) && !isEmpty(threadFormData.ids)) {
            item = { id: threadFormData.ids[0] }; //Must be an existing entity being updated/deleted.  Ensure only one though. (doublecheck)
          } else {
            item = {}; //Must be a "New" entity being created.
          }
          item._guid = threadFormData._guid;
          if (!isNull(threadFormData) && !isNull(definition)) {
            //Default values into form.
            let fields = definitionHelper.findAllDisplayFields(
              definition.fields
            );
            for (let i = 0; i < fields.length; i++) {
              let field = fields[i];
              let value = threadFormData[field.fieldName];
              if (!isNull(value)) {
                item[field.fieldName] = value;
              }
            }
          }
          nextState.threadFormData = threadFormData;
          nextState.versionId =
            oldState && !isNull(oldState.versionId)
              ? oldState.versionId + 1
              : 1;
          item.versionId = nextState.versionId;
          nextState.item = item;
        }

        return nextState;
      } else {
        console.log("OLDER VERSION DETECTED... IGNORING EVENT", oldTS, newTS);
        return oldState;
      }
    },
    [
      taskData,
      thread,
      windowWidth,
      layoutGraph?.allForms,
      layoutGraph?.allWorkflows,
      getThreadFormData,
    ]
  );

  const [state, setState] = useState(() => {
    let newState = getStateFromProps(null);
    if (isNull(newState)) {
      newState = {};
    }
    return newState;
  });
  const [StateEntityForm] = useState(() => withQueryToLayout(EntityForm));

  const onBack = () => {
    history.goBack();
  };

  const onHome = () => {
    history.push("/home");
  };

  const renderEntityLink = useCallback(
    (pluralName) => {
      if (isPage) {
        if (!isEmpty(pluralName)) {
          return (
            <View style={{ flexDirection: "row" }}>
              <Link to={"/entities/" + pluralName.toLowerCase()}>
                <Typography variant="body1" color={"textSecondary"}>
                  Return to {pluralName}
                </Typography>
              </Link>
            </View>
          );
        } else {
          return undefined;
        }
      } else {
        return <View />;
      }
    },
    [isPage]
  );

  const renderButtons = () => {
    if (isPage) {
      return (
        <View style={{ flexDirection: "row" }}>
          <Button
            size={"small"}
            variant={"outlined"}
            color={"secondary"}
            onClick={onBack}
            style={{ marginLeft: 10, width: 75 }}
          >
            {"Back"}
          </Button>
          <Button
            size={"small"}
            variant={"outlined"}
            color={"secondary"}
            onClick={onHome}
            style={{ marginLeft: 10, width: 75 }}
          >
            {"Home"}
          </Button>
        </View>
      );
    } else {
      return <View />;
    }
  };

  const onInvalidate = useCallback((timeStamp) => {
    //thread.timeStamp.toUpperCase() !== taskData.timeStamp.toUpperCase()
    setState((prevState) => {
      if (
        !isNull(prevState?.task) &&
        !isNull(timeStamp) &&
        prevState?.task?.timeStamp.toUpperCase() !== timeStamp.toUpperCase()
      ) {
        let oldTS = moment(
          prevState.task.timeStamp,
          "DD/MM/YYYY HH:mm:ss.SSS A"
        );
        let newTS = moment(timeStamp, "DD/MM/YYYY HH:mm:ss.SSS A");
        let olderVersion = false;
        if (oldTS.isAfter(newTS)) {
          olderVersion = true;
        }
        if (!olderVersion) {
          return {
            ...prevState,
            task: { ...prevState.task, processing: true },
          };
        }
      }
      return prevState;
    });
  }, []);

  useEffect(() => {
    setState((prevState) => {
      let newState = getStateFromProps(prevState);
      if (!isNull(newState)) {
        return { ...prevState, ...newState };
      }
      return prevState;
    });
  }, [getStateFromProps]);

  const {
    task,
    step,
    definition,
    screenSize,
    item,
    stateEntityFactory,
    formName,
  } = state;
  //const { allWorkflows, allForms } = layoutGraph;
  const layoutLoading = layoutGraph ? layoutGraph.loading : true;

  //let formId = step ? step.formId : null;
  const primaryCollection =
    allEntityFactories && allEntityFactories.length > 0
      ? allEntityFactories[0].getPrimaryCollection(layoutGraph)
      : []; //Will be defined elsewhere
  return (
    <View style={{ flex: 1 }}>
      {!isNull(definition) ? (
        <View style={{ flex: 1 }}>
          <StateEntityForm
            key={formName}
            item={item}
            onInvalidate={onInvalidate}
            allEntityFactories={allEntityFactories}
            formName={formName}
            thread={task}
            step={step}
            queryResult={[item]}
            isWorkflowForm={true}
            entityFactory={stateEntityFactory}
            navigationDefaults={navigationDefaults}
            definition={definition}
            formDefinition={definition}
            formEntityFactory={stateEntityFactory}
            primaryCollection={primaryCollection}
            drawerMenu={drawerMenu}
            changeLoginState={changeLoginState}
            history={history}
            screenSize={screenSize}
            isNew={false}
            isModal={false}
            bulkItems={[]}
            layoutGraph={layoutGraph}
            allowSubdefinitionSwitch={true}
            publicStyle={publicStyle}
            hasSideBar={hasSideBar}
            menuOnlyHasHome={menuOnlyHasHome}
            buttonsTop={buttonsTop === false ? false : true}
          />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <AppBar position="static" color="primary">
            <View
              style={{
                flexDirection: "row",
                paddingLeft: hasSideBar ? 64 : 0,
                height: 50,
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  marginLeft: !hasSideBar ? 58 : 10,
                }}
              >
                {renderLogo(32, history)}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "flex-end",
                }}
              >
                {(!isNull(task) && isNull(definition) && task.processing) ||
                loading ? (
                  <Loading
                    messageOnly={true}
                    messageVariant={"subtitle1"}
                    color={"#ff0000"}
                    inverse={true}
                    direction={"row-reverse"}
                    key={"processing"}
                    message={"Processing ... "}
                  />
                ) : undefined}
              </View>
            </View>
          </AppBar>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isNull(task) && isNull(definition) && task.processing ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading message={"Processing ..."} />
                {renderEntityLink()}
                {renderButtons()}
              </View>
            ) : undefined}
            {!isNull(task) && isNull(definition) && !task.processing ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {renderEntityLink()}
                {renderButtons()}
              </View>
            ) : undefined}
            {isNull(thread) && !loading ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" color={"inherit"}>
                  Task complete.
                </Typography>
                {renderButtons()}
              </View>
            ) : undefined}
            {layoutLoading ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></View>
            ) : undefined}
          </View>
        </View>
      )}
    </View>
  );
};

export default WrapperForm;
