export function isNull(value) {
  return value === undefined || value === null;
}

export function isEmpty(value) {
  return isNull(value) || value.length === 0;
}

export function isInt(value) {
  return (
    typeof value === "number" && isFinite(value) && Math.floor(value) === value
  );
}

export function isNumber(value) {
  return typeof value === "number" && isFinite(value);
}

export function stringIsNumeric(value) {
  return !isEmpty(value) && !isNaN(parseInt(value)) && isFinite(value);
}

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export function isObject(obj) {
  return obj === Object(obj);
}

/*
export function isObjectNotArray(obj) {
  return obj === Object(obj) && Object.prototype.toString.call(obj) !== '[object Array]';
};
*/
export function isObjectNotArray(obj) {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

export function safeParse(val) {
  let parsedValue = undefined;
  try {
    parsedValue = val ? JSON.parse(val) : undefined;
    // eslint-disable-next-line no-empty
  } catch (err) {
    console.log("JSON Parse error:", err);
    console.log("JSON Parse failed on:", val);
  }
  return parsedValue;
}

export function safeParseInt(val) {
  let parsedValue = undefined;
  try {
    parsedValue = parseInt(val, 10);
    // eslint-disable-next-line no-empty
  } catch {}
  return isNaN(parsedValue) ? undefined : parsedValue;
}

export function safeParseFloat(val) {
  let parsedValue = undefined;
  try {
    parsedValue = parseFloat(val);
    // eslint-disable-next-line no-empty
  } catch {}
  return isNaN(parsedValue) ? undefined : parsedValue;
}
