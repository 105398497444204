import React from "react";
import { Alert as NativeAlert, Platform, View } from "react-native";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: null,
    };
  }

  static alert(
    title = "",
    desc = "",
    buttons = [
      {
        text: "OK",
        onPress: () => {},
      },
    ],
    options = { cancelable: false }
  ) {
    Platform.OS === "web"
      ? Alert.alertInstance.setState({
          settings: { title, desc, buttons, options },
        })
      : NativeAlert.alert(title, desc, buttons, options);
  }
  static alertInstance = null;

  componentDidMount() {
    Alert.alertInstance = this;
  }
  onSelect(f) {
    this.setState({ settings: null });
    f();
  }
  render() {
    const { settings } = this.state;

    return (
      settings && (
        <View
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {settings.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {settings.desc}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              {settings.buttons.map((button, index) => (
                <Button
                  size="small"
                  color="primary"
                  disabled={button.disabled}
                  key={"b" + index.toString()}
                  onClick={() => this.onSelect(button.onPress)}
                >
                  {button.text}
                </Button>
              ))}
            </CardActions>
          </Card>
        </View>
      )
    );
  }
}

export default Alert;
