/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";

function renderTableChildren(borderStyle, children) {
  return React.Children.map(children, (child) =>
    React.cloneElement(
      child,
      borderStyle && child.type.displayName !== "ScrollView"
        ? { borderStyle: borderStyle }
        : {}
    )
  );
}

export const Table = (props) => {
  const { borderStyle, children } = props;
  const borderLeftWidth = (borderStyle && borderStyle.borderWidth) || 0;
  const borderBottomWidth = borderLeftWidth;
  const borderColor = (borderStyle && borderStyle.borderColor) || "#000";

  return (
    <View
      style={[
        props.style,
        {
          borderLeftWidth,
          borderBottomWidth,
          borderColor,
        },
      ]}
    >
      {renderTableChildren(borderStyle, children)}
    </View>
  );
};

function renderTableWrapperChildren(borderStyle, children) {
  return React.Children.map(children, (child) =>
    React.cloneElement(child, borderStyle ? { borderStyle: borderStyle } : {})
  );
}

export const TableWrapper = (props) => {
  const { style, borderStyle, children } = props;

  return (
    <View style={style}>
      {renderTableWrapperChildren(borderStyle, children)}
    </View>
  );
};
