/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from "react";
import { isNull } from "../util/common";
import * as Location from "expo-location";
import definitionHelper from "../util/definitions.js";

function hasGeog(entityFactory) {
  if (entityFactory?.definition) {
    let fields = definitionHelper.findAllTopLevelDBFields(
      entityFactory.definition.fields
    );

    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      let fieldIsHidden = field.hidden;
      if (!fieldIsHidden) {
        if (field.type === "geog") {
          return true;
        }
      }
    }
  }
  return false;
}

async function _getLocationAsync(setState) {
  let { status } = await Location.requestForegroundPermissionsAsync();
  //console.log("STATUS ::: ", status)
  if (status !== "granted") {
    try {
      fetch("https://ipapi.co/json")
        .then((res) => res.json())
        .then((location) => {
          let geoLocation = { type: "Point", coordinates: [] };
          geoLocation.coordinates.push(location.longitude);
          geoLocation.coordinates.push(location.latitude);
          setState({ geoLocation, failed: false });
        })
        .catch((err) => {
          console.log("Error:", err);
          setState({ geoLocation: null, failed: true });
        });
      // eslint-disable-next-line no-empty
    } catch (ex) {}
  } else {
    let geoLocation = await Location.getCurrentPositionAsync({});
    setState({ geoLocation, failed: false });
  }
}

function withLocation(BaseComponent) {
  const withLocationFn = function CheckUserInner(props) {
    const { entityFactory } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hasGeography = useMemo(() => hasGeog(entityFactory), []);
    const [state, setState] = useState(() => ({
      geoLocation: undefined,
      failed: false,
    }));

    useEffect(() => {
      if (hasGeography) {
        _getLocationAsync(setState);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { failed, geoLocation } = state;
    if (hasGeography && isNull(geoLocation) && !failed) {
      return null;
    } else {
      return (
        <BaseComponent
          {...props}
          hasGeography={hasGeography}
          geoLocation={geoLocation}
        />
      );
    }
  };

  return withLocationFn;
}

export default withLocation;
