import { gql } from "@apollo/client";

export default gql`
  subscription onThreadsDeleted {
    threadsDeleted {
      id
      redirectHint
    }
  }
`;
