/* eslint-disable react/prop-types */
import React, {
  Suspense,
  useEffect,
  useState,
  useRef,
  useMemo,
  useImperativeHandle,
  useCallback,
  forwardRef,
} from "react";
import { Platform, View, BackHandler } from "react-native";
import { Route, Switch, useHistory } from "./ReactRouter";
import Landing from "./pages/Landing";
import { getMenu, getUserMenuItem } from "./util/menu";
import {
  isNull,
  isEmpty,
  findEntityFactory,
  getScreenSize,
} from "./util/common";
import withFileQuery from "./hoc/withFileQuery.js";
import withTaskInterrupt from "./hoc/withTaskInterrupt.js";
import FilePage from "./pages/filePage";
import THREADSCHANGED_GQL from "./entities/thread/updateSub.js";
import { updateThreadLayout } from "./entities/thread/updateThreadLayout.js";
import THREADSDELETED_GQL from "./entities/thread/deleteSub.js";
import { deleteThreadLayout } from "./entities/thread/deleteThreadLayout.js";
import WizardPage from "./pages/wizardPage";
import DBPage from "./pages/DBPage";
import * as cloneDeep from "lodash/cloneDeep";
import { SCREEN } from "./util/constants";
//import Sidebar from './components/Sidebar';
import Toast from "./components/Toast";
import getEnvironment from "./environment/env";
import { useCache } from "./hoc/withCache";
import checkUser from "./hoc/checkUser.js";

let Sidebar = React.lazy(() => import("./components/Sidebar"));

let WrappedDBPage = checkUser(withTaskInterrupt(DBPage));
let WrappedWizardPage = checkUser(withTaskInterrupt(WizardPage));

const TASKLIST_PAGE_DEFINITION = `{"entityName": "Tasklist",
"fields": [
  { 
    "fieldName": "tasks", 
    "type": "taskgrid", 
    "displayFields": [ "workflowTitle", "display", "userName", "timeStamp", "step", "processing" ], 
    "showDetail": false, 
    "title": "Workflow Tasks", 
    "hidden": false, 
    "tab": "main", 
    "lookupFilter": {} 
  }
],
"sections": [
    {
        "id": "main"
    }
],
"displayMode": "None",
"subDefinitions": []
}`;

function getAllPages(allEntityFactories, uiGraph) {
  let pagesById = {};
  let pagesByName = {};
  let pageEntityFactory = findEntityFactory(allEntityFactories, "Page");
  if (!isNull(pageEntityFactory)) {
    let primaryCollection = pageEntityFactory.getPrimaryCollection(uiGraph);
    if (!isEmpty(primaryCollection)) {
      primaryCollection.forEach((row) => {
        let pageItem = cloneDeep(row);
        if (row.name.toLowerCase() !== "bulk import") {
          if (row.name !== "Tasklist") {
            try {
              if (!isEmpty(pageItem.definition)) {
                let def = JSON.parse(pageItem.definition);
                pageItem.definition = def;
              }
            } catch (ex) {
              pageItem = null;
            }
          } else {
            if (pageItem.name === "Tasklist") {
              pageItem.definition = JSON.parse(TASKLIST_PAGE_DEFINITION);
            }
          }
          if (!isNull(pageItem)) {
            pagesById[pageItem.id.toString()] = pageItem;
            pagesByName[pageItem.name.toString().toLowerCase()] = pageItem;
          }
        }
      });
    }
  }
  return { pagesById, pagesByName };
}

function getAutoGridForEntity(entityName, title, short) {
  return {
    id: entityName + "__grid",
    name: entityName,
    versionId: 1,
    definition: JSON.parse(
      `{
          "entityName": "` +
        entityName +
        `",
        "title": "` +
        title +
        `",
        "short": "` +
        short +
        `",        
          "fields": [
              {
                  "fieldName": "field",
                  "type": "entitygrid",
                  "showDetail": false,
                  "hidden": false,
                  "lookup": "` +
        entityName +
        `"
              }
          ],
          "sections": [
              {
                  "id": "main"
              }
          ],
          "displayMode": "None",
          "subDefinitions": [],
          "lookup": "` +
        entityName +
        `"
      }`
    ),
  };
}

function calcFieldsAndSections(currentUser, allEntityFactories, entityFactory) {
  let sections = [];
  let fields = [];
  for (let i = 0; i < entityFactory.definition.references.length; i++) {
    let lookup = entityFactory.definition.references[i];
    for (let j = 0; j < allEntityFactories.length; j++) {
      let lookupEntityFactory = allEntityFactories[j];
      if (
        lookupEntityFactory.definition.entityName === lookup.lookup &&
        lookupEntityFactory.definition.views.length === 0 &&
        lookupEntityFactory.definition.type === "entity"
      ) {
        if (lookupEntityFactory.hasSomeViewRights(currentUser)) {
          const lookupName = lookupEntityFactory.definition.entityName;
          let lookupIcon = lookupEntityFactory.definition.icon;

          if (isNull(lookupIcon)) lookupIcon = "";
          let lookupTitle = lookupEntityFactory.definition.title;
          if (isNull(lookupTitle)) lookupTitle = "";
          let lookupShort = lookupEntityFactory.definition.short;
          if (isNull(lookupShort)) lookupShort = "";
          if (!isEmpty(lookupShort)) {
            lookupTitle = lookupShort;
            lookupShort = "";
          }
          // console.log(
          //   ">>> SIDELINKS",
          //   entityFactory.definition.entityName,
          //   lookupName,
          //   lookupShort,
          //   lookupIcon
          // );
          fields.push(
            `{
            "fieldName": "` +
              lookupName +
              `",
            "type": "entitygrid",
            "showDetail": false,
            "hidden": false,
            "lookup": "` +
              lookupName +
              `",
            "showFilter": false,
            "tab": "` +
              lookupName +
              `",
            "lookupFilter": {
                  "` +
              lookup.field +
              `": {
                      "val": "%%id"
                  }
              }}`
          );
          sections.push({
            id: lookupName,
            icon: lookupIcon,
            title: lookupTitle,
            short: lookupShort,
          });
        }
      }
    }
  }
  if (sections.length > 1) {
    sections.sort((a, b) => {
      let t1 = isEmpty(a.title) ? a.id : a.title;
      let t2 = isEmpty(b.title) ? b.id : b.title;
      t1 = t1.toLowerCase();
      t2 = t2.toLowerCase();
      if (t1 < t2) {
        return -1;
      }
      if (t1 > t2) {
        return 1;
      }
      return 0;
    });
  }

  let formSections = [];
  let childFields = entityFactory.getAllDisplayFields();
  for (let i = 0; i < childFields.length; i++) {
    let childField = childFields[i];
    if (childField.type === "integer" && !isEmpty(childField.lookup)) {
      for (let j = 0; j < allEntityFactories.length; j++) {
        let lookupEntityFactory = allEntityFactories[j];
        if (
          lookupEntityFactory.definition.entityName === childField.lookup &&
          lookupEntityFactory.definition.views.length === 0 &&
          lookupEntityFactory.definition.type === "entity"
        ) {
          if (lookupEntityFactory.hasSomeViewRights(currentUser)) {
            const lookupName = lookupEntityFactory.definition.entityName;
            let lookupIcon = lookupEntityFactory.definition.icon;

            if (isNull(lookupIcon)) lookupIcon = "";
            let lookupTitle = isEmpty(childField.title)
              ? lookupEntityFactory.definition.entityName
              : childField.title;
            let lookupShort = "";
            // console.log(
            //   ">>> SIDELINKS",
            //   entityFactory.definition.entityName,
            //   lookupName,
            //   lookupShort,
            //   lookupIcon
            // );
            fields.push(
              `{
            "fieldName": "` +
                "ef_" +
                lookupName +
                `",
            "type": "entityform",
            "hidden": false,
            "lookup": "` +
                lookupName +
                `",
            "showFilter": false,
            "tab": "` +
                "ef_" +
                lookupName +
                `",
            "lookupFilter": {
                  "id": {
                      "val": "%%` +
                childField.fieldName +
                `"
                  }
              }}`
            );
            formSections.push({
              id: "ef_" + lookupName,
              icon: lookupIcon,
              title: lookupTitle,
              short: lookupShort,
            });
          }
        }
      }
    }
  }
  sections = [...formSections, ...sections];
  sections = sections.map((item) => JSON.stringify(item));
  return { sections, fields };
}

function prepareFormTokens(entityFactory, sections, fields) {
  const entityName = entityFactory.definition.entityName;
  const title = entityFactory.definition.title;
  const short = entityFactory.definition.short;

  let icon = entityFactory.definition.icon;
  if (isNull(icon)) icon = "";
  if (isEmpty(fields)) return { entityName, title, short, icon };

  const fieldsString = fields.join(",");
  const sectionsString = sections.join(",");

  return { entityName, title, short, icon, fieldsString, sectionsString };
}

function getAutoSideLinkFormPage(entityFactory, sections, fields) {
  const { entityName, title, short, icon, fieldsString, sectionsString } =
    prepareFormTokens(entityFactory, sections, fields);
  //console.log(">>> SIDELINK AUTO FORM", entityName, icon);

  let autoPage = {
    id: entityName + "__form",
    name: entityName,
    versionId: 1,
    definition: JSON.parse(
      `{
    "entityName": "` +
        entityName +
        `",
    "title": "` +
        title +
        `",
    "short": "` +
        short +
        `",
    "fields": [
        {
            "fieldName": "` +
        entityName +
        `",
            "type": "entityform",
            "hidden": false,
            "readOnly": false,
            "lookup": "` +
        entityName +
        `",
            "tab": "` +
        entityName +
        `",
            "lookupFilter": {
                "id": {
                    "val": "%%id"
                }
            }
        },` +
        fieldsString +
        `
    ],
    "sections": [
        {
            "id": "` +
        entityName +
        `",
            "title": "Details",
            "icon": "` +
        icon +
        `"
        },` +
        sectionsString +
        `,
        {
          "id": "gobackbutton",
          "title": "Back",
          "workflow": "%%back",
          "icon": "md-arrow-back"
        }
    ],
    "displayMode": "pills",
    "subDefinitions": [],
    "lookup": "` +
        entityName +
        `"}`
    ),
  };
  // console.log(
  //   "------------------------------------------ AUTOPAGE",
  //   entityName
  // );
  // console.log(JSON.stringify(autoPage));
  return autoPage;
}

function getAutoBottomLinkFormPage(entityFactory, sections, fields) {
  const { entityName, title, short, icon, fieldsString, sectionsString } =
    prepareFormTokens(entityFactory, sections, fields);
  //console.log(">>> BOTTOMLINK AUTO FORM", entityName, icon);

  return {
    id: entityName + "__form",
    name: entityName,
    versionId: 1,
    definition: JSON.parse(
      `{
    "entityName": "` +
        entityName +
        `",
    "title": "` +
        title +
        `",
    "short": "` +
        short +
        `",
  "fields": [
      {
          "fieldName": "details",
          "type": "entityform",
          "hidden": false,
          "lookup": "` +
        entityName +
        `",
          "tab": "Details",
          "lookupFilter": {
              "id": {
                  "val": "%%id"
              }
          }
      },
      {
          "fieldName": "other",
          "type": "object",
          "definition": {
              "entityName": "other",
              "fields": [` +
        fieldsString +
        `
              ],
              "displayMode": "pills",
              "sections": [` +
        sectionsString +
        `
              ]
          },
          "tab": "Links"
      }
  ],
  "sections": [
      {
          "id": "Details",
          "columns": 1,
          "icon": "` +
        icon +
        `"
      },
      {
          "id": "Links",
          "icon": "fa-ellipsis-h"
      }
  ],
  "displayMode": "None",
  "subDefinitions": [],
  "lookup": "` +
        entityName +
        `"
}`
    ),
  };
}

function getPlainFormPage(entityFactory) {
  const { entityName, title, short } = prepareFormTokens(
    entityFactory,
    null,
    null
  );
  //console.log(">>> NO LINKS AUTO FORM", entityName, icon);
  return {
    id: entityName + "__form",
    name: entityName,
    versionId: 1,
    definition: JSON.parse(
      `{
        "entityName": "` +
        entityName +
        `",
        "title": "` +
        title +
        `",
        "short": "` +
        short +
        `",
        "fields": [
            {
                "fieldName": "field",
                "type": "entityform",
                "hidden": false,
                "hideWorkflowButtons": true,
                "readOnly": false,
                "lookup": "` +
        entityName +
        `",
                "lookupFilter": {
                    "id": {
                        "val": "%%id"
                    }
                }
            }
        ],
        "sections": [
            {
                "id": "main"
            }
        ],
        "displayMode": "None",
        "subDefinitions": [],
        "lookup": "` +
        entityName +
        `"
    }`
    ),
  };
}
function getAutoFormForEntity(
  currentUser,
  entityFactory,
  page,
  navigation,
  allEntityFactories
) {
  if (page) {
    const entityName = entityFactory.definition.entityName;
    let newPage = { ...page };
    newPage.id = entityName + "__form";
    newPage.name = entityName;
    return newPage;
  } else {
    const isSideLink = navigation === "sideLinks";
    const isBottomLink = navigation === "links";
    if (isSideLink || isBottomLink) {
      const { sections, fields } = calcFieldsAndSections(
        currentUser,
        allEntityFactories,
        entityFactory
      );

      if (fields.length > 0) {
        if (isSideLink) {
          return getAutoSideLinkFormPage(entityFactory, sections, fields);
        } else {
          return getAutoBottomLinkFormPage(entityFactory, sections, fields);
        }
      }
    }
    return getPlainFormPage(entityFactory);
  }
}

function getPageItem(allEntityFactories, uiGraph, pageName) {
  let page = null;
  let pageEntityFactory = findEntityFactory(allEntityFactories, "Page");
  if (pageName) {
    if (!isNull(pageEntityFactory)) {
      page = cloneDeep(pageEntityFactory.getRowByName(uiGraph, pageName));
      try {
        let def = JSON.parse(page.definition);
        page.definition = def;
      } catch (ex) {
        page = null;
      }
    }
  }
  return page;
}

function getEntityRoutes(
  commonProps,
  currentUser,
  allEntityFactories,
  changeLoginState,
  autoGridPages,
  autoFormPages,
  autoFilePages
) {
  let hasKeys = autoGridPages && Object.keys(autoGridPages).length > 0;
  let entityRoutes = [];
  if (hasKeys) {
    for (let i = 0; i < allEntityFactories.length; i++) {
      let entityFactory = allEntityFactories[i];
      if (entityFactory.hasSomeViewRights(currentUser)) {
        let entityName = entityFactory.definition.pluralName;

        let gridPage = autoGridPages[entityFactory.definition.entityName];
        entityRoutes.push(
          <Route
            key={"e1_" + entityName}
            exact
            path={"/entities/" + entityName.toLowerCase()}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={gridPage ? gridPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );
        entityRoutes.push(
          <Route
            key={"e2_" + entityName}
            exact
            path={"/sideview/" + entityName.toLowerCase()}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={gridPage ? gridPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );
        entityRoutes.push(
          <Route
            key={"e3_" + entityName}
            exact
            path={"/taskview/" + entityName.toLowerCase()}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={gridPage ? gridPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );
        entityRoutes.push(
          <Route
            key={"e4_" + entityName}
            exact
            path={"/entityonly/" + entityName.toLowerCase()}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={gridPage ? gridPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );

        let formPage = autoFormPages[entityFactory.definition.entityName];
        entityRoutes.push(
          <Route
            key={"e1_" + entityName}
            exact
            path={"/entities/" + entityName.toLowerCase() + "/:entityId?"}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={formPage ? formPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );
        entityRoutes.push(
          <Route
            key={"e2_" + entityName}
            exact
            path={"/sideview/" + entityName.toLowerCase() + "/:entityId?"}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={formPage ? formPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );
        entityRoutes.push(
          <Route
            key={"e3_" + entityName}
            exact
            path={"/taskview/" + entityName.toLowerCase() + "/:entityId?"}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={formPage ? formPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );
        entityRoutes.push(
          <Route
            key={"e4_" + entityName}
            exact
            path={"/entityonly/" + entityName.toLowerCase() + "/:entityId?"}
            render={(props) => (
              <WrappedDBPage
                changeLoginState={changeLoginState}
                pageId={formPage ? formPage.id : null}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );

        let FilePage = autoFilePages[entityFactory.definition.entityName];
        entityRoutes.push(
          <Route
            key={"e4_" + entityName}
            exact
            path={"/files/" + entityName.toLowerCase() + "/:fileId?"}
            render={(props) => (
              <FilePage
                changeLoginState={changeLoginState}
                commonProps={commonProps}
                {...commonProps}
                {...props}
              />
            )}
          />
        );
      }
    }
  }
  entityRoutes.push(
    <Route
      key={"p_pe"}
      path="/page/:pageId/:entityId?"
      render={(props) => (
        <WrappedDBPage
          changeLoginState={changeLoginState}
          commonProps={commonProps}
          {...commonProps}
          {...props}
        />
      )}
    />
  );
  entityRoutes.push(
    <Route
      key={"p_t1"}
      exact
      path="/threads"
      render={(props) => (
        <WrappedDBPage
          changeLoginState={changeLoginState}
          pageId={"__tasks"}
          commonProps={commonProps}
          {...props}
          {...commonProps}
        />
      )}
    />
  );
  entityRoutes.push(
    <Route
      key={"p_t2"}
      path="/threads/:taskId?"
      render={(props) => (
        <WrappedWizardPage
          changeLoginState={changeLoginState}
          commonProps={commonProps}
          {...props}
          {...commonProps}
        />
      )}
    />
  );
  let TaskFilePage = withFileQuery(FilePage);
  entityRoutes.push(
    <Route
      key={"p_ft"}
      path="/files/task/:fileId?/:entityId?"
      render={(props) => (
        <TaskFilePage
          changeLoginState={changeLoginState}
          commonProps={commonProps}
          {...commonProps}
          {...props}
        />
      )}
    />
  );
  return entityRoutes;
}

function getStateFromProps(
  currentUser,
  allEntityFactories,
  layoutGraph,
  uiGraph,
  loggedIn,
  screenSize
) {
  let navigationDefaults = {};
  let autoGridPages = {};
  let autoFormPages = {};
  let autoFilePages = {};
  let pagesById = {};
  let pagesByName = {};
  let drawerMenu = undefined;
  let drawerIcons = undefined;
  if (
    allEntityFactories &&
    allEntityFactories.length > 0 &&
    uiGraph?.allMenus
  ) {
    let pages = getAllPages(allEntityFactories, uiGraph);
    pagesById = pages.pagesById;
    pagesByName = pages.pagesByName;
    let dbMenuRow = getUserMenuItem(
      currentUser,
      allEntityFactories,
      layoutGraph,
      uiGraph
    );
    for (let i = 0; i < allEntityFactories.length; i++) {
      let entityFactory = allEntityFactories[i];
      if (currentUser && entityFactory.hasSomeViewRights(currentUser)) {
        let pageId = null;
        let page = null;
        let navigation = "sideLinks";
        if (!isEmpty(entityFactory.definition.navigation)) {
          navigation = entityFactory.definition.navigation;
        }
        let navigationDefault = {
          navigation,
          pageId: undefined,
          page: undefined,
        };
        if (navigation === "page") {
          if (!isEmpty(entityFactory.definition.navigationPage)) {
            page = getPageItem(
              allEntityFactories,
              uiGraph,
              entityFactory.definition.navigationPage
            );
            if (!isNull(page)) {
              pageId = page.id;
              navigationDefault.pageId = pageId;
              navigationDefault.page = page;
            } else {
              navigationDefault.navigation = "sideLinks";
            }
          } else {
            navigationDefault.navigation = "sideLinks";
          }
        }
        navigationDefaults[entityFactory.definition.entityName] =
          navigationDefault;
      }
    }

    if (
      dbMenuRow &&
      dbMenuRow.definition &&
      dbMenuRow.definition.defaultNavigation &&
      dbMenuRow.definition.defaultNavigation.length > 0
    ) {
      for (let i = 0; i < dbMenuRow.definition.defaultNavigation.length; i++) {
        let menuNavItem = dbMenuRow.definition.defaultNavigation[i];
        let entityName = menuNavItem.entity;
        let menuPageId = null;
        let menuNavigation = "sideLinks";
        let page = undefined;
        if (!isEmpty(menuNavItem.navigation)) {
          menuNavigation = menuNavItem.navigation;
        }
        if (menuNavigation === "page" && !isEmpty(menuNavItem.navigationPage)) {
          page = getPageItem(
            allEntityFactories,
            uiGraph,
            menuNavItem.navigationPage
          );
          if (!isNull(page)) {
            menuPageId = page.id;
          } else {
            page = undefined;
          }
        }
        let navigationDefault = navigationDefaults[entityName];
        if (!isNull(navigationDefault)) {
          navigationDefaults[entityName] = {
            navigation: menuNavigation,
            pageId: menuPageId,
            page: page,
          };
        }
      }
    }
    for (let i = 0; i < allEntityFactories.length; i++) {
      let entityFactory = allEntityFactories[i];
      let navigationDefault =
        navigationDefaults[entityFactory.definition.entityName];
      if (!isNull(navigationDefault)) {
        autoGridPages[entityFactory.definition.entityName] =
          getAutoGridForEntity(
            entityFactory.definition.entityName,
            entityFactory.definition.title,
            entityFactory.definition.short
          );
        autoFormPages[entityFactory.definition.entityName] =
          getAutoFormForEntity(
            currentUser,
            entityFactory,
            navigationDefault.page,
            navigationDefault.navigation,
            allEntityFactories
          ); //{ id: entityFactory.definition.entityName + "__form", PageComponent: entityFactory.getPageWithEntity("User View"), formEntityFactory: entityFactory }; //this.getAutoFormForEntity(entityFactory.definition.entityName, page);
      }
      autoFilePages[entityFactory.definition.entityName] = checkUser(
        withTaskInterrupt(entityFactory.getFilePage())
      );
    }
    let menu = getMenu(
      currentUser,
      { ...layoutGraph, allPages: uiGraph.allPages },
      uiGraph,
      allEntityFactories,
      dbMenuRow,
      navigationDefaults,
      "Default"
    );
    drawerMenu = {};
    drawerIcons = {};
    for (let key in menu) {
      if (Object.prototype.hasOwnProperty.call(menu, key)) {
        let menuItem = menu[key];
        let rootMenuName = menuItem.path[0];
        if (!Object.prototype.hasOwnProperty.call(drawerMenu, rootMenuName)) {
          drawerMenu[rootMenuName] = [];
        }
        let icon = menuItem.icon;
        if (!isNull(icon) && menuItem.path && menuItem.path.length === 1) {
          if (menuItem.icon.startsWith("fa-")) {
            icon = icon.substring(3);
          } else if (menuItem.icon.startsWith("far-")) {
            icon = icon.substring(4);
          } else if (menuItem.icon.startsWith("fas-")) {
            icon = icon.substring(4);
          } else if (menuItem.icon.startsWith("fa ")) {
            icon = icon.substring(3);
          } else if (menuItem.icon.startsWith("fas ")) {
            icon = icon.substring(4);
          } else if (menuItem.icon.startsWith("far ")) {
            icon = icon.substring(4);
          }
          drawerIcons[menuItem.path[0]] = icon;
        }
        drawerMenu[rootMenuName].push({
          url: key.split(":")[0],
          menuNames: menuItem.path,
          icon: icon,
        });
      }
    }
    for (let key in drawerMenu) {
      if (Object.prototype.hasOwnProperty.call(drawerMenu, key)) {
        let menuItems = drawerMenu[key];
        if (menuItems.length > 1) {
          let rootIndex = -1;
          for (let i = 0; i < menuItems.length; i++) {
            let menuItem = menuItems[i];
            if (menuItem.menuNames.length === 1) {
              rootIndex = i;
              break;
            }
          }
          if (rootIndex > -1) {
            //Remove it.
            menuItems.splice(rootIndex, 1);
          }
        }
      }
    }
  }

  let isLoggedIn = loggedIn && !isNull(drawerMenu) && !isNull(layoutGraph);
  let hasSideBar =
    isLoggedIn &&
    drawerMenu &&
    Platform.OS === "web" &&
    screenSize > SCREEN.SMALL;
  let menuOnlyHasHome = false;
  if (drawerMenu) {
    let keys = Object.keys(drawerMenu);
    if (
      keys.length === 1 &&
      drawerMenu[keys[0]].length === 1 &&
      drawerMenu[keys[0]][0].url === "/home"
    ) {
      hasSideBar = false;
      menuOnlyHasHome = currentUser?.id === 21;
    }
  }
  return {
    drawerMenu,
    drawerIcons,
    navigationDefaults,
    autoGridPages,
    autoFormPages,
    autoFilePages,
    pagesById,
    pagesByName,
    hasSideBar,
    menuOnlyHasHome,
    lastUserId: currentUser?.id,
    lastLoggedIn: loggedIn,
    lastAllMenus: uiGraph?.allMenus,
    lastScreenSize: screenSize,
  };
}
// connected-react-router
// https://github.com/supasate/connected-react-router/blob/master/examples/basic/src/components/Counter.js
// Manage all local state in redux.  Let react router manage routes.  Grab initial state for page from

// eslint-disable-next-line react/display-name
const BrowserRoutes = forwardRef((props, ref) => {
  const history = useHistory();
  const { addWorkflowLog, formCache } = useCache();
  const {
    twoFactorToken,
    currentUser,
    layoutGraph,
    entityGraph,
    uiGraph,
    allEntityFactories,
    changeLoginState,
    publicStyle,
    windowWidth,
    windowHeight,
    loggedIn,
  } = props;
  const layoutGraphCurrentUserId = layoutGraph?.currentUser?.id;
  const subscriptions = useRef({});
  const [subscriptionsReady, setSubscriptionsReady] = useState(false);
  //console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
  //console.log(">>> NEW BrowserRoutes");
  //console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
  const screenSize = useMemo(() => getScreenSize(windowWidth), [windowWidth]);
  //{ drawerMenu, drawerIcons, navigationDefaults, autoGridPages, autoFormPages, autoFilePages, pagesById, pagesByName }
  const [uiState, setUIState] = useState(() =>
    getStateFromProps(
      currentUser,
      allEntityFactories,
      layoutGraph,
      uiGraph,
      loggedIn,
      screenSize
    )
  );

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      () => {
        let location = history.location;
        history.goBack(); // works best when the goBack is async
        if (location !== history.location) {
          return true;
        }
        return false;
      }
    );
    return () => {
      console.log("SUBSCRIPTION: TEARING DOWN");
      if (allEntityFactories) {
        allEntityFactories.forEach((entityFactory) => {
          if (!entityFactory.definition.readOnly && entityFactory.cacheable()) {
            if (
              subscriptions.current[
                "on" + entityFactory.definition.pluralName + "Changed"
              ]
            ) {
              subscriptions.current[
                "on" + entityFactory.definition.pluralName + "Changed"
              ]();
              delete subscriptions.current[
                "on" + entityFactory.definition.pluralName + "Changed"
              ];
            }
            if (
              subscriptions.current[
                "on" + entityFactory.definition.pluralName + "Deleted"
              ]
            ) {
              subscriptions.current[
                "on" + entityFactory.definition.pluralName + "Deleted"
              ]();
              delete subscriptions.current[
                "on" + entityFactory.definition.pluralName + "Deleted"
              ];
            }
          }
        });
      }
      if (subscriptions.current["onThreadsChanged"]) {
        subscriptions.current["onThreadsChanged"]();
        delete subscriptions.current["onThreadsChanged"];
      }
      if (subscriptions.current["onThreadsDeleted"]) {
        subscriptions.current["onThreadsDeleted"]();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        delete subscriptions.current["onThreadsDeleted"];
      }
      backHandler.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autoBackOnCompletedTask = useCallback(
    (tasks) => {
      if (history) {
        if (tasks.length > 0) {
          let ids = tasks.map((a) => a.id);
          let redirectHints = tasks.map((a) => a.redirectHint);
          if (!isEmpty(ids)) {
            let location = history.location
              ? history.location.pathname + ""
              : "";
            for (let i = 0; i < ids.length; i++) {
              if (location.endsWith(ids[i])) {
                let hint = redirectHints[i];
                if (hint) {
                  if (hint.startsWith("/")) {
                    history.push(hint);
                  } else {
                    history.push("/threads/" + hint.toString());
                  }
                } else {
                  history.goBack();
                }
              }
            }
          }
        }
      }
    },
    [history]
  );

  useEffect(() => {
    //console.log("USEEFFECT 1", layoutGraph, layoutGraphCurrentUserId);
    if (layoutGraphCurrentUserId) {
      console.log("SUBSCRIPTION: SETTING UP", currentUser?.id);
      allEntityFactories.forEach((entityFactory) => {
        let isEntity = entityFactory.definition.entityName === "Entity";
        let isUI = entityFactory.entityIsValidForUIGraph();
        let currentQuery = isEntity
          ? entityGraph
          : isUI
          ? uiGraph
          : layoutGraph;
        if (!entityFactory.definition.readOnly && entityFactory.cacheable()) {
          if (
            !subscriptions.current[
              "on" + entityFactory.definition.pluralName + "Changed"
            ]
          ) {
            subscriptions.current[
              "on" + entityFactory.definition.pluralName + "Changed"
            ] = currentQuery.subscribeToMore({
              document: entityFactory.getGraphUpdateSubscription(),
              variables: {},
              updateQuery: (prev, { subscriptionData }) => {
                return entityFactory.updateLayoutGraphAfterUpdate(
                  allEntityFactories,
                  isUI,
                  prev,
                  subscriptionData.data[
                    entityFactory.camelCasePluralName() + "Changed"
                  ]
                );
              },
            });
          }
          if (
            !subscriptions.current[
              "on" + entityFactory.definition.pluralName + "Deleted"
            ]
          ) {
            subscriptions.current[
              "on" + entityFactory.definition.pluralName + "Deleted"
            ] = currentQuery.subscribeToMore({
              document: entityFactory.getGraphDeleteSubscription(),
              variables: {},
              updateQuery: (prev, { subscriptionData }) => {
                return entityFactory.updateLayoutGraphAfterDelete(
                  prev,
                  isUI,
                  subscriptionData.data[
                    entityFactory.camelCasePluralName() + "Deleted"
                  ]
                );
              },
            });
          }
        }
      });

      if (!subscriptions.current["onThreadsChanged"]) {
        //console.log(">>> recreating onThreadsChanged", this["onThreadsChanged"])
        subscriptions.current["onThreadsChanged"] = layoutGraph.subscribeToMore(
          {
            document: THREADSCHANGED_GQL,
            variables: {},
            updateQuery: (prev, { subscriptionData }) => {
              //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
              console.log(
                "++++++++++++++ onThreadsChanged",
                subscriptionData.data["threadsChanged"]
              ); //, timeStampInMs
              let threads = subscriptionData.data["threadsChanged"];
              if (threads) {
                threads.forEach((thread) => {
                  addWorkflowLog(thread.taskId, thread.timeStamp, false);
                });
                return updateThreadLayout(prev, threads, true);
              }
            },
            onError: (err) => console.error(err),
          }
        );
      }
      if (!subscriptions.current["onThreadsDeleted"]) {
        //console.log(">>> recreating onThreadsDeleted", this["onThreadsDeleted"])
        subscriptions.current["onThreadsDeleted"] = layoutGraph.subscribeToMore(
          {
            document: THREADSDELETED_GQL,
            variables: {},
            updateQuery: (prev, { subscriptionData }) => {
              //var timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
              //console.log("++++++++++++++ onThreadsDeleted", subscriptionData.data["threadsDeleted"]) //, timeStampInMs)
              autoBackOnCompletedTask(subscriptionData.data["threadsDeleted"]);
              return deleteThreadLayout(
                prev,
                subscriptionData.data["threadsDeleted"]
              );
            },
          }
        );
      }
      setSubscriptionsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutGraphCurrentUserId]);

  useEffect(() => {
    //console.log("USEEFFECT 2");
    if (layoutGraph?.currentUser?.id) {
      setUIState((prevState) => {
        if (
          prevState.lastUserId !== layoutGraph?.currentUser?.id ||
          prevState.lastLoggedIn !== loggedIn ||
          prevState.lastAllMenus !== uiGraph?.allMenus ||
          prevState.lastScreenSize !== screenSize
        ) {
          // console.log(
          //   "NEW STATE FROM PROPS",
          //   prevState.lastUserId,
          //   layoutGraph?.currentUser?.id
          // );
          // console.log("NEW STATE FROM PROPS", prevState.lastLoggedIn, loggedIn);
          // console.log(
          //   "NEW STATE FROM PROPS",
          //   prevState.lastAllMenus,
          //   uiGraph?.allMenus
          // );
          // console.log(
          //   "NEW STATE FROM PROPS",
          //   prevState.lastScreenSize,
          //   screenSize
          // );
          return getStateFromProps(
            currentUser,
            allEntityFactories,
            layoutGraph,
            uiGraph,
            loggedIn,
            screenSize
          );
        }
        return prevState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutGraph?.currentUser?.id, loggedIn, uiGraph?.allMenus, screenSize]);

  useEffect(() => {
    //console.log("USEEFFECT 3");
    if (!isEmpty(twoFactorToken)) {
      let location = history.location ? history.location.pathname : "UNKNOWN";
      if (!isEmpty(location) && location !== "/" && location !== "/home") {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFactorToken]);

  useImperativeHandle(ref, () => ({
    showError: (graphQLErrors) => {
      let handled = false;
      if (graphQLErrors && formCache) {
        graphQLErrors.map(({ message }) => {
          try {
            let msg = JSON.parse(message);
            console.log("MANAGED ALERT", msg);
            if (msg.toast) {
              Toast.toast(`${msg.message}`, 5000);
            } else {
              if (!isEmpty(msg.redirect)) {
                let params = {};
                if (!isEmpty(msg.message)) {
                  params.message = msg.message;
                }
                if (!isEmpty(msg.version)) {
                  params.version = msg.version;
                }
                window.location.replace(
                  getEnvironment().SITE_URL +
                    msg.redirect +
                    "?" +
                    new URLSearchParams(params).toString()
                );
              } else {
                let subject = isEmpty(msg.subject) ? "Warning" : msg.subject;
                formCache["MessageForm"] = JSON.stringify({
                  values: { subject, message: msg.message },
                });
                history.push("/page/messageresult");
              }
            }
            handled = true;
            // eslint-disable-next-line no-empty
          } catch {}
        });
      }
      return handled;
    },
  }));

  //START RENDER
  const {
    drawerMenu,
    drawerIcons,
    hasSideBar,
    autoGridPages,
    autoFormPages,
    autoFilePages,
    navigationDefaults,
    pagesById,
    pagesByName,
    menuOnlyHasHome,
  } = uiState;

  let finalLayoutGraph = undefined;
  if (layoutGraph && !layoutGraph.loading) {
    if (uiGraph) {
      if (entityGraph) {
        finalLayoutGraph = { ...layoutGraph, ...uiGraph, ...entityGraph };
      } else {
        finalLayoutGraph = { ...layoutGraph, ...uiGraph };
      }
    } else {
      if (entityGraph) {
        finalLayoutGraph = { ...layoutGraph, ...entityGraph };
      } else {
        finalLayoutGraph = { ...layoutGraph };
      }
    }
  }
  let isLoggedIn =
    loggedIn &&
    !isNull(drawerMenu) &&
    !isNull(finalLayoutGraph) &&
    !isNull(subscriptions.current) &&
    subscriptionsReady;
  console.log(">>> subscriptionsReady", subscriptionsReady);
  let commonProps = {
    loggedIn: isLoggedIn,
    currentUser: currentUser,
    layoutGraph: finalLayoutGraph,
    allEntityFactories: allEntityFactories,
    changeLoginState: changeLoginState,
    drawerMenu: drawerMenu,
    navigationDefaults: navigationDefaults,
    autoGridPages: autoGridPages,
    autoFormPages: autoFormPages,
    pagesById: pagesById,
    pagesByName: pagesByName,
    publicStyle: publicStyle,
    hasSideBar: hasSideBar,
    menuOnlyHasHome: menuOnlyHasHome,
    windowWidth,
    windowHeight,
  };

  let entityRoutes = isLoggedIn
    ? getEntityRoutes(
        commonProps,
        currentUser,
        allEntityFactories,
        changeLoginState,
        autoGridPages,
        autoFormPages,
        autoFilePages
      )
    : [];
  //console.log("Browser Routes render", finalLayoutGraph);
  return (
    <View
      style={{
        flex: 1,
        width: "100%",
      }}
    >
      {hasSideBar ? (
        <Suspense fallback={<View />}>
          <Sidebar
            currentUser={currentUser}
            changeLoginState={changeLoginState}
            drawerMenu={drawerMenu}
            drawerIcons={drawerIcons}
            screenSize={screenSize}
            loggedIn={isLoggedIn}
            publicStyle={publicStyle}
          />
        </Suspense>
      ) : undefined}
      <Switch>
        <Route
          key={"home"}
          exact={isLoggedIn ? true : false}
          path="/"
          render={() => (
            <Landing
              changeLoginState={changeLoginState}
              twoFactorToken={twoFactorToken}
              pageName={"home"}
              commonProps={commonProps}
              {...commonProps}
            />
          )}
        />
        {isLoggedIn ? (
          <Route
            key={"home"}
            path="/home"
            render={() => (
              <Landing
                changeLoginState={changeLoginState}
                twoFactorToken={twoFactorToken}
                pageName={"home"}
                commonProps={commonProps}
                {...commonProps}
              />
            )}
          />
        ) : undefined}
        {isLoggedIn ? entityRoutes : undefined}
      </Switch>
    </View>
  );
});

/*
        {!loggedIn ? <Route key={"register"} exact={loggedIn ? true : false} path="/register" render={(props) => <Landing changeLoginState={changeLoginState} twoFactorToken={twoFactorToken} pageName={"register"} commonProps={commonProps} {...commonProps} />} /> : undefined}
        {!loggedIn ? <Route key={"reset"} exact={loggedIn ? true : false} path="/reset" render={(props) => <Landing changeLoginState={changeLoginState} twoFactorToken={twoFactorToken} pageName={"reset"} commonProps={commonProps} {...commonProps} />} /> : undefined}
*/

export default BrowserRoutes;
