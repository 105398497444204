/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { Suspense, useEffect, useRef, useState, useMemo } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import definitionHelper from "../util/definitions.js";
import { isNull, isEmpty } from "../util/common.js";
import * as cloneDeep from "lodash/cloneDeep";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chance from "chance";
import HTML from "../html";
import { Text } from "react-native";
import {
  Image,
  ImageBackground,
  View,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import { resolveTokens } from "../util/tokens";
import { cssStringToRNStyle } from "../html/HTMLStyles"; //HTMLUtils
import { STYLESETS } from "../html/HTMLUtils";
import { DiscardButton } from "../form/widgets/index";
import { SearchButton } from "../form/widgets/index";
import TabBar from "../components/TabBar";
import { Link } from "../ReactRouter";
import { getTokenResolversFromTemplate } from "../util/tokens";
import { withRouter } from "../ReactRouter";

const chance = new Chance();

function setKey(_htmlAttribs) {
  return _htmlAttribs && isEmpty(_htmlAttribs.key)
    ? chance.guid()
    : _htmlAttribs.key;
}

function convertStringStyleToReactNativeStyle(stringStyle, rnClass) {
  if (!isNull(stringStyle)) {
    let rnStyle = cssStringToRNStyle(stringStyle, STYLESETS.TEXT, {});
    return { ...rnClass, ...rnStyle };
  } else {
    return { ...rnClass };
  }
}

function replaceTokens(tokens, text) {
  let result = text;
  for (let token in tokens) {
    if (Object.prototype.hasOwnProperty.call(tokens, token)) {
      let replacement = tokens[token];
      if (isNull(replacement)) replacement = "";
      let fixedToken = token.replace("?", "\\?");
      let regex = new RegExp("{" + fixedToken + "}", "g");
      result = result.replace(regex, replacement);
    }
  }

  return result;
}

function calcHTMLState(
  definition,
  template,
  rawItem,
  layoutGraph,
  versionId,
  tokenResolvers
) {
  let newState = {};
  newState.template = template;
  let resolvedItem = resolveTokens(
    layoutGraph,
    definition,
    rawItem,
    tokenResolvers
  );
  let html = replaceTokens(resolvedItem, newState.template);
  return {
    html,
    versionId: versionId,
    template: newState.template,
  };
}

function suggestSubdefinition(rootDefinition, subDefinition) {
  let suggestedDefinition = subDefinition;
  if (suggestedDefinition) {
    let exists = false;
    for (let i = 0; i < rootDefinition.subDefinitions.length; i++) {
      if (rootDefinition.subDefinitions[i].viewName === suggestedDefinition) {
        exists = true;
        break;
      }
    }
    if (!exists) {
      suggestedDefinition = null;
    }
  }
  return suggestedDefinition;
}

function calculateSubdefinition(rootDefinition, subDefinition) {
  let suggestedDefinition = suggestSubdefinition(rootDefinition, subDefinition);
  if (!isEmpty(suggestedDefinition) && suggestedDefinition !== "default") {
    return definitionHelper.findSubDefinition(
      rootDefinition,
      suggestedDefinition
    );
  }
  return rootDefinition;
}

function getTemplate(isCard, definition) {
  if (isCard) {
    return definition.card;
  } else {
    return definition.formTemplate;
  }
}

function getStateFromProps(
  rawItem,
  template,
  definition,
  layoutGraph,
  versionId,
  isCard,
  tokenResolvers,
  prevState
) {
  if (
    isNull(rawItem) ||
    isNull(prevState.versionId) ||
    versionId !== prevState.versionId ||
    (!isCard && template !== prevState.template)
  ) {
    return calcHTMLState(
      definition,
      template,
      rawItem,
      layoutGraph,
      versionId,
      tokenResolvers
    );
  }
  return prevState;
}

function getClassStyles(rootDefinition, subDef) {
  let classStyles = {};
  if (!isNull(subDef.styles)) {
    try {
      classStyles = JSON.parse(subDef.styles);
      if (isNull(classStyles)) classStyles = {};
      //console.log("RENDERING TEMPLATE WITH EXTRA STYLES:", classesStyles)
    } catch (ex) {
      console.log(
        "ERROR: UNABLE TO PARSE TEMPLATE STYLES",
        subDef.styles,
        rootDefinition
      );
    }
  }
  return classStyles;
}

function getTextChild(children) {
  let realChild = "";
  if (children && children.length > 0) {
    let child1 = children[0];
    if (child1 && child1.length > 0) {
      let child2 = child1[0];
      if (child2.props) {
        let child3 = child2.props;
        if (child3.children && child3.children.length > 0) {
          let child4 = child3.children[0];
          if (child4 && child4.props && !isEmpty(child4.props.children)) {
            realChild = child4.props.children;
          }
        }
      }
    }
  }
  return realChild;
}

function getCommonRenderers(history, classesStyles) {
  return {
    table: (_htmlAttribs, children) => {
      let rnClass = {};
      let style = convertStringStyleToReactNativeStyle(
        _htmlAttribs.style,
        rnClass
      );
      return (
        <table
          style={{ ...style }}
          width={
            _htmlAttribs?.width ? parseInt(_htmlAttribs?.width) : undefined
          }
        >
          {children}
        </table>
      );
    },
    tr: (_htmlAttribs, children) => {
      return <tr>{children}</tr>;
    },
    td: (_htmlAttribs, children) => {
      return <td align={_htmlAttribs?.align}>{children}</td>;
    },
    img: (_htmlAttribs) => {
      return (
        <img
          alt={_htmlAttribs.alt}
          width={_htmlAttribs.width}
          height={_htmlAttribs.height}
          src={_htmlAttribs.src}
        />
      );
    },
    image: (_htmlAttribs) => {
      let rnClass = {};
      if (!isNull(classesStyles) && !isNull(_htmlAttribs.class)) {
        rnClass = classesStyles[_htmlAttribs.class];
        if (isNull(rnClass)) rnClass = {};
      }
      let style = convertStringStyleToReactNativeStyle(
        _htmlAttribs.style,
        rnClass
      );
      return (
        <Image
          key={setKey(_htmlAttribs)}
          style={{ ...style }}
          source={{ uri: _htmlAttribs.src }}
        />
      );
    },
    a: (_htmlAttribs, children) => {
      let title = getTextChild(children);
      let val = isNull(_htmlAttribs.href) ? "" : _htmlAttribs.href;
      if (val.startsWith("http")) {
        if (isEmpty(title) && children) {
          return (
            <a href={val} target={_htmlAttribs.target} rel={_htmlAttribs.rel}>
              {children}
            </a>
          );
        } else {
          return (
            <a href={val} target={_htmlAttribs.target} rel={_htmlAttribs.rel}>
              {title}
            </a>
          );
        }
      } else if (val === "%%back") {
        return (
          <Link
            key={setKey(_htmlAttribs)}
            onClick={(e) => {
              e.stopPropagation();
              history.goBack();
            }}
            style={{ flex: 1 }}
          >
            {title}
          </Link>
        );
      } else {
        return (
          <Link key={setKey(_htmlAttribs)} style={{ flex: 1 }} to={val}>
            {title}
          </Link>
        );
      }
    },
    imagebackground: (_htmlAttribs, children) => {
      let rnClass = {};
      let imageStyle = convertStringStyleToReactNativeStyle(
        _htmlAttribs.imageStyle,
        rnClass
      );
      if (!isNull(classesStyles) && !isNull(_htmlAttribs.class)) {
        rnClass = classesStyles[_htmlAttribs.class];
        if (isNull(rnClass)) rnClass = {};
      }
      let style = convertStringStyleToReactNativeStyle(
        _htmlAttribs.style,
        rnClass
      );
      return (
        <ImageBackground
          key={setKey(_htmlAttribs)}
          style={{ ...style }}
          imageStyle={{ ...imageStyle }}
          source={{ uri: _htmlAttribs.src }}
        >
          {children}
        </ImageBackground>
      );
    },
    scrollview: (_htmlAttribs, children) => {
      let rnClass = {};
      if (!isNull(classesStyles) && !isNull(_htmlAttribs.class)) {
        rnClass = classesStyles[_htmlAttribs.class];
        if (isNull(rnClass)) rnClass = {};
      }
      let style = convertStringStyleToReactNativeStyle(
        _htmlAttribs.style,
        rnClass
      );
      style.height = 100;
      style.width = "100%";
      return (
        <ScrollView
          key={setKey(_htmlAttribs)}
          style={{ height: 100, flex: 1, ...style }}
          contentContainerStyle={{ flex: 1 }}
          nestedScrollEnabled={true}
        >
          {children}
        </ScrollView>
      );
    },
  };
}

function getCustomRenderers(
  history,
  classesStyles,
  onWafelClick,
  screenSize,
  publicStyle,
  renderField,
  rowIndex,
  entityFactory
) {
  return {
    text: (_htmlAttribs, children) => {
      //let realChild = children[0][0].props.children[0].props.children;
      let title = getTextChild(children);
      let rnClass = {};
      if (!isNull(classesStyles) && !isNull(_htmlAttribs.class)) {
        rnClass = classesStyles[_htmlAttribs.class];
        if (isNull(rnClass)) rnClass = {};
      }
      let strStyle =
        _htmlAttribs && _htmlAttribs.style ? _htmlAttribs.style : null;
      let rnStyle = convertStringStyleToReactNativeStyle(strStyle, rnClass);
      return (
        <Text key={setKey(_htmlAttribs)} style={{ ...rnStyle }}>
          {title}
        </Text>
      );
    },
    h1: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="h1" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    h2: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="h2" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    h3: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="h3" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    h4: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="h4" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    h5: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="h5" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    h6: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="h6" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    subtitle1: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="subtitle1" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    subtitle2: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="subtitle2" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    body1: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="body1" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    body2: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="body2" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    caption1: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="caption" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    overline: (_htmlAttribs, children) => {
      let txt = getTextChild(children);
      return (
        <Typography variant="overline" color={"inherit"}>
          {txt}
        </Typography>
      );
    },
    wfbutton: (_htmlAttribs) => {
      let title = isNull(_htmlAttribs.title) ? "" : _htmlAttribs.title;
      let btnWidth = isEmpty(_htmlAttribs.width)
        ? undefined
        : _htmlAttribs.width.toString();
      if (!isEmpty(btnWidth) && !btnWidth.endsWith("%")) {
        if (!btnWidth.endsWith("px")) {
          btnWidth = btnWidth + "px";
        }
      }

      let workflow = isNull(_htmlAttribs.workflow)
        ? isNull(_htmlAttribs.script)
          ? ""
          : _htmlAttribs.script
        : _htmlAttribs.workflow;
      let entityIdStr = isNull(_htmlAttribs.entityid)
        ? ""
        : _htmlAttribs.entityid;
      let entityId = null;
      if (!isEmpty(entityIdStr)) {
        try {
          entityId = parseInt(entityIdStr, 10);
        } catch {
          entityId = null;
        }
      }
      return (
        <Button
          key={setKey(_htmlAttribs)}
          size={isEmpty(_htmlAttribs.size) ? undefined : _htmlAttribs.size}
          variant={
            isEmpty(_htmlAttribs.variant) ? undefined : _htmlAttribs.variant
          }
          color={isEmpty(_htmlAttribs.color) ? undefined : _htmlAttribs.color}
          disabled={isEmpty(workflow) || isNull(entityId) || entityId < 1}
          onClick={() => {
            onWafelClick(workflow, entityId);
          }}
          style={{
            flex: isEmpty(_htmlAttribs.flex) ? undefined : 1,
            justifyContent: "center",
            width: isEmpty(_htmlAttribs.btnWidth)
              ? undefined
              : _htmlAttribs.btnWidth,
          }}
        >
          {title}
        </Button>
      );
    },
    tabbar: (_htmlAttribs, children) => {
      let realChildren = [];
      if (!isEmpty(children)) {
        for (let i = 0; i < children.length; i++) {
          realChildren.push(children[i][0]);
        }
      }
      let ariaLabel = isEmpty(_htmlAttribs["arai-label"])
        ? "Unknown"
        : _htmlAttribs["arai-label"];
      return (
        <TabBar
          ariaLabel={ariaLabel}
          screenSize={screenSize}
          key={setKey(_htmlAttribs)}
          style={{ width: "100%", margin: 0, padding: 0 }}
          disabled={
            !isEmpty(_htmlAttribs.disabled) &&
            _htmlAttribs.disabled.toLowerCase() === "true"
              ? true
              : false
          }
          type={_htmlAttribs.type}
          id={_htmlAttribs.id}
          activeKey={
            !isEmpty(_htmlAttribs.initialtab)
              ? parseInt(_htmlAttribs.initialtab)
              : 0
          }
          publicStyle={publicStyle}
        >
          {realChildren}
        </TabBar>
      );
    },
    tabbaritem: (_htmlAttribs, children) => {
      let strStyle =
        _htmlAttribs && _htmlAttribs.style ? _htmlAttribs.style : null;
      let tbiStyle = convertStringStyleToReactNativeStyle(strStyle, {});
      let realChildren = [];
      if (!isEmpty(children)) {
        for (let i = 0; i < children.length; i++) {
          realChildren.push(children[i][0]);
        }
      }
      return (
        <TabBar.Item
          key={setKey(_htmlAttribs)}
          style={{ ...tbiStyle }}
          icon={_htmlAttribs.icon}
          title={_htmlAttribs.title}
          publicStyle={publicStyle}
        >
          {realChildren}
        </TabBar.Item>
      );
    },
    field: (_htmlAttribs) => {
      if (isNull(renderField)) {
        return (
          <View key={setKey(_htmlAttribs)}>
            <Typography variant="body1" color={"inherit"}>
              Field widget not supported outside of a form.
            </Typography>
          </View>
        );
      } else {
        let rnClass = {};
        if (!isNull(classesStyles) && !isNull(_htmlAttribs.class)) {
          rnClass = classesStyles[_htmlAttribs.class];
          if (isNull(rnClass)) rnClass = {};
        }
        if (!isNull(rowIndex)) {
          return renderField(
            _htmlAttribs.name,
            convertStringStyleToReactNativeStyle(_htmlAttribs.style, rnClass),
            rowIndex
          );
        } else {
          return renderField(
            _htmlAttribs.name,
            convertStringStyleToReactNativeStyle(_htmlAttribs.style, rnClass)
          );
        }
      }
    },
    discardbutton: (_htmlAttribs) => {
      return (
        <Suspense
          fallback={<Skeleton animation="wave" width={160} height={40} />}
        >
          <DiscardButton key={setKey(_htmlAttribs)} publicstyle={publicStyle} />
        </Suspense>
      );
    },
    searchbutton: (_htmlAttribs) => {
      return (
        <Suspense
          fallback={<Skeleton animation="wave" width={160} height={40} />}
        >
          <SearchButton
            key={setKey(_htmlAttribs)}
            submitURL={"/entities/" + entityFactory.lowerCasePluralName()}
            publicstyle={publicStyle}
          />
        </Suspense>
      );
    },
    link: (_htmlAttribs) => {
      let title = isNull(_htmlAttribs.title) ? "" : _htmlAttribs.title;
      let rnClass = {};
      if (!isNull(classesStyles) && !isNull(_htmlAttribs.class)) {
        rnClass = classesStyles[_htmlAttribs.class];
        if (isNull(rnClass)) rnClass = {};
      }
      let style = convertStringStyleToReactNativeStyle(
        _htmlAttribs.style,
        rnClass
      );
      let val = isNull(_htmlAttribs.to) ? "" : _htmlAttribs.to;
      if (val === "%%back") {
        return (
          <Link
            key={setKey(_htmlAttribs)}
            onClick={(e) => {
              e.stopPropagation();
              history.goBack();
            }}
            style={{ ...style }}
          >
            {title}
          </Link>
        );
      } else {
        return (
          <Link key={setKey(_htmlAttribs)} style={{ ...style }} to={val}>
            {title}
          </Link>
        );
      }
    },
    linkbutton: (_htmlAttribs) => {
      let title = isNull(_htmlAttribs.title) ? "" : _htmlAttribs.title;
      let hidden = _htmlAttribs.hidden ? true : false;
      if (hidden) return <View />;
      let disabled = _htmlAttribs.disabled ? true : false;

      let btnWidth = isEmpty(_htmlAttribs.width)
        ? undefined
        : _htmlAttribs.width.toString();
      if (!isEmpty(btnWidth) && !btnWidth.endsWith("%")) {
        if (!btnWidth.endsWith("px")) {
          btnWidth = btnWidth + "px";
        }
      }

      let val = isNull(_htmlAttribs.to) ? "" : _htmlAttribs.to;
      return (
        <Button
          disabled={disabled}
          key={setKey(_htmlAttribs)}
          size={isEmpty(_htmlAttribs.size) ? undefined : _htmlAttribs.size}
          variant={
            isEmpty(_htmlAttribs.variant) ? undefined : _htmlAttribs.variant
          }
          color={isEmpty(_htmlAttribs.color) ? undefined : _htmlAttribs.color}
          onClick={() => {
            if (val === "back" || val === "%%back") {
              history.goBack();
            } else if (val === "refresh" || val === "%%refresh") {
              window.location.reload();
            } else if (val.startsWith("http")) {
              window.location.href = val;
            } else {
              history.push(val);
            }
          }}
          style={{
            flex: isEmpty(_htmlAttribs.flex) ? undefined : 1,
            justifyContent: "center",
            width: isEmpty(_htmlAttribs.btnWidth)
              ? undefined
              : _htmlAttribs.btnWidth,
          }}
        >
          {title}
        </Button>
      );
    },
  };
}

export function getRenderers(
  history,
  classesStyles,
  onWafelClick,
  screenSize,
  publicStyle,
  renderField,
  rowIndex,
  entityFactory
) {
  return {
    ...getCommonRenderers(history, classesStyles),
    ...getCustomRenderers(
      history,
      classesStyles,
      onWafelClick,
      screenSize,
      publicStyle,
      renderField,
      rowIndex,
      entityFactory
    ),
  };
}

export function renderTemplate(classesStyles, html, renderers, hideRuleState) {
  if (isNull(html)) return <View />;
  return (
    <HTML
      hideRuleState={
        isEmpty(hideRuleState) ? "" : JSON.stringify(hideRuleState)
      }
      classesStyles={classesStyles}
      source={{ html }}
      containerStyle={{ flex: 1, flexDirection: "column", width: "100%" }}
      renderers={renderers}
    />
  );
}

export const Template = (props) => {
  const {
    definition,
    subDefinition,
    isCard,
    item,
    layoutGraph,
    versionId,
    history,
    onWafelClick,
    screenSize,
    publicStyle,
    renderField,
    rowIndex,
    entityFactory,
    hideRuleState,
    onItemClick,
    selected,
  } = props;
  const [rootDefinition] = useState(() =>
    definitionHelper.fixDefinition(cloneDeep(definition))
  );
  const template = useMemo(() => getTemplate(isCard, definition), [
    isCard,
    definition,
  ]);
  const [subDef] = useState(() =>
    calculateSubdefinition(rootDefinition, subDefinition)
  );
  const { current: formStyles } = useRef(
    publicStyle?.templatestyles ? publicStyle?.templatestyles : {}
  );
  const [classesStyles] = useState(() => ({
    ...formStyles,
    ...getClassStyles(rootDefinition, subDef),
  }));
  const [tokenResolvers] = useState(() =>
    getTokenResolversFromTemplate(props, rootDefinition, template)
  );
  const rawItem = item?.original?.raw ? item?.original?.raw : item;
  const [htmlState, setHTMLState] = useState(() =>
    calcHTMLState(
      rootDefinition,
      template,
      rawItem,
      layoutGraph,
      null,
      tokenResolvers
    )
  );
  const { html } = htmlState;

  useEffect(() => {
    setHTMLState((prevHTMLState) => {
      return getStateFromProps(
        rawItem,
        template,
        rootDefinition,
        layoutGraph,
        versionId,
        isCard,
        tokenResolvers,
        prevHTMLState
      );
    });
  }, [
    rawItem,
    template,
    rootDefinition,
    layoutGraph,
    versionId,
    isCard,
    tokenResolvers,
  ]);

  const renderers = useMemo(
    () =>
      getRenderers(
        history,
        classesStyles,
        onWafelClick,
        screenSize,
        publicStyle,
        renderField,
        rowIndex,
        entityFactory
      ),
    [
      history,
      classesStyles,
      onWafelClick,
      screenSize,
      publicStyle,
      renderField,
      rowIndex,
      entityFactory,
    ]
  );
  const renderedComponent = useMemo(() => {
    if (isCard) {
      return renderTemplate(classesStyles, html, renderers, "");
    } else {
      return renderTemplate(classesStyles, html, renderers, hideRuleState);
    }
  }, [isCard, classesStyles, html, renderers, hideRuleState]);

  if (isCard) {
    if (Platform && Platform.OS === "web") {
      return (
        <div
          key={"card_" + item.id}
          id={"card_" + item.id}
          onClick={() => {
            if (onItemClick) {
              onItemClick(item);
            }
          }}
          style={{
            alignContent: "flex-start",
            margin: 0,
            padding: 0,
            border: selected ? "1px solid black" : undefined,
          }}
        >
          {renderedComponent}
        </div>
      );
    } else {
      return (
        <TouchableOpacity
          id={"card_" + item.id}
          onPress={() => {
            if (onItemClick) {
              onItemClick(item);
            }
          }}
        >
          <View
            style={{
              alignContent: "flex-start",
              flex: 1,
              margin: 0,
              padding: 0,
              border: selected ? "1px solid black" : undefined,
            }}
          >
            {renderedComponent}
          </View>
        </TouchableOpacity>
      );
    }
  } else {
    return renderedComponent;
  }
};

export const HTMLTemplate = withRouter(Template);
