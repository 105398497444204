/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { filterCollection } from "../util/common";

function withCollection(BaseComponent, fixedFilter) {
  const withCollectionFn = function WithCollectionInner(props) {
    const { layoutGraph, entityFactory, filter } = props;

    const primaryCollection = useMemo(() => {
      const collection = entityFactory.getPrimaryCollection(layoutGraph);
      const resolvedFilter = fixedFilter ? fixedFilter : filter;
      return filterCollection(collection, resolvedFilter);
    }, [entityFactory, layoutGraph, filter]);

    return <BaseComponent {...props} primaryCollection={primaryCollection} />;
  };

  return withCollectionFn;
}

export default withCollection;
