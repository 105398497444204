/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import {
  SafeAreaView,
  StyleSheet,
  KeyboardAvoidingView,
  StatusBar,
} from "react-native";
import { isNull, isEmpty } from "../util/types";
import { findEntityFactory, findLookupFactory } from "../util/common.js";
import BulkImport from "./bulkImport";
import JSONPage from "./jsonPage";

function getPageState(allEntityFactories, layoutGraph, currentUser) {
  let PageComponent = null;
  let pageItem = null;
  let homePageId = currentUser.pageId;
  let homePage = null;
  let formEntityFactory = null;
  let pageEntityFactory = findEntityFactory(allEntityFactories, "Page");
  if (!isNull(homePageId)) {
    if (!isNull(pageEntityFactory)) {
      homePage = pageEntityFactory.getRowById(layoutGraph, homePageId);
    }
  }
  let entityPage = null;
  let userFactory = null;
  let parentFactory = null;

  if (isNull(homePage)) {
    for (let i = 0; i < allEntityFactories.length; i++) {
      let entityFactory = allEntityFactories[i];
      if (
        entityFactory.lowerCaseEntityName() ===
        currentUser.userEntityName.toLowerCase()
      ) {
        userFactory = entityFactory;
        if (isNull(currentUser.parentEntityName) || !isNull(parentFactory))
          break;
      }
      if (
        !isNull(currentUser.parentEntityName) &&
        entityFactory.lowerCaseEntityName() ===
          currentUser.parentEntityName.toLowerCase()
      ) {
        parentFactory = entityFactory;
        if (!isNull(userFactory)) break;
      }
    }
    if (
      !isNull(parentFactory) &&
      !isNull(parentFactory.definition) &&
      !isNull(parentFactory.definition.homePage)
    ) {
      entityPage = parentFactory.definition.homePage;
    }
    if (
      !isNull(userFactory) &&
      !isNull(userFactory.definition) &&
      !isNull(userFactory.definition.homePage)
    ) {
      entityPage = userFactory.definition.homePage;
    }
  } else {
    entityPage = homePage.name;
  }

  if (entityPage === "Tasklist") {
    pageItem = pageEntityFactory.getRowByName(layoutGraph, entityPage);
    PageComponent = JSONPage;
  } else if (entityPage === "Bulk Import") {
    pageItem = pageEntityFactory.getRowByName(layoutGraph, entityPage);
    PageComponent = BulkImport;
  } else {
    pageItem = pageEntityFactory.getRowByName(layoutGraph, entityPage);
    if (pageItem) {
      let decoded = null;
      if (!isEmpty(pageItem.definition)) {
        decoded = JSON.parse(pageItem.definition);
      }
      if (!isNull(decoded) && !isNull(decoded.lookup)) {
        formEntityFactory = findLookupFactory(allEntityFactories, decoded);
        if (formEntityFactory) {
          PageComponent = formEntityFactory.getComponentWithEntity(JSONPage);
        } else {
          PageComponent = JSONPage;
        }
      } else {
        PageComponent = JSONPage;
      }
    }
  }

  if (isNull(PageComponent)) {
    pageItem = pageEntityFactory.getRowByName(layoutGraph, "Tasklist");
    PageComponent = JSONPage;
  }

  return { PageComponent, formEntityFactory, pageItem };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#424242",
  },
});

const Home = ({
  commonProps,
  allEntityFactories,
  layoutGraph,
  currentUser,
  changeLoginState,
}) => {
  const { PageComponent, formEntityFactory, pageItem } = useMemo(
    () => getPageState(allEntityFactories, layoutGraph, currentUser),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser.id]
  );

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle="dark" />
      <KeyboardAvoidingView
        style={{ flex: 1, backgroundColor: "#fff" }}
        behavior="padding"
      >
        <PageComponent
          entityId={currentUser.id}
          entityFactory={formEntityFactory}
          pageItem={pageItem}
          changeLoginState={changeLoginState}
          {...commonProps}
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default Home;
