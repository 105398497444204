/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { View } from "react-native";

const TabBarItem = ({ style, children }) => {
  const tbItemStyle = useMemo(() => {
    let cleanStyle = style ? style : {};
    return { ...styles.container, ...cleanStyle };
  }, [style]);

  return <View style={tbItemStyle}>{children}</View>;
};
export default TabBarItem;

const styles = {
  container: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
  },
};
