import Chance from "chance";
import * as cloneDeep from "lodash/cloneDeep";
import { SELECT_FIELD } from "./constants";

const { detect } = require("detect-browser");
const browser = detect();

function isNull(value) {
  return value === undefined || value === null;
}

function isEmpty(value) {
  return isNull(value) || value.length === 0;
}

function findPrimaryFields(
  definition,
  additional = [],
  additionalOnly = false
) {
  let primaryFields = [];
  if (definition && definition.fields && definition.fields.length > 0) {
    let fields = findAllDisplayFields(definition.fields);
    for (let i = 0; i < fields.length; i++) {
      if (
        (fields[i].primaryField && additionalOnly !== true) ||
        additional.indexOf(fields[i].fieldName) >= 0
      ) {
        primaryFields.push(fields[i]);
      }
    }
  }
  return primaryFields;
}

function findFields(definition, additional = []) {
  let primaryFields = [];
  if (definition && definition.fields && definition.fields.length > 0) {
    let fields = findAllDisplayFields(definition.fields);
    for (let i = 0; i < fields.length; i++) {
      if (additional.indexOf(fields[i].fieldName) >= 0) {
        primaryFields.push(fields[i]);
      }
    }
  }
  return primaryFields;
}

function findFirstGeoField(definition) {
  let geoField = null;
  if (definition && definition.fields && definition.fields.length > 0) {
    let fields = findAllDisplayFields(definition.fields);
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (fields[i].type === "geog") {
        geoField = field;
        break;
      }
    }
  }
  return geoField;
}

function findAllInPlaceFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === "object") {
      let children = findAllInPlaceFields(field.definition.fields);
      results.push(...children);
    } else {
      if ((field.type === "array" || field.type === "json") && field.inPlace) {
        results.push(field);
      }
    }
  }
  return results;
}

function findAllFolderFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === "object") {
      results.push(field);
      let children = findAllFolderFields(field.definition.fields);
      results.push(...children);
    }
  }
  return results;
}

function findAllInPlaceArrayFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === "object") {
      let children = findAllInPlaceArrayFields(field.definition.fields);
      results.push(...children);
    } else {
      if (field.type === "array" && field.inPlace) {
        results.push(field);
      }
    }
  }
  return results;
}

function findAllDisplayFields(fields, graphTypesOnly = true) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === "object") {
      let children = findAllDisplayFields(
        field.definition.fields,
        graphTypesOnly
      );
      results.push(...children);
    } else {
      if (!graphTypesOnly || (field.type !== "id" && field.type !== "button")) {
        results.push(field);
      }
    }
  }
  return results;
}

function findAllDisplayFieldsMapped(fields, graphTypesOnly = true) {
  let rootFields = {};
  let workflowFields = {};
  let displayFields = findAllDisplayFields(fields, graphTypesOnly);
  for (let i = 0; i < displayFields.length; i++) {
    let displayField = displayFields[i];
    rootFields[displayField.fieldName] = displayField;
    if (
      (displayField.type === "button" || displayField.type === "file") &&
      !isEmpty(displayField.workflow)
    ) {
      workflowFields[displayField.workflow.toLowerCase()] = displayField;
    }
    if (
      displayField.type === "array" &&
      displayField.definition &&
      displayField.definition.fields
    ) {
      let childFields = findAllDisplayFields(
        displayField.definition.fields,
        graphTypesOnly
      );
      for (let j = 0; j < childFields.length; j++) {
        let childField = childFields[j];
        if (
          (childField.type === "button" || childField.type === "file") &&
          !isEmpty(childField.workflow)
        ) {
          workflowFields[childField.workflow.toLowerCase()] = {
            ...childField,
            allowInHeader: false,
          };
        }
      }
    }
  }
  return { rootFields, workflowFields, displayFields };
}

function findAllNonObjectFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === "object") {
      let children = findAllNonObjectFields(field.definition.fields);
      results.push(...children);
    } else {
      results.push(field);
      if ((field.type === "array" || field.type === "json") && field.inPlace) {
        let children = findAllNonObjectFields(field.definition.fields);
        results.push(...children);
      }
    }
  }
  return results;
}

function findAllLinkFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === "array") {
      results.push(field);
    }
    if (field.type === "object") {
      let children = findAllLinkFields(field.definition.fields);
      for (let j = 0; j < children.length; j++) {
        let child = children[j];
        results.push(child);
      }
    }
  }
  return results;
}

function findAllTopLevelLookupFields(fields) {
  let topLevelfields = findAllTopLevelDBFields(fields);
  let results = [];
  for (let i = 0; i < topLevelfields.length; i++) {
    let field = topLevelfields[i];
    if (!isEmpty(field.lookup)) {
      results.push(field);
    }
  }
  return results;
}

function findAllTopLevelDBFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (
      field.type !== "array" &&
      field.type !== "object" &&
      field.type !== "button" &&
      field.type !== "login" &&
      field.type !== "id"
    ) {
      results.push(field);
    }
    if (field.type === "object") {
      let children = findAllTopLevelDBFields(field.definition.fields);
      for (let j = 0; j < children.length; j++) {
        let child = children[j];
        results.push(child);
      }
    }
  }
  return results;
}

function findSelectFieldName(field) {
  let selectField = null;
  let fields = field?.definition?.fields ? field?.definition?.fields : [];
  if (fields) {
    let allFields = findAllTopLevelDBFields(fields);
    selectField = allFields.find((field) =>
      field.fieldName.startsWith(SELECT_FIELD)
    );
  }
  if (selectField) {
    return selectField.fieldName;
  } else {
    return SELECT_FIELD;
  }
}

function findAllTopLevelFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.type !== "object") {
      results.push(field);
    }
    if (field.type === "object") {
      let children = findAllTopLevelFields(field.definition.fields);
      for (let j = 0; j < children.length; j++) {
        let child = children[j];
        results.push(child);
      }
    }
  }
  return results;
}

function setNotMandatory(fields) {
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.required) {
      field.required = false;
    }
    if (field.type === "object") {
      setNotMandatory(field.definition.fields);
    }
  }
}

function findAllFields(fields) {
  let results = [];
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (!isNull(field.definition)) {
      let children = findAllFields(field.definition.fields);
      results.push(...children);
    }
    results.push(field);
  }
  return results;
}

function fieldExists(fields, field) {
  for (let i = 0; i < fields.length; i++) {
    let currentField = fields[i];
    if (currentField === field) {
      return true;
    }
    if (
      currentField.definition &&
      currentField.definition.fields &&
      currentField.definition.fields.length > 0
    ) {
      let exists = fieldExists(currentField.definition.fields, field);
      if (exists) {
        return true;
      }
    }
  }
  return false;
}

function subDefinitionCount(definition, viewName) {
  let count = 0;
  if (definition && definition.subDefinitions) {
    for (let i = 0; i < definition.subDefinitions.length; i++) {
      let subDefinition = definition.subDefinitions[i];
      if (subDefinition.viewName === viewName) {
        count += 1;
      }
    }
  }
  return count;
}

function fieldCount(fields, fieldName, count = 0) {
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.fieldName === fieldName) {
      count += 1;
    }
    if (field.type === "object" || (field.type === "array" && field.inPlace)) {
      count = fieldCount(field.definition.fields, fieldName, count);
    }
  }
  return count;
}

function findField(fields, fieldName) {
  if (!isNull(fields)) {
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (field.fieldName === fieldName) {
        return field;
      }
      if (
        field.definition &&
        field.definition.fields &&
        field.definition.fields.length > 0
      ) {
        let childField = findField(field.definition.fields, fieldName);
        if (childField !== null) {
          return childField;
        }
      }
    }
  }
  return null;
}

function findParent(fields, parent, fieldName) {
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    if (field.fieldName === fieldName) {
      return parent;
    }
    if (
      field.definition &&
      field.definition.fields &&
      field.definition.fields.length > 0
    ) {
      let childParent = findParent(field.definition.fields, field, fieldName);
      if (childParent !== null) {
        return childParent;
      }
    }
  }
  return null;
}

function sectionCount(definition, sectionName, count = 0) {
  if (definition) {
    if (definition.sections) {
      for (let i = 0; i < definition.sections.length; i++) {
        let section = definition.sections[i];
        if (section.id === sectionName) {
          count += 1;
        }
      }
    }
    if (definition.fields) {
      for (let i = 0; i < definition.fields.length; i++) {
        let field = definition.fields[i];
        if (
          field.type === "object" ||
          (field.type === "array" && field.inPlace)
        ) {
          count = sectionCount(field.definition, sectionName, count);
        }
      }
    }
  }
  return count;
}

function sectionExists(definition, sectionName) {
  return !isNull(findSection(definition, sectionName));
}

function findSection(definition, sectionName) {
  let section = getSection(definition.sections, sectionName);
  if (section) {
    return section;
  }
  for (let i = 0; i < definition.fields.length; i++) {
    let field = definition.fields[i];
    if (
      field.definition &&
      field.definition.fields &&
      field.definition.sections.length > 0
    ) {
      let childField = findSection(field.definition, sectionName);
      if (childField !== null) {
        return childField;
      }
    }
  }
  return null;
}

function getSection(sections, sectionName) {
  for (let i = 0; i < sections.length; i++) {
    let section = sections[i];
    if (section.id === sectionName) {
      return section;
    }
  }
  return null;
}

function findAllSections(definition, flattening = false) {
  let sections = [...definition.sections];
  for (let i = 0; i < definition.fields.length; i++) {
    let field = definition.fields[i];
    if (
      field.definition &&
      field.definition.fields &&
      field.definition.sections.length > 0
    ) {
      if (!flattening || field.retainOnFlatten !== true) {
        sections.push(...findAllSections(field.definition, flattening));
      }
    }
  }
  return sections;
}

function findView(definition, viewName) {
  if (isEmpty(viewName)) {
    viewName = "default";
  }
  for (let i = 0; i < definition.views.length; i++) {
    let view = definition.views[i];
    if (view.viewName === viewName) {
      return view;
    }
  }
  return definition;
}

function flattenDefinition(definition, renderLevel = 0) {
  let flattened = cloneDeep(definition);
  if (
    definition.displayMode !== "None" &&
    definition.displayMode !== "panels"
  ) {
    flattened.displayMode =
      renderLevel > 1 ? "None" : renderLevel > 0 ? "tabs" : "tabbar";
  }
  let fields = findAllFolderFields(flattened.fields);
  if (fields) {
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (field.retainOnFlatten !== true) {
        field.definition.displayMode = "None";
      }
    }
  }
  let sections = findAllSections(flattened, true);
  if (sections) {
    for (let i = 0; i < sections.length; i++) {
      let section = sections[i];
      if (!isNull(section.columns)) {
        section.columns = 1;
      }
    }
  }
  return flattened;
}

function findSubDefinition(definition, subDefinitionName, sync = true) {
  let finalSubdefinition = null;
  if (
    !isEmpty(subDefinitionName) &&
    !isNull(definition) &&
    !isEmpty(definition.subDefinitions)
  ) {
    for (let i = 0; i < definition.subDefinitions.length; i++) {
      let subDefinition = definition.subDefinitions[i];
      if (subDefinition.viewName === subDefinitionName) {
        let subD = cloneDeep(subDefinition);
        return fixSubDefinition(
          definition,
          subD,
          sync,
          subDefinitionName === "__search"
        );
      }
    }
    if (subDefinitionName === "__search") {
      for (let i = 0; i < definition.subDefinitions.length; i++) {
        let subDefinition = definition.subDefinitions[i];
        if (subDefinition.viewName === "grid") {
          let subD = cloneDeep(subDefinition);
          finalSubdefinition = fixSubDefinition(
            definition,
            subD,
            sync,
            subDefinitionName === "__search"
          );
          break;
        }
      }
    }
  }
  if (subDefinitionName === "__search") {
    if (isNull(finalSubdefinition)) {
      finalSubdefinition = cloneDeep(definition);
    }
    let subDefinitionFields = findAllFields(finalSubdefinition.fields);
    for (let i = 0; i < subDefinitionFields.length; i++) {
      let viewField = subDefinitionFields[i];
      delete viewField.default;
      viewField.readOnly = false;
      viewField.required = false;
      viewField.primaryField = false;
      viewField.unique = false;
      delete viewField.editRoles;
      delete viewField.viewRoles;
      delete viewField.addRoles;
      delete viewField.pattern;
      delete viewField.lookupFilter;
    }
    return finalSubdefinition;
  }
  return definition;
}

function subDefinitionExists(definition, subDefinitionName) {
  let exists = false;
  if (!isEmpty(subDefinitionName)) {
    for (let i = 0; i < definition.subDefinitions.length; i++) {
      let subDefinition = definition.subDefinitions[i];
      if (subDefinition.viewName === subDefinitionName) {
        exists = true;
        break;
      }
    }
  }
  return exists;
}

function fixRules(definition) {
  if (!definition.fields || definition.fields.length === 0) {
    definition.rules = [];
  } else {
    let rules = definition.rules;
    if (rules) {
      for (let i = rules.length - 1; i >= 0; i--) {
        let rule = rules[i];
        let field = findField(definition.fields, rule.fieldName);
        if (
          !rule.fieldName.startsWith("%%") &&
          (!field || (field.type !== "boolean" && !isNull(rule.trigger)))
        ) {
          rules.splice(i, 1);
        } else {
          if (rule.hide) {
            for (let j = rule.hide.length - 1; j >= 0; j--) {
              let hideField = findField(definition.fields, rule.hide[j]);
              if (!hideField || hideField.type === "object") {
                rule.hide.splice(j, 1);
              }
            }
            if (rule.hide.length == 0) delete rule.hide;
          }
          if (rule.readOnly) {
            for (let j = rule.readOnly.length - 1; j >= 0; j--) {
              let roField = findField(definition.fields, rule.readOnly[j]);
              if (!roField || roField.type === "object") {
                rule.readOnly.splice(j, 1);
              }
            }
            if (rule.readOnly.length == 0) delete rule.readOnly;
          }
          if (rule.clear) {
            for (let j = rule.clear.length - 1; j >= 0; j--) {
              let clearField = findField(definition.fields, rule.clear[j]);
              if (!clearField || clearField.type === "object") {
                rule.clear.splice(j, 1);
              }
            }
            if (rule.clear.length == 0) delete rule.clear;
          }
          if (rule.copy) {
            for (let j = rule.copy.length - 1; j >= 0; j--) {
              let copyRule = rule.copy[j];
              let fromName = copyRule.fromField;
              let toName = copyRule.toField;
              let fromField = findField(definition.fields, fromName);
              let toField = findField(definition.fields, toName);
              if (
                !fromField ||
                !toField ||
                toField.type === "object" ||
                fromField.type !== toField.type ||
                ((!isNull(fromField.lookup) || !isNull(toField.lookup)) &&
                  fromField.lookup !== toField.lookup)
              ) {
                rule.copy.splice(j, 1);
              }
            }
            if (rule.copy.length == 0) delete rule.copy;
          }
        }
      }
    }
  }
}

function removeSubDefinitionField(allFields, fields, fieldName) {
  for (let i = fields.length - 1; i >= 0; i--) {
    let field = fields[i];
    let type = field.type;
    if (isNull(type)) {
      for (let j = allFields.length - 1; j >= 0; j--) {
        let fixedField = allFields[j];
        if (fixedField.fieldName === field.fieldName) {
          type = fixedField.type;
          break;
        }
      }
    }
    if (type === "object") {
      let success = removeSubDefinitionField(
        allFields,
        field.definition.fields,
        fieldName
      );
      if (success) {
        return true;
      }
    } else {
      if (field.fieldName === fieldName) {
        fields.splice(i, 1);
        return true;
      }
    }
  }
  return false;
}

function getMinimalSections(field) {
  let sections = [];
  for (let i = 0; i < field.definition.sections.length; i++) {
    sections.push({ id: field.definition.sections[i].id });
  }
  return sections;
}

// function getMinimalDisplayFields(field) {
//   let fields = [];
//   let childFields = findAllDisplayFields(field.definition.fields);
//   for (let i = 0; i < childFields.length; i++) {
//     let childField = childFields[i];
//     if (!childField.inPlace) {
//       fields.push({ fieldName: childField.fieldName });
//     } else {
//       let newField = { fieldName: childField.fieldName };
//       newField.definition = {};
//       newField.definition.entityName = childField.definition.entityName;
//       // eslint-disable-next-line no-unused-vars
//       newField.definition.fields = getMinimalDisplayFields(childField);
//       newField.definition.sections = getMinimalSections(childField);
//       fields.push(newField);
//     }
//   }
// }

function syncSubDefinitions(definition) {
  if (definition && definition.subDefinitions) {
    for (let i = 0; i < definition.subDefinitions.length; i++) {
      let subDefinition = definition.subDefinitions[i];
      let fieldsOverriden = !isEmpty(subDefinition.fields);
      if (fieldsOverriden) {
        let clonedSubDef = findSubDefinition(
          definition,
          subDefinition.viewName,
          false
        );
        syncFields(definition, subDefinition, clonedSubDef);
      }
    }
  }
}

function syncFields(definition, subDefinition, clonedSubDef) {
  let clonedSubDefFields = findAllDisplayFields(clonedSubDef.fields);
  let allClonedSubDefFields = findAllFields(clonedSubDef.fields);

  let subDefFields = []; //findAllDisplayFields(subDefinition.fields);
  for (let i = 0; i < clonedSubDefFields.length; i++) {
    subDefFields.push(
      findField(subDefinition.fields, clonedSubDefFields[i].fieldName)
    );
  }

  let parentFields = findAllDisplayFields(definition.fields);
  for (let i = 0; i < parentFields.length; i++) {
    let parentField = parentFields[i];
    let subDefField = null;
    for (let j = 0; j < subDefFields.length; j++) {
      let sdField = subDefFields[j];
      if (sdField.fieldName === parentField.fieldName) {
        subDefField = sdField;
        break;
      }
    }
    if (isNull(subDefField)) {
      if (!parentField.inPlace) {
        subDefField = { fieldName: parentField.fieldName };
        subDefinition.fields.push(subDefField);
      } else {
        subDefField = { fieldName: parentField.fieldName };
        //childField.definition = {};
        //childField.definition.entityName = parentField.definition.entityName;
        //childField.definition.fields = getMinimalDisplayFields(parentField);
        //childField.definition.sections = getMinimalSections(parentField);
        subDefinition.fields.push(subDefField);
      }
    }
    if (parentField.inPlace) {
      //We should check children.
      if (parentField.definition) {
        if (isNull(subDefField.definition)) {
          subDefField.definition = {};
          subDefField.definition.entityName = parentField.definition.entityName;
          subDefField.definition.fields = [];
          subDefField.definition.sections = getMinimalSections(parentField);
        }
        let clonedSubDefField = findField(
          clonedSubDef.fields,
          parentField.fieldName
        );
        syncFields(
          parentField.definition,
          subDefField.definition,
          clonedSubDefField.definition
        );
      }
    }
    if (!parentField.inPlace) {
      if (!isNull(subDefField.definition)) {
        delete subDefField.definition;
      }
    }
  }

  for (let i = 0; i < subDefFields.length; i++) {
    let subDefField = subDefFields[i];
    let parentField = null;
    for (let j = 0; j < parentFields.length; j++) {
      let pField = parentFields[j];
      if (subDefField.fieldName === pField.fieldName) {
        parentField = pField;
        break;
      }
    }
    if (isNull(parentField)) {
      removeSubDefinitionField(
        allClonedSubDefFields,
        subDefinition.fields,
        subDefField.fieldName
      );
    }
  }
}

function fixSubDefinition(
  definition,
  subDefinition,
  sync = true,
  isSearch = false
) {
  let fieldsOverriden = !isEmpty(subDefinition.fields);
  let sectionsOverriden = !isEmpty(subDefinition.sections);
  subDefinition.entityName = definition.entityName;
  subDefinition.pluralName = definition.pluralName;
  subDefinition.homePage = definition.homePage;
  subDefinition.guidId = definition.guidId;
  if (isNull(subDefinition.styles)) subDefinition.styles = definition.styles;
  if (isNull(subDefinition.textAlign))
    subDefinition.textAlign = definition.textAlign;
  if (isNull(subDefinition.autoTemplate))
    subDefinition.autoTemplate = definition.autoTemplate;
  if (isNull(subDefinition.title)) subDefinition.title = definition.title;
  if (isNull(subDefinition.mode)) subDefinition.mode = definition.mode;
  if (isNull(subDefinition.viewName))
    throw Error(
      "Invalid viewname: subDefinition " + JSON.stringify(subDefinition)
    );
  if (isNull(subDefinition.minimal)) subDefinition.minimal = definition.minimal;
  if (isEmpty(subDefinition.sections))
    subDefinition.sections = cloneDeep(definition.sections);
  if (isNull(subDefinition.warnings))
    subDefinition.warnings = cloneDeep(definition.warnings);
  if (isEmpty(subDefinition.fields))
    subDefinition.fields = cloneDeep(definition.fields);
  if (isNull(subDefinition.filters))
    subDefinition.filters = cloneDeep(definition.filters);
  if (isNull(subDefinition.showDetail))
    subDefinition.showDetail = definition.showDetail;
  if (isNull(subDefinition.leftActions))
    subDefinition.leftActions = cloneDeep(definition.leftActions);
  if (isNull(subDefinition.displayMode))
    subDefinition.displayMode = definition.displayMode;
  if (isNull(subDefinition.hideWorkflowButtons))
    subDefinition.hideWorkflowButtons = definition.hideWorkflowButtons;
  if (isNull(subDefinition.template))
    subDefinition.template = definition.template;
  if (isNull(subDefinition.card)) subDefinition.card = definition.card;
  if (isNull(subDefinition.formTemplate))
    subDefinition.formTemplate = definition.formTemplate;
  if (isNull(subDefinition.readOnly))
    subDefinition.readOnly = definition.readOnly;
  if (isNull(subDefinition.rules))
    subDefinition.rules = cloneDeep(definition.rules);
  if (isNull(subDefinition.views)) subDefinition.views = [];
  if (isNull(subDefinition.fieldTweaks)) subDefinition.fieldTweaks = [];
  if (isNull(subDefinition.sectionTweaks)) subDefinition.sectionTweaks = [];
  if (isNull(subDefinition.subDefinitions)) subDefinition.subDefinitions = [];
  subDefinition.references = definition.references;
  if (isNull(subDefinition.references)) subDefinition.references = [];

  if (isNull(subDefinition.fields))
    subDefinition.fields = cloneDeep(definition.fields);

  //No need if we simply copied the fields from the parent anyway.
  let subDefinitionFields = findAllFields(subDefinition.fields);
  if (fieldsOverriden) {
    //Check for missing fields.
    if (sync && !isSearch) {
      let clonedSubDef = findSubDefinition(
        definition,
        subDefinition.viewName,
        false
      );
      syncFields(definition, subDefinition, clonedSubDef);
    }

    for (let i = 0; i < subDefinitionFields.length; i++) {
      let viewField = subDefinitionFields[i];
      let parentField = findField(definition.fields, viewField.fieldName);
      if (parentField) {
        //Default properties down ... but only if they haven't been explicitly set.
        for (let key in parentField) {
          if (
            Object.prototype.hasOwnProperty.call(parentField, key) &&
            (!isSearch || key !== "default")
          ) {
            if (!Object.prototype.hasOwnProperty.call(viewField, key)) {
              viewField[key] = parentField[key];
            }
            if (key === "definition") {
              if (
                !isNull(parentField.definition.displayMode) &&
                isNull(viewField.definition.displayMode)
              ) {
                viewField.definition.displayMode =
                  parentField.definition.displayMode;
              }
            }
          }
        }
      }
    }
  } else if (isSearch) {
    for (let i = 0; i < subDefinitionFields.length; i++) {
      let viewField = subDefinitionFields[i];
      delete viewField.default;
    }
  }

  //FieldTweaks should only be used really if subDefinition.fields was NULL/UNDEFINED/EMPTY really.
  //They will still work though.
  for (let i = 0; i < subDefinition.fieldTweaks.length; i++) {
    let fieldTweak = subDefinition.fieldTweaks[i];
    let fieldName = fieldTweak.fieldName;
    if (isNull(fieldName))
      throw Error(
        "Invalid fieldTweak: View " +
          subDefinition.entityName +
          " and Index " +
          i.toString()
      );
    let viewField = findField(subDefinition.fields, fieldName);
    if (viewField) {
      for (let key in fieldTweak) {
        if (Object.prototype.hasOwnProperty.call(fieldTweak, key)) {
          if (key === "displayMode" && viewField.definition) {
            if (isNull(fieldTweak[key])) {
              viewField.definition.displayMode = "None";
            } else {
              viewField.definition.displayMode = fieldTweak[key];
            }
          } else {
            if (isNull(fieldTweak[key])) {
              delete viewField[key];
            } else {
              viewField[key] = fieldTweak[key];
            }
          }
        }
      }
    }
  }

  if (isSearch) {
    for (let i = 0; i < subDefinitionFields.length; i++) {
      let viewField = subDefinitionFields[i];
      viewField.readOnly = false;
      viewField.required = false;
      viewField.primaryField = false;
      viewField.unique = false;
      delete viewField.editRoles;
      delete viewField.viewRoles;
      delete viewField.addRoles;
      delete viewField.pattern;
      delete viewField.lookupFilter;
    }
  }

  //No need if we simply copied the sections from the parent anyway.
  if (sectionsOverriden) {
    let sections = findAllSections(subDefinition);
    for (let i = 0; i < sections.length; i++) {
      let viewSection = sections[i];
      let parentSection = findSection(definition, viewSection.id);
      //Default properties down ... but only if they haven't been explicitly set.
      if (parentSection) {
        for (let key in parentSection) {
          if (Object.prototype.hasOwnProperty.call(parentSection, key)) {
            if (!Object.prototype.hasOwnProperty.call(viewSection, key)) {
              viewSection[key] = parentSection[key];
            }
          }
        }
      }
    }
  }

  //Make sure the "sections" referenced on the fields actually exist.
  for (let i = 0; i < subDefinitionFields.length; i++) {
    let viewField = subDefinitionFields[i];
    let sectionId = null;
    if (!isEmpty(viewField.tab)) {
      sectionId = viewField.tab;
    }
    let parentField = findParent(
      subDefinition.fields,
      null,
      viewField.fieldName
    );
    let sectionArray = null;
    if (isNull(parentField)) {
      sectionArray = subDefinition.sections;
    } else {
      if (!parentField.definition.sections) {
        parentField.definition.sections = [];
      }
      sectionArray = parentField.definition.sections;
    }
    let parentSection = null;
    let viewSection = null;
    if (!isNull(sectionId)) {
      if (isNull(parentField)) {
        parentSection = getSection(definition.sections, sectionId);
        viewSection = getSection(subDefinition.sections, sectionId);
      } else {
        let rootParentField = findParent(
          definition.fields,
          null,
          viewField.fieldName
        );
        if (rootParentField) {
          parentSection = getSection(
            rootParentField.definition.sections,
            sectionId
          );
        }
        viewSection = getSection(parentField.definition.sections, sectionId);
      }
    }
    if (isNull(viewSection)) {
      if (!isNull(parentSection)) {
        sectionArray.push(cloneDeep(parentSection));
      } else {
        if (sectionArray.length > 0) {
          viewField.tab = sectionArray[0].id;
        } else {
          let chance = new Chance();
          sectionId = chance.guid();
          viewField.tab = sectionId;
          sectionArray.push({ id: sectionId });
        }
      }
    }
  }

  //SectionTweaks should only be used really if subDefinition.sections was NULL/UNDEFINED/EMPTY really.
  //They will still work though.
  for (let i = 0; i < subDefinition.sectionTweaks.length; i++) {
    let sectionTweak = subDefinition.sectionTweaks[i];
    let sectionName = sectionTweak.id;
    if (isNull(sectionName))
      throw Error(
        "Invalid sectionTweak: View " +
          subDefinition.entityName +
          " and Index " +
          i.toString()
      );
    let viewSection = findSection(subDefinition, sectionName);
    if (!isNull(viewSection)) {
      for (let key in sectionTweak) {
        if (Object.prototype.hasOwnProperty.call(sectionTweak, key)) {
          if (isNull(sectionTweak[key])) {
            delete viewSection[key];
          } else {
            viewSection[key] = sectionTweak[key];
          }
        }
      }
    }
  }

  //Just in case the primaryField was inferred by the parent definition.
  //Make sure it doesn't change for the subDefinition due to rearranged fields.
  if (!isSearch) {
    let parentDisplayFields = findAllDisplayFields(definition.fields);
    let subDefDisplayFields = findAllDisplayFields(subDefinition.fields);
    for (let i = 0; i < parentDisplayFields.length; i++) {
      let parentDisplayField = parentDisplayFields[i];
      for (let j = 0; j < subDefDisplayFields.length; j++) {
        let subDefDisplayField = subDefDisplayFields[j];
        if (subDefDisplayField.fieldName === parentDisplayField.fieldName) {
          subDefDisplayField.primaryField = parentDisplayField.primaryField;
          break;
        }
      }
    }
  }

  if (sync) fixDefinitionFields(subDefinition);

  return subDefinition;
}

function fixWorkflowFormDefinition(definition, references) {
  fixWorkflowFormDefinitionFields(definition);
  return fixDefinition(definition, references);
}

function fixWorkflowFormDefinitionField(field) {
  delete field.table;
  delete field.linkId;
  delete field.valueId;
  delete field.primaryField;
  delete field.unique;
  delete field.dbIndex;
  delete field.viewRoles;
  delete field.addRoles;
  delete field.editRoles;
  if (
    field.type === "object" ||
    ((field.type === "array" || field.type === "json") &&
      field.inPlace &&
      field.definition)
  ) {
    fixWorkflowFormDefinitionFields(field.definition);
  }
}

function fixWorkflowFormDefinitionFields(definition) {
  delete definition.viewGroupRules;
  delete definition.addGroupRules;
  delete definition.editGroupRules;
  delete definition.deleteGroupRules;
  delete definition.viewRoles;
  delete definition.addRoles;
  delete definition.editRoles;
  delete definition.deleteRoles;
  delete definition.viewSchemaRoles;
  delete definition.entityName;
  delete definition.pluralName;
  delete definition.icon;
  delete definition.minimal;
  delete definition.showDetail;
  delete definition.leftActions;
  delete definition.readOnly;
  delete definition.type;
  delete definition.cacheStrategy;
  delete definition.navigation;
  delete definition.navigationPage;
  delete definition.homePage;
  delete definition.inherits;

  for (let i = 0; i < definition.fields.length; i++) {
    let field = definition.fields[i];
    fixWorkflowFormDefinitionField(field);
  }
}

function fixDefinitionFields(definition) {
  let definitionIsReadOnly = definition.readOnly;
  let mainTab = definition.sections[0].id;
  for (let i = 0; i < definition.fields.length; i++) {
    let field = definition.fields[i];
    if (
      !browser ||
      ((browser.name === "safari" || browser.name === "ios") &&
        field.widget === "time")
    )
      field.widget = "updown";
    if (isEmpty(field.fieldName))
      throw Error("Invalid fieldName: Field " + i.toString());
    if (isEmpty(field.type))
      throw Error("Invalid type: Field " + field.fieldName);
    if (
      field.type === "object" ||
      ((field.type === "array" || field.type === "json") && field.inPlace)
    ) {
      field.definition = fixDefinitionMinimal(field.definition);
      if (isNull(field.tab)) field.tab = mainTab;
      if (isNull(field.hidden)) field.hidden = false;
    }
    if (
      field.type === "string" &&
      (isEmpty(field.format) || field.format === "uppercase") &&
      !isEmpty(field.pattern)
    ) {
      field.pattern = field.pattern.toString();
    } else {
      field.pattern = undefined;
    }
    if (field.type !== "boolean" || isNull(field.forceChoice)) {
      field.forceChoice = undefined;
    }
    if (field.type !== "object") {
      if (isNull(field.viewRoles)) field.viewRoles = undefined;
      if (isNull(field.addRoles)) field.addRoles = undefined;
      if (isNull(field.editRoles)) field.editRoles = undefined;
      if (isNull(field.required)) field.required = false;
      if (isNull(field.readOnly) || !field.readOnly)
        field.readOnly = definitionIsReadOnly;
      if (isNull(field.default)) delete field.default;
      if (isNull(field.format)) delete field.format;
      if (
        isNull(field.inPlace) ||
        (field.type !== "array" && field.type !== "json")
      )
        delete field.inPlace;
      if (isNull(field.lookup) || field.inPlace) delete field.lookup;
      if (field.lookup) {
        if (isEmpty(field.lookupFields)) field.lookupFields = [];
        if (isEmpty(field.lookupFields)) delete field.additionalOnly;
        if (isEmpty(field.sortFields)) field.sortFields = [];
      } else {
        delete field.lookupFields;
        delete field.sortFields;
        delete field.additionalOnly;
      }
      if (isNull(field.isReference)) field.isReference = !isNull(field.lookup);
      if (isNull(field.lookupFilter)) delete field.lookupFilter;
      if (isNull(field.unique)) field.unique = false;
      if (isNull(field.primaryField)) field.primaryField = false;
      if (isNull(field.tab)) field.tab = mainTab;
      if (isNull(field.hidden)) field.hidden = false;
      if (isNull(field.showInMore)) field.showInMore = !field.hidden;
      if (isNull(field.widget)) delete field.widget;

      if (
        field &&
        field.type === "integer" &&
        isNull(field.lookup) &&
        isNull(field.widget)
      ) {
        field.widget = "updown";
      }
      if (
        field &&
        field.type === "number" &&
        isNull(field.lookup) &&
        isNull(field.widget)
      ) {
        field.widget = "updown";
      }
      if (
        field &&
        field.type === "integer" &&
        isNull(field.lookup) &&
        !isNull(field.widget) &&
        (field.widget === "radio" ||
          field.widget === "inlineradio" ||
          field.widget === "range")
      ) {
        if (isNull(field.minimum)) {
          field.minimum = 1;
        }
        if (isNull(field.maximum)) {
          field.maximum = 5;
        }
        if (isNull(field.multipleOf)) {
          field.multipleOf = 1;
        }
        if (field.maximum < field.minimum) {
          field.maximum = field.minimum;
        }
        if (field.minimum % field.multipleOf !== 0) {
          field.multipleOf = 1;
        }
        if (field.maximum % field.multipleOf !== 0) {
          field.multipleOf = 1;
        }
        if (field.default && field.default % field.multipleOf !== 0) {
          delete field.default;
        }
        if (field.default && field.default < field.minimum) {
          delete field.default;
        }
        if (field.default && field.default > field.maximum) {
          delete field.default;
        }
      } else {
        if (
          field &&
          !(
            field.type === "integer" &&
            isNull(field.lookup) &&
            field.widget !== "label"
          ) &&
          !(field.type === "array" && field.inPlace)
        ) {
          delete field.minimum;
          delete field.maximum;
        }
        delete field.multipleOf;
      }
      if (
        field.inPlace &&
        field.type === "json" &&
        field.widget === "JSONImportWidget"
      ) {
        if (isNull(field.startLine)) {
          delete field.startLine;
        } else {
          if (field.startLine < 0) {
            delete field.startLine;
          }
        }
        if (isNull(field.blankRows)) {
          delete field.blankRows;
        } else {
          if (field.blankRows < 1) {
            delete field.blankRows;
          }
        }
        if (isNull(field.startColumn)) {
          delete field.startColumn;
        } else {
          if (field.startColumn < 1) {
            delete field.startColumn;
          }
        }
        if (isNull(field.numColumns)) {
          delete field.numColumns;
        } else {
          if (field.numColumns < 1) {
            delete field.numColumns;
          }
        }
      } else {
        delete field.startLine;
        delete field.startColumn;
        delete field.numColumns;
      }
      if (isNull(field.bulkAppend))
        field.bulkAppend = field.type === "array" || field.type === "json";
      if (isNull(field.table)) delete field.table;
      if (isNull(field.linkId)) delete field.linkId;
      if (isNull(field.valueId)) delete field.valueId;
      if (isNull(field.referenceName)) delete field.referenceName;
      if (isNull(field.referenceCache)) delete field.referenceCache;
    }
  }
}

function fixDefinitionMinimal(definition) {
  if (!isEmpty(definition.entityName)) {
    if (isEmpty(definition.pluralName)) {
      definition.pluralName = definition.entityName + "s";
    }
  } else {
    delete definition.entityName;
    delete definition.pluralName;
  }
  if (
    isEmpty(definition.homePage) ||
    (definition.inherits !== "everygroup" &&
      definition.inherits !== "entityuser" &&
      definition.inherits !== "everyuser")
  ) {
    delete definition.homePage;
  }
  if (isNull(definition.guidId)) definition.guidId = false;
  if (isNull(definition.mode)) definition.mode = "";
  if (isNull(definition.viewName)) definition.viewName = definition.pluralName;
  if (isNull(definition.minimal)) definition.minimal = false;
  if (isNull(definition.sections)) definition.sections = [];
  if (isEmpty(definition.fields)) definition.fields = []; //throw Error("Invalid definition.  No fields assigned.")
  if (isNull(definition.showDetail)) definition.showDetail = true;
  if (isNull(definition.leftActions))
    definition.leftActions = ["add", "edit", "delete", "export", "actions"];
  if (isNull(definition.displayMode)) definition.displayMode = "None";
  if (isNull(definition.readOnly)) definition.readOnly = false;
  if (isNull(definition.rules)) definition.rules = [];
  if (isNull(definition.views)) definition.views = [];
  if (isNull(definition.warnings)) definition.warnings = [];
  if (isNull(definition.fieldTweaks)) definition.fieldTweaks = [];
  if (isNull(definition.sectionTweaks)) definition.sectionTweaks = [];
  if (isNull(definition.subDefinitions)) definition.subDefinitions = [];
  if (isNull(definition.filters)) definition.filters = [];

  let sections = definition.sections;
  let mainTab = "main";

  if (sections.length === 0) {
    sections.push({ id: mainTab });
  } else {
    mainTab = definition.sections[0].id;
  }

  if (isNull(definition.title)) definition.title = definition.pluralName;

  for (let i = 0; i < sections.length; i++) {
    if (isNull(sections[i].columns)) {
      delete sections[i].columns;
    }
  }

  fixDefinitionFields(definition);
  return definition;
}

function setPrimaryFields(fields, force) {
  let primaryFields = [];
  let uniqueFields = [];
  let nameFields = [];

  let displayFields = findAllDisplayFields(fields);

  for (let i = 0; i < displayFields.length; i++) {
    let displayField = displayFields[i];
    if (displayField.primaryField) {
      primaryFields.push(displayField);
    }
    if (displayField.unique) {
      uniqueFields.push(displayField);
    }
    if (displayField.fieldName === "name") {
      nameFields.push(displayField);
    }
  }
  if (primaryFields.length === 0) {
    //Honestly it's not really a primaryField.  But we will mark something for use in dropdowns mainly.
    if (uniqueFields.length > 0) {
      //Preferrably one which is "required" (i.e. doesn't allow nulls) otherwise the first one will do.
      let found = false;
      for (let i = 0; i < uniqueFields.length; i++) {
        if (uniqueFields[i].required) {
          uniqueFields[i].primaryField = true;
          found = true;
          break;
        }
      }
      if (!found && force) {
        uniqueFields[0].primaryField = true;
      }
    } else {
      if (nameFields.length > 0 && force) {
        nameFields[0].primaryField = true;
      } else {
        //Probably not unique, but have to choose something for dropdowns.
        if (displayFields.length > 0 && force) {
          displayFields[0].primaryField = true;
        }
      }
    }
  } else {
    if (primaryFields.length === 1) {
      primaryFields[0].unique = true;
    }
    for (let i = 0; i < primaryFields.length; i++) {
      primaryFields[i].required = true;
    }
  }
  let inPlaceFields = findAllInPlaceArrayFields(fields);
  for (let i = 0; i < inPlaceFields.length; i++) {
    setPrimaryFields(inPlaceFields[i].definition.fields, false);
  }
}

function fixDefinition(definition, references) {
  if (isEmpty(definition.cacheStrategy)) {
    definition.cacheStrategy = "always";
    definition.cacheable = "true";
  }
  if (definition.cacheStrategy === "role") {
    if (isNull(definition.cacheRoles)) definition.cacheRoles = [];
  } else {
    definition.cacheRoles = [];
  }
  if (isNull(definition.viewRoles)) definition.viewRoles = [];
  if (isNull(definition.editRoles)) definition.editRoles = [];
  if (isNull(definition.deleteRoles)) definition.deleteRoles = [];
  if (isNull(definition.viewSchemaRoles)) definition.viewSchemaRoles = [];
  if (isNull(definition.addRoles)) definition.addRoles = [];
  if (isNull(definition.viewGroupRules)) definition.viewGroupRules = [];
  if (isNull(definition.editGroupRules)) definition.editGroupRules = [];
  if (isNull(definition.addGroupRules)) definition.addGroupRules = [];
  if (isNull(definition.hidden)) definition.hidden = false;
  if (!isNull(definition.inherits)) {
    definition.inherits = definition.inherits.toLowerCase();
    if (
      definition.inherits !== "entityuser" &&
      definition.inherits !== "everygroup" &&
      definition.inherits !== "everyuser"
    ) {
      definition.inherits = null;
    }
  }

  fixDefinitionMinimal(definition);
  if (!isNull(references)) {
    definition.references = references;
  } else {
    if (isNull(definition.references)) {
      definition.references = [];
    }
  }

  setPrimaryFields(definition.fields, false);

  let views = [...definition.views];
  for (let i = 0; i < views.length; i++) {
    fixSubDefinition(definition, views[i]);
  }

  for (let i = 0; i < definition.views.length; i++) {
    let view = definition.views[i];
    fixDefinition(view, definition.references);
  }

  fixRules(definition);

  return definition;
}

export default {
  fixDefinition: fixDefinition,
  fixWorkflowFormDefinition: fixWorkflowFormDefinition,
  fixWorkflowFormDefinitionField: fixWorkflowFormDefinitionField,
  subDefinitionExists: subDefinitionExists,
  findPrimaryFields: findPrimaryFields,
  findFields: findFields,
  fieldCount: fieldCount,
  sectionCount: sectionCount,
  findField: findField,
  sectionExists: sectionExists,
  findSection: findSection,
  getSection: getSection,
  findAllDisplayFields: findAllDisplayFields,
  findAllDisplayFieldsMapped: findAllDisplayFieldsMapped,
  findAllNonObjectFields: findAllNonObjectFields,
  findView: findView,
  findSubDefinition: findSubDefinition,
  findParent: findParent,
  fieldExists: fieldExists,
  findAllFields: findAllFields,
  findAllSections: findAllSections,
  findAllTopLevelDBFields: findAllTopLevelDBFields,
  findSelectFieldName: findSelectFieldName,
  findAllTopLevelFields: findAllTopLevelFields,
  findAllLinkFields: findAllLinkFields,
  findAllTopLevelLookupFields: findAllTopLevelLookupFields,
  findAllInPlaceFields: findAllInPlaceFields,
  subDefinitionCount: subDefinitionCount,
  findAllFolderFields: findAllFolderFields,
  flattenDefinition: flattenDefinition,
  fixRules: fixRules,
  syncSubDefinitions: syncSubDefinitions,
  findFirstGeoField: findFirstGeoField,
  setNotMandatory: setNotMandatory,
};
