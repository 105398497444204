import React, { useState, useEffect } from "react";
import { View } from "react-native";
//import { Modal, FormGroup, FormControl, Button, Label } from 'react-bootstrap';
import { SUBDEFINITIONS } from "../../util/constants";
import { isNull, isEmpty } from "../../util/common";
import withWorkflowUpdate from "../thread/update.js";
import { withRouter } from "../../ReactRouter";
//import { useWorkflow } from '../../hooks/useWorkflow';

function getStateFromProps(item, entityFactory, isSearch) {
  let newState = {};
  let id = item?.id ? item.id : null;
  let EntityForm = entityFactory.getForm(undefined, isSearch);

  newState.currentId = id;
  newState.currentEntityFactory = entityFactory;
  newState.EntityForm = EntityForm;

  return newState;
}

const EntityFormComponent = (props) => {
  //const { executeAction } = useWorkflow();
  const { item, entityFactory, isSearch } = props;
  const [dirty, setDirty] = useState(false);
  const [state, setState] = useState(() => {
    return getStateFromProps(item, entityFactory, isSearch);
  });

  useEffect(() => {
    setState((oldState) => {
      let oldEntityId = oldState.currentId;
      let newEntityId = item?.id ? item.id : null;
      if (oldEntityId !== newEntityId && newEntityId) {
        setDirty(false);
        return getStateFromProps(item, entityFactory, isSearch);
      } else {
        setDirty((currentDirty) => {
          if (currentDirty) {
            let clearDirty = false;
            if (oldEntityId === newEntityId && newEntityId) {
              clearDirty = true;
            }
            if (clearDirty) return false;
          }
          return currentDirty;
        });
      }
      return oldState;
    });
  }, [item, entityFactory, isSearch]);

  const onFormChange = () => {
    if (!dirty) setDirty(true);
  };

  const onSubmit = () => {
    setDirty(false);
  };

  /*
  const onFormDismiss = () => {
    if (dirty) setDirty(false);
  }

  const executeActionOnCurrentItem = (workflow) => {
    let entityId = item ? item.id : null;
    if (!isNull(entityId)) {
      //Yes ... it seems connected props.onSubmit rather than this.onSubmit at the moment.
      executeAction(workflow, null, props.onSubmit, entityFactory, entityId);
    }
  }
  */
  const {
    match,
    layoutGraph,
    subDefinition,
    screenSize,
    allEntityFactories,
    notificationSystem,
    navigationDefaults,
    setFormState,
    formName,
    hideActionButton,
    renderLevel,
    drawerMenu,
    publicStyle,
    changeLoginState,
  } = props;
  const { currentEntityFactory, EntityForm } = state;

  let subDef = isEmpty(subDefinition)
    ? SUBDEFINITIONS.DEFAULT.NAME
    : subDefinition;
  let primaryCollection = isNull(currentEntityFactory)
    ? null
    : currentEntityFactory.getPrimaryCollection(layoutGraph);

  if (isNull(EntityForm)) {
    return <View />;
  } else {
    return (
      <EntityForm
        style={{ flex: 1, flexDirection: "column" }}
        changeLoginState={changeLoginState}
        onInvalidate={props.onInvalidate}
        thread={props.thread}
        isSearch={isSearch}
        isGrid={props.isGrid}
        searchDefinition={props.searchDefinition}
        readOnly={props.readOnly}
        hideWorkflowButtons={props.hideWorkflowButtons}
        item={item}
        entityId={props.entityId}
        subDefinition={subDef}
        onSubmit={onSubmit}
        allEntityFactories={allEntityFactories}
        entityFactory={currentEntityFactory}
        primaryCollection={primaryCollection}
        screenSize={screenSize}
        dirty={dirty}
        onChange={onFormChange}
        isNew={false}
        isModal={false}
        buttonsTop={true}
        bulkItems={[]}
        layoutGraph={layoutGraph}
        notificationSystem={notificationSystem}
        navigationDefaults={navigationDefaults}
        setFormState={setFormState}
        hideActionButton={hideActionButton}
        formName={formName}
        renderLevel={renderLevel}
        drawerMenu={drawerMenu}
        match={match}
        publicStyle={publicStyle}
      />
    );
  }
};

export default withRouter(withWorkflowUpdate(EntityFormComponent));
