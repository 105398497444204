/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { isNull } from "../util/common";
import Loading from "../components/loading";

function withEntityItem(BaseComponent) {
  const withEntityItemFn = function WithEntityItemInner(props) {
    const { item, entityFactory, layoutGraph, filter } = props;
    const resultingItem = useMemo(() => {
      let result = item;
      if (isNull(item)) {
        if (
          entityFactory &&
          layoutGraph &&
          filter &&
          filter.length > 0 &&
          filter[0].id &&
          filter[0].id.val
        ) {
          let primaryCollection = entityFactory.getPrimaryCollection(
            layoutGraph
          );

          if (primaryCollection) {
            result = primaryCollection.find((currentItem) => {
              return filter[0].id.val.indexOf(currentItem.id) >= 0;
            });
          }
        }
      }
      return result;
    }, [item, entityFactory, layoutGraph, filter]);

    if (!resultingItem) {
      return <Loading />;
    } else {
      return <BaseComponent {...props} item={resultingItem} />;
    }
  };

  return withEntityItemFn;
}

export default withEntityItem;
