import React, { useState, useEffect, useCallback } from "react";
import WizardPage from "../pages/wizardPage";

function withTaskInterrupt(BaseComponent) {
  const withTaskInterruptFn = function WithTaskInterruptInner(props) {
    const { layoutGraph } = props;

    const getInterruptTask = useCallback((allThreads, activeTask) => {
      if (!allThreads) return undefined;
      if (activeTask) {
        let result = allThreads.find((task) => task.id === activeTask.id);
        if (result?.priority === "interrupt") {
          return result;
        }
      }
      return allThreads.find((task) => task.priority === "interrupt");
    }, []);

    const [interruptTask, setInterruptTask] = useState(() => {
      return getInterruptTask(layoutGraph?.allThreads, null);
    });

    useEffect(() => {
      setInterruptTask((prevInterruptTask) => {
        return getInterruptTask(layoutGraph?.allThreads, prevInterruptTask);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutGraph?.allThreads]);

    if (interruptTask) {
      return <WizardPage {...props} task={interruptTask} />;
    } else {
      return <BaseComponent {...props} />;
    }
  };

  return withTaskInterruptFn;
}

export default withTaskInterrupt;
