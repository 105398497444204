/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { isEmpty, isNull } from "../../util/common.js";
import Constants from "expo-constants";
import fixDefinition from "../../util/definitions.js";
import EntityFactory from "../../entities/entityFactory";
import * as cloneDeep from "lodash/cloneDeep";

const CACHE_PREFIX = "ui_cache_";

const NO_FACTORIES = [];

function getEntityFactories(currentUser, entities) {
  const currentUserId = currentUser?.id;
  if (!currentUserId || isEmpty(entities)) return NO_FACTORIES;

  let allEntityFactories = [];
  //  references: [{ lookup: "Stakeholder", field: "primaryAddressId" },],

  let definitions = [];
  entities.forEach((entity) => {
    try {
      let definition = JSON.parse(entity.definition);
      definitions.push({ id: entity.id, definition });
    } catch (error) {
      console.log("ERROR!!!!!!! UNABLE TO PARSE ENTITY!", entity, error);
    }
  });

  definitions.forEach((definitionRow) => {
    let { id, definition } = definitionRow;
    let references = [];
    definitions.forEach((loopDefinitioRow) => {
      let referenceDefinition = loopDefinitioRow.definition;
      let referenceFields = fixDefinition.findAllDisplayFields(
        referenceDefinition.fields
      );
      referenceFields.forEach((field) => {
        if (field.lookup === definition.entityName && field.isReference) {
          references.push({
            lookup: referenceDefinition.entityName,
            field: field.fieldName,
          });
        }
      });
    });
    let origDefinition = definition;
    definition = fixDefinition.fixDefinition(
      cloneDeep(origDefinition),
      references
    );
    allEntityFactories.push(
      new EntityFactory(currentUser, id, definition, origDefinition)
    );
  });

  allEntityFactories.forEach((entityFactory) => {
    entityFactory.fixCacheable(allEntityFactories, []);
  });

  return allEntityFactories;
}

function getGraphQL(allEntityFactories) {
  let queryArray = [];
  allEntityFactories.forEach((entity) => {
    if (entity.entityIsValidForUIGraph()) {
      queryArray.push(entity.getGraphQueryString());
    }
  });
  return queryArray.join("\n");
}

function getStateFromProps(
  BaseComponent,
  client,
  userId,
  cacheVersion,
  allEntityFactories
) {
  if (!userId || isEmpty(allEntityFactories)) return {};

  let newState = null;
  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  console.log("UI getStateFromProps", userId);
  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  console.log("UI cacheVersion", cacheVersion);
  console.log(
    "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
  );
  let ui_cache = isNull(cacheVersion)
    ? null
    : localStorage.getItem(CACHE_PREFIX + userId);
  let isCached = !isEmpty(ui_cache);
  let cacheValue = null;
  if (isCached) {
    try {
      cacheValue = JSON.parse(ui_cache);
      if (
        cacheValue &&
        cacheValue.data &&
        (cacheValue.data.loading === true ||
          cacheValue.data.networkStatus !== 7)
      ) {
        isCached = false;
        cacheValue = null;
      }
    } catch (error) {
      //Unable to parse ... that's a bad sign.  Get it from network
      isCached = false;
      cacheValue = null;
    }
  }
  let entitiesGraphString = getGraphQL(allEntityFactories);
  let uiQuery = gql(
    `
    query UIQuery($globalFilter: String) {
      currentUser {
        id
      }
      ` +
      entitiesGraphString +
      `
    }
    `
  );

  if (isCached) {
    if (
      cacheValue.version === Constants.manifest.version &&
      cacheValue.cacheVersion === cacheVersion
    ) {
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      console.log("UI RESTORING FROM CACHE!!!!!", CACHE_PREFIX + userId);
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      client.writeQuery({
        query: uiQuery,
        data: cacheValue.data,
      });
    } else {
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      console.log("UI INVALID CACHE ... OLD VERSION", CACHE_PREFIX + userId);
      console.log(
        "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      cacheValue = null;
      isCached = false;
    }
  } else {
    console.log(
      "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
    );
    console.log("UI CACHE NOT FOUND", CACHE_PREFIX + userId);
    console.log(
      "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
    );
  }
  let fetchPolicy = isCached ? "cache-only" : "network-only";

  let variables = { userId };
  let entityQuery = graphql(uiQuery, {
    options() {
      return {
        fetchPolicy: fetchPolicy,
        fragments: {},
        variables: variables,
      };
    },
    props({ data }) {
      const { loading, networkStatus, subscribeToMore } = data;
      if (isNull(data.currentUser) && !isNull(data.previousData)) {
        data = data.previousData;
      }
      if (
        !isCached &&
        !isNull(data) &&
        !isNull(cacheVersion) &&
        loading !== true &&
        networkStatus === 7 &&
        data.currentUser?.id === userId
      ) {
        let cacheValue = { data: { ...data } };
        delete cacheValue.data.subscribeToMore;
        cacheValue.version = Constants.manifest.version;
        cacheValue.cacheVersion = cacheVersion;
        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        console.log("UI SAVING CACHE!!!!!", CACHE_PREFIX + userId);
        console.log(
          "++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
        );
        try {
          localStorage.setItem(
            CACHE_PREFIX + userId,
            JSON.stringify(cacheValue)
          );
        } catch (error) {
          //We've run out of cache ... probably too many users logged in on the same machine.  Clear and start again.
          localStorage.clear();
          localStorage.setItem(
            CACHE_PREFIX + userId,
            JSON.stringify(cacheValue)
          );
        }
      }
      let uiGraph = {
        loading: loading,
        subscribeToMore: subscribeToMore,
      };
      allEntityFactories.forEach((entity) => {
        if (entity.entityIsValidForUIGraph()) {
          uiGraph["all" + entity.definition.pluralName] =
            data["getAll" + entity.definition.pluralName];
        }
      });
      return {
        uiGraph,
      };
    },
  });
  let BaseComponentWithLayout = entityQuery(BaseComponent);
  newState = {
    BaseComponentWithLayout: BaseComponentWithLayout,
    userId,
    allEntityFactories,
  };

  return newState;
}

export default function withUIQuery(BaseComponent) {
  const withUIQueryFn = function WithUIQueryInner(props) {
    const { userGraph, entityGraph, client } = props;

    const currentUser = userGraph?.currentUser;
    const currentUserId = userGraph?.currentUser?.id;
    const cacheVersion = userGraph?.cacheVersion;
    const entities = entityGraph?.allEntities;
    const allEntityFactories = useMemo(
      () => getEntityFactories(currentUser, entities),
      [currentUser, entities]
    );
    const [state, setState] = useState(() => {
      return getStateFromProps(
        BaseComponent,
        client,
        currentUserId,
        cacheVersion,
        allEntityFactories
      );
    });

    useEffect(() => {
      setState((previousState) => {
        if (
          previousState?.userId === currentUserId &&
          previousState?.allEntityFactories === allEntityFactories
        )
          return previousState;
        return getStateFromProps(
          BaseComponent,
          client,
          currentUserId,
          cacheVersion,
          allEntityFactories
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserId, entities]);

    const { BaseComponentWithLayout } = state;
    if (BaseComponentWithLayout)
      return (
        <BaseComponentWithLayout
          {...props}
          allEntityFactories={allEntityFactories}
        />
      );
    return <BaseComponent {...props} />;
  };
  return withUIQueryFn;
}
